<template>
  <div class="full-height pa-5" style="margin-left: 56px">
    <div v-if="loading">
      <loader />
    </div>

    <v-snackbar v-model="snackbar" :timeout="snackBarTimeout" :color="color">{{
      snackBarText
    }}</v-snackbar>

    <div v-if="crm != null">
      <v-sheet :elevation="5" dark class="pa-10" style="position: relative">
        <div class="field-detail" v-if="crmObject != null">
          <v-row>
            <v-col
              lg="6"
              v-for="(value, propertyName, i) in mappedCrmObj"
              :key="i"
            >
              <v-text-field
                :value="value"
                :label="propertyName"
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-sheet>

      <v-sheet
        :elevation="5"
        dark
        class="pa-10 mt-5"
        style="position: relative"
      >
        <div>All Applications</div>
        <div>
          <v-data-table
            :headers="losHeaders"
            :items="userLoanApplicationsObj"
            :items-per-page="10"
            item-key="email"
            sort-by="createdAt"
            :sort-desc="true"
            class="elevation-1"
          >
            <template v-slot:item.loanStatus="{ item }">
              <div v-if="item.loanStatus">TBD</div>
            </template>

            <template v-slot:item.createdAt="{ item }">
              <div v-if="item.createdAt">{{ item.createdAt.toDate() }}</div>
            </template>

            <template v-slot:item.actions="{ item }">
              <router-link :to="`/los/applications/${item.loanID}`">
                <v-btn x-small outlined color="green accent-2">
                  View Details
                </v-btn>
              </router-link>
            </template>
          </v-data-table>
        </div>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import Loader from "../../../components/Loader.vue";
import { firebaseDB } from "../../../auth/firebase-auth";
export default {
  name: "crmid",
  components: {
    Loader,
  },
  props: ["id"],
  data() {
    return {
      loading: false,

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,

      userLoanApplicationsObj: [],

      crm: [],
      crmObject: null,
      mappedCrmObj: null,
      crmDetailsFieldMap: [
        {
          field: "firstName",
          value: "First Name",
        },
        {
          field: "lastName",
          value: "Last Name",
        },
        {
          field: "emailAddress",
          value: "Email Address",
        },
        {
          field: "phoneNumber",
          value: "Phone Number",
        },
        {
          field: "createdAt",
          value: "Created At",
        },
      ],

      losHeaders: [
        {
          text: "Email",
          align: "start",
          width: "200",
          value: "B1425", //B1425 - borrower's email
        },
        {
          text: "Reference #",
          align: "start",
          width: "100",
          value: "ReferenceNumber", //B1425 - borrower's email
        },
        {
          text: "Status",
          align: "start",
          width: "100",
          value: "loanStatus",
        },
        {
          text: "Created At",
          align: "start",
          width: "450",
          value: "createdAt",
        },
        {
          text: "Actions",
          align: "center",
          width: "100",
          value: "actions",
        },
      ],
    };
  },

  mounted() {
    this.fetchCrmDetail();
  },

  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },

  methods: {
    async fetchCrmDetail() {
      this.loading = true;
      try {
        const crmRef = await firebaseDB
          .firestore()
          .collection("contacts")
          .doc(this.$props.id)
          .get();
        this.crmObject = { ...crmRef.data() };
        this.crmObject.createdAt = this.crmObject.createdAt.toDate();
        await this.mapfields();

        const loanApplicationRef = await firebaseDB
          .firestore()
          .collection("loans")
          .where("contactRefId", "==", this.$props.id)
          .get();
        let eventsResult = [];
        loanApplicationRef.docs.forEach((doc) => {
          let loanData = doc.data();
          loanData["loanID"] = doc.id;
          eventsResult.push(loanData);
        });

        this.userLoanApplicationsObj = eventsResult;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("Error in fetching crm detail:\n" + error);
      }
    },

    //map fields
    async mapfields() {
      var result = Object.entries(this.crmObject);
      await this.crmDetailsFieldMap.map((crmmapobj) => {
        result.forEach((el) => {
          if (el[0] === crmmapobj.field) {
            el[0] = crmmapobj.value;
          }
        });
      });
      let reverse = result
        .map(([e1, v1]) => ({ [e1]: v1 }))
        .reduce((pv, cv) => {
          return Object.assign(pv, cv);
        });
      this.mappedCrmObj = reverse;
    },
  },
};
</script>
<style lang="scss" scoped>
.full-height {
  min-height: 100vh;
}

.v-text-field .v-label {
  overflow: visible !important;
  top: 1% !important;
}

.label {
  color: #c7c7c7 !important;
}

.item {
  cursor: pointer;
}

.fa {
  margin: 3px;
  font-size: 1rem;
}

.v-tab {
  text-transform: none !important;
}

.tab-nav-btn {
  background: #212121;
  width: 100%;
  max-width: 300px;
  padding: 0 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;

  a {
    text-decoration: none;
    color: #69f0ae;
  }
}
</style>
