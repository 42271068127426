<template>
  <div style="margin-left: 56px">
    <v-container class="conatiner-section">
      <v-sheet :elevation="5" dark class="mx-auto pa-5">
        <v-snackbar
          v-model="snackbar"
          :timeout="snackBarTimeout"
          :color="color"
          >{{ snackBarText }}</v-snackbar
        >
        <v-form v-model="valid" ref="form">
          <v-container>
            <v-text-field
              :readonly="isPreview"
              :rules="[(v) => !!v || 'AutoConfigId is required']"
              v-model="AutoConfigId"
              label="AutoConfigId"
              outlined
              color="white"
            ></v-text-field>

            <v-autocomplete
              v-model="cmpSearchModel"
              :items="cmpItems"
              :loading="cmpIsLoading"
              :search-input.sync="cmpSearch"
              hide-no-data
              hide-selected
              item-text="Name"
              item-value="Id"
              label="Company Id"
              :rules="[(v) => !!v || 'Company is required']"
              placeholder="Start typing to Search Company"
              return-object
              :readonly="isPreview"
              outlined
              color="white"
            ></v-autocomplete>

            <v-autocomplete
              v-model="propSearchModel"
              :items="propItems"
              :loading="propIsLoading"
              :search-input.sync="propSearch"
              hide-no-data
              hide-selected
              item-text="Name"
              item-value="Id"
              label="Property Id"
              :rules="[(v) => !!v || 'Property is required']"
              placeholder="Start typing to Search Property"
              return-object
              :readonly="isPreview"
              outlined
              color="white"
            ></v-autocomplete>

            <div class="config-details">
              <v-subheader class="mb-2 pa-0">Config JSON</v-subheader>
              <MonacoEditor
                v-model="ConfigJson"
                width="100%"
                height="200"
                theme="vs-dark"
                language="json"
                class="mb-12"
                :options="options"
              ></MonacoEditor>
            </div>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="white"
                outlined
                @click="$router.push({ name: 'schedule' })"
                >{{ isPreview ? "Back" : "Cancel" }}</v-btn
              >
              <v-btn color="blue darken-1" v-if="!isPreview" @click="create">{{
                isEdit ? "Update" : "Create"
              }}</v-btn>
            </v-card-actions>
          </v-container>
        </v-form>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import MonacoEditor from "monaco-editor-vue";

export default {
  components: {
    MonacoEditor,
  },
  name: "AutoConfigAssignForm",
  props: ["id", "preview", "autoConfigId"],
  data() {
    return {
      isEdit: false,
      isPreview: false,
      valid: false,
      ConfigJson: null,
      AutoConfigId: "",

      options: {
        //Monaco Editor Options
      },

      //companySearch
      cmpSearch: null,
      cmpSearchModel: null,
      cmpEntries: [],
      cmpIsLoading: false,

      //propertySearch
      propSearch: null,
      propSearchModel: null,
      propEntries: [],
      propIsLoading: false,

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,
    };
  },
  computed: {
    cmpFields() {
      if (!this.cmpSearchModel) return [];

      return Object.keys(this.cmpSearchModel).map((key) => {
        return {
          key,
          value: this.cmpSearchModel[key] || "n/a",
        };
      });
    },
    cmpItems() {
      return this.cmpEntries.map((entry) => {
        const Name = entry.Name;
        return Object.assign({}, entry, { Name });
      });
    },
    propFields() {
      if (!this.propSearchModel) return [];

      return Object.keys(this.propSearchModel).map((key) => {
        return {
          key,
          value: this.propSearchModel[key] || "n/a",
        };
      });
    },
    propItems() {
      return this.propEntries.map((entry) => {
        const Name = entry.Name;
        return Object.assign({}, entry, { Name });
      });
    },
  },
  watch: {
    cmpSearch(val) {
      if (this.cmpSearch != (this.cmpSearchModel && this.cmpSearchModel.Name)) {
        if (!val) {
          return;
        }
        this.clearcmpEntries();
        this.cmpIsLoading = true;
        this.fetchCmpEntriesDebounced();
      }
    },
    propSearch(val) {
      if (
        this.propSearch != (this.propSearchModel && this.propSearchModel.Name)
      ) {
        if (!val) {
          return;
        }
        this.clearpropEntries();
        this.propIsLoading = true;
        this.fetchPropEntriesDebounced();
      }
    },
  },
  methods: {
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    create() {
      let isValidJson = true;
      if (this.ConfigJson != null) {
        isValidJson = this.isJsonString(this.ConfigJson);
        !isValidJson && alert("Please enter valid ConfigJson");
      }
      if (this.$refs.form.validate() && isValidJson) {
        if (this.isEdit) {
          //Update
          axios
            .put(
              `${process.env.VUE_APP_BASEURL}/scheduler/autoconfigassignment/${this.id}`,
              {
                DataToUpdate: {
                  ...(this.ConfigJson != null
                    ? { ConfigJson: this.ConfigJson }
                    : {}),
                  AutoConfigId: this.AutoConfigId,
                  CompanyId: this.cmpSearchModel
                    ? this.cmpSearchModel.Id
                    : null,
                  TargetId: this.propSearchModel
                    ? this.propSearchModel.Id
                    : null,
                },
              }
            )
            .then((response) => {
              if (response.data.status) {
                this.color = "success";
                this.snackBarText = response.data.message;
                this.snackbar = true;
                this.$router.push({
                  name: "schedule",
                });
              } else {
                this.color = "error";
                this.snackBarText = response.data.message;
                this.snackbar = true;
              }
            });
        } else {
          //Create
          axios
            .post(
              `${process.env.VUE_APP_BASEURL}/scheduler/autoconfigassignment`,
              {
                ...(this.ConfigJson != null
                  ? { ConfigJson: this.ConfigJson }
                  : {}),
                AutoConfigId: this.AutoConfigId,
                CompanyId: this.cmpSearchModel ? this.cmpSearchModel.Id : null,
                TargetId: this.propSearchModel ? this.propSearchModel.Id : null,
              }
            )
            .then((response) => {
              if (response.data.status) {
                this.color = "success";
                this.snackBarText = response.data.message;
                this.snackbar = true;
                this.$router.push({
                  name: "schedule",
                });
              } else {
                this.color = "error";
                this.snackBarText = response.data.message;
                this.snackbar = true;
              }
            });
        }
      }
    },
    clearcmpEntries() {
      this.cmpEntries = [];
    },
    fetchCmpEntriesDebounced() {
      clearTimeout(this._searchCmpTimerId);
      this._searchCmpTimerId = setTimeout(() => {
        this.fetchCmpEntries();
      }, 500);
    },
    fetchCmpEntries() {
      axios
        .get(process.env.VUE_APP_BASEURL + "/company?search=" + this.cmpSearch)
        .then((response) => {
          if (response.data.status) {
            this.cmpEntries = response.data.data.companies;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.cmpIsLoading = false));
    },
    clearpropEntries() {
      this.propEntries = [];
    },
    fetchPropEntriesDebounced() {
      clearTimeout(this._searchPropTimerId);
      this._searchPropTimerId = setTimeout(() => {
        this.fetchPropEntries();
      }, 500);
    },
    fetchPropEntries() {
      axios
        .get(
          process.env.VUE_APP_BASEURL + "/property?search=" + this.propSearch
        )
        .then((response) => {
          if (response.data.status) {
            this.propEntries = response.data.data.properties;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.propIsLoading = false));
    },
  },
  mounted() {
    if (typeof this.id !== "undefined") {
      if (this.preview == true) {
        this.isPreview = true;
      } else {
        this.isEdit = true;
      }
      axios
        .get(
          process.env.VUE_APP_BASEURL +
            "/scheduler/autoconfigassignment/" +
            this.id
        )
        .then((response) => {
          if (response.data.status) {
            let configdata = response.data.data.config;
            this.AutoConfigId = configdata.AutoConfigId;
            if (configdata.CompanyId) {
              this.cmpSearch = configdata.Company.Name;
              this.cmpSearchModel = configdata.Company;
              this.cmpEntries.push(configdata.Company);
            }
            if (configdata.TargetId) {
              this.propSearch = configdata.Target.Name;
              this.propSearchModel = configdata.Target;
              this.propEntries.push(configdata.Target);
            }
            this.ConfigJson = configdata.ConfigJson;
          }
        });
    } else {
      if (typeof this.autoConfigId !== "undefined") {
        this.AutoConfigId = this.autoConfigId;
      }
    }
  },
};
</script>
