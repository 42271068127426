<template>
  <div class="full-height pa-10" style="margin-left: 56px">
    <div v-if="loading">
      <loader />
    </div>

    <v-snackbar v-model="snackbar" :timeout="snackBarTimeout" :color="color">{{
      snackBarText
    }}</v-snackbar>

    <div class="mx-auto rounded-0 full-height">
      <!-- TMA Content -->
      <v-sheet class="pa-4" color="grey darken-3" data-app>
        <v-row class="align-center justify-center">
          <v-col>
            <v-text-field
              class="pa-2 my-custom-search"
              v-model="search"
              label="Search Loan Applications..."
              flat
              solo-inverted
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-sheet>
      <div>
        <v-data-table
          :headers="loanHeaders"
          :items="los"
          :items-per-page="50"
          item-key="id"
          :search="search"
          sort-by="createdAt"
          :sort-desc="true"
          class="elevation-1"
        >
          <template v-slot:item.createdAt="{ item }">
            <div v-if="item.createdAt">
              {{
                item.createdAt
                  .toDate()
                  .toLocaleString("en-US", {
                    year: "2-digit",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })
                  .replace(/\//g, "-")
              }}
            </div>
          </template>
          <template v-slot:item.loanStatus="{ item }">
            <div v-if="item.loanStatus">TBD</div>
          </template>
          <template v-slot:item.isProcessApplication="{ item }">
            <div>
              {{
                item.isProcessApplication ? item.isProcessApplication : false
              }}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <router-link :to="`/los/loans/${item.id}`">
              <v-btn x-small outlined color="green accent-2">
                View Details
              </v-btn>
            </router-link>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../../../components/Loader.vue";
import { firebaseDB } from "../../../auth/firebase-auth";

export default {
  template: "#app-template",
  name: "loans",
  components: {
    Loader,
  },
  data() {
    return {
      loanData: [],
      applicationData: {},
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      loading: true,
      color: "success",
      snackbar: false,
      snackBarText: "",
      tab: "",
      snackBarTimeout: 2000,
      search: "",
      los: [],

      loanHeaders: [
        {
          text: "Email",
          align: "start",
          width: "100",
          value: "email",
        },
        {
          text: "Reference #",
          align: "start",
          width: "50",
          value: "ReferenceNumber",
        },
        {
          text: "Address",
          align: "start",
          width: "100",
          value: "street",
        },
        {
          text: "City",
          align: "start",
          width: "50",
          value: "city",
        },
        {
          text: "State",
          align: "start",
          width: "50",
          value: "state",
        },
        // {
        //   text: "Zipcode",
        //   align: "start",
        //   width: "50",
        //   value: "P1005",
        // },
        {
          text: "Created At",
          align: "start",
          width: "100",
          value: "createdAt",
        },
        {
          text: "Processed",
          align: "center",
          width: "100",
          value: "isProcessApplication",
        },
        {
          text: "Actions",
          align: "center",
          width: "100",
          value: "actions",
        },
      ],
    };
  },
  mounted() {
    this.loading = true;
    let start = Date.now();
    console.log("Started : ", start);
    this.fetchLoanData();
  },
  methods: {
    async fetchLoanData() {
      const losRef = firebaseDB
        .firestore()
        .collection("loans")
        .where("source", "==", "app");
      try {
        const { docs } = await losRef.get();
        this.los = docs.map((doc) => {
          const { id } = doc;
          const data = doc.data();
          return { id, ...data };
        });
        this.loading = false;
      } catch (error) {
        console.log("Error in fetching los data:\n" + error);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.my-custom-search {
  width: 15 00px;
  font-size: 16px;
}
</style>
