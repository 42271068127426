<template>
  <div class="full-height" style="margin-left: 56px">
    <div v-if="loading">
      <loader />
    </div>

    <div class="mx-auto rounded-0 full-height">
      <v-sheet
        :elevation="5"
        dark
        class="ma-12 py-8 px-10"
        style="position: relative"
      >
        <h3 class="mb-5 pb-3">
          Zipcode : {{ zipCode }} | Crebrid Rank :
          {{ crebridRank.toFixed(2) }} | Market Share Rank:
          {{ marketShareRank }} out of {{ totalRecords }} | Market Share:
          {{ marketShare }}
        </h3>
        <v-snackbar
          v-model="snackbar"
          :timeout="snackBarTimeout"
          :color="color"
          >{{ snackBarText }}</v-snackbar
        >
        <div id="mapdiv"></div>
        <div>
          <div
            v-if="metadataobj"
            class="d-flex flex-column full-width mt-10 mb-10"
          >
            <v-row>
              <v-col sm="12" lg="4">
                <v-card color="grey darken-3">
                  <v-card-text>
                    <p class="text-h5 text--primary">
                      {{ metadataobj.num_flips }}
                    </p>
                    <span># of Flips</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col sm="12" lg="4">
                <v-card color="grey darken-3">
                  <v-card-text>
                    <p class="text-h5 text--primary">
                      {{ metadataobj.num_unique_first_grantors }}
                    </p>
                    <span># of Flippers</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col sm="12" lg="4">
                <v-card color="grey darken-3">
                  <v-card-text>
                    <p class="text-h5 text--primary">
                      {{ metadataobj.num_unique_first_mortgage_lenders }}
                    </p>
                    <span># of Lending Companies</span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div class="mt-10">
            <v-expansion-panels>
              <v-expansion-panel v-if="itemsGrantor" flat>
                <v-expansion-panel-header>
                  <div class="text-subtitle-1 white--text">Leads List</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-sheet class="pa-4" color="grey darken-4" data-app>
                    <div class="d-flex align-center">
                      <v-text-field
                        v-model="searchGrantor"
                        label="Search Data..."
                        flat
                        solo-inverted
                        hide-details
                        clearable
                        clear-icon="mdi-close-circle-outline"
                      ></v-text-field>
                    </div>
                  </v-sheet>
                  <div>
                    <v-data-table
                      :headers="headersIndexFlip"
                      :items="itemsGrantor"
                      :items-per-page="10"
                      :search="searchGrantor"
                      class="elevation-1"
                    >
                      <template v-slot:item.avgPropertyCost="{ item }">
                        <div v-if="item.avgPropertyCost">
                          ${{
                            parseInt(item.avgPropertyCost).toLocaleString(
                              "en-US"
                            )
                          }}
                        </div>
                        <div v-else>-</div>
                      </template>
                      <template v-slot:item.latestActivity="{ item }">
                        <div v-if="item.latestActivity">
                          {{
                            new Date(item.latestActivity)
                              .toLocaleDateString("en-US", {
                                year: "2-digit",
                                month: "2-digit",
                                day: "2-digit",
                              })
                              .split("/")
                              .join("-")
                          }}
                        </div>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <router-link
                          :to="{
                            path: `/marketing/leads/${item.column1}`,
                            query: {
                              leadsData: JSON.stringify({
                                FLIPGrantor1NameFull: item.column1,
                                NumberOfFlips: item.count,
                                LatestActivity: item.latestActivity,
                                AverageTransferAmount: item.avgPropertyCost,
                              }),
                            },
                          }"
                        >
                          <v-btn
                            x-small
                            outlined
                            color="green accent-2"
                            class="black--text"
                          >
                            View Details
                          </v-btn>
                        </router-link>
                      </template>
                    </v-data-table>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels v-if="itemsLender">
              <v-expansion-panel flat>
                <v-expansion-panel-header>
                  <div class="text-subtitle-1 white--text">
                    Lending Companies
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-sheet class="pa-4" color="grey darken-4" data-app>
                    <div class="d-flex align-center">
                      <v-text-field
                        v-model="searchLender"
                        label="Search Data..."
                        flat
                        solo-inverted
                        hide-details
                        clearable
                        clear-icon="mdi-close-circle-outline"
                      ></v-text-field>
                    </div>
                  </v-sheet>
                  <div>
                    <v-data-table
                      :headers="headersIndex"
                      :items="itemsLender"
                      :items-per-page="10"
                      :search="searchLender"
                      class="elevation-1"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-btn
                          x-small
                          outlined
                          color="green accent-2"
                          class="black--text"
                          @click="
                            (lenderDialog = true), (lenderContent = item.value)
                          "
                        >
                          View Details
                        </v-btn>
                      </template>
                    </v-data-table>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-expansion-panels>
              <v-expansion-panel flat>
                <v-expansion-panel-header>
                  <div class="text-subtitle-1 white--text">Annual Data</div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-expansion-panels>
                    <v-expansion-panels
                      v-if="metadataobj && metadataobj.annual"
                    >
                      <v-expansion-panel flat>
                        <v-expansion-panel-header expand-icon="mdi-menu-down">
                          <div class="text-subtitle-1 white--text">
                            Annual Flipping Activity
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <!-- <p>{{ metadataobj.annual.num_flips_per_year }}</p> -->
                          <v-sheet class="pa-4" color="grey darken-4" data-app>
                            <div class="d-flex align-center">
                              <v-text-field
                                v-model="searchNumFlip"
                                label="Search Data..."
                                flat
                                solo-inverted
                                hide-details
                                clearable
                                clear-icon="mdi-close-circle-outline"
                              ></v-text-field>
                            </div>
                          </v-sheet>
                          <div>
                            <v-data-table
                              :headers="headersYear"
                              :items="itemsNumFlip"
                              :items-per-page="10"
                              :search="searchNumFlip"
                              sort-by="Year"
                              :sort-desc="true"
                              class="elevation-1"
                            />
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels
                      v-if="metadataobj && metadataobj.annual"
                    >
                      <v-expansion-panel flat>
                        <v-expansion-panel-header expand-icon="mdi-menu-down">
                          <div class="text-subtitle-1 white--text">
                            Average Purchase Amounts
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <!-- <p>{{ metadataobj.annual.purchase_amnt }}</p> -->
                          <v-sheet class="pa-4" color="grey darken-4" data-app>
                            <div class="d-flex align-center">
                              <v-text-field
                                v-model="searchPurchase"
                                label="Search Data..."
                                flat
                                solo-inverted
                                hide-details
                                clearable
                                clear-icon="mdi-close-circle-outline"
                              ></v-text-field>
                            </div>
                          </v-sheet>
                          <div>
                            <v-data-table
                              :headers="headersYear"
                              :items="itemsPurchase"
                              :items-per-page="10"
                              :search="searchPurchase"
                              sort-by="Year"
                              :sort-desc="true"
                              class="elevation-1"
                            >
                              <template v-slot:item.value="{ item }">
                                {{ currencyFormatter.format(item.value) }}
                              </template>
                            </v-data-table>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels
                      v-if="metadataobj && metadataobj.annual"
                    >
                      <v-expansion-panel flat>
                        <v-expansion-panel-header expand-icon="mdi-menu-down">
                          <div class="text-subtitle-1 white--text">
                            Average Sale Amounts
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <!-- <p>{{ metadataobj.annual.sale_amnt }}</p> -->
                          <v-sheet class="pa-4" color="grey darken-4" data-app>
                            <div class="d-flex align-center">
                              <v-text-field
                                v-model="searchSale"
                                label="Search Data..."
                                flat
                                solo-inverted
                                hide-details
                                clearable
                                clear-icon="mdi-close-circle-outline"
                              ></v-text-field>
                            </div>
                          </v-sheet>
                          <div>
                            <v-data-table
                              :headers="headersYear"
                              :items="itemsSale"
                              :items-per-page="10"
                              :search="searchSale"
                              sort-by="Year"
                              :sort-desc="true"
                              class="elevation-1"
                            >
                              <template v-slot:item.value="{ item }">
                                {{ currencyFormatter.format(item.value) }}
                              </template>
                            </v-data-table>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div class="mt-10" v-if="zipcodeObj">
            <h3 class="mb-5">Zipcode Raw Data - {{ zipCode }}</h3>
            <MonacoEditor
              v-model="zipcodeObj"
              width="100%"
              height="500"
              theme="vs-dark"
              language="json"
              :options="options"
            ></MonacoEditor>
          </div>
          <div class="mt-10" v-if="editorMetadata">
            <h3 class="mb-5">Meta Data</h3>
            <MonacoEditor
              v-model="editorMetadata"
              width="100%"
              height="500"
              theme="vs-dark"
              language="json"
              :options="options"
            ></MonacoEditor>
          </div>
          <!-- <div class="mt-10" v-if="editorGeodata">
            <h3 class="mb-5">Geo Data</h3>
            <MonacoEditor
              v-model="editorGeodata"
              width="100%"
              height="500"
              theme="vs-dark"
              language="json"
              :options="options"
            ></MonacoEditor>
          </div> -->
          <!-- <div class="mt-10" v-if="editorCrebrid">
            <h3 class="mb-5">Crebrid Data</h3>
            <MonacoEditor
              v-model="editorCrebrid"
              width="100%"
              height="500"
              theme="vs-dark"
              language="json"
              :options="options"
            ></MonacoEditor>
          </div> -->
          <div class="mt-10" v-if="editorFlipsGrantor">
            <h3 class="mb-5">Flips Grantor Data</h3>
            <MonacoEditor
              v-model="editorFlipsGrantor"
              width="100%"
              height="500"
              theme="vs-dark"
              language="json"
              :options="options"
            ></MonacoEditor>
          </div>
          <div class="mt-10" v-if="editorFlipsLender">
            <h3 class="mb-5">Flips Lender Data</h3>
            <MonacoEditor
              v-model="editorFlipsLender"
              width="100%"
              height="500"
              theme="vs-dark"
              language="json"
              :options="options"
            ></MonacoEditor>
          </div>
          <!-- <div class="mt-10" v-if="editorRawdata">
            <h3 class="mb-5">Raw Flips Data</h3>
            <MonacoEditor
              v-model="editorRawdata"
              width="100%"
              height="500"
              theme="vs-dark"
              language="json"
              :options="options"
            ></MonacoEditor>
          </div> -->
          <div class="mt-10" v-if="editorRawCalyxdata">
            <h3 class="mb-5">Raw Calyx Data</h3>
            <MonacoEditor
              v-model="editorRawCalyxdata"
              width="100%"
              height="500"
              theme="vs-dark"
              language="json"
              :options="options"
            ></MonacoEditor>
          </div>
          <div class="mt-10" v-if="editorCommunitydata">
            <div class="" style="display: flex; justify-content: space-between">
              <h3 class="community-title">Community Data</h3>
              <div class="community-header">
                <span v-if="updatedAt">Last updated at: {{ updatedAt }}</span>
                <v-btn
                  color="green accent-2"
                  class="black--text"
                  text
                  @click="updateCommunity"
                  >Refresh Data</v-btn
                >
              </div>
            </div>
            <MonacoEditor
              v-model="editorCommunitydata"
              width="100%"
              height="500"
              theme="vs-dark"
              language="json"
              :options="options"
            ></MonacoEditor>
          </div>
          <div class="mt-10" v-if="editorsalesTrendData">
            <div class="" style="display: flex; justify-content: space-between">
              <h3 class="community-title">Sales Trend Data</h3>
              <div class="community-header">
                <span v-if="updatedAtSales"
                  >Last updated at: {{ updatedAtSales }}</span
                >
                <v-btn
                  color="green accent-2"
                  class="black--text"
                  text
                  @click="updateSalesTrend"
                  >Refresh Data</v-btn
                >
              </div>
            </div>
            <MonacoEditor
              v-model="editorsalesTrendData"
              width="100%"
              height="500"
              theme="vs-dark"
              language="json"
              :options="options"
            ></MonacoEditor>
          </div>
        </div>
        <!--Dialog-->
        <v-dialog v-model="grantorDialog" width="75%">
          <v-card>
            <v-card-title>
              <span class="headline">Grantor Data</span>
            </v-card-title>
            <v-card-text>
              <pre v-html="grantorContent"></pre>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="(grantorDialog = false), (grantorContent = null)"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="lenderDialog" width="75%">
          <v-card>
            <v-card-title>
              <span class="headline">Lender Data</span>
            </v-card-title>
            <v-card-text>
              <pre v-html="lenderContent"></pre>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="(lenderDialog = false), (lenderContent = null)"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import Loader from "../../components/Loader.vue";
import MonacoEditor from "monaco-editor-vue";
import axios from "axios";

export default {
  props: ["id"],
  components: {
    MonacoEditor,
    Loader,
  },
  name: "marketingid",
  data() {
    return {
      loading: true,
      mapStyle: "roadmap",
      totalRecords: null,
      latitude: null,
      longitude: null,
      placeId: null,
      map: null,
      marker: null,
      cityCircle: null,
      zipCode: null,
      crebridRank: null,
      zipcodeObj: null,
      marketShareRank: null,
      marketShare: null,

      options: {},
      metadataobj: null,
      apiobj: null,

      //editor data
      editorMetadata: null,
      editorGeodata: null,
      editorCrebrid: null,
      editorFlipsGrantor: null,
      editorFlipsLender: null,
      editorRawdata: null,
      editorRawCalyxdata: null,
      editorCommunitydata: null,
      updatedAt: null,
      updatedAtSales: null,
      editorsalesTrendData: null,

      headersYear: [
        {
          text: "Year",
          align: "start",
          width: "100",
          value: "column1",
        },
        {
          text: "Value",
          align: "start",
          width: "100",
          value: "value",
        },
      ],

      searchNumFlip: "",
      itemsNumFlip: [],

      searchPurchase: "",
      itemsPurchase: [],

      searchSale: "",
      itemsSale: [],
      headersIndexFlip: [
        {
          text: "Full Name",
          align: "start",
          width: "200",
          value: "column1",
        },
        {
          text: "No of Flips",
          align: "start",
          width: "200",
          value: "count",
        },
        {
          text: "Latest Activity",
          align: "start",
          width: "200",
          value: "latestActivity",
        },
        {
          text: "Average Property Cost",
          align: "start",
          width: "200",
          value: "avgPropertyCost",
        },
        {
          text: "Actions",
          align: "start",
          width: "100",
          value: "actions",
        },
      ],
      headersIndex: [
        {
          text: "Index",
          align: "start",
          width: "200",
          value: "column1",
        },
        {
          text: "Count",
          align: "start",
          width: "200",
          value: "count",
        },
        {
          text: "Actions",
          align: "start",
          width: "100",
          value: "actions",
        },
      ],
      itemsGrantor: [],
      searchGrantor: "",
      grantorDialog: false,
      grantorContent: null,

      itemsLender: [],
      searchLender: "",
      lenderDialog: false,
      lenderContent: null,

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,
    };
  },
  async mounted() {
    this.currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
    this.totalRecords = this.$route?.query?.totalRecords;
    this.latitude = Number(this.$route?.query?.latitude) || "";
    this.longitude = Number(this.$route?.query?.longitude) || "";
    this.placeId = this.$route.query.placeId;
   
    await this.getZipCodeObj();
    await this.getZipcodeData();
    await this.loadMap();
  },
  methods: {
    async getZipCodeObj() {
      this.loading = true;
      const zipcode = this.$props.id;

      try {
        const key = "21b77cec7cb516c40d9d7216ae74e3c3";
        await axios
          .get(
            `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/marketing/zipcode/${zipcode}?key=${key}`
          )
          .then((response) => {
            this.zipcodeObj = response.data;
            console.log("ZipCodeObj : ", this.zipcodeObj);
            this.zipCode = zipcode;
            this.crebridRank = response.data.ziprank;
            this.marketShareRank = response.data.WildcatMarketShareRank;
            this.marketShare = response.data.WildcatMarketShare;
            console.log("CrebridRank : ", this.crebridRank);
            this.zipcodeObj = JSON.stringify(response.data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("Error in fetching Leads data:\n" + error);
      }
    },
    async getZipcodeData() {
      this.loading = true;
      try {
        const key = "21b77cec7cb516c40d9d7216ae74e3c3";

        const zipcode = this.$props.id;
        console.log("zipcode--", zipcode);

        if (zipcode) {
          await axios
            .get(
              `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/market?zipcode=${zipcode}&key=${key}`
              // `http://127.0.0.1:3000/market?zipcode=${zipcode}&key=${key}`
            )
            .then(async (response) => {
              if (response && response.data) {
                const apiData = response.data;

                this.editorMetadata = apiData["01_metadata"]
                  ? JSON.stringify(apiData["01_metadata"])
                  : null;

                // this.editorGeodata = apiData["02_geo_data"]
                //   ? JSON.stringify(apiData["02_geo_data"])
                //   : null;

                // this.editorCrebrid = apiData["03_crebrid_area"]
                //   ? JSON.stringify(apiData["03_crebrid_area"])
                //   : null;

                this.editorFlipsGrantor = apiData["04_flips_by_first_grantor"]
                  ? JSON.stringify(apiData["04_flips_by_first_grantor"])
                  : null;

                this.editorFlipsLender = apiData[
                  "05_flips_by_first_mortgage_lender"
                ]
                  ? JSON.stringify(apiData["05_flips_by_first_mortgage_lender"])
                  : null;

                // this.editorRawdata = apiData["06_raw_flip_data"]
                //   ? JSON.stringify(apiData["06_raw_flip_data"])
                //   : null;

                this.editorRawCalyxdata = apiData["07_raw_calyx_data"]
                  ? JSON.stringify(apiData["07_raw_calyx_data"])
                  : null;

                //assign monaco editor data END

                this.metadataobj = response.data["01_metadata"]
                  ? response.data["01_metadata"]
                  : null;
                this.itemsNumFlip =
                  this.metadataobj && this.metadataobj.annual
                    ? await this.modifyObjFunc(this.metadataobj.annual, 1)
                    : null;
                this.itemsPurchase =
                  this.metadataobj && this.metadataobj.annual
                    ? await this.modifyObjFunc(this.metadataobj.annual, 2)
                    : null;
                this.itemsSale =
                  this.metadataobj && this.metadataobj.annual
                    ? await this.modifyObjFunc(this.metadataobj.annual, 3)
                    : null;

                this.itemsGrantor = apiData["04_flips_by_first_grantor"]
                  ? await this.modifyObjFunc(
                      apiData["04_flips_by_first_grantor"],
                      4
                    )
                  : null;
                this.itemsLender = apiData["05_flips_by_first_mortgage_lender"]
                  ? await this.modifyObjFunc(
                      apiData["05_flips_by_first_mortgage_lender"],
                      5
                    )
                  : null;
              }
            });
          await axios
            .get(
              `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/community?zipcode=${zipcode}`
              // `http://127.0.0.1:3000/community?zipcode=${zipcode}`
            )
            .then(async (response) => {
              if (response && response.data) {
                this.editorCommunitydata = JSON.stringify(response.data);
                this.updatedAt = response.data.updatedAt;
              }
            });
          await axios
            .get(
              `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/salestrend?zipcode=${zipcode}`
              // `http://127.0.0.1:3000/salestrend?zipcode=${zipcode}`
            )
            .then(async (response) => {
              if (response && response.data) {
                this.editorsalesTrendData = JSON.stringify(response.data);
                this.updatedAtSales = response.data.updatedAt;
              }
            });
        } else {
          this.color = "error";
          this.snackBarText = "zipcode not found !";
          this.snackbar = true;
        }
      } catch (e) {
        this.color = "error";
        this.snackBarText = e;
        this.snackbar = true;
        console.log("Error : ", e);
      } finally {
        this.loading = false;
      }
    },
    async updateCommunity() {
      const zipcode = this.$props.id;
      try {
        await axios
          .get(
            `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/update_community?zipcode=${zipcode}`
            // `http://127.0.0.1:3000/update_community?zipcode=${zipcode}`
          )
          .then(async (response) => {
            if (response && response.data) {
              this.editorCommunitydata = JSON.stringify(response.data);
            }
            this.updatedAt = response.data.updatedAt;
          });
      } catch (error) {
        console.error("Error updating community data:", error);
      }
    },
    async updateSalesTrend() {
      const zipcode = this.$props.id;
      try {
        await axios
          .get(
            `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/update_sales_trend?zipcode=${zipcode}`
            // `http://127.0.0.1:3000/update_sales_trend?zipcode=${zipcode}`
          )
          .then(async (response) => {
            if (response && response.data) {
              this.editorsalesTrendData = JSON.stringify(response.data);
            }
            this.updatedAtSales = response.data.updatedAt;
          });
      } catch (error) {
        console.error("Error updating sales trend data:", error);
      }
    },
    modifyObjFunc(obj, type) {
      let annualObj;
      let modifiedObjArr = [];
      let isCount = false;
      switch (type) {
        case 1:
          annualObj = obj.num_flips_per_year;
          break;
        case 2:
          annualObj = obj.purchase_amnt;
          break;
        case 3:
          annualObj = obj.sale_amnt;
          break;
        case 4:
          annualObj = obj;
          isCount = true;
          break;
        case 5:
          annualObj = obj;
          isCount = true;
          break;
        default:
          break;
      }
      for (const [key, val] of Object.entries(annualObj)) {
        if (isCount) {
          modifiedObjArr.push({
            column1: key,
            count: val.propertiesCount,
            latestActivity: val.LatestActivity,
            avgPropertyCost: val.AverageTransferAmount,
            value: val,
          });
        } else {
          modifiedObjArr.push({ column1: key, value: val });
        }
      }
      return modifiedObjArr;
    },

    async loadMap() {
      this.loading = true;
      const canvas = document.createElement("canvas");
      const gl = canvas.getContext("webgl");
      gl.getParameter(gl.RENDERER);

      const mapDiv = document.getElementById("mapdiv");

      const zipCode = this.$props.id;
      const latLong = { lat: this.latitude, lng: this.longitude };
      console.log("latLong---------------------",latLong)

      const zoom = 11;
      const options = {
        center: latLong,
        mapId: "1261b76f0156780e",
        zoom,
        preserveDrawingBuffer: true,
      };
      this.map = new google.maps.Map(mapDiv, options);

      this.marker = new google.maps.Marker({
        position: latLong,
        map: this.map,
      });

      const featureLayer = await this.map.getFeatureLayer("POSTAL_CODE");
      const featureStyleOptions = {
        strokeColor: "#448AFF",
        strokeOpacity: 1.0,
        strokeWeight: 3.0,
        fillColor: "#448AFF",
        fillOpacity: 0.5,
      };

      featureLayer.style = (options) => {
        if (options.feature.placeId == this.placeId) {
          return featureStyleOptions;
        }
      };
      this.loading = false;

      this.map.setCenter(latLong);
      this.marker.setPosition(latLong);
    },
  },
};
</script>

<style lang="scss" scoped>
#mapdiv {
  min-height: 500px;
  width: 100%;
}
</style>
