<template>
  <draggable
    tag="div"
    class="item-container"
    :list="steps"
    v-bind="dragOptions"
    :move="checkMove"
  >
    <div class="item-group" :key="index" v-for="(el, index) in steps">
      <div
        :class="'itemwrapper d-flex flex-wrap index' + index"
        :data-index="index"
        style="position: relative"
      >
        <div class="step-title" @click="clickstep">{{ el.Name }}</div>
        <v-spacer />
        <v-icon @click="clickAdd" id="addStep">fa fa-plus</v-icon>
        <v-icon @click="clickstep" id="editStep">fa fa-edit</v-icon>
        <v-icon @click="clickDel" id="delete">fa fa-trash</v-icon>
        <br />
        <span class="description">{{ el.Label }}</span>
      </div>
      <nested-draggable
        class="item-sub"
        :data-index="index"
        v-if="el.SubSteps && el.SubSteps.length > 0"
        :steps="el.SubSteps"
      />
    </div>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
export default {
  name: "nested-draggable",
  props: {
    steps: {
      type: Array,
    },
  },
  data() {
    return {
      selectedStep: {},
    };
  },
  components: {
    draggable,
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    clickHiddenAdd() {
      document.getElementById("addStep").click();
    },
    clickstep(event) {
      let position = this.calculatePosition(event);
      this.$root.$emit("stepSelected", position);
    },
    clickAdd(event) {
      let position = this.calculatePosition(event);
      this.$root.$emit("subStepAdded", position);
    },
    clickDel(event) {
      let position = this.calculatePosition(event);
      this.$root.$emit("stepDeleted", position);
    },
    checkMove(evt) {
      if (evt.from !== evt.to) {
        return false;
      }
    },
    calculatePosition(event) {
      let elements = document.querySelectorAll(".itemwrapper");
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove("active");
      }
      let stepIndexArr = [];
      let parentEl = event.target.parentElement;
      while (!parentEl.className.includes("rootstep")) {
        if (parentEl.getAttribute("data-index")) {
          stepIndexArr.push(parseInt(parentEl.getAttribute("data-index")));
        }
        parentEl = parentEl.parentElement;
      }
      event.target.parentElement.classList.add("active");
      return stepIndexArr.reverse();
    },
  },
};
</script>
<style scoped>
.item-container {
  max-width: 100%;
  margin: 0;
}
.itemwrapper {
  padding: 1rem;
  border: 1px solid #cfd8dc;
  margin: 12px 0;
  background: #353535;
  border-radius: 3px;
  font-family: Roboto;
}
.description {
  font-size: 10px;
  display: block;
  width: 100%;
}
.item-sub {
  margin: 0 0 0 1rem;
}
.fa {
  margin: 3px;
  font-size: 1rem;
}
.step-title {
  cursor: pointer;
  display: inline;
  margin-right: 5px;
}
.active {
  background-color: #757575;
}
</style>
