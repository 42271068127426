<template>
  <div class="pa-10" style="margin-left: 56px">
    <div class="">
      <v-list-item three-line class="pa-0">
        <v-list-item-content>
          <v-list-item-title class="text-h5 mb-1 font-weight-bold">
            Welcome to Crebrid OS!
          </v-list-item-title>
          <v-list-item-subtitle>
            What do you want to do today?</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <!-- <div class="text-h4 font-weight-black">Welcome to Crebrid OS!</div>
      <div class="text-h6 mt-2 font-weight-regular">
        What do you want to do today?
      </div> -->

      <div class="mt-8">
        <v-row>
          <v-col sm="12" lg="4">
            <v-card class="mx-auto" color="grey darken-3">
              <div class="d-flex align-start justify-center">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                      Loans
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Manage the full life cycle of loans from application,
                      origination and servicing.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <div class="pa-3">
                  <v-icon x-large>mdi-cash-fast</v-icon>
                </div>
              </div>

              <v-card-actions class="pa-4">
                <v-btn color="green accent-2" outlined small to="/los">
                  Go to Loans
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col sm="12" lg="4">
            <v-card class="mx-auto" color="grey darken-3">
              <div class="d-flex align-start justify-center">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                      Marketing
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Manage and research high level marketing campaign
                      discovery.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <div class="pa-3">
                  <v-icon x-large>mdi-bullhorn</v-icon>
                </div>
              </div>

              <v-card-actions class="pa-4">
                <v-btn color="orange accent-4" outlined small to="/marketing">
                  Go to Marketing
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col sm="12" lg="4">
            <v-card class="mx-auto" color="grey darken-3">
              <div class="d-flex align-start justify-center">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                      Data
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Manage and access data lakes from multiple clouds and
                      providers.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <div class="pa-3">
                  <v-icon x-large>mdi-warehouse</v-icon>
                </div>
              </div>

              <v-card-actions class="pa-4">
                <v-btn color="light-blue" outlined small to="/data">
                  Go to Data
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col lg="12"> </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col lg="12">
          <v-card class="mx-auto" color="grey darken-3" width="100%">
            <!-- <div class="d-flex align-center justify-center">
              <div class="pa-3">
                <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                  Automation
                </v-list-item-title>
              </div>

              <v-sheet class="ma-3" width="100%" color="grey darken-3" data-app>
                <div class="d-flex align-center">
                  <v-text-field
                    v-model="search"
                    label="Search Recent Automations..."
                    flat
                    solo-inverted
                    hide-details
                    clearable
                    clear-icon="mdi-close-circle-outline"
                  ></v-text-field>
                </div>
              </v-sheet>

              <div class="pa-3">
                <v-icon x-large>mdi-robot</v-icon>
              </div>
            </div> -->

            <div class="d-flex align-start justify-center">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                    Automations
                    <v-btn
                      color="grey lighten-3"
                      class="ml-2"
                      outlined
                      x-small
                      @click="projectDialog = true"
                    >
                      Add New
                    </v-btn>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >Manage automation projects for all of Crebrid
                    OS.</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <div class="pa-3">
                <v-icon x-large>mdi-robot</v-icon>
              </div>
            </div>
            <!-- <v-sheet class="" color="grey darken-3" data-app>
              <div class="d-flex align-center">
                <v-text-field
                  v-model="search"
                  label="Search Recent Automations..."
                  flat
                  light
                  solo-inverted
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  class="pb-2"
                ></v-text-field>
              </div>
            </v-sheet> -->

            <v-sheet class="pa-4" color="grey darken-3" data-app>
              <div class="d-flex align-center">
                <v-text-field
                  v-model="search"
                  label="Search Automations..."
                  flat
                  solo-inverted
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                ></v-text-field>
              </div>
            </v-sheet>
            <div>
              <v-data-table
                :headers="projectHeaders"
                :items="projects"
                :items-per-page="10"
                item-key="email"
                :search="search"
                sort-by="TimeInserted"
                :sort-desc="true"
                class="elevation-1"
              >
                <template v-slot:item.Namespace="{ item }">
                  <div v-if="item.Namespace == 'Loans'">
                    <span class="green--text text--accent-2">{{
                      item.Namespace
                    }}</span>
                  </div>
                  <div v-else-if="item.Namespace == 'Marketing'">
                    <span class="orange--text text--accent-4">{{
                      item.Namespace
                    }}</span>
                  </div>
                  <div v-else-if="item.Namespace == 'Data'">
                    <span class="light-blue--text">{{ item.Namespace }}</span>
                  </div>
                  <div v-else>
                    {{ item.Namespace }}
                  </div>
                </template>

                <template v-slot:item.TimeInserted="{ item }">
                  {{ item.TimeInserted | formatDate }}
                </template>

                <template v-slot:item.actions="{ item }">
                  <router-link :to="`/automations/projects/${item.Id}`">
                    <v-btn x-small outlined color="grey lighten-3">
                      View Automation
                    </v-btn>
                  </router-link>
                </template>
              </v-data-table>
              <!-- <v-data-table
                :headers="automationHeaders"
                :items="automations"
                :items-per-page="10"
                item-key="email"
                :search="search"
                sort-by="createdAt"
                sort-desc="true"
                class="elevation-1"
              >
                <template v-slot:item.actions="{ item }">
                  <router-link :to="`/los/applications/${item.id}?tab=1`">
                    <v-btn small text :color="item.color">
                      View Automation
                    </v-btn>
                  </router-link>
                </template>
              </v-data-table> -->
            </div>
          </v-card>
        </v-col>
      </v-row>

      <!-- <v-card color="grey darken-3" class="pa-5" width="100%">
        <div class="map-frame">
          <iframe
            width="100%"
            height="800"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://www.google.com/maps/d/u/0/embed?mid=1X1l068DwMcARqFy4l-3umt-369UxuRM&ehbc=2E312F&z=5"
          ></iframe>
        </div>
      </v-card> -->
    </div>

    <v-dialog v-model="projectDialog" max-width="600px">
      <v-card class="pa-5" dark>
        <v-card-title class="justify-center mb-5">
          <span class="headline">Create New Project</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Project Name*"
                  v-model="projectObject.Name"
                  :rules="[(v) => !!v || 'Name is required']"
                  required
                  outlined
                  color="white"
                ></v-text-field>
                <v-switch
                  v-model="projectObject.UsesPuppeteer"
                  label="Use Puppeteer"
                ></v-switch>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Project Namespace*"
                  v-model="projectObject.Namespace"
                  :rules="[(v) => !!v || 'Namespace is required']"
                  required
                  outlined
                  color="white"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="projectDialog = false">Close</v-btn>
          <v-btn
            color="green accent-2"
            class="black--text"
            :disabled="!projectObject.Name || !projectObject.Namespace"
            @click="addProject"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      loading: true,

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,

      projectDialog: false,
      projectObject: {
        Name: null,
        Namespace: null,
        UsesPuppeteer: true,
        StartModuleDefault: "Main",
        Version: 1,
        VersionUpgradeTo: null,
        Environment: "Dev",
        MetaData: {},
        Modules: [
          {
            DataDefaults: {
              PropertyName: [],
              ResmanStatusTypes: [],
            },
            Name: "Main",
            Steps: [],
          },
        ],
      },

      projects: [],
      projectHeaders: [
        {
          text: "Name",
          align: "start",
          width: "200",
          value: "Name",
        },
        {
          text: "Namespace",
          align: "start",
          width: "200",
          value: "Namespace",
        },
        {
          text: "Created At",
          align: "start",
          width: "200",
          value: "TimeInserted",
        },
        {
          text: "Actions",
          align: "center",
          width: "100",
          value: "actions",
        },
      ],

      search: "",
      automations: [
        {
          name: "Hubspot Landing Page Results",
          startTime: "Fri Apr 21 2023 11:30:21",
          endTime: "Fri Apr 21 2023 11:31:21",
          duration: "1min",
          status: "Complete",
          type: "marketing",
          color: "orange accent-4",
        },
        {
          name: "Loan Servicing Sync from TMO",
          startTime: "Thur Apr 20 2023 9:10:14",
          endTime: "Thur Apr 20 2023 9:14:17",
          duration: "4min 3s",
          status: "Complete",
          type: "loans",
          color: "green accent-2",
        },
        {
          name: "U.S. Census Tracts Sync into BigQuery",
          startTime: "Tues Apr 18 2023 17:40:11",
          endTime: "Tues Apr 18 2023 17:41:21",
          duration: "1min 10s",
          status: "Complete",
          type: "data",
          color: "light-blue",
        },
        {
          name: "Hubspot Contacts Sync",
          startTime: "Sun Apr 16 2023 13:16:41",
          endTime: "Sun Apr 16 2023 13:17:11",
          duration: "30s",
          status: "Complete",
          type: "marketing",
          color: "orange accent-4",
        },
        {
          name: "ATTOM Data Property Flips Sync into BigQuery",
          startTime: "Sat Apr 15 2023 3:00:00",
          endTime: "Sat Apr 15 2023 3:04:25",
          duration: "4min 25s",
          status: "Complete",
          type: "data",
          color: "light-blue",
        },
      ],
      automationHeaders: [
        {
          text: "Name",
          align: "start",
          width: "300",
          value: "name", //B1425 - borrower's email
        },
        {
          text: "Start Time",
          align: "start",
          width: "200",
          value: "startTime", //B1425 - borrower's email
        },
        {
          text: "End Time",
          align: "start",
          width: "200",
          value: "endTime",
        },
        {
          text: "Duration",
          align: "start",
          width: "100",
          value: "duration",
        },
        {
          text: "Status",
          align: "start",
          width: "100",
          value: "status",
        },
        {
          text: "Actions",
          align: "center",
          width: "100",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },

  filters: {
    formatDate: function (date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
  },

  mounted() {
    this.getProjects();
  },

  methods: {
    getProjects() {
      axios
        .get(process.env.VUE_APP_BASEURL + "/hermosa/project")
        .then((response) => {
          if (response.data.status) {
            if (response.data.data.projects.length > 0) {
              this.projects = response.data.data.projects.filter(
                (project) => project.Status === "active"
              );
            } else {
              this.projects = [];
            }
          }
          this.loading = false;
        });
    },
    addProject(e) {
      //e.preventDefault();
      axios
        .put(`${process.env.VUE_APP_BASEURL}/hermosa/project/new/version/1`, {
          name: this.projectObject.Name,
          namespace: this.projectObject.Namespace,
          project: JSON.stringify(this.projectObject),
        })
        .then((response) => {
          if (response) {
            if (response.data.status) {
              this.getProjects();
              this.projectDialog = false;
              this.color = "success";
              this.snackBarText = response.data.message;
            } else {
              this.color = "error";
              this.snackBarText = response.data.message;
            }
            this.snackbar = true;
          }
        });
    },

    moment: function () {
      return moment();
    },
  },
};
</script>

<style lang="scss" scoped>
.os-container {
  min-height: calc(100vh - 120px);
}

.map-frame {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
