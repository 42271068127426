import { storage } from "../auth/firebase-auth";

export const uploadImage = (file) => {
  try {
    const storageRef = storage.ref();
    // Generate a unique filename using a timestamp
    const timestamp = new Date().getTime();
    const uniqueFileName = `${timestamp}_${file.name}`;

    const imageRef = storageRef.child(`images/${uniqueFileName}`);
    const uploadTask = imageRef.put(file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Error uploading image:", error);
          reject(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

export const deleteImage = (imageUrl) => {
  try {
    const storageRef = storage.refFromURL(imageUrl);
    storageRef.delete().then(() => {
      console.log("Image deleted successfully");
    });
  } catch (error) {
    console.error("Error deleting image:", error);
    throw error;
  }
};