<template>
  <div>
    <div v-if="loading">
      <loader />
    </div>

    <div class="ml-12 pl-2">
      <v-snackbar
        v-model="snackbar"
        :timeout="snackBarTimeout"
        :color="color"
        >{{ snackBarText }}</v-snackbar
      >

      <div class="mx-auto rounded-0 full-height" v-if="items != null">
        <v-sheet class="pa-12">
          <v-text-field
            v-model="search"
            label="Search Warehouses..."
            dark
            flat
            solo-inverted
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>
        </v-sheet>

        <v-sheet
          :elevation="5"
          class="my-12 mx-12 py-5 px-10"
          style="position: relative"
        >
          <v-card-text>
            <v-btn
              fixed
              fab
              bottom
              right
              color="green accent-2"
              class="black--text"
              @click="createDialog = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-dialog v-model="createDialog" fullscreen>
              <v-card class="pa-5" color="blue-grey darken-4">
                <v-card-text>
                  <v-tabs
                    fixed-tabs
                    background-color="blue-grey darken-4"
                    color="white"
                    height="70"
                    dark
                  >
                    <v-tab>Create Company</v-tab>
                    <v-tab>Create Property</v-tab>
                    <v-tab-item class="mt-5">
                      <v-sheet :elevation="5" class="mx-auto pa-5">
                        <v-text-field
                          outlined
                          label="Company Name*"
                          v-model="companyModel.Name"
                          :rules="companyRules.Name"
                          required
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="IdKey*"
                          v-model="companyModel.IdKey"
                          :rules="companyRules.IdKey"
                          required
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="PropSys*"
                          v-model="companyModel.PropSys"
                          :rules="companyRules.PropSys"
                          required
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="Logo"
                          v-model="companyModel.Logo"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="Website"
                          v-model="companyModel.Website"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="PropSysBaseUrl"
                          v-model="companyModel.PropSysBaseUrl"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="PropSysUsername"
                          v-model="companyModel.PropSysUsername"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="PropSysPassword"
                          v-model="companyModel.PropSysPassword"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="TimezoneId"
                          v-model="companyModel.TimezoneId"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="SmartRent_MinIncrease"
                          v-model="companyModel.SmartRent_MinIncrease"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="SmartRent_MaxIncrease"
                          v-model="companyModel.SmartRent_MaxIncrease"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="SmartRent_1BedMinPrice"
                          v-model="companyModel.SmartRent_1BedMinPrice"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="SmartRent_1BedMaxPrice"
                          v-model="companyModel.SmartRent_1BedMaxPrice"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="SmartRent_2BedMinPrice"
                          v-model="companyModel.SmartRent_2BedMinPrice"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="SmartRent_2BedMaxPrice"
                          v-model="companyModel.SmartRent_2BedMaxPrice"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="SmartRent_3BedMinPrice"
                          v-model="companyModel.SmartRent_3BedMinPrice"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="SmartRent_3BedMaxPrice"
                          v-model="companyModel.SmartRent_3BedMaxPrice"
                          type="Number"
                        ></v-text-field>

                        <v-btn color="blue darken-1" @click="createCompany"
                          >Add Company</v-btn
                        >
                      </v-sheet>
                    </v-tab-item>
                    <v-tab-item class="mt-5">
                      <v-sheet :elevation="5" class="mx-auto pa-5">
                        <v-text-field
                          outlined
                          label="Property Name*"
                          v-model="propertyModel.Name"
                          :rules="propertyRules.Name"
                          required
                        ></v-text-field>
                        <!-- <v-text-field
                          outlined label="CompanyId*"
                          v-model="propertyModel.CompanyId"
                          :rules="propertyRules.CompanyId"
                          required
                        ></v-text-field>-->
                        <v-autocomplete
                          v-model="propertyModel.CompanyId"
                          :items="cmpItems"
                          :loading="cmpIsLoading"
                          :search-input.sync="cmpSearch"
                          hide-no-data
                          hide-selected
                          item-text="Name"
                          item-value="Id"
                          outlined
                          label="Company Id*"
                          :rules="propertyRules.CompanyId"
                          placeholder="Start typing to Search Company"
                          return-object
                          color="white"
                        ></v-autocomplete>
                        <v-text-field
                          outlined
                          label="ExternalId*"
                          v-model="propertyModel.ExternalId"
                          :rules="propertyRules.ExternalId"
                          required
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="IdKey*"
                          v-model="propertyModel.IdKey"
                          :rules="propertyRules.IdKey"
                          required
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="Abbreviation*"
                          v-model="propertyModel.Abbreviation"
                          :rules="propertyRules.Abbreviation"
                          required
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="TimezoneId"
                          v-model="propertyModel.TimezoneId"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="GroupId"
                          v-model="propertyModel.GroupId"
                        ></v-text-field>
                        <v-switch
                          outlined
                          label="Active"
                          v-model="propertyModel.Active"
                        ></v-switch>
                        <v-text-field
                          outlined
                          label="AutomationBatch"
                          v-model="propertyModel.AutomationBatch"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="LogoSmall"
                          v-model="propertyModel.LogoSmall"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="Phone"
                          v-model="propertyModel.Phone"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="Address"
                          v-model="propertyModel.Address"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="Manager"
                          v-model="propertyModel.Manager"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="UnitsCount"
                          v-model="propertyModel.UnitsCount"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="OnboardDate"
                          v-model="propertyModel.OnboardDate"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="PortalLink"
                          v-model="propertyModel.PortalLink"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="PortalShortLink"
                          v-model="propertyModel.PortalShortLink"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="SurveyShortLink"
                          v-model="propertyModel.SurveyShortLink"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="County"
                          v-model="propertyModel.County"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="Latitude"
                          v-model="propertyModel.Latitude"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="Longitude"
                          v-model="propertyModel.Longitude"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="AcquisitionDate"
                          v-model="propertyModel.AcquisitionDate"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="UWInfo_StartingRent"
                          v-model="propertyModel.UWInfo_StartingRent"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="UWInfo_ProFormaRent"
                          v-model="propertyModel.UWInfo_ProFormaRent"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="TotalBasis"
                          v-model="propertyModel.TotalBasis"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="CurrentOccupancy"
                          v-model="propertyModel.CurrentOccupancy"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="SmartRent_MinIncrease"
                          v-model="propertyModel.SmartRent_MinIncrease"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="SmartRent_MaxIncrease"
                          v-model="propertyModel.SmartRent_MaxIncrease"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="SmartRent_1BedMinPrice"
                          v-model="propertyModel.SmartRent_1BedMinPrice"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="SmartRent_1BedMaxPrice"
                          v-model="propertyModel.SmartRent_1BedMaxPrice"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="SmartRent_2BedMinPrice"
                          v-model="propertyModel.SmartRent_2BedMinPrice"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="SmartRent_2BedMaxPrice"
                          v-model="propertyModel.SmartRent_2BedMaxPrice"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="SmartRent_3BedMinPrice"
                          v-model="propertyModel.SmartRent_3BedMinPrice"
                          type="Number"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          label="SmartRent_3BedMaxPrice"
                          v-model="propertyModel.SmartRent_3BedMaxPrice"
                          type="Number"
                        ></v-text-field>

                        <v-btn color="blue darken-1" @click="createProperty"
                          >Add Property</v-btn
                        >
                      </v-sheet>
                    </v-tab-item>
                  </v-tabs>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn color="white" outlined @click="createDialog = false"
                    >Close</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-treeview
              :items="items"
              :search="search"
              :filter="filter"
              :open.sync="open"
              :hoverable="hoverable"
            >
              <template v-slot:prepend="{ item }">
                <v-icon v-if="item.children">mdi-folder</v-icon>
                <span
                  v-if="!item.children"
                  class="item"
                  @click="rowClick(item)"
                >
                  <v-icon small>mdi-launch</v-icon>
                </span>
                <v-icon small @click="prefillCompany(item.Id)"
                  >fa fa-edit</v-icon
                >
                <v-icon small @click="deleteDialog = true">fa fa-trash</v-icon>
                <div v-if="deleteDialog">
                  <v-dialog
                    v-model="deleteDialog"
                    class="delete-model"
                    max-width="600px"
                  >
                    <v-card>
                      <v-card-title>
                        <span class="headline">Delete Company</span>
                      </v-card-title>
                      <v-card-text
                        >Are you sure to delete the Company ?</v-card-text
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small color="error" @click="deleteDialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          small
                          color="blue darken-1"
                          @click="deleteCompany(item.Id)"
                          >Confirm</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </template>
            </v-treeview>
          </v-card-text>
        </v-sheet>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "./Loader.vue";

export default {
  name: "Warehouse",
  components: {
    Loader,
  },
  data() {
    return {
      loading: true,

      companies: [],

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,

      items: null,
      hoverable: true,
      open: [],
      search: null,
      deleteDialog: false,

      createDialog: false,
      editDialog: false,
      companyModel: {
        Name: "", // required:true, type: string
        IdKey: "", // required:true, type: string
        PropSys: "", // required:true, type: string
        Logo: "", // type: string
        Website: "", // type: string
        PropSysBaseUrl: "", // type: string
        PropSysUsername: "", // type: string
        PropSysPassword: "", // type: string
        TimezoneId: "", // type: string
        SmartRent_MinIncrease: 0, // type: number
        SmartRent_MaxIncrease: 0, // type: number
        SmartRent_1BedMinPrice: 0, // type: number
        SmartRent_1BedMaxPrice: 0, // type: number
        SmartRent_2BedMinPrice: 0, // type: number
        SmartRent_2BedMaxPrice: 0, // type: number
        SmartRent_3BedMinPrice: 0, // type: number
        SmartRent_3BedMaxPrice: 0, // type: number
      },
      companyRules: {
        Name: [(v) => !!v || "Name is required"],
        IdKey: [(v) => !!v || "IdKey is required"],
        PropSys: [(v) => !!v || "PropSys is required"],
      },

      //companySearch
      cmpSearch: null,
      cmpEntries: [],
      cmpIsLoading: false,

      propertyModel: {
        Name: "", // required:true, type: string
        CompanyId: null, // required:true, type: string
        ExternalId: "", // required:true, type: string
        IdKey: "", // required:true, type: string
        Abbreviation: "", // required:true, type: string
        GroupId: "", // type: string
        Active: true, // type: boolean
        AutomationBatch: "", // type: string
        LogoSmall: "", // type: string
        Phone: "", // type: string
        Address: "", // type: string
        Manager: "", // type: string
        UnitsCount: 0, // type: number
        OnboardDate: "", // type: string
        PortalLink: "", // type: string
        PortalShortLink: "", // type: string
        SurveyShortLink: "", // type: string
        County: "", // type: string
        Latitude: "", // type: string
        Longitude: "", // type: string
        AcquisitionDate: "", // type: string
        UWInfo_StartingRent: 0, // type: number
        UWInfo_ProFormaRent: 0, // type: number
        TotalBasis: 0, // type: number
        CurrentOccupancy: 0, // type: number
        TimezoneId: "", // type: string
        SmartRent_MinIncrease: 0, // type: number
        SmartRent_MaxIncrease: 0, // type: number
        SmartRent_1BedMinPrice: 0, // type: number
        SmartRent_1BedMaxPrice: 0, // type: number
        SmartRent_2BedMinPrice: 0, // type: number
        SmartRent_2BedMaxPrice: 0, // type: number
        SmartRent_3BedMinPrice: 0, // type: number
        SmartRent_3BedMaxPrice: 0, // type: number
      },
      propertyRules: {
        Name: [(v) => !!v || "Name is required"],
        CompanyId: [(v) => !!v || "PropSys is required"],
        ExternalId: [(v) => !!v || "ExternalId is required"],
        IdKey: [(v) => !!v || "IdKey is required"],
        Abbreviation: [(v) => !!v || "Abbreviation is required"],
      },
    };
  },
  computed: {
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
    cmpFields() {
      if (!this.propertyModel.CompanyId) return [];

      return Object.keys(this.propertyModel.CompanyId).map((key) => {
        return {
          key,
          value: this.propertyModel.CompanyId[key] || "n/a",
        };
      });
    },
    cmpItems() {
      return this.cmpEntries.map((entry) => {
        const Name = entry.Name;
        return Object.assign({}, entry, { Name });
      });
    },
  },
  watch: {
    cmpSearch(val) {
      if (
        this.cmpSearch !=
        (this.propertyModel.CompanyId && this.propertyModel.CompanyId.Name)
      ) {
        if (!val) {
          return;
        }
        this.clearcmpEntries();
        this.cmpIsLoading = true;
        this.fetchCmpEntriesDebounced();
      }
    },
  },
  methods: {
    rowClick(item) {
      if (item) {
        this.$router.push({
          name: "property",
          params: {
            propertyid: item.Id,
          },
        });
      }
    },

    getCompanies() {
      axios
        .get(process.env.VUE_APP_BASEURL + "/company?includeProperty=true")
        .then((response) => {
          if (response) {
            this.companies =
              response.data.data.companies &&
              response.data.data.companies.length > 0
                ? response.data.data.companies
                : [];
            this.rearrangeCompany();
          }
        });
    },

    rearrangeCompany() {
      const updatedKeyCompanies_name = this.companies.map(
        ({ Name: name, ...rest }) => ({
          name,
          ...rest,
        })
      );
      const updatedKeyCompanies = updatedKeyCompanies_name.map((el) => {
        var o = Object.assign({}, el);
        o.id = el.Id;
        return o;
      });
      const result = updatedKeyCompanies.map(
        ({ properties: children, ...rest }) => ({
          children,
          ...rest,
        })
      );
      result.forEach((el) => {
        if (el.children && el.children.length > 0) {
          const tempvar = el.children.map(({ Name: name, ...rest }) => ({
            name,
            ...rest,
          }));
          const tempvar2 = tempvar.map((el) => {
            var o = Object.assign({}, el);
            o.id = el.Id;
            return o;
          });
          tempvar2.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          el.children = tempvar2;
        }
      });

      result.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      this.items = result;
      this.loading = false;
    },
    createCompany() {
      // console.log('createCompany',{...this.companyModel});
      axios
        .post(`${process.env.VUE_APP_BASEURL}/company`, {
          ...this.companyModel,
        })
        .then((response) => {
          if (response) {
            if (response.data.status) {
              this.color = "success";
              this.snackBarText = response.data.message;
              this.getCompanies();
              this.createDialog = false;
            } else {
              this.color = "error";
              this.snackBarText = response.data.message;
            }
            this.snackbar = true;
          }
        });
    },
    createProperty() {
      this.propertyModel.CompanyId =
        this.propertyModel && this.propertyModel.CompanyId
          ? this.propertyModel.CompanyId.Id
          : null;
      // console.log('createProperty',{...this.propertyModel});
      axios
        .post(`${process.env.VUE_APP_BASEURL}/property`, {
          ...this.propertyModel,
        })
        .then((response) => {
          if (response) {
            if (response.data.status) {
              this.color = "success";
              this.snackBarText = response.data.message;
              this.createDialog = false;
            } else {
              this.color = "error";
              this.snackBarText = response.data.message;
            }
            this.snackbar = true;
          }
        });
    },
    clearcmpEntries() {
      this.cmpEntries = [];
    },
    fetchCmpEntriesDebounced() {
      clearTimeout(this._searchCmpTimerId);
      this._searchCmpTimerId = setTimeout(() => {
        this.fetchCmpEntries();
      }, 500);
    },
    fetchCmpEntries() {
      axios
        .get(process.env.VUE_APP_BASEURL + "/company?search=" + this.cmpSearch)
        .then((response) => {
          if (response.data.status) {
            this.cmpEntries = response.data.data.companies;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.cmpIsLoading = false));
    },
    async prefillCompany(id) {
      let responseData;
      await axios
        .get(process.env.VUE_APP_BASEURL + "/company/" + id)
        .then((response) => {
          if (response) {
            if (response.data.status) {
              this.color = "success";
              this.snackBarText = response.data.message;
              responseData = response.data;
            } else {
              this.color = "error";
              this.snackBarText = response.data.message;
            }
          }
        });
      this.$nextTick(() => {
        if (responseData) {
          this.companyModel = responseData.data.company;
        }
        this.createDialog = true;
        // this.editDialog = true;
      });
    },
    editCompany(id) {
      axios
        .put(process.env.VUE_APP_BASEURL + "/company/" + id, {
          DataToUpdate: { ...this.companyModel },
        })
        .then((response) => {
          if (response) {
            if (response.data.status) {
              console.log("updated", response);
              this.color = "success";
              this.snackBarText = response.data.message;
              this.createDialog = false;
              // this.editDialog = false;
              this.getCompanies();
            } else {
              this.color = "error";
              this.snackBarText = response.data.message;
            }
          }
        });
    },
    deleteCompany(id) {
      console.log("del", id);
      this.deleteDialog = false;
      axios
        .delete(process.env.VUE_APP_BASEURL + "/company/" + id)
        .then((response) => {
          if (response) {
            if (response.data.status) {
              this.color = "success";
              this.snackBarText = response.data.message;
              this.getCompanies();
            } else {
              this.color = "error";
              this.snackBarText = response.data.message;
            }
          }
        });
    },
  },
  mounted() {
    this.getCompanies();
  },
};
</script>

<style scoped>
.full-height {
  min-height: 100vh;
}
.v-text-field .v-label {
  overflow: visible !important;
  top: 1% !important;
}
.label {
  color: #c7c7c7 !important;
}
.item {
  cursor: pointer;
}
.fa {
  margin: 3px;
  font-size: 1rem;
}
</style>
