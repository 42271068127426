<template>
  <div class="full-height" style="margin-left: 56px">
    <div v-if="loading">
      <loader />
    </div>

    <div class="mx-auto rounded-0 full-height">
      <v-sheet
        :elevation="5"
        dark
        class="ma-12 py-8 px-10"
        style="position: relative"
      >
        <v-snackbar
          v-model="snackbar"
          :timeout="snackBarTimeout"
          :color="color"
          >{{ snackBarText }}</v-snackbar
        >
        <div class="mb-3">
          <span class="mr-2 text-gray-700"><b>Marketing Address:</b> </span>
          <span class="text-gray-700">{{ addressString }} </span>
        </div>
        <div v-if="this.address" id="mapdiv"></div>
        <div>
          <div
            v-if="metadataobj"
            class="d-flex flex-column full-width mt-10 mb-10"
          >
            <v-row>
              <v-col sm="12" lg="3">
                <v-card color="grey darken-3">
                  <v-card-text>
                    <div
                      class="text-h5 pb-4 text--primary"
                      v-if="currencyFormatter"
                    >
                      {{
                        currencyFormatter.format(metadataobj.avg_purchase_price)
                      }}
                    </div>
                    <span>Avg. Purchase Price</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col sm="12" lg="3">
                <v-card color="grey darken-3">
                  <v-card-text>
                    <p class="text-h5 text--primary" v-if="currencyFormatter">
                      {{ currencyFormatter.format(metadataobj.avg_sale_price) }}
                    </p>
                    <span>Avg. Sales Price</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col sm="12" lg="3">
                <v-card color="grey darken-3">
                  <v-card-text>
                    <p class="text-h5 text--primary">
                      {{ metadataobj.mean_baths.toFixed(2) }}
                    </p>
                    <span>Avg. Baths</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col sm="12" lg="3">
                <v-card color="grey darken-3">
                  <v-card-text>
                    <p class="text-h5 text--primary">
                      {{ metadataobj.mean_beds.toFixed(2) }}
                    </p>
                    <span>Avg. Bedrooms</span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" lg="3">
                <v-card color="grey darken-3">
                  <v-card-text>
                    <p class="text-h5 text--primary">
                      {{ metadataobj.mean_sqft.toFixed(2) }}
                    </p>
                    <span>Avg. Sqft</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col sm="12" lg="3">
                <v-card color="grey darken-3">
                  <v-card-text>
                    <p class="text-h5 text--primary">
                      {{ metadataobj.num_flips }}
                    </p>
                    <span>Num Flips</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col sm="12" lg="3">
                <v-card color="grey darken-3">
                  <v-card-text>
                    <p class="text-h5 text--primary">
                      {{ metadataobj.num_unique_first_grantors }}
                    </p>
                    <span>Num Unique first grantors</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col sm="12" lg="3">
                <v-card color="grey darken-3">
                  <v-card-text>
                    <p class="text-h5 text--primary">
                      {{ metadataobj.num_unique_first_mortgage_lenders }}
                    </p>
                    <span> Num Unique First Mortgage Lenders </span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div class="mt-10">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="text-subtitle-1 white--text">All Data</div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-expansion-panels>
                    <v-expansion-panels
                      v-if="metadataobj && metadataobj.annual"
                    >
                      <v-expansion-panel flat>
                        <v-expansion-panel-header expand-icon="mdi-menu-down">
                          <div class="text-subtitle-1 white--text">
                            Num flips per year
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <!-- <p>{{ metadataobj.annual.num_flips_per_year }}</p> -->
                          <v-sheet class="pa-4" color="grey darken-4" data-app>
                            <div class="d-flex align-center">
                              <v-text-field
                                v-model="searchNumFlip"
                                label="Search Data..."
                                flat
                                solo-inverted
                                hide-details
                                clearable
                                clear-icon="mdi-close-circle-outline"
                              ></v-text-field>
                            </div>
                          </v-sheet>
                          <div>
                            <v-data-table
                              :headers="headersYear"
                              :items="itemsNumFlip"
                              :items-per-page="10"
                              :search="searchNumFlip"
                              sort-by="Year"
                              :sort-desc="true"
                              class="elevation-1"
                            />
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels
                      v-if="metadataobj && metadataobj.annual"
                    >
                      <v-expansion-panel flat>
                        <v-expansion-panel-header expand-icon="mdi-menu-down">
                          <div class="text-subtitle-1 white--text">
                            Purchase amount
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <!-- <p>{{ metadataobj.annual.purchase_amnt }}</p> -->
                          <v-sheet class="pa-4" color="grey darken-4" data-app>
                            <div class="d-flex align-center">
                              <v-text-field
                                v-model="searchPurchase"
                                label="Search Data..."
                                flat
                                solo-inverted
                                hide-details
                                clearable
                                clear-icon="mdi-close-circle-outline"
                              ></v-text-field>
                            </div>
                          </v-sheet>
                          <div>
                            <v-data-table
                              :headers="headersYear"
                              :items="itemsPurchase"
                              :items-per-page="10"
                              :search="searchPurchase"
                              sort-by="Year"
                              :sort-desc="true"
                              class="elevation-1"
                            >
                              <template v-slot:item.value="{ item }">
                                {{ currencyFormatter.format(item.value) }}
                              </template>
                            </v-data-table>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels
                      v-if="metadataobj && metadataobj.annual"
                    >
                      <v-expansion-panel flat>
                        <v-expansion-panel-header expand-icon="mdi-menu-down">
                          <div class="text-subtitle-1 white--text">
                            Sale amount
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <!-- <p>{{ metadataobj.annual.sale_amnt }}</p> -->
                          <v-sheet class="pa-4" color="grey darken-4" data-app>
                            <div class="d-flex align-center">
                              <v-text-field
                                v-model="searchSale"
                                label="Search Data..."
                                flat
                                solo-inverted
                                hide-details
                                clearable
                                clear-icon="mdi-close-circle-outline"
                              ></v-text-field>
                            </div>
                          </v-sheet>
                          <div>
                            <v-data-table
                              :headers="headersYear"
                              :items="itemsSale"
                              :items-per-page="10"
                              :search="searchSale"
                              sort-by="Year"
                              :sort-desc="true"
                              class="elevation-1"
                            >
                              <template v-slot:item.value="{ item }">
                                {{ currencyFormatter.format(item.value) }}
                              </template>
                            </v-data-table>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels v-if="itemsGrantor">
                      <v-expansion-panel flat>
                        <v-expansion-panel-header expand-icon="mdi-menu-down">
                          <div class="text-subtitle-1 white--text">
                            Flips first grantor
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-sheet class="pa-4" color="grey darken-4" data-app>
                            <div class="d-flex align-center">
                              <v-text-field
                                v-model="searchGrantor"
                                label="Search Data..."
                                flat
                                solo-inverted
                                hide-details
                                clearable
                                clear-icon="mdi-close-circle-outline"
                              ></v-text-field>
                            </div>
                          </v-sheet>
                          <div>
                            <v-data-table
                              :headers="headersIndex"
                              :items="itemsGrantor"
                              :items-per-page="10"
                              :search="searchGrantor"
                              class="elevation-1"
                            >
                              <template v-slot:item.actions="{ item }">
                                <v-btn
                                  x-small
                                  outlined
                                  color="green accent-2"
                                  class="black--text"
                                  @click="
                                    (grantorDialog = true),
                                      (grantorContent = item.value.propertyData)
                                  "
                                >
                                  View Details
                                </v-btn>
                              </template>
                            </v-data-table>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels v-if="itemsLender">
                      <v-expansion-panel flat>
                        <v-expansion-panel-header expand-icon="mdi-menu-down">
                          <div class="text-subtitle-1 white--text">
                            Flips first lender
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-sheet class="pa-4" color="grey darken-4" data-app>
                            <div class="d-flex align-center">
                              <v-text-field
                                v-model="searchLender"
                                label="Search Data..."
                                flat
                                solo-inverted
                                hide-details
                                clearable
                                clear-icon="mdi-close-circle-outline"
                              ></v-text-field>
                            </div>
                          </v-sheet>
                          <div>
                            <v-data-table
                              :headers="headersIndex"
                              :items="itemsLender"
                              :items-per-page="10"
                              :search="searchLender"
                              class="elevation-1"
                            >
                              <template v-slot:item.actions="{ item }">
                                <v-btn
                                  x-small
                                  outlined
                                  color="green accent-2"
                                  class="black--text"
                                  @click="
                                    (lenderDialog = true),
                                      (lenderContent = item.value)
                                  "
                                >
                                  View Details
                                </v-btn>
                              </template>
                            </v-data-table>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div class="mt-10" v-if="editorMetadata">
            <h3 class="mb-5">Meta Data</h3>
            <MonacoEditor
              v-model="editorMetadata"
              width="100%"
              height="500"
              theme="vs-dark"
              language="json"
              :options="options"
            ></MonacoEditor>
          </div>
          <!-- <div class="mt-10" v-if="editorGeodata">
            <h3 class="mb-5">Geo Data</h3>
            <MonacoEditor
              v-model="editorGeodata"
              width="100%"
              height="500"
              theme="vs-dark"
              language="json"
              :options="options"
            ></MonacoEditor>
          </div> -->
          <!-- <div class="mt-10" v-if="editorCrebrid">
            <h3 class="mb-5">Crebrid Data</h3>
            <MonacoEditor
              v-model="editorCrebrid"
              width="100%"
              height="500"
              theme="vs-dark"
              language="json"
              :options="options"
            ></MonacoEditor>
          </div> -->
          <div class="mt-10" v-if="editorFlipsGrantor">
            <h3 class="mb-5">Flips Grantor Data</h3>
            <MonacoEditor
              v-model="editorFlipsGrantor"
              width="100%"
              height="500"
              theme="vs-dark"
              language="json"
              :options="options"
            ></MonacoEditor>
          </div>
          <div class="mt-10" v-if="editorFlipsLender">
            <h3 class="mb-5">Flips Lender Data</h3>
            <MonacoEditor
              v-model="editorFlipsLender"
              width="100%"
              height="500"
              theme="vs-dark"
              language="json"
              :options="options"
            ></MonacoEditor>
          </div>
          <!-- <div class="mt-10" v-if="editorRawdata">
            <h3 class="mb-5">Raw Flips Data</h3>
            <MonacoEditor
              v-model="editorRawdata"
              width="100%"
              height="500"
              theme="vs-dark"
              language="json"
              :options="options"
            ></MonacoEditor>
          </div> -->
          <div class="mt-10" v-if="editorRawCalyxdata">
            <h3 class="mb-5">Raw Calyx Data</h3>
            <MonacoEditor
              v-model="editorRawCalyxdata"
              width="100%"
              height="500"
              theme="vs-dark"
              language="json"
              :options="options"
            ></MonacoEditor>
          </div>
        </div>

        <!--Dialog-->
        <v-dialog v-model="grantorDialog" width="75%">
          <v-card>
            <v-card-title>
              <span class="headline">Grantor Data</span>
            </v-card-title>
            <v-card-text>
              <pre v-html="grantorContent"></pre>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="(grantorDialog = false), (grantorContent = null)"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="lenderDialog" width="75%">
          <v-card>
            <v-card-title>
              <span class="headline">Lender Data</span>
            </v-card-title>
            <v-card-text>
              <pre v-html="lenderContent"></pre>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="(lenderDialog = false), (lenderContent = null)"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import { firebaseDB } from "../../auth/firebase-auth";
import Loader from "../../components/Loader.vue";
import MonacoEditor from "monaco-editor-vue";
import axios from "axios";

export default {
  props: ["id"],
  components: {
    MonacoEditor,
    Loader,
  },
  name: "marketingid",
  data() {
    return {
      apiKey: "AIzaSyBkvot_wof6iT87tGnaYMA9ZFIFRvZOK5o",
      loading: true,
      address: null,
      mapStyle: "roadmap",

      map: null,
      marker: null,
      cityCircle: null,
      addressString: null,

      options: {},
      metadataobj: null,
      apiobj: null,

      //editor data
      editorMetadata: null,
      editorGeodata: null,
      editorCrebrid: null,
      editorFlipsGrantor: null,
      editorFlipsLender: null,
      editorRawdata: null,
      editorRawCalyxdata: null,

      headersYear: [
        {
          text: "Year",
          align: "start",
          width: "100",
          value: "column1",
        },
        {
          text: "Value",
          align: "start",
          width: "100",
          value: "value",
        },
      ],

      searchNumFlip: "",
      itemsNumFlip: [],

      searchPurchase: "",
      itemsPurchase: [],

      searchSale: "",
      itemsSale: [],

      headersIndex: [
        {
          text: "Index",
          align: "start",
          width: "200",
          value: "column1",
        },
        {
          text: "Count",
          align: "start",
          width: "200",
          value: "count",
        },
        {
          text: "Actions",
          align: "start",
          width: "100",
          value: "actions",
        },
      ],
      itemsGrantor: [],
      searchGrantor: "",
      grantorDialog: false,
      grantorContent: null,

      itemsLender: [],
      searchLender: "",
      lenderDialog: false,
      lenderContent: null,

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,
    };
  },
  async mounted() {
    this.currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
    await this.getData();
    if (this.address.addressObject) {
      this.addressString =
        this.address.addressString !== undefined
          ? this.address.addressString
          : this.getAddressString(this.address);

      await this.getZipcodeData();
      await this.loadMap();
    }
  },
  methods: {
    async getZipcodeData() {
      this.loading = true;
      try {
        const key = "21b77cec7cb516c40d9d7216ae74e3c3";

        const zipcode = this.address.addressObject.postal_code
          ? Number(this.address.addressObject.postal_code)
          : null;

        if (zipcode) {
          await axios
            .get(
              `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/market?zipcode=${zipcode}&key=${key}`
            )
            .then(async (response) => {
              if (response && response.data) {
                const apiData = response.data;

                //assign monaco editor data START

                this.editorMetadata = apiData["01_metadata"]
                  ? JSON.stringify(apiData["01_metadata"])
                  : null;

                // this.editorGeodata = apiData["02_geo_data"]
                //   ? JSON.stringify(apiData["02_geo_data"])
                //   : null;

                // this.editorCrebrid = apiData["03_crebrid_area"]
                //   ? JSON.stringify(apiData["03_crebrid_area"])
                //   : null;

                this.editorFlipsGrantor = apiData["04_flips_by_first_grantor"]
                  ? JSON.stringify(apiData["04_flips_by_first_grantor"])
                  : null;

                this.editorFlipsLender = apiData[
                  "05_flips_by_first_mortgage_lender"
                ]
                  ? JSON.stringify(apiData["05_flips_by_first_mortgage_lender"])
                  : null;

                // this.editorRawdata = apiData["06_raw_flip_data"]
                //   ? JSON.stringify(apiData["06_raw_flip_data"])
                //   : null;

                this.editorRawCalyxdata = apiData["07_raw_calyx_data"]
                  ? JSON.stringify(apiData["07_raw_calyx_data"])
                  : null;

                //assign monaco editor data END

                this.metadataobj = response.data["01_metadata"]
                  ? response.data["01_metadata"]
                  : null;
                this.itemsNumFlip =
                  this.metadataobj && this.metadataobj.annual
                    ? await this.modifyObjFunc(this.metadataobj.annual, 1)
                    : null;
                this.itemsPurchase =
                  this.metadataobj && this.metadataobj.annual
                    ? await this.modifyObjFunc(this.metadataobj.annual, 2)
                    : null;
                this.itemsSale =
                  this.metadataobj && this.metadataobj.annual
                    ? await this.modifyObjFunc(this.metadataobj.annual, 3)
                    : null;

                this.itemsGrantor = apiData["04_flips_by_first_grantor"]
                  ? await this.modifyObjFunc(
                      apiData["04_flips_by_first_grantor"],
                      4
                    )
                  : null;
                this.itemsLender = apiData["05_flips_by_first_mortgage_lender"]
                  ? await this.modifyObjFunc(
                      apiData["05_flips_by_first_mortgage_lender"],
                      5
                    )
                  : null;
              }
            });
        } else {
          this.color = "error";
          this.snackBarText = "zipcode not found !";
          this.snackbar = true;
        }
      } catch (e) {
        this.color = "error";
        this.snackBarText = e;
        this.snackbar = true;
        console.log("Error : ", e);
      } finally {
        this.loading = false;
      }
    },

    modifyObjFunc(obj, type) {
      let annualObj;
      let modifiedObjArr = [];
      let isCount = false;
      switch (type) {
        case 1:
          annualObj = obj.num_flips_per_year;
          break;
        case 2:
          annualObj = obj.purchase_amnt;
          break;
        case 3:
          annualObj = obj.sale_amnt;
          break;
        case 4:
          annualObj = obj;
          isCount = true;
          break;
        case 5:
          annualObj = obj;
          isCount = true;
          break;
        default:
          break;
      }
      for (const [key, val] of Object.entries(annualObj)) {
        if (isCount) {
          modifiedObjArr.push({
            column1: key,
            count: val.propertiesCount,
            value: val,
          });
        } else {
          modifiedObjArr.push({ column1: key, value: val });
        }
      }
      return modifiedObjArr;
    },

    async getData() {
      const marketRef = firebaseDB
        .firestore()
        .collection("markets")
        .doc(this.$props.id);
      try {
        const snapshot = await marketRef.get();
        const doc = snapshot.data();
        if (!doc) {
          this.snackBarText = "Address you are looking for not found!";
          this.color = "error";
          setTimeout(() => {
            this.$router.push({
              path: "/marketing",
            });
          }, 2000);
          return;
        }
        this.address = doc;
      } catch (e) {
        console.log(e);
      }
    },

    // async getPlaceIdByZipCode(zipCode) {
    //   const apiKey = this.apiKey;
    //   const geocodingUrl = "https://maps.googleapis.com/maps/api/geocode/json";

    //   try {
    //     const response = await axios.get(geocodingUrl, {
    //       params: {
    //         address: zipCode,
    //         key: apiKey,
    //       },
    //     });

    //     const results = response.data.results;
    //     if (results.length > 0) {
    //       const placeId = results[0].place_id;
    //       console.log("Place ID:", placeId);
    //       return placeId;
    //     } else {
    //       console.log("No results found.");
    //       return null;
    //     }
    //   } catch (error) {
    //     console.error("Error:", error.message);
    //     return null;
    //   }
    // },

    async loadMap() {
      this.loading = true;
      const canvas = document.createElement("canvas");
      const gl = canvas.getContext("webgl");
      gl.getParameter(gl.RENDERER);

      const mapDiv = document.getElementById("mapdiv");

      const latLong = {
        lat: this.address.addressObject.latitude,
        lng: this.address.addressObject.longitude,
      };

      // const place_id = await this.getPlaceIdByZipCode(
      //   this.address.addressObject.postal_code
      // );
      const zoom = 13;
      const options = {
        center: latLong,
        mapId: "1261b76f0156780e",
        zoom,
        preserveDrawingBuffer: true,
      };
      this.map = new google.maps.Map(mapDiv, options);

      this.marker = new google.maps.Marker({
        position: latLong,
        map: this.map,
      });

      // this.cityCircle = new google.maps.Circle({
      //   strokeColor: "#0077b6",
      //   strokeOpacity: 0.8,
      //   strokeWeight: 2,
      //   fillColor: "#0077b6",
      //   fillOpacity: 0.35,
      //   map: this.map,
      //   center: latLong,
      //   radius: 1200,
      // });

      // highlight zipcode

      const featureLayer = await this.map.getFeatureLayer("POSTAL_CODE");
      const featureStyleOptions = {
        strokeColor: "#448AFF",
        strokeOpacity: 1.0,
        strokeWeight: 3.0,
        fillColor: "#448AFF",
        fillOpacity: 0.5,
      };

      // featureLayer.style = (options) => {
      //   if (options.feature.placeId == place_id) {
      //     // Hana, HI
      //     return featureStyleOptions;
      //   }
      // };

      // featureLayer.style = async (options) => {
      // const displayname = await options.feature.fetchPlace();
      // console.log('options',options)

      // if (
      //   // options.feature.placeId == this.address.propertyPlaceId
      //   displayname.g.displayName === this.address.addressObject.postal_code
      // ) {
      //   // console.log("success match");
      //   return featureStyleOptions;
      // }
      // };
      this.loading = false;

      this.map.setCenter(latLong);
      this.marker.setPosition(latLong);
      // this.cityCircle.setCenter(latLong);
    },
    // async getPlacesPostCodeById (placeId ) {
    //   return new Promise((resolve, reject) => {
    //     if (!placeId) reject("placeId not provided")

    //     try {
    //       new window.google.maps.places.PlacesService(
    //         document.createElement("div")
    //       ).getDetails(
    //         {
    //           placeId,
    //           fields: ["address_components"],
    //         },
    //         details => {
    //           let postcode = null
    //           details?.address_components?.forEach(entry => {
    //             if (entry.types?.[0] === "postal_code") {
    //               postcode = entry.long_name
    //             }
    //           })
    //           return resolve(postcode)
    //         }
    //       )
    //     } catch (e) {
    //       reject(e)
    //     }
    //   })
    // },

    getAddressString(address) {
      const marketingAddress = address.addressObject;
      if (!marketingAddress) return "";
      let addressString = "";
      addressString += marketingAddress.street_number
        ? ", " + marketingAddress.street_number
        : "";
      addressString += marketingAddress.route
        ? ", " + marketingAddress.route
        : "";
      addressString += marketingAddress.locality
        ? ", " + marketingAddress.locality
        : "";
      addressString += marketingAddress.administrative_area_level_2
        ? ", " + marketingAddress.administrative_area_level_2
        : "";
      addressString += marketingAddress.administrative_area_level_1
        ? ", " + marketingAddress.administrative_area_level_1
        : "";
      addressString += marketingAddress.country
        ? ", " + marketingAddress.country
        : "";
      addressString += marketingAddress.postal_code
        ? ", " + marketingAddress.postal_code
        : "";
      return addressString.slice(2);
    },
  },
};
</script>

<style lang="scss" scoped>
#mapdiv {
  min-height: 500px;
  width: 100%;
}
</style>
