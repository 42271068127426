<template>
  <div>
    <div v-if="loading">
      <loader />
    </div>

    <div v-else class="main-div" style="margin-left: 56px">
      <v-snackbar
        v-model="snackbar"
        :timeout="snackBarTimeout"
        :color="color"
        >{{ snackBarText }}</v-snackbar
      >

      <v-btn
        fab
        fixed
        bottom
        right
        color="green accent-2"
        @click="projectDialog = true"
      >
        <v-icon color="black">mdi-plus</v-icon>
      </v-btn>

      <div class="mx-auto pb-10 rounded-0 full-height" v-if="items != null">
        <v-sheet dark class="pa-12">
          <div class="d-flex align-center">
            <v-text-field
              v-model="search"
              class="black--text"
              label="Search Projects..."
              flat
              solo-inverted
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
            ></v-text-field>
            <!-- <v-btn
              large
              color="green accent-2"
              class="ml-2 black--text"
              @click="projectDialog = true"
            >
              Add Project
            </v-btn> -->
          </div>
        </v-sheet>

        <v-sheet
          :elevation="5"
          dark
          class="my-12 mx-12 py-5 px-10 main-content"
          style="position: relative"
        >
          <v-card-text>
            <div class="d-flex">
              <v-card-title>{{
                switchArchive ? "Archived Projects" : "Active Projects"
              }}</v-card-title>
              <v-spacer />
              <v-switch
                v-model="switchArchive"
                :label="`Show Archived Projects: ${switchArchive.toString()}`"
                @change="rearrangeProject"
              ></v-switch>
            </div>
            <v-treeview
              :items="items"
              :search="search"
              :filter="filter"
              :open.sync="open"
              :hoverable="hoverable"
            >
              <template v-slot:prepend="{ item }">
                <div class="d-flex">
                  <div>
                    <v-icon v-if="item.children">mdi-folder</v-icon>
                    <!-- <span class="item" @click="rowClick(item)">{{
                      item.Name
                    }}</span> -->

                    <span
                      v-if="!item.children"
                      class="item"
                      @click="rowClick(item)"
                    >
                      <v-icon small>mdi-launch</v-icon></span
                    >
                  </div>

                  <v-spacer />
                </div>
              </template>

              <template v-slot:append="{ item }">
                <v-btn
                  class="mx-3"
                  v-if="!item.children"
                  @click="updateStatus(item)"
                  x-small
                  outlined
                  color="orange accent-2"
                >
                  {{ switchArchive ? "Active" : "Archived" }}
                </v-btn>
              </template>
            </v-treeview>
          </v-card-text>
        </v-sheet>
      </div>

      <v-dialog v-model="projectDialog" max-width="600px">
        <v-card class="pa-5" dark>
          <v-card-title class="justify-center mb-5">
            <span class="headline">Create New Project</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Project Name*"
                    v-model="projectObject.Name"
                    :rules="[(v) => !!v || 'Name is required']"
                    required
                    outlined
                    color="white"
                  ></v-text-field>
                  <v-switch
                    v-model="projectObject.UsesPuppeteer"
                    label="Use Puppeteer"
                  ></v-switch>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Project Namespace*"
                    v-model="projectObject.Namespace"
                    :rules="[(v) => !!v || 'Namespace is required']"
                    required
                    outlined
                    color="white"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="white" text @click="projectDialog = false"
              >Close</v-btn
            >
            <v-btn
              color="green accent-2"
              class="black--text"
              :disabled="!projectObject.Name || !projectObject.Namespace"
              @click="addProject"
              >Create</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../../components/Loader.vue";

export default {
  name: "Projects",
  components: {
    Loader,
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASEURL,
      loading: true,

      switchArchive: false,

      projects: [],
      items: null,
      hoverable: true,
      open: [],
      search: null,
      caseSensitive: false,

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,

      projectDialog: false,
      projectObject: {
        Name: null,
        Namespace: null,
        UsesPuppeteer: true,
        StartModuleDefault: "Main",
        Version: 1,
        VersionUpgradeTo: null,
        Environment: "Dev",
        MetaData: {},
        Modules: [
          {
            DataDefaults: {
              PropertyName: [],
              ResmanStatusTypes: [],
            },
            Name: "Main",
            Steps: [],
          },
        ],
      },
    };
  },
  computed: {
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
  },
  methods: {
    updateStatus(item) {
      const status_data = this.switchArchive ? "active" : "archived";
      axios
        .put(
          `${process.env.VUE_APP_BASEURL}/hermosa/project/updateStatus/${item.id}`,
          {
            Status: status_data,
          }
        )
        .then((response) => {
          if (response) {
            if (response.data.status) {
              this.getProjects();
              this.projectDialog = false;
              this.color = "success";
              this.snackBarText = response.data.message;
            } else {
              this.color = "error";
              this.snackBarText = response.data.message;
            }
            this.snackbar = true;
          }
        });
    },

    rowClick(item) {
      if (item) {
        this.$router.push({
          name: "project",
          params: {
            id: item.Id,
          },
        });
      }
    },

    addProject(e) {
      //e.preventDefault();
      axios
        .put(`${process.env.VUE_APP_BASEURL}/hermosa/project/new/version/1`, {
          name: this.projectObject.Name,
          namespace: this.projectObject.Namespace,
          project: JSON.stringify(this.projectObject),
        })
        .then((response) => {
          if (response) {
            if (response.data.status) {
              this.getProjects();
              this.projectDialog = false;
              this.color = "success";
              this.snackBarText = response.data.message;
            } else {
              this.color = "error";
              this.snackBarText = response.data.message;
            }
            this.snackbar = true;
          }
        });
    },

    getProjects() {
      axios
        .get(process.env.VUE_APP_BASEURL + "/hermosa/project")
        .then((response) => {
          if (response.data.status) {
            this.projects =
              response.data.data.projects.length > 0
                ? response.data.data.projects
                : [];
            this.rearrangeProject();
          }
          console.log(this.projects);
        });
    },

    rearrangeProject() {
      const status = this.switchArchive ? "archived" : "active";
      const activeProject = this.projects.filter(
        (project) => project.Status === status
      );
      const updatedKeyProjects_name = activeProject.map(
        ({ Name: name, ...rest }) => ({ name, ...rest })
      );
      const updatedKeyProjects = updatedKeyProjects_name.map((el) => {
        var o = Object.assign({}, el);
        o.id = el.Id;
        return o;
      });
      let group = updatedKeyProjects.reduce((r, a) => {
        r[a.Namespace] = [...(r[a.Namespace] || []), a];
        return r;
      }, {});
      let result = [];
      Object.keys(group).forEach((key) => {
        let tempObj = this.createProperty(result, key);
        if (!tempObj.children) {
          tempObj.children = group[key];
        } else {
          tempObj.children = [...tempObj.children, ...group[key]];
        }
      });
      if (activeProject.length !== 0) {
        result[0].children.sort(function (a, b) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      }

      this.items = result;
      console.log("Itmes: ", this.items);
      this.loading = false;
    },

    createProperty(result2, path) {
      const propArray = path.split(".");
      let tempObj;
      for (let i = 0; i < propArray.length; i++) {
        const prop = propArray[i];
        let tempRef = tempObj ? tempObj.children : result2;
        const index = tempRef.findIndex((obj) => obj.name === prop);
        let obj;
        if (index === -1) {
          obj = {
            id: Math.floor(Math.random() * 1000) + 1,
            name: prop,
            children: [],
          };
          tempRef.push(obj);
        } else {
          obj = tempRef[index];
        }
        tempObj = obj;
      }
      return tempObj;
    },

    stopProject(item) {
      axios
        .post(
          process.env.VUE_APP_BASEURL +
            `/hermosa/project/${item.HermosaProjectId}/stop`,
          { version: item.Version }
        )
        .then((response) => {
          if (response) {
            if (response.data.status) {
              this.color = "success";
              this.snackBarText = response.data.message;
              this.snackbar = true;
            } else {
              this.color = "error";
              this.snackBarText = response.data.message;
              this.snackbar = true;
            }
          }
        });
    },
  },
  mounted() {
    this.getProjects();
  },
};
</script>
<style scoped>
h1 {
  text-align: center;
  margin: 10px;
}
.v-text-field .v-label {
  overflow: visible !important;
  top: 1% !important;
}
/* .label {
  color: #c7c7c7 !important;
} */
.item {
  cursor: pointer;
}

@media (max-width: 991px) {
  .main-div {
    margin-left: auto !important;

    .main-content {
      margin: 0 !important;
      padding: 5px !important;

      .d-flex {
        display: block !important;
      }
      .v-input {
        margin: 16px !important;
      }
    }
  }
}
</style>
