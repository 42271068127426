<template>
  <div class="full-height pa-10" style="margin-left: 56px">
    <div v-if="loading">
      <loader />
    </div>

    <v-snackbar v-model="snackbar" :timeout="snackBarTimeout" :color="color">{{
      snackBarText
    }}</v-snackbar>

    <div class="mx-auto rounded-0 full-height">
      <v-sheet class="pa-4" color="grey darken-3" data-app>
        <v-row class="align-center justify-center">
          <v-col>
            <v-text-field
              class="pa-2 my-custom-search"
              v-model="search"
              label="Search Loan Applications..."
              flat
              solo-inverted
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
            ></v-text-field>
          </v-col>

          <v-col align="end">
            <v-btn class="mr-10" small outlined @click="exportAsCsv">
              Export CSV
            </v-btn>
            <v-btn small outlined @click="fetchLosData">
              Refresh Application
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>
      <div>
        <v-data-table
          :headers="losHeaders"
          :items="loan"
          :items-per-page="50"
          item-key="email"
          :search="search"
          sort-by="creation_date"
          :sort-desc="true"
          class="elevation-1"
        >
          <template v-slot:item.creation_date="{ item }">
            <div v-if="item.creation_date">
              {{
                new Date(item.creation_date * 1000).toLocaleString("en-US", {
                  year: "2-digit",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })
              }}
            </div>
          </template>
          <template v-slot:item.status="{ item }">
            <div>{{ getStatusLabel(item.status) }}</div>
          </template>
          <template v-slot:item.substatus="{ item }">
            <div>{{ getSubStatusLabel(item.substatus) }}</div>
          </template>
          <template v-slot:item.actions="{ item }">
            <router-link :to="`/los/loans/${item.id}/${item.loan_id}`">
              <v-btn x-small outlined color="green accent-2">
                View Details
              </v-btn>
            </router-link>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../../../components/Loader.vue";
import { firebaseDB } from "../../../auth/firebase-auth";

export default {
  template: "#app-template",
  name: "loans",
  components: {
    Loader,
  },
  data() {
    return {
      loanData: [],
      applicationData: {},
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      loading: true,
      color: "success",
      snackbar: false,
      snackBarText: "",
      tab: "",
      snackBarTimeout: 2000,
      search: "",
      loan: [],
      los: [],
      webLoans: [],
      losHeaders: [
        {
          text: "Loan Title",
          align: "start",
          value: "loan_title",
        },
        {
          text: "Reference #",
          align: "start",
          value: "loan_id",
        },
        {
          text: "Address",
          align: "start",
          value: "property.address.street",
        },
        {
          text: "City",
          align: "start",
          value: "property.address.city",
        },
        {
          text: "State",
          align: "start",
          value: "property.address.prov",
        },
        {
          text: "Created At",
          align: "start",
          value: "creation_date",
        },
        {
          text: "Status",
          align: "center",
          value: "status",
        },
        {
          text: "SubStatus",
          align: "center",
          value: "substatus",
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
        },
      ],
    };
  },
  mounted() {
    let start = Date.now();
    console.log("Started : ", start);
    this.fetchLosData();
  },
  methods: {
    getStatusLabel(statusCode) {
      const statusMap = {
        0: "In Progress",
        1: "Funded",
        2: "Discharged",
        3: "Terminated",
      };
      return statusMap[statusCode] || "Unknown Status";
    },
    getSubStatusLabel(statusCode) {
      const statusMap = {
        25672: "Online Imports for In Progress",
        25267: "Applications for In Progress",
        25544: "LO Underwriting for In Progress",
        25784: "Withdrawn/Denied for In Progress",
        25906: "Pre-Approved for In Progress",
        25247: "Active Loan for In Progress",
        25248: "Ordered Docs for In Progress",
        25703: "Pending BPO/Appraisal for In Progress",
        25250: "Pending Title for In Progress",
        25249: "LO Review for In Progress",
        25251: "Send to Attorney for In Progress",
        25252: "Attorney Prepping Docs for In Progress",
        25253: "LO Docs @ Title for In Progress",
        25254: "HUD Approved for In Progress",
        25395: "CLOSED for In Progress",
        25256: "-- No Status -- for Funded",
        25378: "Missing ACH for Funded",
        25257: "NSF Payment for Funded",
        25258: "Payment Deferral Req'd for Funded",
        25259: "Irregular Payment for Funded",
        25260: "Non-Renewal for Funded",
        25261: "Renewal Requested for Funded",
        25262: "Pending Discharge for Funded",
        25263: "Past Maturity for Funded",
        25271: "Foreclosure/BK Process for Funded",
        25270: "REO for Funded",
        0: "Substatus is not set",
      };

      return statusMap[statusCode] || " ";
    },

    async fetchLosData() {
      this.loading = true;
      const losRef = firebaseDB.firestore().collection("mortgageLoans");
      try {
        const { docs } = await losRef.orderBy("creation_date", "desc").get();
        this.loan = docs.map((doc) => {
          const { id } = doc;
          const data = doc.data();
          return { id, ...data };
        });
        this.loading = false;
      } catch (error) {
        console.log("Error in fetching loan data:\n" + error);
        this.loading = false;
      }
    },

    async exportAsCsv() {
      this.loading = true;
      const losRef = firebaseDB.firestore().collection("mortgageLoans");

      try {
        const querySnapshot = await losRef
          .where("status", "==", 1)
          .orderBy("creation_date", "asc")
          .limit(500)
          .get();

        const uniqueZipCodes = new Set();
        const uniqueDocs = [];

        querySnapshot.forEach((doc) => {
          const subjectPropertyZipCode = doc.data();
          const zip = subjectPropertyZipCode.property.address.zip;
          if (!uniqueZipCodes.has(zip)) {
            uniqueZipCodes.add(zip);
            uniqueDocs.push(doc);
          }
        });
        const fetchNestedDocs = uniqueDocs.map(async (doc) => {
          const { latestExportDataId } = doc.data();
          const id = doc.id;

          if (latestExportDataId) {
            const nestedDocRef = losRef
              .doc(id)
              .collection("export")
              .doc(latestExportDataId);
            const nestedDocSnapshot = await nestedDocRef.get();
            if (nestedDocSnapshot.exists) {
              this.loanData.push(nestedDocSnapshot.data());
            }
          }
        });

        await Promise.all(fetchNestedDocs);
      } catch (error) {
        console.error("Error fetching mortgage loans:", error);
      }

      const csvFields = [
        "Subject Property Address",
        "Subject Property City",
        "Subject Property State",
        "Subject Property Zip Code",
        "Square Feet",
        "Purchase Price",
        "Rehab Budget",
        "Rehab Budget Per Square Feet",
        "As Is Value (AVM)",
        "Attom AVM",
        "Investor ARV",
        "Keystone (v4)",
        "% of houses priced at ARV or higher (can we add up all the….housing_Owner_Households_Valued_15000_19999_Pct  that are at the ARV and Higher?)",
        "Affordability",
        "Crime Index",
        "Income Under $15000",
        "population_In_Poverty_Pct",
        "housing_Median_Built_Yr",
        "housing_Built_2000_2009_Pct",
        "housing_Built_2010_Or_Later_Pct",
        "avg_Household_Income",
        "household_Income_Per_Capita",
        "population",
        "population_5_Yr_Projection",
        "population_Chg_Pct_2020",
        "population_Chg_Pct_5_Yr_Projection",
        "population_Density_Sq_Mi",
      ];

      const fields = [
        "subjectPropertyAddress",
        "subjectPropertyCity",
        "subjectPropertyState",
        "subjectPropertyZipCode",
        "squareFeet",
        "purchasePrice",
        "rehabBudget",
        "rehabBudgetPerSquareFeet",
        "asIsValue",
        "attomAVM",
        "investorARV",
        "keystone(v4)",
        "arvAndHigherValuedHouseholds",
        "affordability",
        "crimeIndex",
        "incomeUnder$15000",
        "population_In_Poverty_Pct",
        "housing_Median_Built_Yr",
        "housing_Built_2000_2009_Pct",
        "housing_Built_2010_Or_Later_Pct",
        "avg_Household_Income",
        "household_Income_Per_Capita",
        "population",
        "population_5_Yr_Projection",
        "population_Chg_Pct_2020",
        "population_Chg_Pct_5_Yr_Projection",
        "population_Density_Sq_Mi",
      ];
      const filename = "FundedLoans.csv";
      const csvRows = this.loanData.map((item) => {
        return fields
          .map((field) => {
            let value = item[field] !== undefined ? item[field] : "";
            if (typeof value === "string" && value.includes(",")) {
              value = `"${value}"`;
            }
            return value;
          })
          .join(",");
      });

      const csvContent = [csvFields.join(","), ...csvRows].join("\n");

      const csvBlob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8;",
      });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(csvBlob, filename);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(csvBlob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.my-custom-search {
  width: 15 00px;
  font-size: 16px;
}
</style>
