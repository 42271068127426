<template>
  <div class="full-height pa-10" style="margin-left: 56px">
    <div v-if="loading">
      <loader />
    </div>

    <div>
      <!-- <h1>Lead Details</h1> -->
      <v-row class="">
        <v-col sm="12" lg="3">
          <v-card color="grey darken-3">
            <v-card-text>
              <div class="text-h5 pb-4 text--primary">
                {{ lendersData["LendingCompanies"] }}
              </div>
              <span>Lending Company</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="12" lg="3">
          <v-card color="grey darken-3">
            <v-card-text>
              <p class="text-h5 text--primary">
                {{ lendersData["NumberOfFlips"] }}
              </p>
              <span>No of Flips</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="12" lg="3">
          <v-card color="grey darken-3">
            <v-card-text>
              <p class="text-h5 text--primary">
                {{
                  lendersData &&
                  lendersData["LatestActivity"] &&
                  !isNaN(new Date(lendersData["LatestActivity"]))
                    ? new Date(lendersData["LatestActivity"])
                        .toLocaleDateString("en-US", {
                          year: "2-digit",
                          month: "numeric",
                          day: "numeric",
                        })
                        .replace(/\//g, "-")
                    : "-"
                }}
              </p>
              <span>Latest Activity</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="12" lg="3">
          <v-card color="grey darken-3">
            <v-card-text>
              <p class="text-h5 text--primary">
                {{
                  lendersData["AverageTransferAmount"]
                    ? "$" +
                      parseInt(
                        lendersData["AverageTransferAmount"]
                      ).toLocaleString("en-US")
                    : "-"
                }}
              </p>
              <span>Average Property Cost</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-sheet :elevation="5" dark class="pa-5 mt-10">
      <div class="text-subtitle-1 white--text pb-5">Lenders Details</div>

      <div class="code-details" v-if="this.loading == false">
        <MonacoEditor
          v-model="this.items"
          width="100%"
          height="400"
          theme="vs-dark"
          language="json"
          :options="options"
        >
        </MonacoEditor>
      </div>
    </v-sheet>

    <v-row>
      <v-col lg="6">
        <v-sheet :elevation="5" dark class="pa-5 mt-10" height="700px">
          <div class="text-subtitle-1 white--text pb-5">
            Flipped Properties Locations
          </div>
          <div id="mapdiv"></div>
        </v-sheet>
      </v-col>

      <v-col lg="6">
        <v-sheet :elevation="5" dark class="pa-5 mt-10 ml-200" height="700px">
          <div class="text-subtitle-1 white--text pb-5">Lenders Timeline</div>
          <div
            class="d-flex justify-center"
            style="height: 90%; overflow: scroll"
          >
            <div>
              <template>
                <v-timeline density="compact" side="end" align="center">
                  <v-timeline-item
                    v-for="item of lendersDetails"
                    :key="item.FLIPPurchaseMortgage1LenderNameFullStandardized"
                    small
                    class="timeline-item"
                  >
                    <div class="mt-2 text-caption align-self-end">
                      <p><strong>Sale Date: </strong>{{ item.FLIPSaleDate }}</p>
                      <p>
                        <strong>Sale Transfer Amount: </strong>
                        ${{
                          parseInt(item.FLIPSaleTransferAmount).toLocaleString(
                            "en-US"
                          )
                        }}
                      </p>
                      <p>
                        <strong>Address: </strong
                        >{{ item.PropertyAddressFull }},
                        {{ item.PropertyAddressCity }},
                        {{ item.PropertyAddressState }},
                        {{ item.PropertyAddressZIP }}
                      </p>

                      <v-btn
                        x-small
                        outlined
                        color="green accent-2"
                        class="black--text"
                        @click="openDialog(item)"
                      >
                        View Details
                      </v-btn>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </template>
            </div>
            <v-dialog v-model="dialogVisible" max-width="500px">
              <v-card>
                <v-card-title class="headline">Details</v-card-title>
                <v-card-text>
                  {{ selectedLenderItem }}
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" text @click="closeDialog">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MonacoEditor from "monaco-editor-vue";
import Loader from "../../components/Loader.vue";
import axios from "axios";
export default {
  components: { MonacoEditor, Loader },

  data() {
    return {
      options: {},
      loading: false,
      lendersData: [],
      items: null,
      lendersDetails: null,
      lendersDataString: null,
      dialogVisible: false,
      selectedLenderItem: null,
    };
  },
  mounted() {
    this.lendersDataString = this.$route.query.lendersData;
    this.lendersData = JSON.parse(this.lendersDataString);
    this.getMarketLendersData();
  },
  methods: {
    openDialog(logItem) {
      this.selectedLenderItem = logItem;
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    async propertiesMap(items) {
      this.loading = true;
      const canvas = document.createElement("canvas");
      const gl = canvas.getContext("webgl");
      gl.getParameter(gl.RENDERER);

      const mapDiv = document.getElementById("mapdiv");

      const randomIndex = Math.floor(Math.random() * items.length);
      const randomObject = items[randomIndex];

      console.log("Items : ", randomObject);

      const latLong = {
        lat: Number(randomObject.PropertyLatitude),
        lng: Number(randomObject.PropertyLongitude),
      };

      const zoom = 11;
      const options = {
        zoom,
        styles: [
          { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
          {
            elementType: "labels.text.stroke",
            stylers: [{ color: "#242f3e" }],
          },
          { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
          {
            featureType: "administrative.locality",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#263c3f" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#6b9a76" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#38414e" }],
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#212a37" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9ca5b3" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#746855" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#1f2835" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{ color: "#f3d19c" }],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#2f3948" }],
          },
          {
            featureType: "transit.station",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#17263c" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#515c6d" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#17263c" }],
          },
        ],
      };

      this.map = new google.maps.Map(mapDiv, options);

      for (let i = 0; i < items.length; i++) {
        let latlong = {
          lat: Number(items[i].PropertyLatitude),
          lng: Number(items[i].PropertyLongitude),
        };
        var local_marker = new google.maps.Marker({
          position: latlong,
          map: this.map,
          title: items[i].PropertyAddressFull,
          animation: google.maps.Animation.DROP,
        });

        // Create infoWindow for each marker
        var infoWindow = new google.maps.InfoWindow({
          content: `
          <div id="content">
          <div id="bodyContent">
          </br>
          <p style='color: black;'>
            <b>Address:</b> ${items[i].PropertyAddressFull} </br>
            <b>City:</b> ${items[i].PropertyAddressCity} </br>
            <b>State:</b> ${items[i].PropertyAddressState}
          </p>
          <p style='color: black;'>
            <b>Amount:</b> $${parseInt(
              items[i].FLIPSaleTransferAmount
            ).toLocaleString("en-US")} </br>
            <b>Sale Date:</b> ${items[i].FLIPSaleDate} 
          </p>
          
          </div>
          </div>
          
            
            `,
        });

        // Attach click event listener to marker
        local_marker.addListener(
          "click",
          (function (marker, infoWin) {
            return function () {
              infoWin.open(this.map, marker);
            };
          })(local_marker, infoWindow)
        );
      }

      this.loading = false;
      this.map.setCenter(latLong);
    },

    async getMarketLendersData() {
      this.loading = true;

      try {
        const key = "21b77cec7cb516c40d9d7216ae74e3c3";
        console.log(
          `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/lender/detail?LendingCompanies=${this.lendersData["LendingCompanies"]}&key=${key}`
        );
        await axios
          .get(
            `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/lender/detail?LendingCompanies=${this.lendersData["LendingCompanies"]}&key=${key}`
            // `http://localhost:3000/lender/detail?LendingCompanies=${this.lendersData["LendingCompanies"]}&key=${key}`
          )
          .then((response) => {
            this.lendersDetails = response.data.flips;
            this.lendersDetails.sort(
              (a, b) => new Date(b.FLIPSaleDate) - new Date(a.FLIPSaleDate)
            );
            this.propertiesMap(this.lendersDetails);
            this.items = JSON.stringify(this.lendersDetails);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("Error in fetching markets data:\n" + error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#mapdiv {
  min-height: 600px;
  width: 100%;
}
</style>
