var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-height pa-5",staticStyle:{"margin-left":"56px"}},[(_vm.loading)?_c('div',[_c('loader')],1):_vm._e(),_c('v-snackbar',{attrs:{"timeout":_vm.snackBarTimeout,"color":_vm.color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackBarText))]),_c('div',[_c('v-sheet',{staticClass:"pa-10",staticStyle:{"position":"relative"},attrs:{"elevation":5,"dark":""}},[(_vm.mappedCompanyObj != null)?_c('div',{staticClass:"field-detail"},[_c('v-row',_vm._l((_vm.mappedCompanyObj),function(value,propertyName,i){return _c('v-col',{key:i,attrs:{"lg":"6"}},[[_c('v-text-field',{attrs:{"value":value,"label":propertyName,"outlined":"","readonly":""}})]],2)}),1),(_vm.photokey != null)?[_c('v-row',{staticClass:"image-grid"},_vm._l((_vm.count),function(index){return _c('v-col',{key:index},[_c('v-img',{attrs:{"src":_vm.combineImageURL(
                    'https://d2sa73fkuaa019.cloudfront.net/listings/cbrrets/',
                    _vm.photokey,
                    index
                  ),"aspect-ratio":"1","contain":""},on:{"click":function($event){_vm.openImageModal(
                    _vm.combineImageURL(
                      'https://d2sa73fkuaa019.cloudfront.net/listings/cbrrets/',
                      _vm.photokey,
                      index
                    )
                  )}}})],1)}),1)]:_vm._e()],2):_vm._e()])],1),(_vm.showModal)?_c('div',{staticClass:"modal"},[_c('span',{staticClass:"close",on:{"click":_vm.closeImageModal}},[_vm._v("×")]),_c('img',{attrs:{"src":_vm.selectedImage,"alt":"Full Size Image"}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }