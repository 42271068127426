<template>
  <div class="full-height pa-5" style="margin-left: 56px">
    <div v-if="this.loading">
      <loader />
    </div>

    <v-snackbar v-model="snackbar" :timeout="snackBarTimeout" :color="color">{{
      snackBarText
    }}</v-snackbar>

    <div class="mx-auto rounded-0 full-height">
      <v-tabs
        v-model="tab"
        color="green accent-2"
        slider-color="green accent-2"
      >
        <v-tab>Hot ({{ (totalCrmData / 1000).toFixed(1) + "K" }})</v-tab>
        <v-tab>Warm ({{ (totalShapeRecords / 1000).toFixed(1) + "K" }})</v-tab>
        <v-tab>Cold ({{ (totalLeads / 1000).toFixed(1) + "K" }})</v-tab>
        <v-tab
          >Forecasa ({{ (totalCompanyRecords / 1000).toFixed(1) + "K" }})</v-tab
        >
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-sheet class="pa-4" color="grey darken-3" data-app>
            <div class="d-flex align-center justify-end">
              <v-text-field
                v-model="crmSearch"
                label="Search CRM..."
                flat
                solo-inverted
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
              ></v-text-field>
              <v-btn
                class="ml-2 pl-11 pr-11"
                outlined
                color="green accent-2"
                @click="exportAsCsv('crm')"
                >Export</v-btn
              >
              <v-btn
                class="ml-2"
                outlined
                color="green accent-2"
                @click="addToHuspot('items')"
                >Add to Hubspot</v-btn
              >
            </div>
          </v-sheet>
          <div>
            <v-data-table
              :headers="crmHeaders"
              :items="crm"
              :items-per-page="10"
              item-key="email"
              :search="crmSearch"
              class="elevation-1"
              show-select
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <v-checkbox
                      :value="isSelected(item)"
                      @change="toggleSelection(item)"
                      class="ma-0"
                      hide-details
                    ></v-checkbox>
                  </td>
                  <td>{{ item.emailAddress }}</td>
                  <td>{{ item.firstName }}</td>
                  <td>{{ item.lastName }}</td>
                  <td>
                    <router-link :to="`/marketing/crm/${item.id}?tab=1`">
                      <v-btn x-small outlined color="green accent-2">
                        View Details
                      </v-btn>
                    </router-link>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-tab-item>
        <v-tab-item>
          <v-sheet class="pa-4" color="grey darken-3" data-app>
            <div class="d-flex align-center">
              <v-text-field
                v-model="shapeSearch"
                label="Search Shapes..."
                flat
                solo-inverted
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
              ></v-text-field>
              <v-btn
                class="ml-2 pl-11 pr-11"
                outlined
                color="green accent-2"
                @click="exportAsCsv('shapes')"
                >Export</v-btn
              >
              <v-btn
                class="ml-2"
                outlined
                color="green accent-2"
                @click="addToHuspot('shapes')"
                >Add to Hubspot</v-btn
              >
            </div>
          </v-sheet>
          <div>
            <v-data-table
              :headers="shapesHeaders"
              :items="shapesData"
              :items-per-page="limit"
              item-key="Email"
              :search="shapeSearch"
              hide-default-footer
              class="elevation-1"
              show-select
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <v-checkbox
                      :value="isSelectedShape(item)"
                      @change="toggleShapeSelection(item)"
                      class="ma-0"
                      hide-details
                    ></v-checkbox>
                  </td>
                  <td>{{ item.Email }}</td>
                  <td>{{ item.FirstName }}</td>
                  <td>{{ item.LastName }}</td>
                  <td>{{ item.MobilePhone }}</td>
                  <td>{{ item.Purpose }}</td>
                  <td>{{ item.Source }}</td>
                  <td>{{ item.Status }}</td>
                  <td>{{ item.SubjectPropertyFullAddress }}</td>
                </tr>
              </template>
            </v-data-table>

            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="5"
              @input="fetchShapesData"
            ></v-pagination>
          </div>
        </v-tab-item>
        <v-tab-item>
          <v-sheet class="pa-4" color="grey darken-3" data-app>
            <div class="d-flex align-center">
              <v-text-field
                v-model="marketSearch"
                label="Search Leads..."
                flat
                solo-inverted
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
              ></v-text-field>
              <v-btn
                class="ml-2 pl-11 pr-11"
                outlined
                color="green accent-2"
                @click="exportAsCsv('flips')"
                >Export</v-btn
              >
              <v-btn
                class="ml-2"
                outlined
                color="green accent-2"
                @click="addToHuspot('flips')"
                >Add to Hubspot</v-btn
              >
              <v-btn
                class="ml-2"
                outlined
                color="white accent-2"
                @click="openFilterModal"
                >Filter</v-btn
              >
              <v-dialog v-model="showModal" max-width="500">
                <v-card>
                  <v-card-title class="headline">Filter</v-card-title>
                  <v-card-text>
                    <h4>Number of Property flips</h4>
                    <v-select
                      class="mt-3"
                      v-model="selectedOperator"
                      label="Comparision Operators"
                      :items="operatorItems"
                      outlined
                      dense
                      @change="updateNumberOfFlipsValidation"
                    ></v-select>
                    <div class="filter-item">
                      <v-text-field
                        ref="numberOfFlipsField"
                        outlined
                        label="Value"
                        v-model="numberOfFlips"
                        dense
                        :disabled="!isNumberOfFlipsFieldEnabled"
                        :rules="numberOfFlipsRules"
                      ></v-text-field>
                    </div>
                  </v-card-text>
                  <v-card-text>
                    <h4>Recent Activity</h4>
                    <div class="filter-item">
                      <v-text-field
                        class="mt-3"
                        outlined
                        label="Recent Activity Date"
                        type="date"
                        v-model="recentActivityDate"
                        dense
                      ></v-text-field>
                    </div>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn @click="applyFilters">Apply Filters</v-btn>
                    <v-btn @click="clearFilterModal">Clear Filters</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-sheet>

          <div>
            <!-- {{ selectedLeadItems }} -->
            <v-data-table
              :headers="marketHeaders"
              :items="marketData"
              :items-per-page="limit"
              item-key="Full Name"
              :search="marketSearch"
              hide-default-footer
              class="elevation-1"
              show-select
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <v-checkbox
                      :value="isSelectedLead(item)"
                      @change="toggleLeadSelection(item)"
                      class="ma-0"
                      hide-details
                    ></v-checkbox>
                  </td>
                  <td>{{ item.FLIPGrantor1NameFull }}</td>
                  <td>{{ item.NumberOfFlips }}</td>
                  <td>
                    <div v-if="item.LatestActivity">
                      {{
                        new Date(item.LatestActivity)
                          .toLocaleDateString("en-US", {
                            year: "2-digit",
                            month: "numeric",
                            day: "numeric",
                          })
                          .replace(/\//g, "-")
                      }}
                    </div>
                  </td>
                  <td>
                    <div v-if="item.AverageTransferAmount">
                      ${{
                        parseInt(item.AverageTransferAmount).toLocaleString(
                          "en-US"
                        )
                      }}
                    </div>
                    <div v-else>-</div>
                  </td>
                  <td>{{ item.Score.toFixed(1) }}</td>
                  <td>
                    <router-link
                      :to="{
                        path: `/marketing/leads/${item.FLIPGrantor1NameFull}`,
                        query: { leadsData: JSON.stringify(item) },
                      }"
                    >
                      <v-btn
                        x-small
                        outlined
                        color="green accent-2"
                        class="black--text"
                      >
                        View Details
                      </v-btn>
                    </router-link>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="5"
              @input="fetchMarketLeadsData()"
            ></v-pagination>
          </div>
        </v-tab-item>
        <v-tab-item>
          <v-sheet class="pa-4" color="grey darken-3" data-app>
            <div class="d-flex align-center">
              <v-text-field
                v-model="companySearch"
                label="Search Companies..."
                flat
                solo-inverted
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
              ></v-text-field>
              <v-btn
                class="ml-2 pl-11 pr-11"
                outlined
                color="green accent-2"
                @click="exportAsCsv()"
                >Export</v-btn
              >
              <v-btn
                class="ml-2"
                outlined
                color="green accent-2"
                @click="addToHuspot()"
                >Add to Hubspot</v-btn
              >
            </div>
          </v-sheet>

          <div>
            <!-- {{ selectedLeadItems }} -->
            <v-data-table
              :headers="companiesHeaders"
              :items="CompaniesData"
              :items-per-page="limit"
              item-key="id"
              :search="companySearch"
              hide-default-footer
              class="elevation-1"
              show-select
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <v-checkbox
                      :value="isSelectedCompany(item)"
                      @change="toggleCompanySelection(item)"
                      class="ma-0"
                      hide-details
                    ></v-checkbox>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.principal_name }}</td>
                  <td>{{ item.email_address }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.mortgage_transactions }}</td>
                  <td>{{ item.last_transaction_date }}</td>
                  <td>{{ item.Score }}</td>
                  <td>
                    <router-link :to="`/marketing/company/${item.id}?tab=1`">
                      <v-btn x-small outlined color="green accent-2">
                        View Details
                      </v-btn>
                    </router-link>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="5"
              @input="fetchCompaniesData()"
            ></v-pagination>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../../../components/Loader.vue";
import { firebaseDB } from "../../../auth/firebase-auth";

export default {
  name: "crm",
  components: {
    Loader,
  },
  data() {
    return {
      showModal: false,
      compareOperator: null,
      selectedOperator: null,
      numberOfFlips: null,
      recentActivityDate: null,
      isNumberOfFlipsFieldEnabled: false, // Initially enabled
      numberOfFlipsRules: [
        (v) => !!v || "Number of Property flips is required",
      ],

      singleSelect: false,
      loading: true,
      limit: 100,
      page: 1,
      totalLeads: 0,
      totalCrmData: 0,
      selected: [],
      shapeSearch: "",
      shapesData: null,
      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,
      tab: "",
      totalShapeRecords: 0,
      totalCompanyRecords: 0,
      pageCount: 0,

      marketHeaders: [
        {
          text: "Full Name",
          align: "start",
          width: "100",
          value: "FLIPGrantor1NameFull",
        },
        {
          text: "No of Flips",
          value: "NumberOfFlips",
          align: "start",
          width: "100",
        },
        {
          text: "Latest Activity",
          value: "LatestActivity",
          align: "start",
          width: "100",
        },
        {
          text: "Average Property Cost",
          value: "AverageTransferAmount",
          align: "start",
          width: "100",
        },
        {
          text: "Score",
          value: "OverallScore",
          align: "start",
          width: "100",
        },
        {
          text: "Actions",
          value: "actions",
          align: "start",
          width: "100",
        },
      ],
      marketData: [],
      marketSearch: "",
      selectedItems: [],
      selectedShapeItems: [],
      selectedLeadItems: [],
      selectedCompanyItems: [],

      crm: [],
      csvAllData: null,
      crmSearch: "",
      crmHeaders: [
        {
          text: "Email",
          align: "start",
          width: "380",
          value: "emailAddress",
        },
        {
          text: "First Name",
          align: "start",
          width: "150",
          value: "firstName",
        },
        {
          text: "Last Name",
          align: "start",
          width: "150",
          value: "lastName",
        },
        {
          text: "Actions",
          align: "start",
          width: "100",
          value: "actions",
        },
      ],
      shapesHeaders: [
        {
          text: "Email",
          align: "start",
          width: "100",
          value: "Email",
        },
        {
          text: "First Name",
          value: "FirstName",
          align: "start",
          width: "50",
        },
        {
          text: "Last Name",
          value: "LastName",
          align: "start",
          width: "50",
        },
        {
          text: "Mobile",
          value: "MobilePhone",
          align: "start",
          width: "100",
        },
        {
          text: "Purpose",
          value: "Purpose",
          align: "start",
          width: "100",
        },
        {
          text: "Source",
          value: "Source",
          align: "start",
          width: "50",
        },
        {
          text: "Status",
          value: "Status",
          align: "start",
          width: "100",
        },
        {
          text: "Subject Property",
          value: "SubjectPropertyFullAddress",
          align: "start",
          width: "200",
        },
      ],
      companiesHeaders: [
        {
          text: "Name",
          align: "start",
          width: "100",
          value: "name",
        },
        {
          text: "Principal Name",
          align: "start",
          width: "100",
          value: "principal_name",
        },
        {
          text: "Email",
          align: "start",
          width: "100",
          value: "email_address",
        },
        {
          text: "Phone Number",
          align: "start",
          width: "100",
          value: "phone",
        },
        {
          text: "Mortgage Transactions",
          align: "start",
          width: "100",
          value: "mortgage_transactions",
        },
        {
          text: "Latest Transactions Date",
          align: "start",
          width: "100",
          value: "last_transaction_date",
        },
        {
          text: "Score",
          align: "start",
          width: "100",
          value: "Score",
        },
        {
          text: "Actions",
          align: "start",
          width: "100",
          value: "actions",
        },
      ],
      CompaniesData: [],
      companySearch: "",
    };
  },

  mounted() {
    this.fetchCrmData();
    this.fetchShapesData();
    this.fetchMarketLeadsData();
    this.fetchCompaniesData();
  },

  computed: {
    operatorItems() {
      return [
        { text: "Less than", value: "lt" },
        { text: "Equal or less than", value: "lte" },
        { text: "Equal", value: "eq" },
        { text: "Greater than", value: "gt" },
        { text: "Equal or greater than", value: "gte" },
      ];
    },
  },

  methods: {
    async exportAsCsv(type) {
      let csvFields, csvHeader, filename;
      if (type === "crm") {
        csvFields = ["emailAddress", "firstName", "lastName"];
        csvHeader = ["Email", "First Name", "Last Name"];
        filename = "hot_data.csv";
        this.csvAllData = this.crm;
      } else if (type === "shapes") {
        csvFields = [
          "Email",
          "FirstName",
          "LastName",
          "MobilePhone",
          "Purpose",
          "Source",
          "Status",
          "SubjectPropertyFullAddress",
        ];
        csvHeader = [
          "Email",
          "First Name",
          "Last Name",
          "Mobile",
          "Purpose",
          "Source",
          "Status",
          "Subject Property",
        ];
        filename = "warm_data.csv";
        this.loading = true;

        try {
          const key = "21b77cec7cb516c40d9d7216ae74e3c3";

          console.log(
            `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/shapes?page=${1}&limit=${
              this.totalShapeRecords
            }&key=${key}`
          );
          await axios
            .get(
              `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/shapes?page=${1}&limit=${
                this.totalShapeRecords
              }&key=${key}`
              // `http://localhost:3000/shapes?page=${this.page}&limit=${this.limit}&key=${key}`
            )
            .then((response) => {
              console.log("response", response);
              this.csvAllData = response.data.shapes;
              // console.log(
              //   "Shapes Data ---------------to download: ",
              //   this.csvAllData
              // );
              this.loading = false;
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } catch (error) {
          this.loading = false;

          console.log("Error in fetching Shapes data:\n" + error);
        }
      } else if (type === "flips") {
        csvFields = [
          "FLIPGrantor1NameFull",
          "NumberOfFlips",
          "LatestActivity",
          "LatestAddressInfo",
          "AverageTransferAmount",
        ];
        csvHeader = [
          "Full Name",
          "No of Flips",
          "Latest Activity",
          "Mailing Address",
          "Average Property Cost",
        ];
        filename = "cold_data.csv";
        this.loading = true;

        try {
          const key = "21b77cec7cb516c40d9d7216ae74e3c3";

          console.log(
            `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/leads?page=${1}&limit=${
              this.totalLeads
            }&key=${key}`
          );
          let apiURL = `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/leads?page=${1}&limit=${
            this.totalLeads
          }&totalRecords=${this.totalLeads}&totalRecords=${
            this.totalLeads
          }&key=${key}`;
          // let apiURL = `http://127.0.0.1:3000/leads?page=${this.page}&limit=${this.limit}&key=${key}`;

          if (this.selectedOperator) {
            apiURL += `&compareValue=${this.selectedOperator}`;
          }
          if (this.numberOfFlips) {
            apiURL += `&numberOfFlips=${this.numberOfFlips}`;
          }
          if (this.recentActivityDate) {
            apiURL += `&recentActivityDate=${this.recentActivityDate}`;
          }
          console.log("API", apiURL);

          await axios
            .get(apiURL)
            .then((response) => {
              console.log("response", response);
              this.csvAllData = response.data.flips;
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
          this.loading = false;
        } catch (error) {
          this.loading = false;

          console.log("Error in fetching Leads data:\n" + error);
        }
      } else {
        csvFields = [
          "Name",
          "PrincipalName",
          "Email",
          "PhoneNumber",
          "MortgageTransactions",
          "LastLenderUsed",
          "LastMortgageDate",
          "AvgMortgageAmount",
        ];
        csvHeader = [
          "Name",
          "Principal Name",
          "Email",
          "Phone Number",
          "Mortgage Transactions",
          "Last Lender Used",
          "Last Mortgage Date",
          "Avg Mortgage Amount",
        ];
        filename = "companies_data.csv";
        this.loading = true;

        try {
          const key = "21b77cec7cb516c40d9d7216ae74e3c3";
          await axios
            .get(
              `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/companies?page=${this.page}&limit=${this.totalCompanyRecords}&key=${key}`
              // `http://localhost:3000/companies?page=${this.page}&limit=${this.totalCompanyRecords}&key=${key}`
            )
            .then((response) => {
              console.log("response", response);
              this.csvAllData = response.data.companiesData;
              this.loading = false;
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } catch (error) {
          this.loading = false;

          console.log("Error in fetching Companies data:\n" + error);
        }
      }

      const csvData = this.csvAllData.map((item) => {
        const rowData = csvFields.map((field) => {
          const value = item[field];

          if (field === "LatestActivity") {
            const formattedDate = new Date(value)
              .toLocaleDateString("en-US", {
                year: "2-digit",
                month: "numeric",
                day: "numeric",
              })
              .replace(/\//g, "-");

            return `"${formattedDate}"`;
          }
          if (field === "LatestAddressInfo") {
            const addressInfo = item[field];
            const addressString =
              `${addressInfo.FLIPGrantorMailAddressFull || ""} ` +
              `${addressInfo.FLIPGRANTORMailAddressCity || ""}, ` +
              `${addressInfo.FLIPGRANTORMailAddressState || ""} ` +
              `${addressInfo.FLIPGRANTORMailAddressZIP || ""}`;
            return `"${addressString}"`;
          }

          return `"${value ? value.toString().replace(/"/g, '""') : ""}"`;
        });
        return rowData.join(",");
      });

      const csvRows = [csvHeader, ...csvData].join("\n");
      const csvBlob = new Blob([csvRows], { type: "text/csv;charset=utf-8;" });

      // Check if the browser supports the download attribute
      if (navigator.msSaveBlob) {
        // For Internet Explorer and Microsoft Edge
        navigator.msSaveBlob(csvBlob, filename);
      } else {
        // For other modern browsers
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(csvBlob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    updateNumberOfFlipsValidation() {
      // Enable or disable the numberOfFlipsField based on the selection
      this.isNumberOfFlipsFieldEnabled = !!this.selectedOperator;
    },
    openFilterModal() {
      this.showModal = true;
    },
    applyFilters() {
      this.page = 1;

      // Validation: At least one of the fields is required
      if (!this.recentActivityDate && !this.numberOfFlips) {
        alert(
          "Please fill in either Number of Property flips or Recent Activity."
        );
        return;
      }

      // Handle the selected filters here
      console.log("Selected Filters: ", {
        compareOperator: this.compareOperator,
        compareValue: this.selectedOperator,
        numberOfFlips: this.numberOfFlips,
        recentActivityDate: this.recentActivityDate,
      });
      this.fetchMarketLeadsData();

      this.showModal = false;
    },
    clearFilterModal() {
      this.showModal = false;
      this.compareOperator = null;
      this.selectedOperator = null;
      this.numberOfFlips = null;
      this.recentActivityDate = null;
      this.isNumberOfFlipsFieldEnabled = false;
      this.page = 1;
      this.totalLeads = 0;

      this.fetchMarketLeadsData();
      this.showModal = false;
    },
    isSelected(item) {
      return this.selectedItems.some(
        (selectedItem) => selectedItem.id === item.id
      );
    },
    toggleSelection(item) {
      const index = this.selectedItems.findIndex(
        (selectedItem) => selectedItem.id === item.id
      );
      if (index === -1) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems.splice(index, 1);
      }
    },
    isSelectedShape(item) {
      return this.selectedShapeItems.some(
        (selectedShapeItems) => selectedShapeItems.Email === item.Email
      );
    },
    isSelectedCompany(item) {
      return this.selectedCompanyItems.some(
        (selectedCompanyItems) => selectedCompanyItems.name === item.name
      );
    },
    toggleShapeSelection(item) {
      const index = this.selectedShapeItems.findIndex(
        (selectedShapeItems) => selectedShapeItems.Email === item.Email
      );
      if (index === -1) {
        this.selectedShapeItems.push(item);
      } else {
        this.selectedShapeItems.splice(index, 1);
      }
    },
    toggleCompanySelection(item) {
      const index = this.selectedCompanyItems.findIndex(
        (selectedCompanyItems) => selectedCompanyItems.name === item.name
      );
      if (index === -1) {
        this.selectedCompanyItems.push(item);
      } else {
        this.selectedCompanyItems.splice(index, 1);
      }
    },
    isSelectedLead(item) {
      return this.selectedLeadItems.some(
        (selectedLeadItems) =>
          selectedLeadItems.FLIPGrantor1NameFull === item.FLIPGrantor1NameFull
      );
    },
    toggleLeadSelection(item) {
      const index = this.selectedLeadItems.findIndex(
        (selectedLeadItems) =>
          selectedLeadItems.FLIPGrantor1NameFull === item.FLIPGrantor1NameFull
      );
      if (index === -1) {
        this.selectedLeadItems.push(item);
      } else {
        this.selectedLeadItems.splice(index, 1);
      }
    },
    async addToHuspot(buttonSelected) {
      let contactsDetails;
      if (buttonSelected === "items") {
        contactsDetails = {
          inputs: this.selectedItems.map((item) => {
            return {
              properties: {
                firstname: item.firstName,
                email: item.emailAddress,
                lastname: item.lastName,
                crebrid_crm_id: item.id,
                phone: item.phoneNumber,
                address: item.address,
                city: item.city,
                state: item.state,
                zip: item.zip,
              },
            };
          }),
        };
      } else if (buttonSelected === "shapes") {
        contactsDetails = {
          inputs: this.selectedShapeItems.map((item) => {
            return {
              properties: {
                firstname: item.FirstName,
                email: item.Email,
                lastname: item.LastName,
                crebrid_crm_id: "",
                phone: item.MobilePhone,
              },
            };
          }),
        };
      } else if (buttonSelected === "flips") {
        contactsDetails = {
          inputs: this.selectedLeadItems.map((item) => {
            return {
              properties: {
                firstname: item.FLIPGrantor1NameFull,
                email: "",
                lastname: "",
                crebrid_crm_id: "",
                phone: "",
                address: item.LatestAddressInfo.FLIPGrantorMailAddressFull,
                city: item.LatestAddressInfo.FLIPGRANTORMailAddressCity,
                state: item.LatestAddressInfo.FLIPGRANTORMailAddressState,
                zip: item.LatestAddressInfo.FLIPGRANTORMailAddressZIP,
              },
            };
          }),
        };
      } else {
        contactsDetails = {
          inputs: this.selectedCompanyItems.map((item) => {
            return {
              properties: {
                email: item.email_address,
                crebrid_crm_id: "",
                phone: item.phone,
                name: item.Name,
                assignment_of_mortgage_transactions:
                  item.assignment_of_mortgage_transactions,
                average_mortgage_amount: item.AvgMortgageAmount,
                deed_transactions: item.deed_transactions,
                last_county: item.last_county,
                last_lender_used: item.last_lender_used,
                last_mortgage_date: item.last_mortgage_date,
                last_transaction_date: item.last_transaction_date,
                leads: item.leads,
              },
            };
          }),
        };
      }
      console.log("transformed contacts", contactsDetails);
      const token = `Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJtZXNzYWdlIjoiVG9rZW4gZm9yIGNyZWJyaWQtb3MgQVBJIiwiaWF0IjoxNjc1MzQxOTA1fQ._MKnNlPgvURchmlE1YuewP_FEfJ7rJMmVnsiR0FyfPJ_1BQAdPojeOvfwTPaegyqMFYXQN21ytPMl24Zky589A`;

      const url =
        "https://us-central1-crebrid-os.cloudfunctions.net/app/hubspot-create-contact";
      await axios
        .post(`${url}`, contactsDetails)
        .then((response) => {
          this.snackBarText = response.data.message;
          this.snackbarColor = "green";
          this.snackbar = true;
          console.log("Response", response);
        })
        .catch((err) => {
          this.snackBarText = "Something went wrong, please check the logs!";
          this.snackbarColor = "red";
          this.snackbar = true;
          console.log("Error: ", err);
        });
    },
    async fetchMarketLeadsData() {
      this.loading = true;

      try {
        const key = "21b77cec7cb516c40d9d7216ae74e3c3";
        console.log("pagee--", this.page);
        console.log("limit--", this.limit);
        console.log(
          `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/leads?page=${this.page}&limit=${this.limit}&key=${key}`
        );
        let apiURL = `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/leads?page=${this.page}&limit=${this.limit}&totalRecords=${this.totalLeads}&key=${key}`;
        // let apiURL = `http://127.0.0.1:3000/leads?page=${this.page}&limit=${this.limit}&key=${key}`;

        if (this.selectedOperator) {
          apiURL += `&compareValue=${this.selectedOperator}`;
        }
        if (this.numberOfFlips) {
          apiURL += `&numberOfFlips=${this.numberOfFlips}`;
        }
        if (this.recentActivityDate) {
          apiURL += `&recentActivityDate=${this.recentActivityDate}`;
        }
        console.log("API", apiURL);

        await axios
          .get(apiURL)
          .then((response) => {
            console.log("response", response);
            this.marketData = response.data.flips;
            this.totalLeads = response.data.flips[0].TotalRecords;
            console.log("total_records--", this.totalLeads);
            this.pageCount = Math.ceil(this.totalLeads / this.limit);
            console.log("pagecount---", this.pageCount);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        this.loading = false;
      } catch (error) {
        this.loading = false;

        console.log("Error in fetching Leads data:\n" + error);
      }
    },
    async fetchShapesData() {
      this.loading = true;

      try {
        const key = "21b77cec7cb516c40d9d7216ae74e3c3";
        console.log("pagee--", this.page);
        console.log("limit--", this.limit);
        console.log(
          `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/shapes?page=${this.page}&limit=${this.limit}&key=${key}`
        );
        await axios
          .get(
            `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/shapes?page=${this.page}&limit=${this.limit}&key=${key}`
            // `http://localhost:3000/shapes?page=${this.page}&limit=${this.limit}&key=${key}`
          )
          .then((response) => {
            console.log("response", response);
            this.shapesData = response.data.shapes;
            console.log("Shapes Data : ", this.shapesData);
            this.totalShapeRecords = response.data.TotalRecords;
            console.log("total_records--", this.totalShapeRecords);
            this.pageCount = Math.ceil(this.totalShapeRecords / this.limit);
            console.log("pagecount---", this.pageCount);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        this.loading = false;

        console.log("Error in fetching Shapes data:\n" + error);
      }
    },
    async fetchCompaniesData() {
      this.loading = true;

      try {
        const key = "21b77cec7cb516c40d9d7216ae74e3c3";
        console.log("pagee--", this.page);
        console.log("limit--", this.limit);

        await axios
          .get(
            `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/companies?page=${this.page}&limit=${this.limit}&key=${key}`
            // `http://localhost:3000/companies?page=${this.page}&limit=${this.limit}&key=${key}`
          )
          .then((response) => {
            console.log("response", response);
            this.CompaniesData = response.data.companiesData;
            console.log("Shapes Data : ", this.CompaniesData);
            this.totalCompanyRecords = response.data.TotalRecords;
            console.log("total_records--", this.totalCompanyRecords);
            this.pageCount = Math.ceil(this.totalCompanyRecords / this.limit);
            console.log("pagecount---", this.pageCount);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        this.loading = false;

        console.log("Error in fetching Shapes data:\n" + error);
      }
    },
    async fetchCrmData() {
      this.loading = true;
      const crmRef = firebaseDB.firestore().collection("contacts");
      try {
        const { docs } = await crmRef.get();

        this.crm = docs.map((doc) => {
          const { id } = doc;
          const data = doc.data();
          return { id, ...data };
        });

        this.totalCrmData = this.crm.length;
      } catch (error) {
        this.loading = false;

        console.log("Error in fetching los data:\n" + error);
      }
    },
  },
};
</script>

<style scoped>
.full-height {
  min-height: 100vh;
}
.v-tab {
  text-transform: none !important;
}

a {
  text-decoration: none;
}
#input-561 {
  color: #ffffff;
}
</style>
