<template>
  <div class="full-height pa-5" style="margin-left: 56px">
    <div v-if="loading">
      <loader />
    </div>

    <v-snackbar v-model="snackbar" :timeout="snackBarTimeout" color="grey darken-3">{{
      snackBarText
    }}</v-snackbar>

    <div class="mx-auto rounded-0 full-height">
      <v-tabs
        v-model="tab"
        color="green accent-2"
        slider-color="green accent-2"
      >
        <v-tab>Blog Articles</v-tab>
        <v-tab>Blog Calendar</v-tab>
        <!-- New tab for Blog Calendar -->
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-sheet class="pa-4" color="grey darken-3" data-app>
            <div class="d-flex align-center justify-end">
              <v-text-field
                v-model="blogSearch"
                label="Search Blog Articles..."
                flat
                solo-inverted
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
              ></v-text-field>

              <router-link to="/marketing/blog/create">
                <v-btn class="ml-2 pl-11 pr-11" outlined color="green accent-2">
                  Create New Blog Article
                </v-btn>
              </router-link>
            </div>
          </v-sheet>
          <div>
            <v-data-table
              :headers="blogHeaders"
              :items="blog"
              :items-per-page="10"
              item-key="id"
              :search="blogSearch"
              class="elevation-1"
              show-select
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <v-checkbox
                      :value="isSelected(item)"
                      @change="toggleSelection(item)"
                      class="ma-0"
                      hide-details
                    ></v-checkbox>
                  </td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.status }}</td>
                  <td>{{ formatDate(item.publishDate) }}</td>
                  <!-- Use the filter here -->
                  <td>
                    <router-link :to="`/marketing/blog/${item.id}`">
                      <v-btn x-small outlined color="green accent-2">
                        Edit
                      </v-btn>
                    </router-link>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-tab-item>

        <v-tab-item>
          <v-sheet class="pa-4" color="grey darken-3" data-app>
            <v-calendar
              v-model="selectedDate"
              :events="calendarEvents"
              color="green"
              @click:date="onDateClick"
            ></v-calendar>
          </v-sheet>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import Loader from "../../../components/Loader.vue";
import { firebasewebDB } from "../../../auth/firebase-auth"; // Import firebasewebDB
import moment from "moment";

export default {
  name: "blog",
  components: {
    Loader,
  },
  data() {
    return {
      loading: true, // Set loading to true initially
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,
      blog: [],
      blogSearch: "",
      selectedDate: new Date().toISOString().substr(0, 10), // Default selected date
      calendarEvents: [], // Array to hold calendar events
      blogHeaders: [
        {
          text: "Article Title",
          align: "start",
          width: "380",
          value: "title", // Change to match the data structure
        },
        {
          text: "Status",
          align: "start",
          width: "150",
          value: "status",
        },
        {
          text: "Published Date",
          align: "start",
          width: "150",
          value: "publishDate",
        },
        {
          text: "Actions",
          align: "start",
          width: "100",
          value: "actions",
        },
      ],
      tab: 0, // Default to the first tab
    };
  },

  mounted() {
    this.loadArticles(); // Load articles when the component is mounted
    if (this.$route.query.message) {
      this.snackBarText = this.$route.query.message;
      this.snackbar = true;
      
      // Clear query parameters after displaying the snackbar
      this.$router.replace({ query: {} }); 
    }
  },

  methods: {
    formatDate(date) {
      // Check if the date is a Firestore Timestamp
      if (date && date.toDate) {
        date = date.toDate(); // Convert Firestore Timestamp to JavaScript Date
      }
      const momentDate = moment(date);
      return momentDate.isValid()
        ? momentDate.format("MMMM Do YYYY, h:mm:ss a")
        : "Invalid Date";
    },
    async loadArticles() {
      try {
        const db = firebasewebDB.firestore();
        const snapshot = await db.collection("articles").get();
        this.blog = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        this.prepareCalendarEvents(); // Prepare calendar events after loading articles
      } catch (error) {
        console.error("Error loading articles: ", error);
      } finally {
        this.loading = false; // Set loading to false after articles are loaded
      }
    },
    prepareCalendarEvents() {
      this.calendarEvents = this.blog.map((article) => {
        return {
          name: article.title,
          start: article.publishDate.toDate(), // Ensure this is a JavaScript Date
          end: article.publishDate.toDate(), // Use the same date for start and end
        };
      });
    },
    onDateClick(date) {
      // Handle date click if needed
      console.log("Date clicked:", date);
    },
    isSelected(item) {
      // Implement your selection logic here
    },
    toggleSelection(item) {
      // Implement your toggle selection logic here
    },
  },
};
</script>

<style>
/* Add any additional styles if needed */
</style>
