<template>
  <div>
    <div v-if="loading">
      <loader />
    </div>

    <div>
      <v-snackbar
        v-model="snackbar"
        :timeout="snackBarTimeout"
        :color="color"
        >{{ snackBarText }}</v-snackbar
      >
      <div style="border-radius: 0; min-height: 80vh; font-family: Kumbh Sans !important;" v-if="pdfData != null">
        <v-sheet
          :elevation="5"
          light
          style="position: relative; width: 320mm; height: 400mm"
        >
          <div
            style="
              position: absolute;
              top: 0;
              display: flex;
              align-items: center;
              margin-left: 80px;
              margin-top: 20px;
            "
          >
            <img
              src="@/assets/crebrid-logo-text-v2.png"
              alt="Crebrid Logo"
              style="width: 130px; margin-top: 30px;"
            />
          </div>

          <div style="padding-top: 110px; margin-left: 80px; font-weight:400; ">
            <p style="font-size: 25px; font-weight: 800;">Project Proposal - Term Sheet</p>
            <p style="margin-top: 15px;">Crebrid Fund One</p>
            <p style="margin-top: 5px;">Kai Chandler</p>
            <p style="margin-top: 5px;">Cell: 214-474-9987</p>
            <p style="margin-top: 5px;">4800 Dexter Dr. Plano TX 75093</p>
            <div style="border-top: 1px solid #000; margin: 10px 0; width: 900px; margin-top: 30px;"></div>

            <div style="display: flex; font-size: 21px; margin-top: 30px; font-weight:800;">
              <div> 
                <div style="display: flex">
                  <div style="width: 200px; font-weight: 800;">Client Name:</div>
                  <div style="width: 400px; font-weight: 800; margin-left:10px;">{{pdfData["firstName"]}} {{pdfData["lastName"]}}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 200px; font-weight: 200;">Property Address:</div>
                  <div style="width: 400px; font-weight: 200; margin-left:10px;">{{pdfData["street"]}} {{pdfData["city"]}} {{pdfData["state"]}} {{pdfData["zip"]}}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 200px; font-weight: 200;">Property ARV:</div>
                  <div style="width: 130px; font-weight: 200; margin-left:10px;">{{ pdfData["ARV"] }}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 200px; font-weight: 200;">Loan Amount:</div>
                  <div style="width: 130px; font-weight: 200; margin-left:10px;">${{ pdfData["loanAmount"] }}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 200px; font-weight: 200;">Purchase Price:</div>
                  <div style="width: 130px; font-weight: 200; margin-left:10px;">{{ pdfData["purchasePrice"] }}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 200px; font-weight: 200;">Rehab Budget:</div>
                  <div style="width: 130px; font-weight: 200; margin-left:10px;">{{ pdfData["rehabBudget"] }}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 200px; font-weight: 200;">PP + RB:</div>
                  <div style="width: 130px; font-weight: 200; margin-left:10px;">${{pdfData["totalAcquisitionCost"] }}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 200px; font-weight: 200;">LTV:</div>
                  <div style="width: 130px; font-weight: 200; margin-left:10px;">{{pdfData["LTV"]}}%</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 200px; font-weight: 200;">LTC:</div>
                  <div style="width: 130px; font-weight: 200; margin-left:10px;">{{ pdfData["LTC"] }}%</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 200px; font-weight: 200;">Term Due:</div>
                  <div style="width: 130px; font-weight: 200; margin-left:10px;">{{pdfData["loanTermDuration"]}} months</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 200px; font-weight: 200;">Interest Rate:</div>
                  <div style="width: 130px; font-weight: 200; margin-left:5px;">{{ pdfData["interestRate"] }}%</div>
                </div>
                <div style="width: 150px; font-size: 22px; font-weight: 900; margin-top: 40px;">The Numbers</div>

                <div style="display: flex; margin-top: 30px;">  
                  <div style="width: 450px; font-weight: 200;">Loan Origination Fee -2%:</div>
                  <div style="width: 130px; font-weight: 200; margin-left:5px;">${{ pdfData["financingExpense"] }}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 450px; font-weight: 200;">Processing Fee:</div>
                  <div style="width: 130px; font-weight: 200; margin-left:5px;">${{pdfData["processingFee"]}}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 450px; font-weight: 200;">Appraisal Fee:</div>
                  <div style="width: 130px; font-weight: 200; margin-left:5px;">${{ pdfData["appraisalFee"] }}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 450px; font-weight: 200;">Crebrid Report Fee:</div>
                  <div style="width: 130px; font-weight: 200; margin-left:5px;">${{ pdfData["creditReportFee"] }}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 450px; font-weight: 200;">Flood Certificate Fee:</div>
                  <div style="width: 130px; font-weight: 200; margin-left:5px;">${{ pdfData["floodCertFee"] }}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 450px; font-weight: 200;">Document Review Fee:</div>
                  <div style="width: 130px; font-weight: 200; margin-left:5px;">${{ pdfData["documentReviewFee"] }}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 450px; font-weight: 200;">Attorneys Document Prep Fee:</div>
                  <div style="width: 130px; font-weight: 200; margin-left:5px;">${{ pdfData["attorneyFee"] }}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 450px; font-weight: 200;">Escrow Holdback:</div>
                  <div style="width: 130px; font-weight: 200; margin-left:5px;">${{ pdfData["escrowFee"] }}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 450px; font-weight: 200;">6 Month Insurance Premium: </div>
                  <div style="width: 130px; font-weight: 200; margin-left:5px;">${{ pdfData["premiumFee"] }}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 450px; font-weight: 200;">Monthly Payment: </div>
                  <div style="width: 130px; font-weight: 200; margin-left:5px;">${{ pdfData["interestMonthlyAmount"] }}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 450px; font-weight: 200;">Down Payment: </div>
                  <div style="width: 130px; font-weight: 200; margin-left:5px;">${{ pdfData["reportDownPayment"] }}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 450px; font-weight: 200;">Crebrid Costs: </div>
                  <div style="width: 130px; font-weight: 200; margin-left:5px;">${{ pdfData["crebridCost"] }}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 450px; font-weight: 200;">Funds Due at Closing: </div>
                  <div style="width: 130px; font-weight: 200; margin-left:5px;">${{ pdfData["fundsClosing"] }}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 450px; font-weight: 200;">Recommended Cash Reserves after Closing: </div>
                  <div style="width: 130px; font-weight: 200; margin-left:5px;">${{ pdfData["reservedCaseAmount"] }}</div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style="width: 450px; font-weight: 200;">Needed for Bank Statements: </div>
                  <div style="width: 130px; font-weight: 200; margin-left:5px;">${{ pdfData["bankStatementAmount"] }}</div>
                </div>
            
                           
              </div>
            </div>
          </div>
          <div  style="
              position: absolute;
              top: 0;
              display: flex;
              align-items: center;
              margin-left: 70px;
              margin-top: 1640px;
              font-size: 12px;
            ">
            <br>
            Disclosure: All amounts and figures are estimated and may differ from actual results. Out of pocket costs may vary. *Down payment may vary by up to 2 payments due to prepaid prorations at closing. <br>
            To ensure your property is adequately covered, we are able to offer Home Owners Insurance provided through WCL Insurance. Premium is fully earned at time of sale
          </div>
        </v-sheet>
      </div>
    </div>
  </div>
</template>
    
  <script>
import Loader from "./Loader.vue";
export default {
  name: "losDownLoad",
  components: {
    Loader,
  },
  props: ["id"],
  data() {
    return {
      loading: false,

      search: "",
      pdfData: null,
    };
  },

  mounted() {
    this.pdfData = window.losObject ? window.losObject : {};
  },
};
</script>
  