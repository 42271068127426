<template>
  <div>
    <div v-if="loading">
      <loader />
    </div>

    <div class="ml-12 pl-2">
      <v-snackbar
        v-model="snackbar"
        :timeout="snackBarTimeout"
        :color="color"
        >{{ snackBarText }}</v-snackbar
      >
      <v-btn
        dark
        fab
        fixed
        bottom
        right
        color="green accent-2"
        @click="userDialog = true"
      >
        <v-icon color="black">mdi-plus</v-icon>
      </v-btn>

      <div class="mx-auto rounded-0 full-height" v-if="users != null">
        <v-sheet dark class="pa-12">
          <v-text-field
            v-model="search"
            label="Search Users..."
            dark
            flat
            solo-inverted
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>
        </v-sheet>
        <v-sheet
          :elevation="5"
          dark
          class="my-12 mx-12 py-5 px-10"
          style="position: relative"
        >
          <v-card-text>
            <v-data-table
              :headers="usersHeaders"
              :items="users"
              :items-per-page="10"
              item-key="email"
              :search="search"
              class="elevation-1"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon @click="openEdit(item.id, item.email)" small dark
                  >mdi-account-edit</v-icon
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-sheet>
      </div>
      <v-dialog v-model="userDialog" max-width="600px">
        <v-card class="pa-5" dark>
          <v-card-title class="justify-center mb-5">
            <span class="headline">Create New User</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="First Name*"
                    v-model="userObject.firstName"
                    :rules="[(v) => !!v || 'First Name is required']"
                    required
                    outlined
                    color="white"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Last Name*"
                    v-model="userObject.lastName"
                    required
                    outlined
                    color="white"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-text-field
                  label="Email"
                  v-model="userObject.email"
                  :rules="[(v) => !!v || 'Email is required']"
                  required
                  outlined
                  color="white"
                ></v-text-field>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="white" text @click="userDialog = false">Close</v-btn>
            <v-btn
              color="green accent-2"
              class="black--text"
              :disabled="!userObject.firstName || !userObject.email"
              @click="addUser"
              >Create</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="userEditDialog" max-width="600px">
        <v-card class="pa-5" dark>
          <v-card-title class="justify-center mb-5">
            <span class="headline">Update User</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="First Name*"
                    v-model="userObject.firstName"
                    :rules="[(v) => !!v || 'First Name is required']"
                    required
                    outlined
                    color="white"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Last Name*"
                    v-model="userObject.lastName"
                    required
                    outlined
                    color="white"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="white" text @click="userEditDialog = false"
              >Close</v-btn
            >
            <v-btn
              color="green accent-2"
              class="black--text"
              :disabled="!userObject.firstName"
              @click="updateUser(userUid)"
              >Update</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Loader from "./Loader.vue";
import { firebaseDB } from "../auth/firebase-auth";

export default {
  name: "Users",
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,

      search: "",
      userDialog: false,
      userEditDialog: false,
      userObject: {
        firstName: "",
        lastName: "",
        email: "",
      },
      userUid: null,
      users: [],
      usersHeaders: [
        {
          text: "Email",
          align: "start",
          width: "380",
          value: "email",
        },
        {
          text: "First Name",
          align: "start",
          width: "150",
          value: "firstName",
        },
        {
          text: "Last Name",
          align: "start",
          width: "150",
          value: "lastName",
        },
        {
          text: "Actions",
          align: "center",
          width: "100",
          value: "actions",
        },
      ],
    };
  },
  mounted() {
    this.fetchUsersData();
  },
  methods: {
    async fetchUsersData() {
      this.loading = true;
      const usersRef = firebaseDB.firestore().collection("users");
      try {
        const { docs } = await usersRef.orderBy("createdAt", "desc").get();

        this.users = docs.map((doc) => {
          const { id } = doc;
          const data = doc.data();
          return { id, ...data };
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("Error in fetching users data:\n" + error);
      }
    },
    async addUser() {
      // this.userObject = {};
      this.loading = true;
      try {
        await firebaseDB
          .firestore()
          .collection("users")
          .add({
            email: this.userObject.email,
            firstName: this.userObject.firstName,
            lastName: this.userObject.lastName ? this.userObject.lastName : "",
            createdAt: new Date(),
          });

        this.userObject = {};
        this.fetchUsersData();
        this.userDialog = false;

        this.color = "success";
        this.snackBarText = "User added Successfully";
      } catch (error) {
        this.color = "error";
        this.snackBarText = error;
      }
      this.loading = false;
      this.snackbar = true;
    },
    async updateUser(userUid) {
      this.loading = true;
      try {
        const userRef = await firebaseDB
          .firestore()
          .collection("users")
          .doc(userUid);

        userRef.update({
          firstName: this.userObject.firstName,
          lastName: this.userObject.lastName ? this.userObject.lastName : "",
        });

        await this.fetchUsersData();
        this.userEditDialog = false;

        this.userObject = {};

        this.color = "success";
        this.snackBarText = "User updated Successfully";
      } catch (error) {
        this.color = "error";
        this.snackBarText = error;
      }
      this.userUid = null;
      this.loading = false;
      this.snackbar = true;
    },
    async openEdit(userUid) {
      this.userUid = userUid;
      this.userEditDialog = true;
      const userRef = await firebaseDB
        .firestore()
        .collection("users")
        .doc(userUid)
        .get();
      this.userObject = { ...userRef.data() };
    },
  },
};
</script>

<style scoped>
.full-height {
  min-height: 100vh;
}
.v-text-field .v-label {
  overflow: visible !important;
  top: 1% !important;
}
.label {
  color: #c7c7c7 !important;
}
.item {
  cursor: pointer;
}
.fa {
  margin: 3px;
  font-size: 1rem;
}
</style>
