<template>
  <div class="full-height pa-10" style="margin-left: 56px">
    <div v-if="loading">
      <loader />
    </div>

    <div class="mx-auto rounded-0 full-height">
      <div class="radio-buttons">
        <label>
          <input
            type="radio"
            value="all"
            v-model="selectedState"
            @change="handleRadioChange"
          />
          All
        </label>
        <label>
          <input
            type="radio"
            value="tx"
            v-model="selectedState"
            @change="handleRadioChange"
          />
          TX
        </label>
        <label>
          <input
            type="radio"
            value="oh"
            v-model="selectedState"
            @change="handleRadioChange"
          />
          OH
        </label>
      </div>
      <v-sheet :elevation="5" dark class="pa-5" style="position: relative">
        <div class="text-subtitle-1 white--text pb-5">
          Zipcode Ranked Lead Generator
        </div>
        <div id="mapdiv"></div>
      </v-sheet>

      <v-sheet
        :elevation="5"
        dark
        class="pa-5 mt-10"
        style="position: relative"
      >
        <v-sheet class="pa-4" color="grey darken-3" data-app>
          <div class="d-flex align-center">
            <v-text-field
              v-model="search"
              label="Search markets..."
              flat
              solo-inverted
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
            ></v-text-field>
          </div>
        </v-sheet>
        <div>
          <v-data-table
            :headers="zipRankDataHeaders"
            :items="zipRankData"
            :items-per-page="limit"
            :search="search"
            item-key="_zip_code"
            sort-by="ziprank"
            :sort-desc="true"
            class="elevation-1"
          >
            <template
              v-slot:item.query_response.AverageTransferAmount="{ item }"
            >
              <div v-if="item.query_response.AverageTransferAmount">
                ${{
                  parseInt(
                    item.query_response.AverageTransferAmount
                  ).toLocaleString("en-US")
                }}
              </div>
              <div v-else>-</div>
            </template>
            <template v-slot:item.actions="{ item }">
              <router-link
                :to="{
                  path: `/marketing/zipcode/${item._zip_code}`,
                  query: {
                    totalRecords: totalRecords,
                    latitude: item.latitude,
                    longitude: item.longitude,
                    placeId: item.place_id,
                  },
                }"
              >
                <v-btn x-small outlined color="green accent-2">
                  View Details
                </v-btn>
              </router-link>
            </template>
          </v-data-table>
        </div>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../../components/Loader.vue";
import { firebaseDB } from "../../auth/firebase-auth";

export default {
  components: {
    Loader,
  },

  data() {
    return {
      selectedState: "all",
      loading: false,
      mapStyle: "roadmap",
      map: null,

      addressObject: "",
      propertyPlaceId: "",
      disableSubmit: false,

      zipRankData: [],
      zipCodes: [],
      limit: 10,
      page: 1,
      totalRecords: 0,
      pageCount: 0,

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,

      search: "",

      zipRankDataHeaders: [
        {
          text: "Zipcode",
          value: "_zip_code",
        },
        {
          text: "State",
          value: "query_response.PropertyAddressState",
        },
        {
          text: "Crebrid Rank",
          value: "ziprank",
        },
        {
          text: "No of Unique Flippers",
          value: "query_response.UniqueFlippersCount",
        },
        {
          text: "No of Unique Lending Companies",
          value: "query_response.UniqueLenderCompaniesCount",
        },
        {
          text: "Avg Flip Transfer Amount",
          value: "query_response.AverageTransferAmount",
        },
        {
          text: "City",
          value: "major_city",
        },
        {
          text: "Population",
          value: "population",
        },
        {
          text: "WildcatMarketShare",
          value: "WildcatMarketShare",
        },
        {
          text: "WildcatMarketShareRank",
          value: "WildcatMarketShareRank",
        },
        {
          text: "isActive",
          value: "is_active",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
    };
  },
  mounted() {
    this.getZipRankData();
  },
  methods: {
    handleRadioChange() {
      this.getZipRankData();
    },
    async getZipRankData() {
      this.loading = true;

      try {
        const key = "21b77cec7cb516c40d9d7216ae74e3c3";
        console.log("pagee--", this.page);
        console.log("limit--", this.limit);
        console.log("selectedState----", this.selectedState);
        const collectionRef = firebaseDB
          .firestore()
          .collection("markets_final");
        let query = collectionRef.orderBy("ziprank", "desc");

        if (this.selectedState !== "all") {
          query = query.where(
            "query_response.PropertyAddressState",
            "==",
            this.selectedState.toUpperCase()
          );
        }
        const querySnapshot = await query.get();
        this.totalRecords = querySnapshot.size;

        this.zipRankData = querySnapshot.docs.map((doc) => {
          const { id } = doc;
          const data = doc.data();

          return { id, ...data };
        });
        
        this.loadMap();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("Error in fetching Leads data:\n" + error);
      }
    },

    async loadMap() {
      this.loading = true;
      const canvas = document.createElement("canvas");
      const gl = canvas.getContext("webgl");
      gl.getParameter(gl.RENDERER);

      const mapDiv = document.getElementById("mapdiv");

      let zipCode;
      if (this.selectedState.toUpperCase() == "OH") {
        zipCode = this.zipRankData[11];
      } else {
        zipCode = this.zipRankData[1];
      }
      console.log(zipCode);

      const latLong = {
        lat: Number(zipCode.latitude),
        lng: Number(zipCode.longitude),
      };
      const zoom = 5;
      const options = {
        center: latLong,
        mapId: "1261b76f0156780e",
        zoom,
        preserveDrawingBuffer: true,
      };
      this.map = new google.maps.Map(mapDiv, options);

      for (const zipCode of this.zipRankData) {
        if (zipCode.latitude && zipCode.longitude) {
          const latLng = {
            lat: Number(zipCode.latitude),
            lng: Number(zipCode.longitude),
          };
          const local_marker = new google.maps.Marker({
            position: latLng,
            map: this.map,
          });
          this.marker = local_marker;
          var infoWindow = new google.maps.InfoWindow({
            content: `
          <div id="content">
            <div id="bodyContent">
            </br>
              <p style='color: black;'>
                <b>Zipcode:</b> ${zipCode._zip_code}
              </p>
              <p style='color: black;'>
                <b>State:</b> ${zipCode.query_response.PropertyAddressState}
              </p>
              <p style='color: black;'>
                <b>City:</b> ${zipCode.major_city}
              </p>
              <p style='color: black;'>
                <b>Population:</b> ${zipCode.population}
              </p>
              <p style='color: black;'>
                <b>WildcatMarketShare:</b> ${zipCode.WildcatMarketShare}
              </p>
              <p style='color: black;'>
                <b>WildcatMarketShareRank:</b> ${zipCode.WildcatMarketShareRank}
              </p>
              <p style='color: black;'>
                <b>Crebrid Rank:</b> ${zipCode.ziprank}
              </p>
              <p style='color: black;'>
                <b>No of Unique Flippers:</b> ${
                  zipCode.query_response.UniqueFlippersCount
                }
              </p>
              <p style='color: black;'>
                <b>No of Unique Lending Companies:</b> ${
                  zipCode.query_response.UniqueLenderCompaniesCount
                }
              </p>
              <p style='color: black;'>
                <b>Avg Flip Transfer Amount:</b> $${parseInt(
                  zipCode.query_response.AverageTransferAmount
                ).toLocaleString("en-US")}
              </p>
            </div>
          </div>  
            `,
          });

          local_marker.addListener(
            "click",
            (function (marker, infoWin) {
              return function () {
                infoWin.open(this.map, marker);
              };
            })(local_marker, infoWindow)
          );
        }
      }

      const featureLayer = await this.map.getFeatureLayer("POSTAL_CODE");
      const featureStyleOptions = {
        strokeColor: "#448AFF",
        strokeOpacity: 1.0,
        strokeWeight: 3.0,
        fillColor: "#448AFF",
        fillOpacity: 0.5,
      };

      let placeIds = [];
      for (const zipCode of this.zipRankData) {
        if (zipCode?.place_id !== undefined) {
          placeIds.push(zipCode.place_id);
        }
      }

      featureLayer.style = (options) => {
        if (placeIds.includes(options.feature.placeId)) {
          return featureStyleOptions;
        }
      };

      this.loading = false;

      this.map.setCenter(latLong);
      this.marker.setPosition(latLong);
    },
  },
};
</script>

<style lang="scss" scoped>
.hero-container {
  // background: #1852f0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background-image: linear-gradient(
      to bottom,
      rgba(82, 84, 86, 0.85),
      rgba(109, 110, 111, 0.9)
    ),
    url("~@/assets/map-hero.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  .__content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;

    .v-dialog__container {
      display: block;
    }
  }
  .search-container {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;

    .search-btn {
      min-height: 56px;
    }
  }
  .googlemap {
    // border-bottom: 1px solid;
    width: 100%;
    min-width: 250px;
    // min-height: 64px;
    //   min-height: 48px;
    min-height: 56px;
    // background: rgba(0, 0, 0, 0.06);
    background: #ffffff;
    // background: #000;
    // background: #1852f0;
    // color: white;
    color: black;
    padding: 0 12px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    // color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    // border: 1px solid #9e9e9e;
    border: thin solid rgba(0, 0, 0, 0.12);
    // border: 1px solid #1852f0;
    border-radius: 4px;
    // border-radius: 4px 4px 0 0;
    //   box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    //     0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

    &::placeholder {
      // color: rgba(0, 0, 0, 0.87);
      // color: white;
      color: black;
      padding: 8px;
      opacity: 1;
      // transition: opacity 0.5s;
    }

    &:focus {
      outline: none;
      border: 2px solid #1852f0;

      &::placeholder {
        opacity: 0;
      }
    }
  }
}
@media (max-width: 575px) {
  .hero-container {
    margin-top: 55px;
  }

  .search-container {
    width: 100% !important;
  }
}

#mapdiv {
  min-height: 500px;
  width: 100%;
}

.radio-buttons {
  display: flex;
}
.radio-buttons input[type="radio"] {
  /* Increase size here */
  width: 20px;
  height: 20px;
}

.radio-buttons input[type="radio"]::before {
  /* Customization styles */
  border-radius: 50%;
  border: 2px solid #000;
  background-color: #fff;
}
</style>
