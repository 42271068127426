<template>
  <div class="full-height pa-10" style="margin-left: 56px">
    <div v-if="loading">
      <loader />
    </div>

    <v-snackbar v-model="snackbar" :timeout="snackBarTimeout" :color="color">{{
      snackBarText
    }}</v-snackbar>

    <div class="mx-auto rounded-0 full-height">
      <v-sheet class="pa-4" color="grey darken-3" data-app>
        <v-row class="align-center justify-center">
          <v-text-field
            class="pa-2"
            v-model="search"
            label="Search Loan Applications..."
            flat
            solo-inverted
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>

          <template>
            <v-btn
              class="mx-2"
              color="white"
              small
              dark
              outlined
              @click="dialog = true"
            >
              New Application
            </v-btn>

            <v-dialog v-model="dialog" fullscreen width="auto">
              <v-card width="auto">
                <!-- <v-card-title class="text-h6">
                    Loan Application Logs

                    <v-spacer />

                    <div class="c-pointer d-flex" @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </div>
                  </v-card-title> -->
                <v-toolbar dark>
                  <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>New Application</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <!-- <v-btn variant="text" @click="createApplication"> -->
                    <v-btn
                      variant="text"
                      @click="createApplication"
                      :disabled="isCreateDisabled"
                    >
                      Create Application
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text class="mt-5">
                  <template>
                    <v-row>
                      <v-col
                        lg="6"
                        v-for="losField in losDetailsFieldMap"
                        :key="losField.order"
                      >
                        <v-select
                          v-if="losField.type == 'dropdown'"
                          v-model="losField.user_value"
                          :label="losField.value"
                          :items="['Yes', 'No']"
                          outlined
                          dense
                          :rules="
                            losField.required && losField.required === true
                              ? [(v) => !!v || 'This field is required']
                              : []
                          "
                        ></v-select>
                        <v-text-field
                          v-else
                          v-model="losField.user_value"
                          :label="losField.value"
                          outlined
                          dense
                          :rules="
                            losField.required && losField.required == true
                              ? [
                                  () =>
                                    !!losField.user_value ||
                                    'This field is required',
                                ]
                              : []
                          "
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </template>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
        </v-row>
      </v-sheet>
      <div>
        <v-data-table
          :headers="losHeaders"
          :items="los"
          :items-per-page="10"
          item-key="email"
          :search="search"
          sort-by="createdAt"
          :sort-desc="true"
          class="elevation-1"
        >
          <template v-slot:item.loanStatus="{ item }">
            <div v-if="item.loanStatus">TBD</div>
          </template>

          <template v-slot:item.createdAt="{ item }">
            <div v-if="item.createdAt">
              {{
                item.createdAt
                  .toDate()
                  .toLocaleString("en-US", {
                    year: "2-digit",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })
                  .replace(/\//g, "-")
              }}
            </div>
          </template>
          <template v-slot:item.isProcessApplication="{ item }">
            <div>
              {{
                item.isProcessApplication ? item.isProcessApplication : false
              }}
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <router-link :to="`/los/applications/${item.id}`">
              <v-btn x-small outlined color="green accent-2">
                View Details
              </v-btn>
            </router-link>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../../../components/Loader.vue";
import { firebaseDB } from "../../../auth/firebase-auth";

export default {
  template: "#app-template",
  name: "Los",
  components: {
    Loader,
  },
  data() {
    return {
      applicationData: {},
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,

      loading: true,

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,
      search: "",
      los: [],
      losHeaders: [
        {
          text: "Email",
          align: "start",
          width: "100",
          value: "B1425", //B1425 - borrower's email
        },
        {
          text: "Reference #",
          align: "start",
          width: "50",
          value: "ReferenceNumber", //B1425 - borrower's email
        },
        // {
        //   text: "Status",
        //   align: "start",
        //   width: "50",
        //   value: "loanStatus",
        // },
        {
          text: "Address",
          align: "start",
          width: "100",
          value: "P1002",
        },
        {
          text: "City",
          align: "start",
          width: "50",
          value: "P1003",
        },
        {
          text: "State",
          align: "start",
          width: "50",
          value: "P1004",
        },
        // {
        //   text: "Zipcode",
        //   align: "start",
        //   width: "50",
        //   value: "P1005",
        // },
        {
          text: "Created At",
          align: "start",
          width: "100",
          value: "createdAt",
        },
        {
          text: "Processed",
          align: "center",
          width: "100",
          value: "isProcessApplication",
        },
        {
          text: "Actions",
          align: "center",
          width: "100",
          value: "actions",
        },
      ],

      losDetailsFieldMap: [
        {
          field: "contactRefId",
          value: "Crebrid CRM Contact ID",
          order: 1,
        },
        {
          field: "createdAt",
          value: "Created At",
          order: 2,
        },
        {
          field: "ReferenceNumber",
          value: "Reference Number",
          order: 4,
        },
        {
          field: "B1425",
          value: "Borrower's Email Address *",
          order: 5,
          required: true,
        },
        {
          field: "B1417",
          value: "Borrower's First Name *",
          order: 6,
          required: true,
        },
        {
          field: "B1418",
          value: "Borrower's M.I.",
          order: 7,
        },
        {
          field: "B1419",
          value: "Borrower's Last Name",
          order: 8,
        },
        {
          field: "F9009",
          value: "Liquid Assets *",
          order: 9,
          required: true,
        },
        {
          field: "CUSTOM_CreditScore",
          value: "Credit Score *",
          order: 10,
          required: true,
        },
        {
          field: "B1034",
          value: "Borrower's SSN *",
          order: 0,
          required: true,
        },
        {
          field: "B1035",
          value: "Borrower's Phone *",
          order: 11,
          required: true,
        },
        {
          field: "B1035_2",
          value: "Co-Borrower's Phone",
          order: 12,
        },
        {
          field: "B1036",
          value: "Borrower's DOB *",
          order: 13,
          required: true,
        },
        {
          field: "B1038",
          value: "Borrower's Marital Status *",
          order: 14,
          required: true,
        },
        {
          field: "B1042",
          value: "Borrower's Address *",
          order: 15,
          required: true,
        },
        {
          field: "B1044",
          value: "Borrower's City *",
          order: 16,
          required: true,
        },
        {
          field: "B1045",
          value: "Borrower's State *",
          order: 17,
          required: true,
        },
        {
          field: "B1046",
          value: "Borrower's Zip *",
          order: 18,
          required: true,
        },
        {
          field: "B1057",
          value: "Borrower's Employer Address",
          order: 19,
        },
        {
          field: "B1058",
          value: "Borrower's Employer City",
          order: 20,
        },
        {
          field: "B1058_2",
          value: "Co-Borrower's Employer City",
          order: 21,
        },
        {
          field: "B1059",
          value: "Borrower's Employer State",
          order: 22,
        },

        {
          field: "B1060",
          value: "Borrower's Employer Zip",
          order: 23,
        },

        {
          field: "CUSTOM_CoBorrower",
          value: "Do you have a Co-Borrower? *",
          order: 24,
          required: true,
          type: "dropdown",
        },
        {
          field: "B1425_2",
          value: "Co-Borrower's Email Address",
          order: 25,
        },
        {
          field: "B1417_2",
          value: "Co-Borrower's First Name",
          order: 26,
        },
        {
          field: "B1418_2",
          value: "Co-Borrower's M.I.",
          order: 27,
        },
        {
          field: "B1419_2",
          value: "Co-Borrower's Last Name",
          order: 28,
        },
        {
          field: "F9009_2",
          value: "Co-Borrower - 1003 - Asset - Subtotal Liquid Assets",
          order: 29,
        },
        {
          field: "B1034_2",
          value: "Co-Borrower's SSN",
          order: 30,
        },
        {
          field: "B1036_2",
          value: "Co-Borrower's DOB",
          order: 31,
        },
        {
          field: "B1038_2",
          value: "Co-Borrower's Marital Status",
          order: 32,
        },
        {
          field: "B1042_2",
          value: "Co-Borrower's Address",
          order: 33,
        },
        {
          field: "B1044_2",
          value: "Co-Borrower's City",
          order: 34,
        },
        {
          field: "B1045_2",
          value: "Co-Borrower's State",
          order: 35,
        },
        {
          field: "B1046_2",
          value: "Co-Borrower's Zip",
          order: 36,
        },
        {
          field: "B1057_2",
          value: "Co-Borrower's Employer Address",
          order: 37,
        },
        {
          field: "B1059_2",
          value: "Co-Borrower's Employer State",
          order: 38,
        },
        {
          field: "B1060_2",
          value: "Co-Borrower's Employer Zip",
          order: 39,
        },

        {
          field: "CUSTOM_EntityNameYesOrNo",
          value: "Business Entity Name, Yes or No? *",
          order: 40,
          required: true,
          type: "dropdown",
        },
        {
          field: "CUSTOM_BusinessEntityName",
          value: "Business Entity Name",
          order: 41,
        },

        {
          field: "CUSTOM_ForeClosure",
          value: "Have you had a Foreclosure? *",
          order: 42,
          required: true,
          type: "dropdown",
        },
        {
          field: "CUSTOM_ForeClosexplain",
          value: "Foreclosure Explanation",
          order: 43,
        },
        {
          field: "CUSTOM_Bankruptcy",
          value: "Have you filed for Bankruptcy? *",
          order: 44,
          required: true,
          type: "dropdown",
        },

        {
          field: "F2373",
          value: "Additional Comments",
          order: 45,
        },

        {
          field: "CUSTOM_FlipOrRent",
          value: "Flip or Rent *",
          order: 46,
          required: true,
        },

        {
          field: "CUSTOM_PropertyUnderContract",
          value: "Property Under Contract *",
          order: 47,
          required: true,
        },
        {
          field: "CUSTOM_StatePropertyPurchase",
          value: "State Property Purchase",
          order: 48,
        },

        {
          field: "CUSTOM_ContractCloseDate",
          value: "Contract Close Date *",
          order: 49,
          required: true,
        },

        {
          field: "CUSTOM_EstimatedTimeFrame",
          value: "Estimated Time Frame *",
          order: 50,
          required: true,
        },

        {
          field: "P1002",
          value: "Subject Property Address *",
          order: 51,
          required: true,
        },
        {
          field: "P1003",
          value: "Subject Property City *",
          order: 52,
          requried: true,
        },
        {
          field: "P1004",
          value: "Subject Property State *",
          order: 53,
          requried: true,
        },
        {
          field: "P1005",
          value: "Subject Property Zip *",
          order: 54,
          required: true,
        },
        {
          field: "P1694",
          value: "Purchase Price *",
          order: 55,
          required: true,
        },
        {
          field: "CUSTOM_RehabBudgetAmount",
          value: "Rehab Budget Amount",
          order: 56,
        },
        {
          field: "P1697",
          value: "ARV *",
          order: 57,
          required: true,
        },

        {
          field: "CUSTOM_RenovationProjects",
          value: "Renovation Projects *",
          order: 58,
          required: true,
        },

        {
          field: "CUSTOM_RenoAddress1",
          value: "Renovation Project 1 - Address",
          order: 59,
        },
        {
          field: "CUSTOM_RenoCity1",
          value: "Renovation Project 1 - City",
          order: 60,
        },
        {
          field: "CUSTOM_RenoState1",
          value: "Renovation Project 1 - State",
          order: 61,
        },
        {
          field: "CUSTOM_RenoZip1",
          value: "Renovation Project 1 - Zip",
          order: 62,
        },
        {
          field: "CUSTOM_RenoAddress1",
          value: "Renovation Project 2 - Address",
          order: 63,
        },
        {
          field: "CUSTOM_RenoCity1",
          value: "Renovation Project 2 - City",
          order: 64,
        },
        {
          field: "CUSTOM_RenoState1",
          value: "Renovation Project 2 - State",
          order: 65,
        },
        {
          field: "CUSTOM_RenoZip1",
          value: "Renovation Project 2 - Zip",
          order: 66,
        },
        {
          field: "CUSTOM_RenoAddress1",
          value: "Renovation Project 3 - Address",
          order: 67,
        },
        {
          field: "CUSTOM_RenoCity1",
          value: "Renovation Project 3 - City",
          order: 68,
        },
        {
          field: "CUSTOM_RenoState1",
          value: "Renovation Project 3 - State",
          order: 69,
        },
        {
          field: "CUSTOM_RenoZip1",
          value: "Renovation Project 3 - Zip",
          order: 70,
        },
        {
          field: "CUSTOM_RenoAddress1",
          value: "Renovation Project 4 - Address",
          order: 71,
        },
        {
          field: "CUSTOM_RenoCity1",
          value: "Renovation Project 4 - City",
          order: 72,
        },
        {
          field: "CUSTOM_RenoState1",
          value: "Renovation Project 4 - State",
          order: 73,
        },
        {
          field: "CUSTOM_RenoZip1",
          value: "Renovation Project 4 - Zip",
          order: 74,
        },
        {
          field: "CUSTOM_RenoAddress1",
          value: "Renovation Project 5 - Address",
          order: 75,
        },
        {
          field: "CUSTOM_RenoCity1",
          value: "Renovation Project 5 - City",
          order: 76,
        },
        {
          field: "CUSTOM_RenoState1",
          value: "Renovation Project 5 - State",
          order: 77,
        },
        {
          field: "CUSTOM_RenoZip1",
          value: "Renovation Project 5 - Zip",
          order: 78,
        },

        {
          field: "CUSTOM_HearAboutUs",
          value: "How did you hear about us? *",
          order: 79,
          required: true,
        },

        {
          field: "CUSTOM_WildcatAccntExecutive",
          value: "Wildcat Account Executive? *",
          order: 80,
          required: true,
          type: "dropdown",
        },
        {
          field: "CUSTOM_AccountExec",
          value: "Wildcat Account Executive",
          order: 81,
        },

        {
          field: "B9167",
          value: "1003 - Sex - I do not wish to provide this information* ",
          order: 82,
          required: true,
        },
        {
          field: "B9080",
          value: "1003 - Hispanic or Latino",
          order: 83,
        },
        {
          field: "B9081",
          value: "1003 - Not Hispanic or Latino",
          order: 84,
        },
        {
          field: "B9082",
          value: "1003 - American Indian or Alaska Native",
          order: 85,
        },
        {
          field: "B9083",
          value: "1003 - Asian",
          order: 86,
        },
        {
          field: "B9084",
          value: "1003 - Black or African American",
          order: 87,
        },
        {
          field: "B9085",
          value: "1003 - Native Hawaiian or Other Pacific Islander",
          order: 88,
        },
        {
          field: "B9086",
          value: "1003 - White",
          order: 89,
        },
        {
          field: "B9087",
          value: "1003 - Female",
          order: 90,
        },
        {
          field: "B9088",
          value: "1003 - Male",
          order: 91,
        },
        {
          field: "B9087",
          value: "1003 - Female",
          order: 92,
        },
        {
          field: "B9087",
          value: "1003 - Female",
          order: 93,
        },
        {
          field: "CUSTOM_Agreement",
          value: "Agree to Wildcat's Terms",
          order: 94,
          type: "dropdown",
        },
      ],
    };
  },

  mounted() {
    let start = Date.now();
    console.log("Started : ", start);
    this.fetchLosData();
    console.log("Ended : ", Date.now() - start);
  },

  computed: {
    isCreateDisabled() {
      // Check if any required field is empty
      return this.losDetailsFieldMap.some((losField) => {
        return losField.required === true && !losField.user_value;
      });
    },
  },

  methods: {
    createApplication() {
      this.losDetailsFieldMap.forEach((item) => {
        this.applicationData[item.field] = item.user_value;
      });
      console.log("Updated Los Detail", this.losDetailsFieldMap);
      console.log("Application Data", this.applicationData);

      const token = `Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJtZXNzYWdlIjoiVG9rZW4gZm9yIGNyZWJyaWQtb3MgQVBJIiwiaWF0IjoxNjc1MzQxOTA1fQ._MKnNlPgvURchmlE1YuewP_FEfJ7rJMmVnsiR0FyfPJ_1BQAdPojeOvfwTPaegyqMFYXQN21ytPMl24Zky589A`;

      const url =
        "https://us-central1-crebrid-os.cloudfunctions.net/app/add-data";
      axios
        .post(`${url}`, this.applicationData, {
          headers: { Authorization: token },
        })
        .then((response) => {
          console.log("Response", response);
        })
        .catch((err) => {
          this.alertMessage = "Something went wrong, please try again later!";
          this.alertType = "error";
          this.alertState = true;
          console.log("Error: ", err);
        });
    },

    async fetchLosData() {
      this.loading = true;
      const losRef = firebaseDB.firestore().collection("loans");
      try {
        const { docs } = await losRef.get();
        this.los = docs.map((doc) => {

          const { id } = doc;
          const data = doc.data();
          return { id, ...data };
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("Error in fetching los data:\n" + error);
      }
    },
  },
};
</script>

<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}
</style>
