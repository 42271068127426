<template>
  <div class="control-group">
    <span @click="toggleBold" :class="{ 'is-active': editor.isActive('bold') }">
      Bold
    </span>
    <span
      @click="toggleItalic"
      :class="{ 'is-active': editor.isActive('italic') }"
    >
      Italic
    </span>
    <span
      @click="toggleStrike"
      :class="{ 'is-active': editor.isActive('strike') }"
    >
      Strike
    </span>
    <span @click="toggleCode" :class="{ 'is-active': editor.isActive('code') }">
      Code
    </span>
    <span
      v-for="alignment in alignments"
      :key="alignment"
      @click="setTextAlign(alignment)"
      :class="{ 'is-active': editor.isActive({ textAlign: alignment }) }"
    >
      {{ alignment }}
    </span>
    <span @click="unsetAllMarks"> Clear marks </span>
    <span @click="clearNodes"> Clear nodes </span>
    <span
      @click="setParagraph"
      :class="{ 'is-active': editor.isActive('paragraph') }"
    >
      Paragraph
    </span>
    <span
      v-for="level in headingLevels"
      :key="level"
      @click="toggleHeading(level)"
      :class="{ 'is-active': editor.isActive('heading', { level: level }) }"
    >
      H{{ level }}
    </span>
    <span
      @click="toggleBulletList"
      :class="{ 'is-active': editor.isActive('bulletList') }"
    >
      Bullet list
    </span>
    <span
      @click="toggleOrderedList"
      :class="{ 'is-active': editor.isActive('orderedList') }"
    >
      Ordered list
    </span>
    <span
      @click="toggleCodeBlock"
      :class="{ 'is-active': editor.isActive('codeBlock') }"
    >
      Code block
    </span>
    <span
      @click="toggleBlockquote"
      :class="{ 'is-active': editor.isActive('blockquote') }"
    >
      Blockquote
    </span>
    <span @click="setHorizontalRule"> Horizontal rule </span>
    <span @click="setHardBreak"> Hard break </span>
    <span @click="undo"> Undo </span>
    <span @click="redo"> Redo </span>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        alignments: ["left", "center", "right"],
        headingLevels: [1, 2, 3, 4, 5, 6],
      };
    },
    props: {
      editor: {
        type: Object,
        required: true,
      },
    },
    methods: {
      toggleBold() {
        this.editor.chain().focus().toggleBold().run();
      },
      toggleItalic() {
        this.editor.chain().focus().toggleItalic().run();
      },
      toggleStrike() {
        this.editor.chain().focus().toggleStrike().run();
      },
      toggleCode() {
        this.editor.chain().focus().toggleCode().run();
      },
      setTextAlign(alignment) {
        this.editor.chain().focus().setTextAlign(alignment).run();
      },
      unsetAllMarks() {
        this.editor.chain().focus().unsetAllMarks().run();
      },
      clearNodes() {
        this.editor.chain().focus().clearNodes().run();
      },
      setParagraph() {
        this.editor.chain().focus().setParagraph().run();
      },
      toggleHeading(level) {
        this.editor.chain().focus().toggleHeading({ level }).run();
      },
      toggleBulletList() {
        this.editor.chain().focus().toggleBulletList().run();
      },
      toggleOrderedList() {
        this.editor.chain().focus().toggleOrderedList().run();
      },
      toggleCodeBlock() {
        this.editor.chain().focus().toggleCodeBlock().run();
      },
      toggleBlockquote() {
        this.editor.chain().focus().toggleBlockquote().run();
      },
      setHorizontalRule() {
        this.editor.chain().focus().setHorizontalRule().run();
      },
      setHardBreak() {
        this.editor.chain().focus().setHardBreak().run();
      },
      undo() {
        this.editor.chain().focus().undo().run();
      },
      redo() {
        this.editor.chain().focus().redo().run();
      },
    },
  };
</script>
