var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-height pa-10",staticStyle:{"margin-left":"56px"}},[(_vm.loading)?_c('div',[_c('loader')],1):_vm._e(),_c('v-snackbar',{attrs:{"timeout":_vm.snackBarTimeout,"color":_vm.color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackBarText))]),_c('div',{staticClass:"mx-auto rounded-0 full-height"},[_c('v-sheet',{staticClass:"pa-4",attrs:{"color":"grey darken-3","data-app":""}},[_c('v-row',{staticClass:"align-center justify-center"},[_c('v-text-field',{staticClass:"pa-2",attrs:{"label":"Search Loan Applications...","flat":"","solo-inverted":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"white","small":"","dark":"","outlined":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" New Application ")]),_c('v-dialog',{attrs:{"fullscreen":"","width":"auto"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"width":"auto"}},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("New Application")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"variant":"text","disabled":_vm.isCreateDisabled},on:{"click":_vm.createApplication}},[_vm._v(" Create Application ")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"mt-5"},[[_c('v-row',_vm._l((_vm.losDetailsFieldMap),function(losField){return _c('v-col',{key:losField.order,attrs:{"lg":"6"}},[(losField.type == 'dropdown')?_c('v-select',{attrs:{"label":losField.value,"items":['Yes', 'No'],"outlined":"","dense":"","rules":losField.required && losField.required === true
                            ? [(v) => !!v || 'This field is required']
                            : []},model:{value:(losField.user_value),callback:function ($$v) {_vm.$set(losField, "user_value", $$v)},expression:"losField.user_value"}}):_c('v-text-field',{attrs:{"label":losField.value,"outlined":"","dense":"","rules":losField.required && losField.required == true
                            ? [
                                () =>
                                  !!losField.user_value ||
                                  'This field is required',
                              ]
                            : []},model:{value:(losField.user_value),callback:function ($$v) {_vm.$set(losField, "user_value", $$v)},expression:"losField.user_value"}})],1)}),1)]],2)],1)],1)]],2)],1),_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.losHeaders,"items":_vm.los,"items-per-page":10,"item-key":"email","search":_vm.search,"sort-by":"createdAt","sort-desc":true},scopedSlots:_vm._u([{key:"item.loanStatus",fn:function({ item }){return [(item.loanStatus)?_c('div',[_vm._v("TBD")]):_vm._e()]}},{key:"item.createdAt",fn:function({ item }){return [(item.createdAt)?_c('div',[_vm._v(" "+_vm._s(item.createdAt .toDate() .toLocaleString("en-US", { year: "2-digit", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", hour12: true, }) .replace(/\//g, "-"))+" ")]):_vm._e()]}},{key:"item.isProcessApplication",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(item.isProcessApplication ? item.isProcessApplication : false)+" ")])]}},{key:"item.actions",fn:function({ item }){return [_c('router-link',{attrs:{"to":`/los/applications/${item.id}`}},[_c('v-btn',{attrs:{"x-small":"","outlined":"","color":"green accent-2"}},[_vm._v(" View Details ")])],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }