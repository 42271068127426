<template>
  <div style="margin-left: 56px" class="main-div">
    <v-container class="conatiner-section">
      <v-sheet :elevation="5" dark class="mx-auto pa-5">
        <v-snackbar
          v-model="snackbar"
          :timeout="snackBarTimeout"
          :color="color"
          >{{ snackBarText }}</v-snackbar
        >
        <v-form v-model="valid" ref="form">
          <v-container>
            <v-select
              v-model="AutoClassType"
              :items="autoClassTypeArray"
              label="AutoClass Type"
              :rules="[(v) => !!v || 'AutoClass Type is required']"
              :readonly="isPreview"
              outlined
              color="white"
            ></v-select>

            <v-text-field
              :readonly="isPreview"
              v-model="Name"
              :rules="[(v) => !!v || 'Name is required']"
              label="Name"
              required
              outlined
              color="white"
            ></v-text-field>

            <v-text-field
              :readonly="isPreview"
              v-model="IdKey"
              :rules="[(v) => !!v || 'IdKey is required']"
              label="IdKey"
              required
              outlined
              color="white"
            ></v-text-field>

            <v-select
              v-if="extSystemType != null"
              v-model="ExtSystemTypeName"
              :items="extSystemTypeArray"
              label="ExtSystem Type"
              :rules="[(v) => !!v || 'ExtSystem Type is required']"
              required
              :readonly="isPreview"
              outlined
              color="white"
            ></v-select>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="white"
                outlined
                @click="$router.push({ name: 'schedule' })"
                >{{ isPreview ? "Back" : "Cancel" }}</v-btn
              >
              <v-btn color="blue darken-1" v-if="!isPreview" @click="create">{{
                isEdit ? "Update" : "Create"
              }}</v-btn>
            </v-card-actions>
          </v-container>
        </v-form>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AutoClassForm",
  components: {},
  props: ["id", "preview"],
  data() {
    return {
      isEdit: false,
      isPreview: false,
      valid: false,
      Name: "",
      IdKey: "",

      AutoClassType: null,
      autoClassTypeArray: ["Campaign", "Sync"],

      ExtSystemTypeName: "",
      extSystemType: [],
      extSystemTypeArray: [],

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,
    };
  },
  methods: {
    create() {
      if (this.$refs.form.validate()) {
        let selectedClass = null;
        if (this.ExtSystemTypeName != null) {
          selectedClass = this.extSystemType.find(
            (e) => e.Name == this.ExtSystemTypeName
          );
        }
        if (this.isEdit) {
          //Update
          axios
            .put(
              `${process.env.VUE_APP_BASEURL}/scheduler/autoclass/${this.id}`,
              {
                DataToUpdate: {
                  Name: this.Name,
                  AutoClassType: this.AutoClassType,
                  IdKey: this.IdKey,
                  ExtSystemTypeId: selectedClass ? selectedClass.Id : null,
                },
              }
            )
            .then((response) => {
              if (response.data.status) {
                this.color = "success";
                this.snackBarText = response.data.message;
                this.snackbar = true;
                this.$router.push({
                  name: "schedule",
                });
              } else {
                this.color = "error";
                this.snackBarText = response.data.message;
                this.snackbar = true;
              }
            });
        } else {
          //Create
          axios
            .post(`${process.env.VUE_APP_BASEURL}/scheduler/autoclass`, {
              Name: this.Name,
              AutoClassType: this.AutoClassType,
              IdKey: this.IdKey,
              ExtSystemTypeId: selectedClass ? selectedClass.Id : null,
            })
            .then((response) => {
              if (response.data.status) {
                this.color = "success";
                this.snackBarText = response.data.message;
                this.snackbar = true;
                this.$router.push({
                  name: "schedule",
                });
              } else {
                this.color = "error";
                this.snackBarText = response.data.message;
                this.snackbar = true;
              }
            });
        }
      }
    },
    getAutoClass() {
      axios
        .get(process.env.VUE_APP_BASEURL + "/scheduler/extsystemtype")
        .then((response) => {
          if (response.data.status) {
            this.extSystemType =
              response.data.data.extSystemTypes.length > 0
                ? response.data.data.extSystemTypes
                : [];
            this.extSystemType.length > 0 &&
              this.extSystemType.map((el) => {
                this.extSystemTypeArray.push(el.Name);
              });
          }
        });
    },
  },
  mounted() {
    this.getAutoClass();
    if (typeof this.id !== "undefined") {
      if (this.preview == true) {
        this.isPreview = true;
      } else {
        this.isEdit = true;
      }
      axios
        .get(process.env.VUE_APP_BASEURL + "/scheduler/autoclass/" + this.id)
        .then((response) => {
          if (response.data.status) {
            let classdata = response.data.data.autoClass;
            this.Name = classdata.Name;
            this.AutoClassType = classdata.AutoClassType;
            this.IdKey = classdata.IdKey;
            if (classdata.ExtSystemTypeId) {
              this.ExtSystemTypeName = classdata.ExtSystemType.Name;
            }
          }
        });
    }
  },
};
</script>
<style scoped>
@media (max-width: 991px) {
  .main-div {
    margin: 5px !important;
  }
}
</style>
