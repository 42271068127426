<template>
  <div class="full-height" style="margin-left: 56px">
    <div class="pa-10">
      <v-row>
        <v-col lg="3">
          <v-card color="grey darken-3">
            <v-card-text>
              <div>Daily # of Applications</div>
              <p class="text-h4 text--primary">13</p>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col lg="3">
          <v-card color="grey darken-3">
            <v-card-text>
              <div>Weekly # of Applications</div>
              <p class="text-h4 text--primary">45</p>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col lg="3">
          <v-card color="grey darken-3">
            <v-card-text>
              <div>Monthly # of Applications</div>
              <p class="text-h4 text--primary">192</p>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col lg="3">
          <v-card color="grey darken-3">
            <v-card-text>
              <div>Monthly Approval Rate</div>
              <p class="text-h4 text--primary">87%</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card color="grey darken-3" class="pa-5" width="100%">
            <div class="map-frame">
              <iframe
                width="100%"
                height="800"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://www.google.com/maps/d/u/0/embed?mid=1X1l068DwMcARqFy4l-3umt-369UxuRM&ehbc=2E312F&z=5"
              ></iframe>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.map-frame {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
