<template>
  <div>
    <div v-if="loading">
      <loader />
    </div>

    <div>
      <v-snackbar
        v-model="snackbar"
        :timeout="snackBarTimeout"
        :color="color"
        >{{ snackBarText }}</v-snackbar
      >
      <div
        style="
          border-radius: 0;
          min-height: 100vh;
          font-family: Kumbh Sans !important;
        "
        v-if="pdfData != null"
      >
        <v-sheet
          :elevation="5"
          light
          style="position: relative; width: 320mm; height: 452mm"
        >
          <div
            style="
              position: absolute;
              top: 0;
              display: flex;
              align-items: center;
              margin-left: 80px;
              margin-top: 20px;
            "
          >
            <img
              src="@/assets/crebrid-logo-text-v2.png"
              alt="Crebrid Logo"
              style="width: 130px; margin-top: 30px;"
            />
          </div>

          <div
            style="
              padding-top: 110px;
              margin-left: 80px;
              font-weight: 400;
              font-size: 21px;
            "
          >
            <p style="font-size: 30px">
              <span style="font-weight: bold">Crebrid</span> Lending
            </p>
            <p style="margin-top: 20px; font-weight: bold; font-size: 23px">
              Date: {{ pdfData["Date"] }}
            </p>
            <p style="margin-top: 20px; font-weight: bold; font-size: 23px">
              Borrower: {{ pdfData["firstName"] }} {{ pdfData["lastName"] }}
            </p>
            <p style="margin-top: 20px; font-weight: bold; font-size: 23px">
              Price: {{ pdfData["purchasePrice"] }}
            </p>
            <p style="font-weight: bold; font-size: 30px; margin-top: 20px">
              Loan Pre-Approval Letter
            </p>
            <p style="margin-top: 20px; font-weight: 20">
              To Whom It May Concern,
            </p>
            <p style="margin-top: 20px">
              This is to notify you that your loan application with Crebrid
              Lending, LLC. has been pre-approved as of <br />
              <span style="font-weight: bold">{{ pdfData["Date"] }}</span> for
              the amount listed above.
            </p>

            <p style="margin-top: 27px">
              The following items are still necessary to do the final
              underwriting. Any items marked below need to be<br />
              provided prior to final approval; we will obtain all other items
              on behalf of our client.
            </p>

            <ul style="margin-left: 60px; margin-top: 15px">
              <li>Most recent bank statements.</li>
              <li>
                Fully executed purchase agreement including all addenda and
                assignments.
              </li>
              <li>Title company contact and title commitment.</li>
              <li>
                Detailed Renovation Budget along with bids from contractors.
              </li>
              <li>
                Insurance on the subject property (6-month policy must be paid
                in full on or before loan closing).
              </li>
            </ul>
            <br />
            <p style="">
              Final Approval for the amount indicated above is
              <span style="font-weight: bold; text-decoration: underline"
                >not</span
              >
              a guarantee. Circumstances that could prevent Crebrid <br />
              Lending, LLC. from issuing a final approval:
            </p>

            <ol style="margin-left: 60px; margin-top: 15px">
              <li>
                An item of information not yet known to Crebrid Lending, LLC.
                Inc. disqualifies the borrower from <br />
                applicable loan program.
              </li>
              <li>
                There is a change in the borrower’s financial circumstances that
                affect eligibility for the loan.
              </li>
            </ol>

            <p style="margin-top: 35px">
              Should you have any questions regarding this letter, please feel
              free to contact me any time.
            </p>
            <p style="margin-top: 35px">Thank you for your business!</p>
            <p style="margin-top: 35px; font-style: italic">Kai B. Chandler</p>
            <p style="margin-top: 35px">
              Kai B Chandler <br />
              Chief Operations Officer
            </p>

            <p
              style="
                font-family: 'Times New Roman,Times, serif';
                margin-left: 400px;
                margin-top: 430px;
                color: grey;
              "
            >
              200 CRESCENT COURT DALLAS, TX 75201
            </p>
          </div>
        </v-sheet>
      </div>
    </div>
  </div>
</template>

<!-- Rest of your script and styles -->


<script>
import Loader from "./Loader.vue";
// import { firebaseDB } from "../auth/firebase-auth";
// import html2pdf from "html2pdf.js";
// import axios from "axios";

export default {
  name: "losDownLoad",
  components: {
    Loader,
  },
  props: ["id"],
  data() {
    return {
      loading: false,

      search: "",
      pdfData: null,
    };
  },

  mounted() {
    this.pdfData = window.losObject ? window.losObject : {};
  },
};
</script>
