<template>
  <div class="full-height">
    <div v-if="loading">
      <loader />
    </div>

    <div class="mx-auto rounded-0 full-height">
      <v-sheet :elevation="5" dark class="pa-5" style="position: relative">
        <v-row class="align-center justify-center">
          <v-text-field
            class="pa-2"
            v-model="search"
            label="Search Leads..."
            flat
            solo-inverted
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>

          <div class="ml-2">Total Records: {{ totalRecords }}</div>
        </v-row>
        <!-- </v-sheet> -->

        <div class="mt-4">
          <v-data-table
            :headers="marketHeaders"
            :items="marketData"
            :items-per-page="limit"
            item-key="Full Name"
            :search="search"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:item.LatestActivity="{ item }">
              <div v-if="item.LatestActivity">
                {{
                  new Date(item.LatestActivity)
                    .toLocaleDateString("en-US", {
                      year: "2-digit",
                      month: "numeric",
                      day: "numeric",
                    })
                    .replace(/\//g, "-")
                }}
              </div>
            </template>
            <template v-slot:item.AverageTransferAmount="{ item }">
              <div v-if="item.AverageTransferAmount">
                ${{
                  parseInt(item.AverageTransferAmount).toLocaleString("en-US")
                }}
              </div>
              <div v-else>-</div>
            </template>
            <template v-slot:item.actions="{ item }">
              <!-- <router-link :to="`/marketing/leads/${item.id}`"> -->
              <router-link
                :to="{
                  path: `/marketing/leads/${item.FLIPGrantor1NameFull}`,
                  query: { leadsData: JSON.stringify(item) },
                }"
              >
                <v-btn
                  x-small
                  outlined
                  color="green accent-2"
                  class="black--text"
                >
                  View Details
                </v-btn>
              </router-link>
            </template>
          </v-data-table>
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="5"
            @input="getMarketLeadsData"
          ></v-pagination>
        </div>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../../components/Loader.vue";
export default {
  components: { Loader },
  props: ["value"],

  data() {
    return {
      loading: false,
      search: "",
      market: [],
      limit: 100,
      page: 1,
      totalRecords: 0,
      pageCount: 0,
      marketHeaders: [
        {
          text: "Full Name",
          align: "start",
          width: "100",
          value: "FLIPGrantor1NameFull",
        },
        {
          text: "No of Flips",
          value: "NumberOfFlips",
          align: "start",
          width: "100",
        },
        {
          text: "Latest Activity",
          value: "LatestActivity",
          align: "start",
          width: "100",
        },
        {
          text: "Average Property Cost",
          value: "AverageTransferAmount",
          align: "start",
          width: "100",
        },
        {
          text: "Actions",
          value: "actions",
          align: "start",
          width: "100",
        },
      ],
      marketData: [],
    };
  },
  mounted() {
    this.getMarketLeadsData();
    const childData = this.totalRecords;
  },
  methods: {
    async getMarketLeadsData() {
      this.loading = true;

      try {
        const key = "21b77cec7cb516c40d9d7216ae74e3c3";
        console.log("pagee--", this.page);
        console.log("limit--", this.limit);
        console.log(
          `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/leads?page=${this.page}&limit=${this.limit}&totalRecords=${this.totalRecords}&key=${key}`
        );
        axios
          .get(
            `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/leads?page=${this.page}&limit=${this.limit}&totalRecords=${this.totalRecords}&key=${key}`
          )
          .then((response) => {
            console.log("response", response);
            this.marketData = response.data.flips;
            this.totalRecords = response.data.total_records;
            console.log("total_records--", this.totalRecords);
            this.$emit("child-data", this.totalRecords);
            this.pageCount = Math.ceil(this.totalRecords / this.limit);
            console.log("pagecount---", this.pageCount);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("Error in fetching Leads data:\n" + error);
      }
    },
  },
};
</script>
