import { getInstance } from "./firebase-auth";
import { firebaseDB } from "./firebase-auth";
//import { getInstance } from "./index";

export const authGuard = (to, from, next) => {
  const authService = getInstance();
  console.log("Outside:", authService);

  const fn = () => {
    console.log("Inside fn:", authService);
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      const userDetails = authService.user.providerData[0];
      console.log("User authenticated");
      return next();
    }

    // Otherwise, log in
    console.log("User needs to login");
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};
