<template>
   <v-container>
    <Loader v-if="isLoading" />
    <BlogForm
    :article="article"
    @submit="submitForm"
    @remove-image="removeImage"
    variant="Update"
    />
  </v-container>
</template>

<script>
import { firebasewebDB } from "../../../auth/firebase-auth"; // Import firebasewebDB
import BlogForm from "../../../components/blog/BlogForm.vue";
import Loader from "../../../components/Loader.vue";
import { deleteImage, uploadImage } from "../../../helpers/fireStorageImageHandlers";

export default {
  components: {
    Loader,
    BlogForm
  },
  data() {
    return {
      isLoading: false,
      article: {
        title: "",
        content: "",
        status: "draft",
        publishDate: new Date().toISOString().substr(0, 10),
        image: null,
        imageUrl: null
      }
    };
  },
  mounted() {
    // Fetch article data from id
    this.fetchArticle(this.$route.params.id)
  },

  methods: {
    async fetchArticle(id) {
      try {
        this.isLoading = true
        const db = firebasewebDB.firestore();
        const snapshot = await db.collection("articles").doc(id).get();
        if(!snapshot.exists) {
          return this.$router.push({ path: '/marketing/blog', query: { message: 'Article not found' } })
        }
        this.article = {
          id: snapshot.id,
          ...snapshot.data(),
        };
        
        this.article.publishDate = this.article.publishDate.toDate().toISOString().substr(0, 10);
        this.isLoading = false
      } catch (error) {
        console.error("Error loading articles: ", error);
      } finally {
        this.loading = false; // Set loading to false after articles are loaded
      }
    },
    async submitForm() {
      try {
        this.isLoading = true
        let imageUrl = this.article.imageUrl || ''
        if (this.article.image) {
          // A new image is selected, we need to delete the old one
          if (this.article.imageUrl) {
            await deleteImage(this.article.imageUrl);
          }
          imageUrl = await uploadImage(this.article.image)
        }
        const db = firebasewebDB.firestore(); // Use firebasewebDB for Firestore
        await db.collection("articles").doc(this.article.id).update({
          title: this.article.title,
          content: this.article.content,
          status: this.article.status,
          publishDate: new Date(this.article.publishDate),
          createdAt: new Date(),
          imageUrl
        });
        // Reset form and navigate away
        this.resetForm();
        this.$router.push({ path: '/marketing/blog', query: { message: 'Article updated successfully' } })
      } catch (e) {
        console.error("Error adding document: ", e);
      } finally {
        this.isLoading = false
      }
    },
    resetForm() {
      this.article = {
        title: "",
        content: "",
        status: "draft",
        publishDate: new Date().toISOString().substr(0, 10),
      };
    },
    async removeImage() {
      // This method will remove the image from Firebase Storage
      deleteImage(this.article.imageUrl);

      // After successful removal, clear the imageUrl in your component
      this.article.imageUrl = null;
    },

  },
};
</script>