<template>
  <div>
    <div v-if="loading">
      <loader />
    </div>

    <div class="ml-12 pl-2 nalu-container">
      <v-snackbar
        v-model="snackbar"
        :timeout="snackBarTimeout"
        :color="color"
        >{{ snackBarText }}</v-snackbar
      >

      <div class="mx-auto rounded-0 full-height">
        <div>
          <v-tabs
            fixed-tabs
            background-color="teal darken-3"
            color="white"
            height="70"
            dark
          >
            <v-tab>Units</v-tab>
            <v-tab>Residents</v-tab>
            <v-tab>Leases</v-tab>

            <!-- Units List -->
            <v-tab-item class="mt-5">
              <v-sheet
                :elevation="5"
                color="blue-grey darken-4"
                class="ma-12 pa-10"
                style="position: relative"
              >
                <v-data-table
                  loading-text="Loading... Please wait"
                  :headers="headersUnits"
                  :items="units"
                  :options.sync="unitOptions"
                  :server-items-length="totalUnits"
                  :loading="unitLoading"
                  class="elevation-1"
                />
              </v-sheet>
            </v-tab-item>
            <!-- End Units List -->

            <!-- Residents List -->
            <v-tab-item class="mt-5">
              <v-sheet
                :elevation="5"
                color="blue-grey darken-4"
                class="ma-12 pa-10"
                style="position: relative"
              >
                <v-data-table
                  loading-text="Loading... Please wait"
                  :headers="headersResidents"
                  :items="residents"
                  :options.sync="residentOptions"
                  :server-items-length="totalResidents"
                  :loading="residentLoading"
                  class="elevation-1"
                >
                  <template #item.full_name="{ item }"
                    >{{ item.FirstName }} {{ item.LastName }}</template
                  >
                </v-data-table>
              </v-sheet>
            </v-tab-item>
            <!-- End Residents List -->

            <!-- Leases List -->
            <v-tab-item class="mt-5">
              <v-sheet
                :elevation="5"
                color="blue-grey darken-4"
                class="ma-12 pa-10"
                style="position: relative"
              >
                <v-data-table
                  loading-text="Loading... Please wait"
                  :headers="headersLeases"
                  :items="leases"
                  :options.sync="leaseOptions"
                  :server-items-length="totalLeases"
                  :loading="leaseLoading"
                  class="elevation-1"
                />
              </v-sheet>
            </v-tab-item>
            <!-- End Units List -->
          </v-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "./Loader.vue";

export default {
  name: "Property",
  components: {
    Loader,
  },
  props: ["propertyid"],
  data() {
    return {
      loading: false,

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,

      headersUnits: [
        { text: "Number", value: "Number" },
        { text: "Bld_Flr", value: "Bld_Flr" },
        { text: "SqFt", value: "SqFt" },
        { text: "Occupied", value: "Occupied" },
        { text: "Status", value: "Status" },
      ],
      units: [],
      unitOptions: {
        itemsPerPage: 10,
        page: 1,
      },
      totalUnits: 0,
      unitLoading: true,

      headersResidents: [
        { text: "CompanyId", value: "CompanyId" },
        { text: "Full Name", value: "full_name" },
        { text: "Email", value: "Email" },
      ],
      residents: [],
      residentOptions: {
        itemsPerPage: 10,
        page: 1,
      },
      totalResidents: 0,
      residentLoading: true,

      headersLeases: [
        { text: "Unit", value: "Unit" },
        { text: "UnitType", value: "UnitType" },
        { text: "Lease StartDate", value: "LeaseStartDate" },
        { text: "Lease EndDate", value: "LeaseEndDate" },
        { text: "Lease Status", value: "LeaseStatus" },
      ],
      leases: [],
      leaseOptions: {
        itemsPerPage: 10,
        page: 1,
      },
      totalLeases: 0,
      leaseLoading: true,

      count: 0,
    };
  },
  watch: {
    count() {
      if (this.count == 3 && this.loading) {
        this.loading = false;
      }
    },
    unitOptions: {
      handler() {
        this.getDataFromUnitApi().then((data) => {
          this.units = data.items;
          this.totalUnits = data.total;
        });
      },
      deep: true,
    },
    residentOptions: {
      handler() {
        this.getDataFromResidentApi().then((data) => {
          this.residents = data.items;
          this.totalResidents = data.total;
        });
      },
      deep: true,
    },
    leaseOptions: {
      handler() {
        this.getDataFromLeaseApi().then((data) => {
          this.leases = data.items;
          this.totalLeases = data.total;
        });
      },
      deep: true,
    },
  },
  methods: {
    getUnits() {
      return axios
        .get(process.env.VUE_APP_BASEURL + "/unit", {
          params: {
            propertyId: this.propertyid,
            pageNo: this.unitOptions.page,
            pageSize: this.unitOptions.itemsPerPage,
          },
        })
        .then((response) => {
          if (response) {
            let total = response.data.data.count;
            let items =
              response.data.data.units.length > 0
                ? response.data.data.units
                : [];
            return { items, total };
            // this.count++;
          }
        });
    },
    getResidents() {
      return axios
        .get(process.env.VUE_APP_BASEURL + "/resident", {
          params: {
            propertyId: this.propertyid,
            pageNo: this.residentOptions.page,
            pageSize: this.residentOptions.itemsPerPage,
          },
        })
        .then((response) => {
          if (response) {
            let total = response.data.data.count;
            let items =
              response.data.data.residents.length > 0
                ? response.data.data.residents
                : [];
            return { items, total };
            // this.count++;
          }
        });
    },
    getLeases() {
      return axios
        .get(process.env.VUE_APP_BASEURL + "/lease", {
          params: {
            propertyId: this.propertyid,
            pageNo: this.leaseOptions.page,
            pageSize: this.leaseOptions.itemsPerPage,
          },
        })
        .then((response) => {
          if (response) {
            let total = response.data.data.count;
            let items =
              response.data.data.leases.length > 0
                ? response.data.data.leases
                : [];
            return { items, total };
            // this.count++;
          }
        });
    },
    async getDataFromUnitApi() {
      this.unitLoading = true;
      const { page, itemsPerPage } = this.unitOptions;

      let response = await this.getUnits();
      let { items, total } = response;

      this.unitLoading = false;
      return { items, total };
    },
    async getDataFromResidentApi() {
      this.residentLoading = true;
      const { page, itemsPerPage } = this.residentOptions;

      let response = await this.getResidents();
      let { items, total } = response;

      this.residentLoading = false;
      return { items, total };
    },
    async getDataFromLeaseApi() {
      this.leaseLoading = true;
      const { page, itemsPerPage } = this.leaseOptions;

      let response = await this.getLeases();
      let { items, total } = response;

      this.leaseLoading = false;
      return { items, total };
    },
  },
  mounted() {
    this.getDataFromUnitApi().then((data) => {
      this.units = data.items;
      this.totalUnits = data.total;
    });
    this.getDataFromResidentApi().then((data) => {
      this.residents = data.items;
      this.totalResidents = data.total;
    });
    this.getDataFromLeaseApi().then((data) => {
      this.leases = data.items;
      this.totalLeases = data.total;
    });
  },
};
</script>

<style scoped>
.full-height {
  min-height: 100vh;
}
.nalu-container {
  background-color: #1e1e1e !important;
}
h1 {
  text-align: center;
  margin: 10px;
}
.v-text-field .v-label {
  overflow: visible !important;
  top: 1% !important;
}
.label {
  color: #c7c7c7 !important;
}
.search-field {
  max-width: 50%;
}
.item {
  cursor: pointer;
}
</style>
