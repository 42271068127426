<template>
  <div class="full-height pa-5" style="margin-left: 56px">
    <div v-if="loading">
      <loader />
    </div>

    <v-snackbar v-model="snackbar" :timeout="snackBarTimeout" :color="color">{{
      snackBarText
    }}</v-snackbar>

    <div>
      <!-- <div v-if="crm != null"> -->
      <v-sheet :elevation="5" dark class="pa-10" style="position: relative">
        <div class="field-detail" v-if="mappedCompanyObj != null">
          <v-row>
            <v-col
              lg="6"
              v-for="(value, propertyName, i) in mappedCompanyObj"
              :key="i"
            >
              <v-text-field
                :value="value"
                :label="propertyName"
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import Loader from "../../components/Loader.vue";
import axios from "axios";

export default {
  name: "PropertyFlipsId",
  components: {
    Loader,
  },
  props: ["id"],
  data() {
    return {
      loading: false,
      companyDetails: null,
      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,
      mappedCompanyObj: null,
      userLoanApplicationsObj: [],

      crm: [],
    };
  },

  mounted() {
    this.getCompanyData();
  },

  methods: {
    async getCompanyData() {
      this.loading = true;
      console.log(this.$props.id);
      try {
        const key = "21b77cec7cb516c40d9d7216ae74e3c3";
        await axios
          .get(
            // `http://127.0.0.1:3000/property_flips/detail?id=${this.$props.id}&key=${key}`
            `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/property_flips/detail?id=${this.$props.id}&key=${key}`
          )
          .then((response) => {
            console.log(response.data[0]);
            this.companyDetails = response.data[0];
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        await this.mapfields();

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("Error in fetching companies data:\n" + error);
      }
    },

    //map fields
    async mapfields() {
      var result = Object.entries(this.companyDetails);
      let reverse = result
        .map(([e1, v1]) => ({ [e1]: v1 }))
        .reduce((pv, cv) => {
          return Object.assign(pv, cv);
        });
      this.mappedCompanyObj = reverse;
      console.log(reverse);
    },
  },
};
</script>
<style lang="scss" scoped>
.full-height {
  min-height: 100vh;
}

.v-text-field .v-label {
  overflow: visible !important;
  top: 1% !important;
}

.label {
  color: #c7c7c7 !important;
}

.item {
  cursor: pointer;
}

.fa {
  margin: 3px;
  font-size: 1rem;
}

.v-tab {
  text-transform: none !important;
}

.tab-nav-btn {
  background: #212121;
  width: 100%;
  max-width: 300px;
  padding: 0 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;

  a {
    text-decoration: none;
    color: #69f0ae;
  }
}
</style>
