import { firebaseDB } from "../src/auth/firebase-auth.js";


export async function fetchData() {
  try {
    const losRef = firebaseDB.firestore().collection("mortgageLoans");
    const snapshot = await losRef.get();
    // Extract funded and in-progress loans from snapshot
    const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    // Store data in component state
    const fundedLoans = data.filter(loan => loan.status === 1);
    const inProgressLoans = data.filter(loan => loan.status === 0);

    return {fundedLoans, inProgressLoans}
  } catch (error) {
    console.log("Error fetching loans:", error);
  }
}