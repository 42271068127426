<template>
  <v-card class="grey darken-4 pa-4">
    <v-form @submit.prevent="handleSubmit">
      <h2>{{ variant }} an article</h2>
      <v-text-field v-model="article.title" label="Title" required />

      <div class="editor">
        <EditorToolbar :editor="editor" v-if="editor" />
        <editor-content :editor="editor" />
      </div>

      <v-select
        v-model="article.status"
        :items="statusOptions"
        label="Status"
        class="mt-4"
        required
      />

      <div v-if="article.imageUrl">
        <v-img :src="article.imageUrl" height="200px" class="mb-2" />
        <v-btn @click="$emit('remove-image')" color="error" x-small>
          Remove Image
        </v-btn>
      </div>

      <v-file-input
        clearable
        v-model="article.image"
        label="Featured Image"
        prepend-icon="mdi-camera"
        variant="underlined"
        accept="image/*"
      />
      <v-menu
        v-model="dateMenu"
        :close-on-content-click="false"
        :nudge-right="40" 
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="article.publishDate"
            label="Publish Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="article.publishDate"
          @input="dateMenu = false"
        />
      </v-menu>

      <v-btn
        color="primary"
        class="mt-4"
        @click="handleSubmit"
        :disabled="isSubmitting"
      >
        {{ variant }} Article
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import EditorToolbar from "./EditorToolbar.vue";

export default {
  components: {
    EditorContent,
    EditorToolbar,
  },
  props: {
    article: {
      type: Object,
      default: () => ({
        title: "",
        content: "",
        status: "draft",
        publishDate: new Date().toISOString().substr(0, 10),
        image: null,
        imageUrl: null,
      }),
    },
    variant: {
      type: String,
      default: "Create",
    },
  },
  data() {
    return {
      isSubmitting: false,
      editor: null,
      // article: { ...this.initialArticleData },
      statusOptions: ["draft", "published"],
      dateMenu: false,
    };
  },
  watch: {
  article: {
    handler(newArticle) {
      if (!this.editor) {
        // Editor not initialized yet, initialize it with content
        this.initializeEditor(newArticle.content || ''); 
      } else {
        // Editor already exists, update its content
        this.editor.commands.setContent(newArticle.content || '', false);
      }
    },
    immediate: true, // Run on component creation
  },
},
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    initializeEditor(content) {
      this.editor = new Editor({
        content: content, // Use the provided content
        extensions: [
          StarterKit,
          TextAlign.configure({
            types: ["heading", "paragraph"],
          }),
        ],
        onUpdate: () => {
          this.article.content = this.editor.getHTML();
        },
      });
    },
    handleSubmit() {
      this.isSubmitting = true;
      this.$emit("submit", {...this.article}, () => {
        this.isSubmitting = false;
      });
    },
  },
};
</script>

<style lang="scss">
.editor {
  outline: -webkit-focus-ring-color auto 1px;
}
.control-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
  span {
    background: grey;
    border-radius: 8px;
    display: inline-block;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    &.is-active{
      background: #2196f3;
    }
  }
}
.tiptap {
  height: 45vh;
  padding: 1rem;
  margin-top: 0.5rem;
  outline: 0;
  overflow: auto;
  &:focus-visible {
    outline: 0;
  }
  :first-child {
    margin-top: 0;
  }
  /* List styles */
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
    text-align: unset;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  /* Code and preformatted text styles */
  code {
    border-radius: 0.4rem;
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: lightgrey;
    border-radius: 0.5rem;
    color: black;
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid grey;
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid grey;
    margin: 2rem 0;
  }
}
/* Add any additional styles if needed */

</style>

