<template>
  <div>
    <div v-if="loading">
      <loader />
    </div>

    <div class="ml-12 pl-2">
      <v-snackbar
        v-model="snackbar"
        :timeout="snackBarTimeout"
        :color="color"
        >{{ snackBarText }}</v-snackbar
      >

      <div class="mx-auto rounded-0 full-height">
        <v-sheet
          :elevation="5"
          dark
          class="my-12 mx-12 py-5 px-10"
          style="position: relative"
        >
          <h1 class="mb-10">User Details</h1>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="First Name*"
                  v-model="userObject.firstName"
                  :rules="[(v) => !!v || 'First Name is required']"
                  required
                  outlined
                  color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Last Name*"
                  v-model="userObject.lastName"
                  required
                  outlined
                  color="white"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Email"
                  v-model="userObject.email"
                  disabled
                  outlined
                  color="white"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
              color="green accent-2"
              class="black--text"
              @click="updateUser()"
              >Update</v-btn
            >
          </v-card-text>
        </v-sheet>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../../components/Loader.vue";
import { firebaseDB } from "../../auth/firebase-auth";

export default {
  name: "settings",
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,

      userDialog: false,
      userObject: {
        firstName: "",
        lastName: "",
        email: "",
      },
      userUid: null,
    };
  },
  async mounted() {
    await this.getUserData();
  },
  methods: {
    async getUserData() {
      this.loading = true;
      let userEmail = this.$cookies.get("user").email;
      const userRef = firebaseDB
        .firestore()
        .collection("users")
        .where("email", "==", userEmail);
      try {
        if (userEmail) {
          const { docs } = await userRef.get();

          let userObject = docs.map((doc) => {
            const { id } = doc;
            const data = doc.data();
            return { id, ...data };
          });

          this.userObject = userObject[0];
          this.userUid = this.userObject.id;
        }

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("Error in fetching users data:\n" + error);
      }
    },
    async updateUser() {
      this.loading = true;
      try {
        const userRef = await firebaseDB
          .firestore()
          .collection("users")
          .doc(this.userUid);

        userRef.update({
          firstName: this.userObject.firstName,
          lastName: this.userObject.lastName ? this.userObject.lastName : "",
        });

        this.userEditDialog = false;

        this.color = "success";
        this.snackBarText = "User updated Successfully";
      } catch (error) {
        this.color = "error";
        this.snackBarText = error;
      }
      this.loading = false;
      this.snackbar = true;
    },
  },
};
</script>

<style scoped>
.full-height {
  min-height: 100vh;
}
.v-text-field .v-label {
  overflow: visible !important;
  top: 1% !important;
}
.label {
  color: #c7c7c7 !important;
}
.item {
  cursor: pointer;
}
.fa {
  margin: 3px;
  font-size: 1rem;
}
</style>
