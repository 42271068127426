<template>
  <v-container>
    <Loader v-if="isLoading"/>
   <BlogForm
    :article="article"
    @submit="submitForm"
    variant="Create"
  />
  </v-container>
</template>

<script>
import { firebasewebDB } from "../../../auth/firebase-auth"; // Import firebasewebDB
import Loader from "../../../components/Loader.vue";
import BlogForm from "../../../components/blog/BlogForm.vue";
import { uploadImage } from "../../../helpers/fireStorageImageHandlers";

export default {
  components: {
    Loader,
    BlogForm
  },
  data() {
    return {
      isLoading: false,
      article: {
        title: "",
        content: "",
        status: "draft",
        publishDate: new Date().toISOString().substr(0, 10),
        image: null,
        imageUrl: null
      },
      statusOptions: ["draft", "published"],
      dateMenu: false,
      featureImageRules: [
        value => {
          return !value || !value.length || value[0].size < 5000000 || 'Feature image size should be less than 5 MB!'
        },
      ],
    };
  },
  methods: {
    async submitForm() {
      try {
        this.isLoading = true
        let imageUrl = null
        if (this.article.image) {
          imageUrl = await uploadImage(this.article.image)
        }
        const db = firebasewebDB.firestore(); // Use firebasewebDB for Firestore
        const docRef = await db.collection("articles").add({
          title: this.article.title,
          content: this.article.content,
          status: this.article.status,
          publishDate: new Date(this.article.publishDate),
          createdAt: new Date(),
          imageUrl
        });
        // console.log("Document written with ID: ", docRef.id);
        // Reset form and navigate away
        this.resetForm();
        this.$router.push({ path: '/marketing/blog', query: { message: 'Article created successfully', articleId: 'docRef.id' } })
      } catch (e) {
        console.error("Error adding document: ", e);
      } finally {
        this.isLoading = false
      }
    },
    resetForm() {
      this.article = {
        title: "",
        content: "",
        status: "draft",
        publishDate: new Date().toISOString().substr(0, 10),
      };
    }
  },
};
</script>
