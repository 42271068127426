<template>
  <div class="landing-page">
    <div v-if="showOverlay" class="overlay">
      <div>
        <div class="modal">
          <div
            class="logo-wrapper"
            style="margin-bottom: 25px; justify-content: center"
          >
            <img src="@/assets/crebrid-logo-full.png" class="logo" />
          </div>
          <div class="d-flex">
            <v-text-field
              v-model="password"
              label="Enter site password..."
              type="password"
              light
              flat
              solo-inverted
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
              style="width: 200px"
              @keyup.enter="verifyPassword"
            ></v-text-field>

            <v-btn
              @click="verifyPassword"
              color="#F5F0E6"
              style="font-weight: 900; margin-left: 10px; height: 48px"
              elevation="0"
              light
              >Submit</v-btn
            >
          </div>
        </div>
      </div>
    </div>

    <div v-else class="overlay">
      <div>
        <div class="modal">
          <div
            class="logo-wrapper"
            style="margin-bottom: 20px; justify-content: center"
          >
            <img src="@/assets/crebrid-logo-full.png" class="logo" />
          </div>
          <v-btn
            @click="login"
            color="#F5F0E6"
            style="font-weight: 900; height: 48px"
            elevation="0"
            light
            >Login to OS</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      showOverlay: true,
    };
  },

  mounted() {},

  methods: {
    // Log the user in
    login() {
      //this.$auth.loginWithRedirect();
      this.$auth.loginWithPopup();
    },

    verifyPassword() {
      if (this.password === "wcl") {
        this.showOverlay = false;
      } else {
        alert("Wrong password!");
        this.password = ""; // Clear input for retry
      }
    },
  },
};
</script>

<style lang="scss" scoped>
body,
*,
p,
a,
button {
  font-family: "Kumbh Sans" !important ;
}

.v-btn {
  text-transform: none !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3e3e40;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.logo-wrapper {
  display: flex;
}

@media only screen and (min-width: 769px) {
  .logo {
    width: 175px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 24px;
  }

  .logo {
    width: 125px;
  }
}
</style>
