<template>
  <div class="full-height pa-5" style="margin-left: 56px">
    <div v-if="loading">
      <loader />
    </div>

    <v-snackbar v-model="snackbar" :timeout="snackBarTimeout" :color="color">{{
      snackBarText
    }}</v-snackbar>

    <div>
      <!-- <div v-if="crm != null"> -->
      <v-sheet :elevation="5" dark class="pa-10" style="position: relative">
        <div class="field-detail" v-if="mappedCompanyObj != null">
          <v-row>
            <v-col
              lg="6"
              v-for="(value, propertyName, i) in mappedCompanyObj"
              :key="i"
            >
              <template>
                <v-text-field
                  :value="value"
                  :label="propertyName"
                  outlined
                  readonly
                ></v-text-field>
              </template>
            </v-col>
          </v-row>
          <template v-if="photokey != null">
            <!-- Display images in a grid/gallery -->
            <v-row class="image-grid">
              <v-col v-for="index in count" :key="index">
                <v-img
                  :src="
                    combineImageURL(
                      'https://d2sa73fkuaa019.cloudfront.net/listings/cbrrets/',
                      photokey,
                      index
                    )
                  "
                  aspect-ratio="1"
                  contain
                  @click="
                    openImageModal(
                      combineImageURL(
                        'https://d2sa73fkuaa019.cloudfront.net/listings/cbrrets/',
                        photokey,
                        index
                      )
                    )
                  "
                ></v-img>
              </v-col>
            </v-row>
          </template>
        </div>
      </v-sheet>
    </div>
    <div v-if="showModal" class="modal">
      <span class="close" @click="closeImageModal">&times;</span>
      <img :src="selectedImage" alt="Full Size Image" />
    </div>
  </div>
</template>

<script>
import Loader from "../../components/Loader.vue";
import axios from "axios";

export default {
  name: "AnalyticsId",
  components: {
    Loader,
  },
  props: ["id"],
  data() {
    return {
      loading: false,
      companyDetails: null,
      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,
      mappedCompanyObj: null,
      userLoanApplicationsObj: [],
      count: null,
      photokey: null,
      showModal: false,
      crm: [],
    };
  },

  mounted() {
    this.getCompanyData();
  },

  methods: {
    combineImageURL(prefix, key, count) {
      // Combine PhotoURLPrefix and PhotoKey to form the image URL
      return prefix + key + "/photo_" + count + ".jpg";
    },
    openImageModal(imageUrl) {
      console.log(imageUrl);
      this.selectedImage = imageUrl;
      this.showModal = true;
    },
    closeImageModal() {
      this.selectedImage = "";
      this.showModal = false;
    },
    async getCompanyData() {
      this.loading = true;
      console.log(this.$props.id);
      try {
        const key = "21b77cec7cb516c40d9d7216ae74e3c3";
        await axios
          .get(
            // `http://127.0.0.1:3000/listing_analytics/detail?id=${this.$props.id}&key=${key}`
            `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/listing_analytics/detail?id=${this.$props.id}&key=${key}`
          )
          .then((response) => {
            console.log(response.data[0]);
            this.companyDetails = response.data[0];
            console.log(
              "PhotoURLPrefix-----",
              this.companyDetails.PhotoURLPrefix
            );
            console.log(this.companyDetails.PhotoKey);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        await this.mapfields();

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("Error in fetching companies data:\n" + error);
      }
    },

    //map fields
    async mapfields() {
      var result = Object.entries(this.companyDetails);
      let reverse = result
        .map(([e1, v1]) => ({ [e1]: v1 }))
        .reduce((pv, cv) => {
          return Object.assign(pv, cv);
        });
      this.mappedCompanyObj = reverse;
      console.log("mappedCompanyObj-------------", this.mappedCompanyObj);
      console.log(typeof this.mappedCompanyObj.PhotosCount);
      this.count =
        Array.from(
          { length: +this.mappedCompanyObj.PhotosCount },
          (_, index) => index + 1
        ) || null;
      this.photokey = this.mappedCompanyObj.PhotoKey.toString() || null;
      console.log(this.photokey, typeof this.mappedCompanyObj.PhotoKey);
    },
  },
};
</script>
<style lang="scss" scoped>
.full-height {
  min-height: 100vh;
}

.v-text-field .v-label {
  overflow: visible !important;
  top: 1% !important;
}

.label {
  color: #c7c7c7 !important;
}

.item {
  cursor: pointer;
}

.fa {
  margin: 3px;
  font-size: 1rem;
}

.v-tab {
  text-transform: none !important;
}

.tab-nav-btn {
  background: #212121;
  width: 100%;
  max-width: 300px;
  padding: 0 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;

  a {
    text-decoration: none;
    color: #69f0ae;
  }
}
.modal {
  display: block;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal img {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close {
  color: #fff;
  font-size: 30px;
  position: absolute;
  top: 15px;
  right: 25px;
  cursor: pointer;
}
.image-grid {
  display: grid;
  grid-template-columns: repeat(
    4,
    minmax(100px, 1fr)
  ); /* Display 4 images in a row */
  gap: 10px;
}
</style>
