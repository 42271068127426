import { render, staticRenderFns } from "./Flippers.vue?vue&type=template&id=4c710c74&scoped=true&"
import script from "./Flippers.vue?vue&type=script&lang=js&"
export * from "./Flippers.vue?vue&type=script&lang=js&"
import style0 from "./Flippers.vue?vue&type=style&index=0&id=4c710c74&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c710c74",
  null
  
)

export default component.exports