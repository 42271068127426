<template>
  <div>
    <div v-if="loading">
      <loader />
    </div>

    <div v-else class="main-div" style="margin-left: 56px">
      <v-snackbar
        v-model="snackbar"
        :timeout="snackBarTimeout"
        :color="color"
        >{{ snackBarText }}</v-snackbar
      >

      <div class="mx-auto rounded-0 full-height" v-if="items != null">
        <v-sheet dark class="pa-12">
          <v-text-field
            v-model="search"
            label="Search Jobs by Companies..."
            dark
            flat
            solo-inverted
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>
        </v-sheet>

        <div>
          <v-tabs fixed-tabs color="white">
            <v-tab>
              Calendar
              <v-chip
                class="ma-2 black--text"
                color="green accent-2"
                label
                x-small
              >
                Beta
              </v-chip>
            </v-tab>
            <v-tab> Auto Config List </v-tab>
            <v-tab> Auto Class List </v-tab>
            <v-tab> Auto Schedule List </v-tab>
            <v-tab> Auto Job List </v-tab>

            <!-- Calendar -->
            <v-tab-item class="pa-12 nalu-container">
              <Calendar viewtype="month" />
            </v-tab-item>

            <!-- Auto Config List -->
            <v-tab-item class="pa-12 nalu-container">
              <v-sheet
                :elevation="5"
                class="pa-10 main-content"
                style="position: relative"
              >
                <v-btn
                  dark
                  fab
                  fixed
                  bottom
                  right
                  color="green accent-2"
                  @click="addItem('config')"
                >
                  <v-icon color="black">mdi-plus</v-icon>
                </v-btn>
                <v-data-table
                  loading-text="Loading... Please wait"
                  v-model="selected"
                  :headers="headers"
                  :items="configs"
                  item-key="name"
                  :items-per-page="10"
                  class="elevation-1"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-menu bottom right>
                      <template v-slot:activator="{ on }">
                        <v-btn dark icon v-on="on">
                          <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(listitem, i) in items"
                          :key="i"
                          @click="
                            triggerClick(listitem.clickEvent, item, 'config')
                          "
                        >
                          <v-list-item-title>{{
                            listitem.title
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-sheet>
            </v-tab-item>
            <!-- End Auto Config List -->

            <!-- Auto Class List -->
            <v-tab-item class="pa-12 nalu-container">
              <v-sheet
                :elevation="5"
                dark
                class="pa-10 main-content"
                style="position: relative"
              >
                <v-btn
                  dark
                  fab
                  fixed
                  bottom
                  right
                  color="green accent-2"
                  @click="addItem('class')"
                >
                  <v-icon color="black">mdi-plus</v-icon>
                </v-btn>
                <v-data-table
                  loading-text="Loading... Please wait"
                  v-model="selectedClasses"
                  :headers="headersClasses"
                  :items="classes"
                  item-key="name"
                  :items-per-page="10"
                  class="elevation-1"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-menu bottom right>
                      <template v-slot:activator="{ on }">
                        <v-btn dark icon v-on="on">
                          <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(listitem, i) in items"
                          :key="i"
                          @click="
                            triggerClick(listitem.clickEvent, item, 'class')
                          "
                        >
                          <v-list-item-title>{{
                            listitem.title
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-sheet>
            </v-tab-item>
            <!-- End Auto Class List -->

            <!-- Auto Schedule List -->
            <v-tab-item class="pa-12 nalu-container">
              <v-sheet
                :elevation="5"
                dark
                class="pa-10 main-content"
                style="position: relative"
              >
                <v-btn
                  dark
                  fab
                  fixed
                  bottom
                  right
                  color="green accent-2"
                  @click="addItem('schedule')"
                >
                  <v-icon color="black">mdi-plus</v-icon>
                </v-btn>
                <v-data-table
                  loading-text="Loading... Please wait"
                  v-model="selectedSchedule"
                  :headers="headersSchedule"
                  :items="schedule"
                  item-key="name"
                  :items-per-page="10"
                  class="elevation-1"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-menu bottom right>
                      <template v-slot:activator="{ on }">
                        <v-btn dark icon v-on="on">
                          <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(listitem, i) in items"
                          :key="i"
                          @click="
                            triggerClick(listitem.clickEvent, item, 'schedule')
                          "
                        >
                          <v-list-item-title>{{
                            listitem.title
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-sheet>
            </v-tab-item>
            <!-- End Auto Schedule List -->

            <!-- Auto Job List -->
            <v-tab-item class="pa-12 nalu-container">
              <v-sheet
                :elevation="5"
                dark
                class="pa-10 main-content"
                style="position: relative"
              >
                <v-data-table
                  loading-text="Loading... Please wait"
                  v-model="selectedJob"
                  :headers="headersJob"
                  :items="job"
                  item-key="name"
                  :items-per-page="10"
                  class="elevation-1"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      dark
                      small
                      v-if="
                        item.Status !== 'Complete' &&
                        item.Status !== 'Exited' &&
                        item.Status !== 'Exit'
                      "
                      color="green accent-2"
                      class="black--text mr-right"
                      @click="stopJob(item)"
                    >
                      Stop
                    </v-btn>
                    <v-btn
                      dark
                      small
                      color="green accent-2"
                      class="black--text"
                      @click="showInstance(item)"
                    >
                      View AutoConfigInstance
                    </v-btn>
                  </template>
                </v-data-table>
              </v-sheet>
            </v-tab-item>
            <!-- End Auto Job List -->
          </v-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../../components/Loader.vue";
import Calendar from "../../components/dashboard/Calendar.vue";

export default {
  name: "schedule",
  components: {
    Loader,
    Calendar,
  },
  data() {
    return {
      loading: true,

      showItems: false,
      items: [
        { title: "Preview", clickEvent: "previewItem" },
        { title: "Edit", clickEvent: "editItem" },
        { title: "Delete", clickEvent: "deleteItem" },
      ],
      selected: [],
      headers: [
        {
          text: "Config Name",
          align: "left",
          sortable: false,
          value: "Name",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      configs: [],

      selectedClasses: [],
      headersClasses: [
        {
          text: "Classes Name",
          align: "left",
          sortable: false,
          value: "Name",
        },
        { text: "AutoClass Type", value: "AutoClassType" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      classes: [],

      selectedSchedule: [],
      headersSchedule: [
        {
          text: "Schedule Name",
          align: "left",
          sortable: false,
          value: "Name",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      schedule: [],

      selectedJob: [],
      headersJob: [
        { text: "Config Name", value: "AutoConfig.Name" },
        { text: "StartTime", value: "StartTime" },
        { text: "EndTime", value: "EndTime" },
        { text: "Duration", value: "Duration" },
        { text: "Status", value: "Status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      job: [],
      // selectedConfigAssign: [],
      // headersConfigAssign: [
      //   {
      //     text: "ConfigAssign ID",
      //     align: "left",
      //     value: "AutoConfigId"
      //   },
      //   { text: "id", value: "Id" }
      // ],
      // configAssign: [],

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,
      search: null,
    };
  },
  methods: {
    triggerClick(function_name, item, type) {
      this[function_name](item, type);
    },
    getRouteType(type) {
      let routerName = "";
      if (type == "config") {
        routerName = "autoConfigForm";
      } else if (type == "class") {
        routerName = "autoClassForm";
      } else if (type == "schedule") {
        routerName = "autoScheduleForm";
      }
      return routerName;
    },
    addItem(type) {
      let routerName = this.getRouteType(type);
      this.$router.push({
        name: routerName,
      });
    },
    deleteItem(item, type) {
      let endpoint;
      if (type == "config") {
        endpoint = "autoconfig";
      } else if (type == "class") {
        endpoint = "autoclass";
      } else if (type == "schedule") {
        endpoint = "autoschedule";
      }
      confirm("Are you sure you want to delete this item?") &&
        axios
          .delete(
            process.env.VUE_APP_BASEURL + `/scheduler/${endpoint}/${item.Id}`
          )
          .then((response) => {
            if (response) {
              if (response.data.status) {
                this.color = "success";
                this.snackBarText = response.data.message;
                this.snackbar = true;
                if (type == "config") {
                  this.getConfigs();
                } else if (type == "class") {
                  this.getClasses();
                } else if (type == "schedule") {
                  this.getSchedule();
                }
              } else {
                this.color = "error";
                this.snackBarText = response.data.message;
                this.snackbar = true;
              }
            }
          });
    },
    editItem(item, type) {
      let routerName = this.getRouteType(type);
      this.$router.push({
        name: routerName,
        params: {
          id: item.Id,
        },
      });
    },
    previewItem(item, type) {
      let routerName = this.getRouteType(type);
      this.$router.push({
        name: routerName,
        params: {
          id: item.Id,
          preview: true,
        },
      });
    },
    getConfigs() {
      axios
        .get(process.env.VUE_APP_BASEURL + "/scheduler/autoconfig")
        .then((response) => {
          if (response.data.status) {
            this.configs =
              response.data.data.configs.length > 0
                ? response.data.data.configs
                : [];
          }
        });
    },
    getClasses() {
      axios
        .get(process.env.VUE_APP_BASEURL + "/scheduler/autoclass")
        .then((response) => {
          if (response.data.status) {
            this.classes =
              response.data.data.autoClasses.length > 0
                ? response.data.data.autoClasses
                : [];
          }
        });
    },
    getSchedule() {
      axios
        .get(process.env.VUE_APP_BASEURL + "/scheduler/autoschedule")
        .then((response) => {
          if (response.data.status) {
            this.schedule =
              response.data.data.autoSchedules.length > 0
                ? response.data.data.autoSchedules
                : [];
          }
        });
    },
    async getJob() {
      await axios
        .get(process.env.VUE_APP_BASEURL + "/scheduler/autojob")
        .then((response) => {
          if (response.data.status) {
            this.job =
              response.data.data.autoJobs.length > 0
                ? response.data.data.autoJobs
                : [];
          }
          this.loading = false;
        });
    },
    stopJob(item) {
      item.Status = "Complete";
      axios
        .get(process.env.VUE_APP_BASEURL + `/scheduler/autojob/${item.Id}/stop`)
        .then((response) => {
          if (response) {
            if (response.data.status) {
              this.color = "success";
              this.snackBarText = response.data.message;
              this.snackbar = true;
            } else {
              this.color = "error";
              this.snackBarText = response.data.message;
              this.snackbar = true;
            }
          }
        });
    },
    showInstance(item) {
      this.$router.push({
        name: "autoConfigInstance",
        params: {
          id: item.Id,
        },
      });
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    // getConfigAssign() {
    //   axios
    //     .get(process.env.VUE_APP_BASEURL + "/scheduler/autoconfigassignment")
    //     .then(response => {
    //       if (response) {
    //         this.configAssign =
    //           response.data.data.configs.length > 0
    //             ? response.data.data.configs
    //             : [];
    //       }
    //     });
    // }
  },
  mounted() {
    this.getConfigs();
    this.getClasses();
    this.getSchedule();
    this.getJob();
    //this.getConfigAssign();
  },
};
</script>
<style lang="scss" scoped>
.nalu-container {
  background-color: #121212 !important;
}
h1 {
  text-align: center;
  margin: 10px;
}
.v-text-field .v-label {
  overflow: visible !important;
  top: 1% !important;
}
.label {
  color: #c7c7c7 !important;
}
.search-field {
  max-width: 50%;
}
.mr-right {
  margin-right: 5px;
}

@media (max-width: 991px) {
  .main-div {
    margin-left: auto !important;
    .nalu-container {
      padding: 20px !important;
      .v-sheet {
        padding: 10px !important;
      }
    }
  }
}
</style>
