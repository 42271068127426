<template>
  <div class="full-height main__wrapper">
    <!-- <automations-nav /> -->

    <v-snackbar v-model="snackbar" :timeout="snackBarTimeout" :color="color">{{
      snackBarText
    }}</v-snackbar>

    <div class="pa-5">
      <!-- <v-row>
        <v-col lg="4">
          <v-card color="grey darken-3">
            <v-card-text>
              <div>Total # of Active Automations</div>
              <p class="text-h4 text--primary">13</p>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col lg="4">
          <v-card color="grey darken-3">
            <v-card-text>
              <div>Today's # of Automations</div>
              <p class="text-h4 text--primary">4/7</p>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col lg="4">
          <v-card color="grey darken-3">
            <v-card-text>
              <div>Today's # of Automations</div>
              <p class="text-h4 text--primary">4/7</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row> -->

      <v-row>
        <v-col lg="12">
          <h1 class="mb-5" color="white">Calendar</h1>
          <v-card color="grey darken-3">
            <v-card-text>
              <div>
                <Calendar viewtype="week" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col lg="12">
          <v-card color="grey darken-3">
            <v-card-text>
              <div>
                <!-- data table of projects -->
                <div class="mx-auto rounded-0">
                  <v-sheet class="pa-4" color="grey darken-3" data-app>
                    <div class="d-flex align-center">
                      <v-text-field
                        v-model="search"
                        label="Search Projects..."
                        flat
                        solo-inverted
                        hide-details
                        clearable
                        clear-icon="mdi-close-circle-outline"
                      ></v-text-field>
                    </div>
                  </v-sheet>
                  <div class="d-flex flex-wrap">
                    <v-card-title>{{
                      switchArchive ? "Archived Projects" : "Active Projects"
                    }}</v-card-title>
                    <v-spacer />
                    <v-switch
                      v-model="switchArchive"
                      :label="`Show Archived Projects: ${switchArchive.toString()}`"
                      @change="changeProjectStatus"
                    ></v-switch>
                  </div>
                  <div>
                    <v-data-table
                      :headers="projectHeaders"
                      :items="activeProject"
                      :items-per-page="10"
                      item-key="email"
                      :search="search"
                      sort-by="TimeInserted"
                      class="elevation-1"
                    >
                      <template v-slot:item.status="{ item }">
                        <v-btn
                          class="mx-3"
                          @click="updateStatus(item)"
                          x-small
                          outlined
                          color="orange accent-2"
                        >
                          {{ switchArchive ? "Active" : "Archived" }}
                        </v-btn>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <router-link :to="`/automations/projects/${item.Id}`">
                          <v-btn
                            small
                            color="green accent-2"
                            class="black--text"
                          >
                            View Details
                          </v-btn>
                        </router-link>
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- <v-tabs v-model="tab" dark color="white" height="70">
      <v-tab disabled style="width: 300px">Crebrid OS</v-tab>
      <v-tab href="#1" style="width: 300px">Dashboard</v-tab>
    </v-tabs>

    <div class="ma-5">
      <v-tabs-items :value="tab">
        <v-tab-item value="1">

        </v-tab-item>
      </v-tabs-items> -->
  </div>
</template>

<script>
import axios from "axios";
import Calendar from "../../components/dashboard/Calendar.vue";
export default {
  components: { Calendar },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASEURL,
      loading: true,
      search: "",

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,

      switchArchive: false,

      projects: [],
      activeProject: [],
      projectHeaders: [
        {
          text: "Name",
          align: "start",
          width: "200",
          value: "Name",
        },
        {
          text: "Namespace",
          align: "start",
          width: "100",
          value: "Namespace",
        },
        {
          text: "Created At",
          align: "start",
          width: "200",
          value: "TimeInserted",
        },
        {
          text: "Status",
          align: "center",
          width: "100",
          value: "status",
        },
        {
          text: "Actions",
          align: "center",
          width: "100",
          value: "actions",
        },
      ],
    };
  },
  computed: {},
  methods: {
    getProjects() {
      axios
        .get(process.env.VUE_APP_BASEURL + "/hermosa/project")
        .then((response) => {
          if (response.data.status) {
            this.projects =
              response.data.data.projects.length > 0
                ? response.data.data.projects
                : [];

            this.activeProject = this.projects;
          }
          this.loading = false;
        });
    },
    updateStatus(item) {
      const status_data = this.switchArchive ? "active" : "archived";
      axios
        .put(
          `${process.env.VUE_APP_BASEURL}/hermosa/project/updateStatus/${item.Id}`,
          {
            Status: status_data,
          }
        )
        .then((response) => {
          if (response) {
            if (response.data.status) {
              this.getProjects();
              this.projectDialog = false;
              this.color = "success";
              this.snackBarText = response.data.message;
            } else {
              this.color = "error";
              this.snackBarText = response.data.message;
            }
            this.snackbar = true;
          }
        });
    },
    changeProjectStatus() {
      const status = this.switchArchive ? "archived" : "active";
      const activeProject = this.projects.filter(
        (project) => project.Status === status
      );

      this.activeProject = activeProject;
    },

    rowClick(item) {
      if (item) {
        this.$router.push({
          name: "project",
          params: {
            id: item.Id,
          },
        });
      }
    },
  },
  mounted() {
    this.getProjects();
  },
};
</script>

<style lang="scss" scoped>
.main__wrapper {
  @media (min-width: 991px) {
    margin-left: 112px;
  }
  .v-text-field.v-text-field--solo .v-label {
    height: 100%;
    top: 0 !important;
    margin: 0;
  }
}
</style>
