<template>
  <div>
    <div v-if="loading">
      <loader />
    </div>
    <v-sheet tile height="54" class="d-flex overflow-auto">
      <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-select
        v-model="type"
        :items="types"
        dense
        outlined
        hide-details
        class="ma-2"
        style="flex: 1 0 180px"
        label="type"
      ></v-select>
      <v-select
        v-model="mode"
        :items="modes"
        dense
        outlined
        hide-details
        label="event-overlap-mode"
        class="ma-2"
        style="flex: 1 0 180px"
      ></v-select>
      <v-select
        v-model="weekday"
        :items="weekdays"
        dense
        outlined
        hide-details
        label="weekdays"
        class="ma-2"
        style="flex: 1 0 180px"
      ></v-select>
      <v-spacer></v-spacer>
      <v-btn icon class="ma-2" @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet style="height: 600px">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @change="getEvents"
        :interval-height="200"
      >
      </v-calendar>
    </v-sheet>
  </div>
</template>
<script>
import axios from "axios";
import Loader from "../Loader.vue";

export default {
  components: { Loader },
  props: ["viewtype"],
  data() {
    return {
      jobEvent: [],
      loading: true,
      type: "month",
      types: ["month", "week", "day", "4day"],
      mode: "stack",
      modes: ["stack", "column"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      value: "",
      events: [],
      colors: {
        Running: "orange",
        Complete: "green",
        Exit: "red",
        Exited: "red",
        DuplicateTrigger: "red",
      },
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
    };
  },
  methods: {
    getEvents({ start, end }) {
      var startday = new Date(start.date);
      var endday = new Date(end.date);
      startday.setDate(startday.getDate() - 7);
      endday.setDate(endday.getDate() + 7);
      var startDate = startday.toISOString().substr(0, 10);
      var endDate = endday.toISOString().substr(0, 10);

      this.getJobEvent(startDate, endDate);
    },
    getEventColor(event) {
      return event.color;
    },
    async getJobEvent(startDate, endDate) {
      await axios
        .get(
          process.env.VUE_APP_BASEURL +
            `/scheduler/autojob?startDate=${startDate},&endDate=${endDate}"&isCal=false`
        )
        .then((response) => {
          if (response.data.status) {
            this.jobEvent =
              response.data.data.autoJobs.length > 0
                ? response.data.data.autoJobs
                : [];
          }
          if (this.jobEvent.length > 0) {
            let events = [];
            this.jobEvent.map((item, index) => {
              events.push({
                name: item.AutoConfig.AutoClass.Name,
                start: new Date(item.StartTime),
                end: new Date(item.EndTime),
                color: this.colors[item.Status],
                timed: true,
              });
            });

            this.events = [...events];
          }
          this.loading = false;
        });
    },
  },
  mounted() {
    // console.log('view',this.viewtype)
    this.type = this.viewtype;
  },
};
</script>

<style lang="scss">
.theme--dark.v-calendar-daily .v-calendar-daily_head-day,
.theme--dark.v-calendar-daily .v-calendar-daily__day {
  min-width: 100px;
}

.v-card__text .d-flex.overflow-auto {
  height: auto !important;
}
</style>
