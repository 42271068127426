<template>
  <div>
    <div v-if="loading">
      <loader />
    </div>

    <!-- <div class="ml-12 pl-2 full-height"> -->
    <div v-else style="margin-left: 56px" class="main-div">
      <!-- Alerts/Notifications -->
      <v-snackbar
        v-model="snackbar"
        :timeout="snackBarTimeout"
        :color="color"
        >{{ snackBarText }}</v-snackbar
      >
      <!-- End Alerts/Notifications -->

      <!-- Tabs -->
      <v-tabs fixed-tabs dark color="white" height="70">
        <!-- Tab menu -->
        <v-tab disabled>{{
          this.list && this.list.Namespace ? this.list.Namespace : "Namespace"
        }}</v-tab>
        <v-tab disabled>{{
          this.list && this.list.Name ? this.list.Name : "Name"
        }}</v-tab>
        <v-tab
          >Version:
          {{ this.list && this.list.Version ? this.list.Version : null }}</v-tab
        >
        <v-tab>Code</v-tab>
        <v-tab @click="getManualRunProjects()">Test</v-tab>
        <v-tab @click="getManualRunProjects()">Run</v-tab>
        <v-tab @click="getManualRunProjects()">Logs</v-tab>
        <!-- End Tab menu -->

        <!-- Tab items -->
        <!-- Namespace -->
        <v-tab-item class="pa-12 nalu-container">
          <v-sheet :elevation="5" class="mx-auto pa-5">
            <span>Namespace Content</span>
          </v-sheet>
        </v-tab-item>
        <!-- End Namespace -->

        <!-- Name -->
        <v-tab-item class="pa-12 nalu-container">
          <v-sheet :elevation="5" class="mx-auto pa-5">
            <span>Name Content</span>
          </v-sheet>
        </v-tab-item>
        <!-- End Name -->

        <!-- Versions -->
        <v-tab-item class="nalu-container">
          <div class="ma-12 nalu-container">
            <v-sheet :elevation="5" class="py-5 px-10">
              <div v-if="this.versionData && this.versionData.length > 0">
                <v-data-table
                  v-model="versionSelected"
                  :headers="versionHeaders"
                  :items="this.versionData"
                  :items-per-page="10"
                  class="elevation-1 module-table my-5"
                  @click:row="showModule"
                />
              </div>

              <v-btn
                fixed
                dark
                fab
                bottom
                right
                color="green accent-2"
                @click="addProjectVersionDialog = true"
              >
                <v-icon color="black">mdi-plus</v-icon>
              </v-btn>

              <v-dialog v-model="addProjectVersionDialog" max-width="350px">
                <v-card class="pa-5" dark>
                  <v-card-title class="justify-center mb-5">
                    <span class="headline">Add Project Version?</span>
                  </v-card-title>
                  <v-card-actions class="justify-center">
                    <v-btn
                      color="white"
                      outlined
                      @click="addProjectVersionDialog = false"
                      >Close</v-btn
                    >
                    <v-btn color="blue darken-1" @click="addProjectVersion"
                      >Create</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-sheet>
          </div>
        </v-tab-item>
        <!-- End Versions -->

        <!-- Code -->
        <v-tab-item class="pa-12 nalu-container">
          <v-btn
            fixed
            dark
            fab
            bottom
            right
            color="green accent-2"
            @click="updateProjectDialog = true"
          >
            <v-icon color="black">mdi-content-save</v-icon>
          </v-btn>

          <v-row>
            <v-col md="4">
              <v-sheet
                :elevation="5"
                dark
                class="pa-10"
                style="position: relative"
              >
                <v-expansion-panels>
                  <v-expansion-panel flat>
                    <v-expansion-panel-header expand-icon="mdi-menu-down"
                      >Module:
                      {{ this.selectedModule }}</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <div v-if="this.list.Modules">
                        <v-data-table
                          hide-default-header
                          v-model="selected"
                          :headers="headers"
                          :items="this.list.Modules"
                          item-key="name"
                          :items-per-page="10"
                          class="elevation-1 module-table mb-4"
                          @click:row="showStep"
                        />
                      </div>
                      <v-btn
                        small
                        color="green accent-2"
                        outlined
                        dark
                        @click="moduleFormDialog = true"
                        >+ New Module</v-btn
                      >
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <div v-if="this.moduleSteps.Steps">
                  <v-sheet :elevation="5" class="mt-12 mb-5 pa-5">
                    <div
                      class="step-section"
                      v-if="this.moduleSteps.Steps.length > 0"
                    >
                      <nested-draggable
                        class="rootstep"
                        :steps="this.moduleSteps.Steps"
                        @stepSelected="stepSelected"
                        @subStepAdded="subStepAdded"
                        @stepDeleted="stepDeleted"
                      />
                    </div>
                    <v-btn
                      small
                      color="primary"
                      class="btn"
                      outlined
                      dark
                      @click="dialog = true"
                      >+ New Step</v-btn
                    >
                  </v-sheet>
                </div>
              </v-sheet>
            </v-col>

            <v-col
              v-if="
                editDisplay && (this.selectedStepIndex != null || isAddSubstep)
              "
              md="8"
            >
              <v-sheet
                :elevation="5"
                dark
                class="mr-8 py-5 px-10"
                style="position: relative"
              >
                <div class="edit-step">
                  <h3>
                    {{
                      this.isAddSubstep
                        ? "Project Add Step Form"
                        : "Snap : " + this.modeledit.Name
                    }}
                  </h3>
                  <v-icon id="close" @click="editDisplay = false"
                    >fa fa-close</v-icon
                  >
                  <v-btn
                    x-small
                    color="blue darken-1"
                    class="my-2"
                    outlined
                    :disabled="editDisplay && modeledit.StepType == 'Module'"
                    @click="loadDynamicData(isAddSubstep ? 'add' : 'edit')"
                    >{{
                      isAddSubstep ? "Add dynamic data" : "Edit dynamic data"
                    }}</v-btn
                  >
                  <v-jsonschema-form
                    v-if="schemaedit"
                    :schema="schemaedit"
                    :model="modeledit"
                    :options="schemaOptions"
                  />
                  <v-btn
                    small
                    v-if="this.isAddSubstep"
                    class="btn"
                    color="error"
                    dark
                    @click="isAddSubstep = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    small
                    v-if="this.isAddSubstep"
                    class="btn"
                    color="primary"
                    dark
                    @click="addSubStep"
                    >Add</v-btn
                  >
                </div>
              </v-sheet>
            </v-col>
          </v-row>

          <!-- RunData Start -->
          <v-sheet
            :elevation="5"
            dark
            class="my-8 py-5 px-10"
            style="position: relative"
          >
            <div class="add-more-section ma-5">
              <h3 class="mb-5">Run Data Inputs</h3>
              <v-row
                class="container-row mt-2"
                :key="index"
                v-for="(input, index) in inputs"
              >
                <v-col md="4">
                  <v-text-field
                    label="Enter Name"
                    v-model="input.name"
                  ></v-text-field>
                </v-col>
                <v-col md="1">
                  <v-icon color="red" class="mt-6" @click="deleteRow(index)"
                    >fa fa-trash</v-icon
                  >
                </v-col>
              </v-row>
              <v-btn color="green accent-2" outlined small @click="addRow"
                >Add Input</v-btn
              >
            </div>
          </v-sheet>
          <!-- RunData End -->

          <!-- Dialogs -->
          <div v-if="dialog">
            <v-dialog v-model="dialog" class="add-step-model" max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="headline">Project Step Create</span>
                </v-card-title>
                <v-card-text>
                  <v-btn
                    small
                    color="blue darken-1"
                    text
                    @click="loadDynamicData('add')"
                    >Add dynamic data</v-btn
                  >
                  <!-- <schema-form :schema="schemaadd" v-model="modeladd" /> -->
                  <!-- <v-form v-model="formValid"> -->
                  <!-- <v-jsf v-if="schemaadd" :schema="schemaadd" :model="modeladd" :options="schemaOptions" /> -->
                  <!-- </v-form> -->
                  <v-jsonschema-form
                    v-if="schemaadd"
                    :schema="schemaadd"
                    :model="modeladd"
                    :options="schemaOptions"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn small color="error" @click="dialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn small color="blue darken-1" @click="addStep"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>

          <div v-if="deleteDialog">
            <v-dialog
              v-model="deleteDialog"
              class="delete-model"
              max-width="600px"
            >
              <v-card>
                <v-card-title>
                  <span class="headline">Delete Step</span>
                </v-card-title>
                <v-card-text>Are you sure to delete the step ?</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn small color="error" @click="deleteDialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn small color="blue darken-1" @click="deleteStep"
                    >Confirm</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>

          <v-dialog v-model="updateProjectDialog" max-width="350px">
            <v-card class="pa-5" dark>
              <v-card-title class="justify-center mb-5">
                <span class="headline">Update Project</span>
              </v-card-title>
              <v-card-actions class="justify-center">
                <v-btn
                  color="white"
                  outlined
                  @click="updateProjectDialog = false"
                  >Close</v-btn
                >
                <v-btn color="blue darken-1" @click="updateProjectJson"
                  >Update</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dataAddDialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span>Data fields</span>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-jsonschema-form
                      v-if="
                        schemaDynamicData &&
                        Object.keys(schemaDynamicData).length > 0
                      "
                      :schema="schemaDynamicData"
                      :model="modelDynamicData"
                      :options="schemaOptions"
                    />
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn small color="blue darken-1" @click="addDynamicData"
                  >Save</v-btn
                >
                <v-btn small color="error" @click="dataAddDialog = false"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dataEditDialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span>Data fields</span>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-jsonschema-form
                      v-if="
                        schemaEditDynamicData &&
                        Object.keys(schemaEditDynamicData).length > 0
                      "
                      :schema="schemaEditDynamicData"
                      :model="modelEditDynamicData"
                      :options="schemaOptions"
                    />
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn small color="blue darken-1" @click="editDynamicData"
                  >Save</v-btn
                >
                <v-btn small color="error" @click="dataEditDialog = false"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="moduleDialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span>module search</span>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-text-field
                      label="Module Name*"
                      v-model="moduleName"
                      :rules="[(v) => !!v || 'Module Name is required']"
                      required
                    ></v-text-field>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn small color="blue darken-1" @click="searchModule"
                  >Search</v-btn
                >
                <v-btn small color="error" @click="moduleDialog = false"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="moduleFormDialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Module Basic Details</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-text-field
                    label="Module Name*"
                    v-model="moduleObject.Name"
                    :rules="[(v) => !!v || 'Name is required']"
                    required
                  ></v-text-field>
                  <v-textarea
                    label="DataDefaults (json)"
                    v-model="moduleObject.DataDefaults"
                  ></v-textarea>
                </v-container>
                <small>*indicates required field</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="moduleFormDialog = false"
                  >Close</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  :disabled="!moduleObject.Name"
                  @click="addModule"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- End Dialogs -->
        </v-tab-item>
        <!-- End Code -->

        <!-- Test -->
        <v-tab-item class="pa-12 nalu-container">
          <v-sheet :elevation="5" dark class="py-7 px-10">
            <v-btn
              fixed
              dark
              fab
              bottom
              right
              color="green accent-2"
              :disabled="!runData"
              @click="runDialog = true"
            >
              <v-icon color="black">mdi-play</v-icon>
            </v-btn>
            <span
              >Note : JSON will be saved after each Run and it is global for all
              users</span
            >
            <v-subheader class="mb-2 pt-5 pb-3 px-0"
              >Project Test Data (json) *</v-subheader
            >

            <MonacoEditor
              v-model="runData"
              width="100%"
              height="500"
              theme="vs-dark"
              language="javascript"
              :options="options"
              :rules="[(v) => !!v || 'Data is required']"
            ></MonacoEditor>

            <v-dialog v-model="runDialog" max-width="350px">
              <v-card class="pa-5" dark>
                <v-card-title class="justify-center mb-5">
                  <span class="headline">Run Project?</span>
                </v-card-title>
                <v-card-actions class="justify-center">
                  <v-btn color="white" outlined @click="runDialog = false"
                    >Close</v-btn
                  >
                  <v-btn color="blue darken-1" @click="runProject">Run</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-sheet>
        </v-tab-item>
        <!-- End Test -->

        <!-- Run -->
        <v-tab-item class="pa-12 nalu-container">
          <v-sheet :elevation="5" dark class="py-7 px-10">
            <v-btn
              fixed
              dark
              fab
              bottom
              right
              color="green accent-2"
              :disabled="!runDataConfig"
              @click="runDialogConfig = true"
            >
              <v-icon color="black">mdi-play</v-icon>
            </v-btn>

            <v-subheader class="mb-5 pt-5 pb-3 px-0"
              >Project Run Data</v-subheader
            >

            <v-autocomplete
              v-model="cmpSearchModel"
              :items="cmpItems"
              :loading="cmpIsLoading"
              :search-input.sync="cmpSearch"
              hide-no-data
              hide-selected
              item-text="Name"
              item-value="Id"
              label="Company Id"
              :rules="[(v) => !!v || 'Company is required']"
              placeholder="Start typing to Search Company"
              return-object
              outlined
              color="white"
            ></v-autocomplete>

            <v-select
              v-model="propertyModel"
              :items="propertyArray"
              :menu-props="{ maxHeight: '400' }"
              label="Select Properties"
              multiple
            ></v-select>

            <span
              >Note : JSON will be saved after each Run and it is global for all
              users</span
            >

            <v-subheader class="mb-2 pt-5 pb-3 px-0"
              >Other Config Data (json) *</v-subheader
            >

            <MonacoEditor
              v-model="runDataConfig"
              width="100%"
              height="500"
              theme="vs-dark"
              language="javascript"
              :options="options"
              :rules="[(v) => !!v || 'Data is required']"
            ></MonacoEditor>

            <!-- <MonacoEditor
                v-model="runData"
                width="100%"
                height="500"
                theme="vs-dark"
                language="javascript"
                :options="options"
                :rules="[(v) => !!v || 'Data is required']"
              ></MonacoEditor> -->

            <v-dialog v-model="runDialogConfig" max-width="350px">
              <v-card class="pa-5" dark>
                <v-card-title class="justify-center mb-5">
                  <span class="headline">Run Project?</span>
                </v-card-title>
                <v-card-actions class="justify-center">
                  <v-btn color="white" outlined @click="runDialogConfig = false"
                    >Close</v-btn
                  >
                  <v-btn color="blue darken-1" @click="runProjectConfig"
                    >Run</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-sheet>
        </v-tab-item>
        <!-- End Run -->

        <!-- Logs -->
        <v-tab-item class="pa-12 nalu-container">
          <v-sheet :elevation="5" dark class="pa-10">
            <div v-if="projectsRun.length > 0">
              <v-data-table
                loading-text="Loading... Please wait"
                v-model="selectedProjectRun"
                :headers="headersProjectRun"
                :items="projectsRun"
                item-key="name"
                :items-per-page="10"
                class="elevation-1"
              >
                <template v-slot:item.StartTime="{ item }">
                  {{ item.StartTime | formatDate }}
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-btn icon @click="getLogs(item)">
                    <v-icon small dark>mdi-view-list</v-icon>
                  </v-btn>
                  <v-btn
                    dark
                    small
                    v-if="
                      item.Status !== 'Complete' &&
                      item.Status !== 'Exited' &&
                      item.Status !== 'Exit'
                    "
                    color="primary"
                    @click="stopProject(item)"
                    >Stop</v-btn
                  >
                </template>
              </v-data-table>
            </div>
            <span v-else>No Logs found for this project.</span>

            <!-- Dialogs -->
            <v-dialog v-model="logDialog" width="75%">
              <v-card>
                <v-card-title>
                  <span class="headline">Log Data</span>
                </v-card-title>
                <v-card-text>
                  <pre v-html="logContent"></pre>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="(logDialog = false), (logContent = null)"
                    >Close</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- End Dialogs -->
          </v-sheet>
        </v-tab-item>
        <!-- End Logs -->
        <!-- End Tab items -->
      </v-tabs>
      <!-- End Tabs -->

      <!-- Dialogs -->
    </div>
  </div>
</template>

<script>
import Loader from "../../components/Loader.vue";
import nestedDraggable from "../../components/dragndrop/nested";
import axios from "axios";
import moment from "moment";
// import { SchemaForm } from "vue-json-schema-form";
import MonacoEditor from "monaco-editor-vue";
import VJsonschemaForm from "@koumoul/vuetify-jsonschema-form";
import "@koumoul/vuetify-jsonschema-form/dist/main.css";

export default {
  name: "Project",
  display: "Nested",
  order: 15,
  components: {
    nestedDraggable,
    // SchemaForm,
    VJsonschemaForm,
    Loader,
    MonacoEditor,
  },
  props: ["id"],
  watch: {
    cmpSearch(val) {
      if (this.cmpSearch != (this.cmpSearchModel && this.cmpSearchModel.Name)) {
        if (!val) {
          return;
        }
        this.clearcmpEntries();
        this.cmpIsLoading = true;
        this.fetchCmpEntriesDebounced();
      }
    },
    modeladd: {
      deep: true,
      handler(val) {
        this.stepTypeConfig(val);
      },
    },
    selectedVersion: {
      handler(val) {
        this.editDisplay = false;
        if (this.versionData && this.versionData.length > 0) {
          this.showModule(val);
        }
      },
    },
    modeledit: {
      deep: true,
      handler(val) {
        this.stepTypeConfig(val);
      },
    },
    "modeladd.Snap": function (newVal, oldVal) {
      this.snaplist.find((o, i) => {
        if (o.Name == this.modeladd.Snap) {
          this.modeladd.Namespace = o.Namespace;
          this.modeladd.Name = o.Name;
          return true;
        }
      });
    },
    "modeledit.Snap": function (newVal, oldVal) {
      if (this.modeledit.StepType == "Snap") {
        if (
          (this.modeledit.Snap == null || this.modeledit.Snap == "") &&
          (this.modeledit.Name != "" || this.modeledit.Name != null)
        ) {
          this.modeledit.Snap = this.modeledit.Name;
        } else if (this.modeledit.Snap != "" || this.modeledit.Snap == null) {
          this.snaplist.find((o, i) => {
            if (o.Name == this.modeledit.Snap) {
              this.modeledit.Namespace = o.Namespace;
              this.modeledit.Name = o.Name;
            }
          });
        }
        return true;
      }
    },
    "modeladd.ModuleType": function () {
      if (this.modeladd.StepType == "Module") {
        if (this.modeladd.ModuleType == "Internal") {
          this.searchInternalModule();
          this.modeladd.Namespace = "";
        } else if (this.modeladd.ModuleType == "External") {
          this.moduleDialog = true;
        }
      }
    },
    //old
    "modeledit.ModuleType": function () {
      // if(this.modeledit.StepType == "Module" && !this.modeledit.ModuleType) {
      //   this.modeledit.ModuleType = "Internal";
      // }
      if (
        this.modeledit.ModuleType == "Internal" &&
        this.modeledit.StepType == "Module"
      ) {
        this.searchInternalModule();
        this.modeledit.Namespace = "";
      } else if (
        this.modeledit.ModuleType == "External" &&
        this.modeledit.StepType == "Module"
      ) {
        if (
          this.schemaedit.properties.ModuleSelected.enum.length == 0 &&
          this.modeledit.ModuleSelected != ""
        ) {
          this.schemaedit.properties.ModuleSelected.enum = [];
          this.schemaedit.properties.ModuleSelected.enum.push(
            this.modeledit.ModuleSelected
          );
          this.moduleDialog = false;
        } else {
          this.moduleDialog = true;
        }
      }
    },
    "modeladd.ModuleSelected": function () {
      if (
        this.modeladd.ModuleType == "External" &&
        this.modeladd.ModuleSelected != ""
      ) {
        this.moduleNameList.find((e) => {
          if (e.Name == this.modeladd.ModuleSelected) {
            this.modeladd.Namespace = e.Namespace;
            this.modeladd.Name = e.Name;
            this.modeladd.ModuleName = e.Name;
          }
        });
      } else if (
        this.modeladd.ModuleType == "Internal" &&
        this.modeladd.ModuleSelected != ""
      ) {
        this.modeladd.Name = this.modeladd.ModuleSelected;
        this.modeladd.ModuleName = this.modeladd.ModuleSelected;
      }
    },
    //old
    "modeledit.ModuleSelected": function () {
      if (
        this.modeledit.ModuleType == "External" &&
        this.modeledit.ModuleSelected != ""
      ) {
        this.moduleNameList.find((e) => {
          if (e.Name == this.modeledit.ModuleSelected) {
            this.modeledit.Namespace = e.Namespace;
            this.modeledit.Name = e.Name;
            this.modeledit.ModuleName = e.Name;
          }
        });
      } else if (
        this.modeledit.ModuleType == "Internal" &&
        this.modeledit.ModuleSelected != ""
      ) {
        this.modeledit.Name = this.modeledit.ModuleSelected;
        this.modeledit.ModuleName = this.modeledit.ModuleSelected;
      }
    },
    "modeladd.Logic": function () {
      if (this.modeladd.StepType == "Logic" && this.modeladd.Logic != "") {
        this.modeladd.Name = this.modeladd.Logic;
      }
    },
    "modeledit.Logic": function () {
      if (this.modeledit.StepType == "Logic") {
        if (this.modeledit.Name != "" || this.modeledit.Name != null) {
          this.modeledit.Logic = this.modeledit.Name;
        } else if (this.modeledit.Logic != "") {
          this.modeledit.Name = this.modeledit.Logic;
        }
      }
    },
    "modeledit.SubSteps": function (newVal, oldVal) {
      //for substeps removal issue
      if (!newVal && oldVal) {
        this.modeledit.SubSteps = oldVal;
      }
    },
    "modeledit.Data": function (newVal, oldVal) {
      if (!newVal && oldVal) {
        this.modeledit.Data = oldVal;
      }
    },
  },
  data() {
    return {
      loading: true,
      projectName: "",
      schemaedit: require("../../components/form/projectEditForm"),
      modeledit: {},
      schemaadd: require("../../components/form/projectAddForm"),
      modeladd: {
        Namespace: "",
        Name: "",
        Label: "",
        StepType: "",
        Snap: "",
        Logic: "",
        ModuleType: "",
        ModuleSelected: "",
        ModuleName: "",
        Version: 1,
        Enabled: true,
        DisableForTest: false,
        PreCode: [],
        PostCode: [],
        PreWait: {
          Page: null,
          WaitForNavigation: false,
          NetworkIdle2: false,
          Timeout: 25000,
          WaitTime: 50,
          WaitForSelector: null,
        },
        PostWait: {
          Page: null,
          WaitForNavigation: false,
          NetworkIdle2: false,
          Timeout: 25000,
          WaitTime: 50,
          WaitForSelector: null,
        },
      },
      editDisplay: true,
      dialog: false,
      projectid: null,
      list: [],
      selected: [],
      headers: [
        {
          text: "Project Module:",
          align: "left",
          sortable: false,
          value: "Name",
        },
      ],

      versionSelected: [],
      versionHeaders: [
        {
          text: "Project Version:",
          align: "left",
          sortable: false,
          value: "Version",
        },
      ],

      selectedModule: "",
      moduleSteps: [],
      versionList: [],
      versionData: [],
      selectedVersion: null,
      selectedStepIndex: null,
      selectedModuleIndex: null,
      selectedStep: null,

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,

      isAddSubstep: false,
      tempPos: [],

      addProjectVersionDialog: false,
      updateProjectDialog: false,
      deleteDialog: false,

      dataAddDialog: false,
      dataEditDialog: false,
      schemaDynamicData: {},
      modelDynamicData: {},
      schemaEditDynamicData: {},
      modelEditDynamicData: {},

      moduleFormDialog: false,
      moduleObject: {
        DataDefaults: null,
        Name: null,
        Steps: [],
      },
      snaplist: {},
      moduleDialog: false,
      moduleName: null,
      moduleNameList: [],
      runDialog: false,
      runData: "",
      runDialogConfig: false,
      runDataConfig: "",
      runDataConfigOther: {},

      selectedProjectRun: [],
      headersProjectRun: [
        { text: "Name", value: "HermosaProject.Name" },
        { text: "Start Time", value: "StartTime" },
        { text: "Version", value: "Version" },
        { text: "Status", value: "Status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      projectsRun: [],
      logDialog: false,
      logContent: null,

      options: {
        //Monaco Editor Options
      },
      schemaOptions: {
        debug: false,
        disableAll: false,
        autoFoldObjects: true,
      },

      //Run Tab
      //companySearch
      cmpSearch: null,
      cmpSearchModel: null,
      cmpEntries: [],
      cmpIsLoading: false,

      propertyModel: [],
      propertyArray: [],
      propertyList: {},

      inputs: [],
      typeItems: ["string", "number", "boolean", "array"],
    };
  },
  computed: {
    cmpFields() {
      if (!this.cmpSearchModel) return [];

      return Object.keys(this.cmpSearchModel).map((key) => {
        return {
          key,
          value: this.cmpSearchModel[key] || "n/a",
        };
      });
    },
    cmpItems() {
      return this.cmpEntries.map((entry) => {
        const Name = entry.Name;
        return Object.assign({}, entry, { Name });
      });
    },
  },
  filters: {
    formatDate: function (date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
  },
  methods: {
    moment: function () {
      return moment();
    },
    clearcmpEntries() {
      this.cmpEntries = [];
    },
    fetchCmpEntriesDebounced() {
      clearTimeout(this._searchCmpTimerId);
      this._searchCmpTimerId = setTimeout(() => {
        this.fetchCmpEntries();
      }, 500);
    },
    fetchCmpEntries() {
      axios
        .get(process.env.VUE_APP_BASEURL + "/company?search=" + this.cmpSearch)
        .then((response) => {
          if (response.data.status) {
            this.cmpEntries = response.data.data.companies;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.cmpIsLoading = false));
    },
    async addModule() {
      this.moduleFormDialog = true;
      let isValidJson = true;
      if (
        this.moduleObject.DataDefaults !== null &&
        this.moduleObject.DataDefaults !== ""
      ) {
        isValidJson = this.isJsonString(this.moduleObject.DataDefaults);
        !isValidJson && alert("Please enter valid Json Data");
      } else {
        isValidJson = true;
      }
      if (isValidJson) {
        this.list.Modules.push(this.moduleObject);
        await this.updateProjectJson();
        this.moduleFormDialog = false;
      }
    },

    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },

    runProject() {
      this.runDialog = false;
      let isValidJson = false;
      if (this.runData != null) {
        isValidJson = this.isJsonString(this.runData);
        !isValidJson && alert("Please enter valid Json Data");
      }
      if (this.runData && isValidJson) {
        axios
          .post(
            process.env.VUE_APP_BASEURL +
              `/hermosa/project/${this.id}/run-test`,
            {
              version: this.selectedVersion,
              testJson: this.runData,
            }
          )
          .then((response) => {
            if (response) {
              if (response.data.status) {
                this.color = "success";
                this.snackBarText = response.data.message;
                this.snackbar = true;
                // this.getManualRunProjects();
              } else {
                this.color = "error";
                this.snackBarText = response.data.message;
                this.snackbar = true;
              }
            }
          });
      }
    },
    runProjectConfig() {
      this.runDialogConfig = false;
      let isSingleProperty = this.propertyModel.length > 1 ? false : true;

      let PropertyObj = {};
      PropertyObj.PropertyNames = this.propertyModel;
      PropertyObj.PropertyIds = [];

      this.propertyModel.forEach((pm, i) => {
        this.propertyList.forEach((pl, j) => {
          if (pm === pl.Name) {
            PropertyObj.PropertyIds.push(pl.Id);
          }
        });
      });

      if (isSingleProperty && this.propertyModel.length > 0) {
        PropertyObj.isSingleProperty = true;
        PropertyObj.PropertyName =
          PropertyObj.PropertyNames.length > 0
            ? PropertyObj.PropertyNames[0]
            : "";
        PropertyObj.PropertyId =
          PropertyObj.PropertyIds.length > 0 ? PropertyObj.PropertyIds[0] : "";
      }

      this.runDataConfigOther = {
        CompanyId: this.cmpSearchModel.Id,
        ...PropertyObj,
      };

      let isValidJson = false;
      if (this.runDataConfig != null) {
        isValidJson = this.isJsonString(this.runDataConfig);
        !isValidJson && alert("Please enter valid Json Data");
      }
      if (this.runDataConfig && isValidJson) {
        let runObj = Object.assign(
          this.runDataConfigOther,
          JSON.parse(this.runDataConfig)
        );

        axios
          .post(
            process.env.VUE_APP_BASEURL + `/hermosa/project/${this.id}/run`,
            {
              version: this.selectedVersion,
              configJson: JSON.stringify(runObj),
            }
          )
          .then((response) => {
            if (response) {
              if (response.data.status) {
                this.color = "success";
                this.snackBarText = response.data.message;
                this.snackbar = true;
                // this.getManualRunProjects();
              } else {
                this.color = "error";
                this.snackBarText = response.data.message;
                this.snackbar = true;
              }
            }
          });
      }
    },

    addProjectVersion() {
      if (this.versionData.length > 0) {
        let newVersionData = {};
        let versionNumber = this.versionData.length + 1;
        newVersionData = this.versionData[this.versionData.length - 1];
        newVersionData.ClonedFromVersion = this.versionData.length;
        let projectData = JSON.parse(newVersionData.ProjectJson);
        projectData.Version = versionNumber;
        if (this.projectid) {
          axios
            .put(
              `${process.env.VUE_APP_BASEURL}/hermosa/project/${this.projectid}/version/${versionNumber}`,
              {
                project: JSON.stringify(projectData),
                name: projectData.Name,
                namespace: projectData.Namespace,
              }
            )
            .then((response) => {
              if (response) {
                if (response.data.status) {
                  this.color = "success";
                  this.snackBarText = response.data.message;
                  this.snackbar = true;
                  this.loadProjectVersionData();
                } else {
                  this.color = "error";
                  this.snackBarText = response.data.message;
                  this.snackbar = true;
                }
              }
            });
        }
      } else {
        console.log("No version found to clonned from");
      }
    },

    loadProjectVersionData() {
      axios
        .get(
          `${process.env.VUE_APP_BASEURL}/hermosa/project/${this.projectid}/version`
        )
        .then((response) => {
          if (response.data.status) {
            this.projectName = response.data.data.projectName;
            this.versionData =
              response.data.data.projectVersions &&
              response.data.data.projectVersions.length > 0
                ? response.data.data.projectVersions
                : [];
            this.selectedVersion =
              this.versionData[this.versionData.length - 1];
          }
          this.loading = false;
        });
    },

    searchModule() {
      axios
        .get(
          `${process.env.VUE_APP_BASEURL}/hermosa/project?search=${this.moduleName}`
        )
        .then((response) => {
          if (response.data.status) {
            this.moduleNameList = response.data.data.projects;
            let moduleArray = [];
            this.moduleNameList.length > 0 &&
              this.moduleNameList.map((el) => {
                moduleArray.push(el.Name);
              });
            if (this.schemaedit.properties) {
              this.schemaedit.properties.ModuleSelected.enum = moduleArray;
            }
            if (this.schemaadd.properties) {
              this.schemaadd.properties.ModuleSelected.enum = moduleArray;
            }
          }
        });
      this.moduleDialog = false;
    },

    searchInternalModule() {
      let moduleObj = this.list.Modules;
      let internalModuleArray = [];
      moduleObj.length > 0 &&
        moduleObj.map((e) => {
          internalModuleArray.push(e.Name);
        });
      if (this.schemaedit.properties) {
        this.schemaedit.properties.ModuleSelected.enum = internalModuleArray;
      }
      if (this.schemaadd.properties) {
        this.schemaadd.properties.ModuleSelected.enum = internalModuleArray;
      }
    },

    showModule(e) {
      if (e.Version) {
        this.selectedVersion = e.Version;
        const index = this.versionData.findIndex(
          (moduleObj) => moduleObj.Version == e.Version
        );
        this.list = JSON.parse(this.versionData[index].ProjectJson);
        this.inputs = JSON.parse(this.versionData[index].InputData);
        this.moduleSteps = [];
      }
    },

    showStep(e) {
      if (e.Name) {
        this.selectedModule = e.Name;
        const index = this.list.Modules.findIndex(
          (moduleObj) => moduleObj.Name == e.Name
        );
        this.selectedModuleIndex = index;
        this.moduleSteps = this.list.Modules[index];
      }
    },

    stepSelected(position) {
      this.modeledit = {};
      this.editDisplay = true;
      this.selectedStepIndex = null;
      this.isAddSubstep = false;
      setTimeout(() => {
        if (position.length > 0) {
          let newObj = this.list.Modules[this.selectedModuleIndex].Steps;
          position.map((i, index) => {
            if (index == 0) {
              newObj = newObj[i];
            } else {
              // console.log('newObj',newObj)
              newObj =
                newObj.SubSteps && newObj.SubSteps.length > 0
                  ? newObj.SubSteps[i]
                  : null;
            }
          });
          //testing for substeps
          if (newObj) {
            this.modeledit = newObj;

            if (this.modeledit.Data) {
              this.modelEditDynamicData = this.modeledit.Data;
            }
          }

          this.selectedStepIndex = position;
        }
      });
    },

    subStepAdded(position) {
      this.editDisplay = true;
      this.selectedStepIndex = null;
      setTimeout(() => {
        this.modeledit = {
          Namespace: "",
          Name: "",
          Label: "",
          StepType: "",
          Snap: "",
          Logic: "",
          ModuleType: "",
          ModuleSelected: "",
          ModuleName: "",
          Version: 1,
          Enabled: true,
          DisableForTest: false,
          PreCode: [],
          PostCode: [],
          PreWait: {
            Page: null,
            WaitForNavigation: false,
            NetworkIdle2: false,
            Timeout: 25000,
            WaitTime: 50,
            WaitForSelector: null,
          },
          PostWait: {
            Page: null,
            WaitForNavigation: false,
            NetworkIdle2: false,
            Timeout: 25000,
            WaitTime: 50,
            WaitForSelector: null,
          },
        };
        this.isAddSubstep = true;
        this.tempPos = position;
      });
    },

    async addSubStep() {
      let position = this.tempPos;
      if (position.length > 0) {
        let newObj = this.list.Modules[this.selectedModuleIndex].Steps;
        position.map((i, index) => {
          if (index == 0) {
            newObj = newObj[i];
          } else {
            newObj =
              newObj.SubSteps && newObj.SubSteps.length > 0
                ? newObj.SubSteps[i]
                : null;
          }
        });
        if (newObj.SubSteps) {
          newObj.SubSteps.push(this.modeledit);
        } else {
          newObj.SubSteps = [];
          newObj.SubSteps.push(this.modeledit);
        }

        await this.updateProjectJson();
        this.isAddSubstep = false;
        this.tempPos = [];
        this.modeledit = {};
        //this.selectedStepIndex = position;
      }
    },

    deleteStep() {
      let position = this.tempPos;
      this.selectedStepIndex = null;
      this.isAddSubstep = false;
      if (position.length > 0) {
        let newObj = this.list.Modules[this.selectedModuleIndex].Steps;
        position.map((i, index) => {
          if (index == 0 && index == position.length - 1) {
            newObj = newObj.splice(i, 1);
          } else if (index == position.length - 1) {
            newObj = newObj.splice(i, 1);
          } else {
            newObj =
              newObj[i].SubSteps && newObj[i].SubSteps.length > 0
                ? newObj[i].SubSteps
                : [];
          }
        });
      }
      this.deleteDialog = false;
    },

    stepDeleted(position) {
      this.deleteDialog = true;
      this.tempPos = position;
    },

    async addStep(e) {
      if (this.modeladd.Name != "" || this.modeladd.StepType != "") {
        // if(this.modeladd.StepType == "Module" && this.modeladd.ModuleType == "External") {
        //   if(this.modeladd.Namespace == "") {
        //     alert("please fill required fields");
        //     return;
        //   }
        // }
        this.list.Modules[this.selectedModuleIndex].Steps.push(this.modeladd);
        e.preventDefault();
        await this.updateProjectJson();
        this.modeladd = {
          Namespace: "",
          Name: "",
          Label: "",
          StepType: "",
          Snap: "",
          Logic: "",
          ModuleType: "",
          ModuleSelected: "",
          ModuleName: "",
          Version: 1,
          Enabled: true,
          DisableForTest: false,
          PreCode: [],
          PostCode: [],
          PreWait: {
            Page: null,
            WaitForNavigation: false,
            NetworkIdle2: false,
            Timeout: 25000,
            WaitTime: 50,
            WaitForSelector: null,
          },
          PostWait: {
            Page: null,
            WaitForNavigation: false,
            NetworkIdle2: false,
            Timeout: 25000,
            WaitTime: 50,
            WaitForSelector: null,
          },
        };
        this.dialog = false;
      } else {
        alert("please fill required fields");
      }
    },

    updateProjectJson() {
      if (this.updateProjectDialog) this.updateProjectDialog = false;
      this.moduleSteps = [];
      setTimeout(() => {
        axios
          .put(
            `${process.env.VUE_APP_BASEURL}/hermosa/project/${this.projectid}/version/${this.selectedVersion}`,
            {
              project: JSON.stringify(this.list),
              name: this.list.Name,
              namespace: this.list.Namespace,
              inputData: JSON.stringify(this.inputs),
            }
          )
          .then((response) => {
            if (response) {
              if (response.data.status) {
                this.list = JSON.parse(
                  response.data.data.projectVersion.ProjectJson
                );
                if (
                  this.list.Modules &&
                  this.list.Modules.length > 0 &&
                  this.selectedModuleIndex
                ) {
                  this.moduleSteps =
                    this.list.Modules[this.selectedModuleIndex];
                }
                this.color = "success";
                this.snackBarText = response.data.message;
                this.snackbar = true;
              } else {
                this.color = "error";
                this.snackBarText = response.data.message;
                this.snackbar = true;
              }
            }
          });
      });
      this.selectedStep = null;
      this.selectedStepIndex = null;
    },

    getSnaps() {
      axios
        .get(`${process.env.VUE_APP_BASEURL}/hermosa/snap`)
        .then((response) => {
          if (response.data.status) {
            this.snaplist = response.data.data.snaps;
            let snapArray = [];
            this.snaplist.length > 0 &&
              this.snaplist.map((el) => {
                snapArray.push(el.Name);
              });
            if (this.schemaedit.properties) {
              this.schemaedit.properties.Snap.enum = snapArray;
            }
            if (this.schemaadd.properties) {
              this.schemaadd.properties.Snap.enum = snapArray;
            }
          }
        });
    },

    getProperties() {
      axios.get(`${process.env.VUE_APP_BASEURL}/property`).then((response) => {
        if (response.data.status) {
          this.propertyList = response.data.data.properties;
          this.propertyList.length > 0 &&
            this.propertyList.map((el) => {
              this.propertyArray.push(el.Name);
            });
        }
      });
    },

    addDynamicData() {
      this.modeladd.Data = this.modelDynamicData;
      this.dataAddDialog = false;
      this.schemaDynamicData = {};
      this.modelDynamicData = {};
    },

    editDynamicData() {
      this.modeledit.Data = this.modelEditDynamicData;
      this.dataEditDialog = false;
      this.schemaEditDynamicData = {};
      this.modelEditDynamicData = {};
    },

    loadDynamicData(type) {
      var modelObj = {};
      if (type == "edit") {
        this.modeledit.Data = this.modelEditDynamicData;
        modelObj = this.modeledit;
      } else {
        modelObj = this.modeladd;
      }
      if (modelObj.StepType == "Snap" && modelObj.Snap != "") {
        let snapObj = this.snaplist.find((e) => e.Name == modelObj.Snap);
        if (snapObj && Object.keys(snapObj).length > 0) {
          axios
            .post(`${process.env.VUE_APP_BASEURL}/hermosa/snap/getSnapConfig`, {
              name: snapObj.Name,
              namespace: snapObj.Namespace,
            })
            .then((response) => {
              if (response.data.status) {
                if (this.isJsonString(response.data.data.SnapConfigJson)) {
                  let snapConfig = JSON.parse(
                    response.data.data.SnapConfigJson
                  );
                  if (snapConfig.DataInput) {
                    if (type == "edit") {
                      this.schemaEditDynamicData = {};
                      this.$nextTick(() => {
                        this.schemaEditDynamicData = snapConfig.DataInput;
                      });
                      this.dataEditDialog = true;
                    } else {
                      this.modelDynamicData = {};
                      this.$nextTick(() => {
                        this.schemaDynamicData = snapConfig.DataInput;
                      });
                      this.dataAddDialog = true;
                    }
                  }
                } else {
                  // alert("Please add proper JSON for this snap inside Snap module.");
                  this.color = "error";
                  this.snackBarText =
                    "Please add proper JSON for this snap inside Snap module.";
                  this.snackbar = true;
                }
              }
            });
        }
      } else if (modelObj.StepType == "Logic" && modelObj.Logic != "") {
        var schemaObj = {};
        if (type == "edit") {
          schemaObj = this.schemaEditDynamicData;
        } else if (type == "add") {
          schemaObj = this.schemaDynamicData;
        }
        if (modelObj.Logic == "If") {
          schemaObj = {
            type: "object",
            title: "",
            properties: {
              Condition: {
                type: "string",
                title: "Condition ",
                attrs: {
                  placeholder: "Condition",
                  title: "Please enter Condition",
                },
              },
            },
          };
        } else if (modelObj.Logic == "ElseIf") {
          schemaObj = {
            type: "object",
            title: "",
            properties: {
              Condition: {
                type: "string",
                title: "Condition ",
                attrs: {
                  placeholder: "Condition",
                  title: "Please enter Condition",
                },
              },
            },
          };
        } else if (modelObj.Logic == "Loop") {
          schemaObj = {
            type: "object",
            title: "",
            properties: {
              Array: {
                type: "array",
                title: "Array",
                items: {
                  type: "string",
                },
              },
            },
          };
        }
        if (type == "edit") {
          this.modeledit.Name = this.modeledit.Logic;
          this.schemaEditDynamicData = {};
          this.$nextTick(() => {
            this.schemaEditDynamicData = schemaObj;
          });
          this.dataEditDialog = true;
        } else if (type == "add") {
          this.modeladd.Name = this.modeladd.Logic;
          this.schemaDynamicData = {};
          this.$nextTick(() => {
            this.schemaDynamicData = schemaObj;
          });
          this.dataAddDialog = true;
        }
      }
    },

    stepTypeConfig(val) {
      if (val.StepType) {
        switch (val.StepType) {
          case "Snap":
            val.Logic = "";
            val.ModuleType = "";
            val.ModuleSelected = "";
            val.ModuleName = "";
            break;
          case "Logic":
            val.Snap = "";
            val.ModuleType = "";
            val.ModuleSelected = "";
            val.ModuleName = "";
            val.Namespace = "";
            break;
          case "Module":
            val.Snap = "";
            val.Logic = "";
            break;
          default:
            break;
        }
      }
    },
    getManualRunProjects() {
      axios
        .get(
          process.env.VUE_APP_BASEURL +
            "/hermosa/project/manual?projectId=" +
            this.projectid
        )
        .then((response) => {
          if (response.data.status) {
            this.projectsRun =
              response.data.data.projects.length > 0
                ? response.data.data.projects
                : [];

            if (this.projectsRun && this.projectsRun.length > 0) {
              var lastTestJson = this.projectsRun.find((testEl) =>
                Boolean(testEl.TestJson)
              ).TestJson;
              console.log("Testjson", lastTestJson);

              var lastConfigJson = this.projectsRun.find((configEl) =>
                Boolean(configEl.ConfigJson)
              );
              console.log("ConfigJson", lastConfigJson);

              if (lastTestJson) {
                console.log("test json", lastTestJson);
                this.runData = lastTestJson;
              }
              if (lastConfigJson && lastConfigJson.ConfigJson) {
                let configJsonObj = JSON.parse(lastConfigJson.ConfigJson);
                console.log("parsed configjson", configJsonObj);
                if (configJsonObj.CompanyId && lastConfigJson.company) {
                  let companyObj = {};
                  Object.assign(companyObj, lastConfigJson.company);
                  // console.log('cmpdata',companyObj)
                  this.cmpSearch = companyObj.Name;
                  this.cmpSearchModel = companyObj;
                  this.cmpEntries.push(companyObj);
                }
                if (
                  configJsonObj.PropertyNames &&
                  configJsonObj.PropertyNames.length > 0
                ) {
                  this.propertyModel = configJsonObj.PropertyNames;
                }
                const {
                  isSingleProperty,
                  PropertyName,
                  PropertyNames,
                  PropertyId,
                  PropertyIds,
                  CompanyId,
                  ...other
                } = configJsonObj;
                console.log("other", other);
                this.runDataConfig = JSON.stringify(other);
              } else {
                console.log("convert");
                this.convertInputsToJson();
              }
            }
          }
        });
    },
    getLogs(item) {
      this.logContent = null;
      axios
        .get(
          process.env.VUE_APP_BASEURL + "/hermosa/project/" + item.Id + "/log"
        )
        .then((response) => {
          if (response) {
            if (response.data.status) {
              this.logContent = response.data.data.logs;
              this.logDialog = true;
            } else {
              this.color = "error";
              this.snackBarText = response.data.message;
              this.snackbar = true;
            }
          }
        });
    },
    stopProject(item) {
      item.Status = "Complete";
      axios
        .post(
          process.env.VUE_APP_BASEURL +
            `/hermosa/project/${item.HermosaProjectId}/stop`,
          { version: item.Version }
        )
        .then((response) => {
          if (response) {
            if (response.data.status) {
              this.color = "success";
              this.snackBarText = response.data.message;
              this.snackbar = true;
            } else {
              this.color = "error";
              this.snackBarText = response.data.message;
              this.snackbar = true;
            }
          }
        });
    },
    addRow() {
      this.inputs.push({
        name: "",
      });
    },
    deleteRow(index) {
      this.inputs.splice(index, 1);
    },
    convertInputsToJson() {
      let convertedData = "{";

      if (this.inputs && this.inputs.length > 0) {
        this.inputs.map((el, i) => {
          convertedData += '"' + el.name + '":""';
          if (i < this.inputs.length - 1) {
            convertedData += ",";
          } else {
            convertedData += "}";
          }
        });
      }

      // console.log('convertedData',convertedData);
      // console.log('convertedData',this.isJsonString(convertedData));

      this.runDataConfig = this.isJsonString(convertedData)
        ? convertedData
        : "";
    },
  },

  beforeDestroy() {
    this.$root.$off("subStepAdded");
    this.$root.$off("stepSelected");
    this.$root.$off("stepDeleted");
  },

  mounted() {
    //Listen child's methods
    this.$root.$on("subStepAdded", (position) => {
      this.subStepAdded(position);
    });
    this.$root.$on("stepSelected", (position) => {
      this.stepSelected(position);
    });
    this.$root.$on("stepDeleted", (position) => {
      this.stepDeleted(position);
    });

    //To get snaps from api
    this.getSnaps();

    this.getProperties();

    this.projectid = this.id;
    if (this.projectid) {
      this.loadProjectVersionData();
    }
    this.getManualRunProjects();
    // this.loading = false;
  },
};
</script>
<style>
.nalu-container {
  background-color: #121212 !important;
}

.v-data-table-header {
  background: #444444 !important;
}

.v-data-table-header tr th {
  cursor: initial !important;
  font-size: 15px;
}

.v-data-table tr {
  cursor: pointer !important;
}

.v-data-table td,
.v-data-table th {
  padding: 10px 16px;
}

.v-text-field .v-label {
  overflow: visible !important;
  top: 1% !important;
}

.label {
  color: #c7c7c7 !important;
  margin-bottom: -0.1rem !important;
}

.conatiner-section {
  width: 100% !important;
}

form button[type="submit"] {
  display: none;
}

.form {
  text-align: left;
  width: 90%;
  margin: auto;
}

h2 {
  font-size: 1.7em;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0.2em;
}

h2 + small {
  display: block;
  text-align: center;
  margin-bottom: 1.2em;
}

small {
  line-height: 20px;
  display: block;
}

.json {
  text-align: left;
}

label {
  display: block;
  margin: 5px;
  padding: 5px;
}

.add-step-model input,
.edit-step input {
  padding: 5px;
  /* border: 1px solid black; */
}

.module-section,
.step-section {
  margin: 5px;
}

.btn {
  margin: 5px;
}

.module-table th {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  height: 36px !important;
}

h1 {
  text-align: center;
  font-size: 24px !important;
}

#steps-text {
  font-size: 10px;
  background: #5c005d;
  padding: 5px 15px;
  border-radius: 10px 10px 0 0;
  display: block;
}

#steps-heading {
  background: #00e676;
  padding: 5px 15px 5px;
}

#close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px 18px;
}

.mr-left {
  margin-left: 10px;
}

.full-height {
  min-height: 100vh;
}
.v-tab {
  text-transform: none !important;
}
.v-tab--disabled {
  background: #424242 !important;
  color: white !important;
  opacity: 0.85 !important;
  text-transform: none !important;
}

.v-tab--disabled:nth-child(3) {
  background: #424242 !important;
  color: white !important;
}
@media (max-width: 991px) {
  .main-div {
    margin-left: auto !important;
    margin-top: 48px !important;

    .v-slide-group {
      margin: 10px !important;
      .v-slide-group__prev--disabled {
        display: none !important;
      }
    }
    
    .nalu-container {
      margin: 5px !important;
      padding: 20px !important;
      .v-sheet {
        padding: 10px !important;
      }
    }
  }
}
</style>
