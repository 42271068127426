<template>
  <div class="full-height pa-10" style="margin-left: 56px">
    <div v-if="loading">
      <loader />
    </div>
    <v-row>
      <v-col sm="12" lg="6">
        <v-card class="mx-auto" color="grey darken-3">
          <div class="d-flex align-start justify-center">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                  Total Funded Loans
                </v-list-item-title>
                <v-list-item-subtitle
                  class="display-1 font-weight-bold light-blue--text"
                  >{{ fundedmarkerCount.toLocaleString("en-US") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-card>
      </v-col>
      <v-col sm="12" lg="6">
        <v-card class="mx-auto" color="grey darken-3">
          <div class="d-flex align-start justify-center">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                  Total In Progress Loans
                </v-list-item-title>
                <v-list-item-subtitle
                  class="display-1 font-weight-bold light-blue--text"
                  >{{ inprogressCount.toLocaleString("en-US") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" class="">
        <v-card class="mx-auto" color="grey darken-3">
          <v-card-title class="text-h5 mb-1 font-weight-bold"
            >Loan Type</v-card-title
          >
          <v-card-text class="d-flex">
            <v-select
              v-model="selectedLoanType"
              :items="loanType"
              label="Loan Type"
              multiple
              chips
              small-chips
              @change="handleLoanTypeFilterChange"
            ></v-select>
            <v-select
              v-if="selectedLoanType.includes('funded')"
              v-model="selectedFundedSubstatuses"
              :items="fundedSubstatuses"
              label="Funded Loan Substatus"
              multiple
              chips
              small-chips
              id="funded"
              style="margin-left: 1em; margin-right: 1em"
              @change="handleFundedFilterChange"
            ></v-select>
            <v-select
              v-if="selectedLoanType.includes('inProgress')"
              v-model="selectedinProgressSubstatuses"
              :items="inProgressSubstatuses"
              label="In Progress Loan Substatus"
              multiple
              chips
              small-chips
              id="inprogress"
              style="margin-left: 1em; margin-right: 1em"
              @change="handleInProgressFilterChange"
            ></v-select>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div class="mx-auto rounded-0 full-height">
      <v-sheet :elevation="5" dark class="pa-5" style="position: relative">
        <div class="text-h5 mb-1 font-weight-bold">
          Maps
          <v-btn
            outlined
            color="green accent-2"
            style="float: right; margin-bottom: 0.7em"
            @click="refreshData"
            >Refresh Data</v-btn
          >
        </div>
        <div id="mapdiv"></div>
      </v-sheet>

      <v-sheet
        :elevation="5"
        dark
        class="pa-5 mt-10"
        style="position: relative"
      >
      </v-sheet>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
import Loader from "../../components/Loader.vue";
import { firebaseDB } from "../../auth/firebase-auth.js";
import Zipcode from "../marketing/zipcode.vue";

export default {
  components: {
    Loader,
  },
  name: "mapcomponent",
  data() {
    return {
      fetchController: null,
      inProgressFetchController: null,
      isLosDataFetching: false,
      isInProgressDataFetching: false,
      selectedState: "all",
      loading: true,
      apiKey: "AIzaSyBkvot_wof6iT87tGnaYMA9ZFIFRvZOK5o",
      mapStyle: "roadmap",
      map: null,
      addressObject: "",
      propertyPlaceId: "",
      disableSubmit: false,
      mortgageLoansData: null,
      fundedZipCodes: [],
      inProgressZipCodes: [],
      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,
      fundedmarkerCount: 0,
      inprogressCount: 0,
      search: "",
      loan: [],
      inProgressloan: [],
      selectedinProgressSubstatuses: [],
      inProgressSubstatuses: [
        { value: 25672, text: "Online Imports for In Progress" },
        { value: 25267, text: "Applications for In Progress" },
        { value: 25544, text: "LO Underwriting for In Progress" },
        { value: 25784, text: "Withdrawn/Denied for In Progress" },
        { value: 25906, text: "Pre-Approved for In Progress" },
        { value: 25247, text: "Active Loan for In Progress" },
        { value: 25248, text: "Ordered Docs for In Progress" },
        { value: 25703, text: "Pending BPO/Appraisal for In Progress" },
        { value: 25250, text: "Pending Title for In Progress" },
        { value: 25249, text: "LO Review for In Progress" },
        { value: 25251, text: "Send to Attorney for In Progress" },
        { value: 25252, text: "Attorney Prepping Docs for In Progress" },
        { value: 25253, text: "LO Docs @ Title for In Progress" },
        { value: 25254, text: "HUD Approved for In Progress" },
        { value: 25395, text: "CLOSED for In Progress" },
      ],
      selectedFundedSubstatuses: [],
      fundedSubstatuses: [
        { value: 25256, text: "-- No Status -- for Funded" },
        { value: 25378, text: "Missing ACH for Funded" },
        { value: 25257, text: "NSF Payment for Funded" },
        { value: 25258, text: "Payment Deferral Req'd for Funded" },
        { value: 25259, text: "Irregular Payment for Funded" },
        { value: 25260, text: "Non-Renewal for Funded" },
        { value: 25261, text: "Renewal Requested for Funded" },
        { value: 25262, text: "Pending Discharge for Funded" },
        { value: 25263, text: "Past Maturity for Funded" },
        { value: 25271, text: "Foreclosure Process for Funded" },
        { value: 26148, text: "Bankruptcy for Funded" },
        { value: 25270, text: "REO for Funded" },
      ],
      selectedLoanType: [],
      loanType: [
        { value: "funded", text: "Funded" },
        { value: "inProgress", text: "In Progress" },
      ],
      filteredLoans: [],
    };
  },
  async mounted() {
    try {
      this.loading = true;
      await this.fetchControllerfun();
      await this.inProgressFetchControllerfun();
      await Promise.allSettled([await this.$store.dispatch("fetchLoans")]);
      await Promise.allSettled([
        await this.fetchInProgressLoansData(),
        await this.fetchLosData(),
      ]);
      await Promise.allSettled([
        this.loadInProgressMap(),
        this.loadFundedMap(),
      ]).then(() => {
        this.loading = false;
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  computed: {
    fundedLoansData() {
      return this.$store.state.fundedLoans;
    },
    inProgressData() {
      return this.$store.state.inProgressLoans;
    },
  },
  methods: {
    async getLatLongFromZipcode(zipcode, street, country) {
      const apiKey = this.apiKey;
      let url;
      if (
        street == null &&
        country == null &&
        street == undefined &&
        country == undefined
      ) {
        url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}&key=${apiKey}`;
      } else if (street == null || street === undefined) {
        url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}|country:${country}&key=${apiKey}`;
      } else if (country === null || country === undefined) {
        url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}|locality:${street}&key=${apiKey}`;
      } else {
        url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}|locality:${street}|country:${country}&key=${apiKey}`;
      }

      try {
        const response = await axios.get(url);
        const data = response.data;
        if (data.status === "OK" && data.results.length > 0) {
          const result = data.results[0];
          const lat = result.geometry.location.lat;
          const lng = result.geometry.location.lng;
          return { latitude: lat, longitude: lng };
        } else {
          return { latitude: "", longitude: "" };
        }
      } catch (error) {
        console.error("Error:", error.message);
        return null;
      }
    },
    async loadInProgressMap() {
      // this.loading = true;
      const canvas = document.createElement("canvas");
      const gl = canvas.getContext("webgl");
      gl.getParameter(gl.RENDERER);

      const mapDiv = document.getElementById("mapdiv");

      const latLong = { lat: 32.8761782, lng: -96.7841387 };
      const zoom = 5;
      const options = {
        center: latLong,
        mapId: "1261b76f0156780e",
        zoom,
        preserveDrawingBuffer: true,
      };
      this.map = new google.maps.Map(mapDiv, options);

      try {
        const markerPromises = this.inProgressloan.map(async (zipCode) => {
          if (this.inProgressFetchController.signal.aborted) {
            this.inProgressFetchController = null;
            this.inProgressFetchControllerfun();
            return;
          }
          if (this.inProgressloan.length === 0) {
            this.inprogressCount = 0;
            return;
          }
          let location = {};

          if (
            zipCode.property.address &&
            zipCode.property.address.latitude !== undefined &&
            zipCode.property.address.longitude !== undefined
          ) {
            location.latitude = zipCode.property.address.latitude;
            location.longitude = zipCode.property.address.longitude;
          } 
          else {

              location = await this.getLatLongFromZipcode(
                zipCode.property.address.zip,
                zipCode.property.address.street,
                zipCode.property.address.country
              );
              await firebaseDB
                .firestore()
                .collection("mortgageLoans")
                .doc(zipCode.id)
                .update({
                        "property.address.latitude": location.latitude,
                        "property.address.longitude": location.longitude
                      });
          }

          if (!location || !location.latitude || !location.longitude) {
            console.error("Error fetching location data");
            return null; // Skip this marker
          }

          let loanAmount = zipCode.amount;

          if (!loanAmount) {
            const mortgageLoanId = zipCode.mortgageLoanId;
            loanAmount = await this.getLoanAmount(zipCode.id, mortgageLoanId);
          }

          const { latitude, longitude } = location;
          const latLng = { lat: latitude, lng: longitude };
          const { AdvancedMarkerElement, PinElement } =
            await google.maps.importLibrary("marker");

          const pinBackground = new PinElement({
            background: "#4169e1",
            borderColor: "#4169e1",
            glyphColor: "white",
          });

          const markerViewBackground = new AdvancedMarkerElement({
            map: this.map,
            position: latLng,
            content: pinBackground.element,
          });

          var infoWindow = new google.maps.InfoWindow({
            content: `
                        <div id="content">
                            <div id="bodyContent">
                                </br>
                                <p style='color: black;'>
                                    <b>Loan Title:</b> ${zipCode.loan_title}
                                </p>
                                <p style='color: black;'>
                                    <b>Address:</b> ${
                                      zipCode.property.address.street
                                    }, ${zipCode.property.address.city}, ${
              zipCode.property.address.country
            }
                                </p>
                                <p style='color: black;'>
                                    <b>Loan Amount:</b> $${Number(
                                      loanAmount
                                    ).toLocaleString("en-US")}
                                </p>
                            </div>
                        </div>  
                    `,
          });

          markerViewBackground.addListener(
            "click",
            (function (marker, infoWin) {
              return function () {
                infoWin.open(this.map, marker);
              };
            })(markerViewBackground, infoWindow)
          );

          return markerViewBackground;
        });

        const markers = await Promise.all(markerPromises);

        // Count the number of valid markers
        this.inprogressCount = markers.filter(
          (marker) => marker !== null
        ).length;
      } catch (error) {
        console.error("Error loading markers:", error);
      } finally {
        console.log("this.inprogressCount", this.inprogressCount);
        // this.loading = false;
      }
    },
    async loadFundedMap() {
      // this.loading = true;

      const canvas = document.createElement("canvas");
      const gl = canvas.getContext("webgl");
      gl.getParameter(gl.RENDERER);

      const mapDiv = document.getElementById("mapdiv");

      this.fundedmarkerCount = 0;
      const latLong = { lat: 32.8761782, lng: -96.7841387 };
      const zoom = 5;
      const options = {
        center: latLong,
        mapId: "1261b76f0156780e",
        zoom,
        preserveDrawingBuffer: true,
      };
      this.map = new google.maps.Map(mapDiv, options);
      try {
        const markerPromises = this.loan.map(async (zipCode) => {
          let location = {};
          if (this.fetchController.signal.aborted) {
            this.fetchController = null;
            this.fetchControllerfun();
            return;
          }
          if (this.loan.length === 0) {
            this.fundedmarkerCount = 0;
            return;
          }

          if (
            zipCode.property.address &&
            zipCode.property.address.latitude !== undefined &&
            zipCode.property.address.longitude !== undefined
          ) {
            location.latitude = zipCode.property.address.latitude;
            location.longitude = zipCode.property.address.longitude;
          } 
            else {
              location = await this.getLatLongFromZipcode(
                zipCode.property.address.zip,
                zipCode.property.address.street,
                zipCode.property.address.country
              );
              await firebaseDB
                .firestore()
                .collection("mortgageLoans")
                .doc(zipCode.id)
                .update({
                        "property.address.latitude": location.latitude,
                        "property.address.longitude": location.longitude
                      });
            }

          if (!location || !location.latitude || !location.longitude) {
            console.error("Error fetching location data");
            return null; // Skip this marker
          }

          let loanAmount = zipCode.amount;

          if (!loanAmount) {
            const mortgageLoanId = zipCode.mortgageLoanId;
            loanAmount = await this.getLoanAmount(zipCode.id, mortgageLoanId);
          }

          const { latitude, longitude } = location;
          const latLng = { lat: latitude, lng: longitude };
          const { AdvancedMarkerElement, PinElement } =
            await google.maps.importLibrary("marker");

          const pinBackground = new PinElement({
            background: "#339933",
            borderColor: "#339933",
            glyphColor: "white",
          });

          const markerViewBackground = new AdvancedMarkerElement({
            map: this.map,
            position: latLng,
            content: pinBackground.element,
          });

          var infoWindow = new google.maps.InfoWindow({
            content: `
                        <div id="content">
                            <div id="bodyContent">
                                </br>
                                <p style='color: black;'>
                                    <b>Loan Title:</b> ${zipCode.loan_title}
                                </p>
                                <p style='color: black;'>
                                    <b>Address:</b> ${
                                      zipCode.property.address.street
                                    }, ${zipCode.property.address.city}, ${
              zipCode.property.address.country
            }
                                </p>
                                <p style='color: black;'>
                                    <b>Loan Amount:</b> $${Number(
                                      loanAmount
                                    ).toLocaleString("en-US")}
                                </p>
                            </div>
                        </div>  
                    `,
          });

          markerViewBackground.addListener(
            "click",
            (function (marker, infoWin) {
              return function () {
                infoWin.open(this.map, marker);
              };
            })(markerViewBackground, infoWindow)
          );

          return markerViewBackground;
        });

        const markers = await Promise.all(markerPromises);

        this.fundedmarkerCount = markers.filter(
          (marker) => marker !== null
        ).length;
      } catch (error) {
        console.error("Error loading markers:", error);
      }
    },
    async fetchLosData() {
      this.fundedZipCodes = [];
      this.isLosDataFetching = true;

      let filteredLoans = [];

      if (this.selectedFundedSubstatuses.length > 0) {
        filteredLoans = this.$store.state.fundedLoans.filter((loan) => {
          const substatus = parseInt(loan.substatus);
          return this.selectedFundedSubstatuses.includes(substatus);
        });
      } else {
        filteredLoans = this.$store.state.fundedLoans;
      }

      try {
        this.fundedZipCodes = filteredLoans.map(
          (loan) => loan.property.address.zip
        );

        this.loan = filteredLoans.map((loan) => {
          const { id, ...data } = loan;
          return { id, ...data };
        });
        console.log("this.loan-------------", this.loan);
        this.loadFundedMap();
        // this.loading=false;
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch request aborted");
        } else {
          console.log("Error in fetching loan data:\n" + error);
        }
      } finally {
        // Reset the fetchController
        this.isLosDataFetching = false;
        console.log("fetchController", this.fetchController);
      }
    },
    async fetchInProgressLoansData() {
      // this.loading = true;

      this.inProgressZipCodes = [];
      this.isInProgressDataFetching = true;

      let filteredLoans = [];
      if (this.selectedinProgressSubstatuses.length > 0) {
        filteredLoans = this.$store.state.inProgressLoans.filter((loan) => {
          const substatus = parseInt(loan.substatus);
          return this.selectedinProgressSubstatuses.includes(substatus);
        });
      } else {
        filteredLoans = this.$store.state.inProgressLoans; // Get funded loans from Vuex store
      }

      try {
        this.inProgressZipCodes = filteredLoans.map(
          (loan) => loan.property.address.zip
        );

        this.inProgressloan = filteredLoans.map((loan) => {
          const { id, ...data } = loan;
          return { id, ...data };
        });
        console.log("in progress loans--------", this.inProgressloan);
        this.loadInProgressMap();
        // this.loading = false;
      } catch (error) {
        console.log("Error in fetching loan data:\n" + error);
      } finally {
        // Reset the fetchController
        this.isInProgressDataFetching = false;
        console.log(
          "inProgressFetchController",
          this.inProgressFetchController
        );
      }
    },
    async getLoanAmount(collectionid, mortgageLoanId) {
      try {
        let loanAmount = 0;
        let mortgageLoanDocument = await firebaseDB
          .firestore()
          .collection("mortgageLoans")
          .doc(collectionid)
          .get();

        if (mortgageLoanDocument.exists) {
          const mortgageLoansDocData = await firebaseDB
            .firestore()
            .collection("mortgageLoans")
            .doc(collectionid)
            .collection("mortgageAutomatorLoan")
            .doc(mortgageLoanId)
            .get();
          let mortgageLoans = mortgageLoansDocData.data();
          if (
            mortgageLoans &&
            mortgageLoans.mortgage &&
            mortgageLoans.mortgage.amount
          ) {
            loanAmount = mortgageLoans.mortgage.amount;
            await firebaseDB
              .firestore()
              .collection("mortgageLoans")
              .doc(collectionid)
              .update({
                amount: loanAmount,
              });
            return loanAmount;
          }
        }
        return loanAmount;
      } catch (error) {
        console.error("Error fetching mortgage data:", error);
      }
    },
    async handleInProgressFilterChange() {
      this.loan = [];
      this.inProgressloan = [];
      this.fundedmarkerCount = 0;
      this.inprogressCount = 0;
      if (this.selectedFundedSubstatuses.length > 0) {
        this.fetchController.abort();
        this.fetchController = null;
        this.fetchControllerfun();
        this.fetchLosData();
      }
      this.inProgressFetchController.abort();
      this.inProgressFetchController = null;
      this.inProgressFetchControllerfun();
      this.fetchInProgressLoansData();
    },
    async handleFundedFilterChange() {
      this.loan = [];
      this.inProgressloan = [];
      this.fundedmarkerCount = 0;
      this.inprogressCount = 0;
      if (this.selectedinProgressSubstatuses.length > 0) {
        this.inProgressFetchController.abort();
        this.inProgressFetchController = null;
        this.inProgressFetchControllerfun();
        this.fetchInProgressLoansData();
      }
      this.fetchController.abort();

      this.fetchController = null;
      this.fetchControllerfun();
      this.fetchLosData();
    },
    async handleLoanTypeFilterChange() {
      this.loan = [];
      this.inProgressloan = [];
      this.fundedmarkerCount = 0;
      this.inprogressCount = 0;

      console.log(
        "funded data controller, inprogress data controller",
        this.fetchController,
        this.inProgressFetchController
      );

      if (
        this.selectedLoanType.includes("funded") &&
        this.selectedLoanType.includes("inProgress")
      ) {
        this.fetchLosData();
        this.fetchInProgressLoansData();
      }
      if (
        !this.selectedLoanType.includes("funded") &&
        !this.selectedLoanType.includes("inProgress")
      ) {
        this.selectedFundedSubstatuses = [];
        this.selectedinProgressSubstatuses = [];
        this.fetchLosData();
        this.fetchInProgressLoansData();
      }
      if (this.selectedLoanType.includes("funded")) {
        if (!this.selectedLoanType.includes("inProgress")) {
          this.inProgressFetchController.abort();
          this.inProgressloan = [];
          this.selectedinProgressSubstatuses = [];
          this.inprogressCount = 0;
          this.fetchLosData();
        }
      }
      if (this.selectedLoanType.includes("inProgress")) {
        if (!this.selectedLoanType.includes("funded")) {
          this.fetchController.abort();
          this.loan = [];
          this.selectedFundedSubstatuses = [];
          this.fundedmarkerCount = 0;
          this.fetchInProgressLoansData();
        }
      }
    },
    async fetchControllerfun() {
      this.fetchController = new AbortController();
    },
    async inProgressFetchControllerfun() {
      this.inProgressFetchController = new AbortController();
    },
    async refreshData() {
      try {
        location.reload();
        this.loading = true;
        this.loan = [];
        this.inProgressloan = [];
        this.fundedmarkerCount = 0;
        this.inprogressCount = 0;
        await this.fetchControllerfun();
        await this.inProgressFetchControllerfun();
        await Promise.allSettled([await this.$store.dispatch("fetchLoans")]);
        await Promise.allSettled([
          await this.fetchInProgressLoansData(),
          await this.fetchLosData(),
        ]).then(() => {
          this.loading = false;
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.hero-container {
  // background: #1852f0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background-image: linear-gradient(
      to bottom,
      rgba(82, 84, 86, 0.85),
      rgba(109, 110, 111, 0.9)
    ),
    url("~@/assets/map-hero.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  .__content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;

    .v-dialog__container {
      display: block;
    }
  }
  .search-container {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;

    .search-btn {
      min-height: 56px;
    }
  }
  .googlemap {
    // border-bottom: 1px solid;
    width: 100%;
    min-width: 250px;
    // min-height: 64px;
    //   min-height: 48px;
    min-height: 56px;
    // background: rgba(0, 0, 0, 0.06);
    background: #ffffff;
    // background: #000;
    // background: #1852f0;
    // color: white;
    color: black;
    padding: 0 12px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    // color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    // border: 1px solid #9e9e9e;
    border: thin solid rgba(0, 0, 0, 0.12);
    // border: 1px solid #1852f0;
    border-radius: 4px;
    // border-radius: 4px 4px 0 0;
    //   box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    //     0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

    &::placeholder {
      // color: rgba(0, 0, 0, 0.87);
      // color: white;
      color: black;
      padding: 8px;
      opacity: 1;
      // transition: opacity 0.5s;
    }

    &:focus {
      outline: none;
      border: 2px solid #1852f0;

      &::placeholder {
        opacity: 0;
      }
    }
  }
}
@media (max-width: 575px) {
  .hero-container {
    margin-top: 55px;
  }

  .search-container {
    width: 100% !important;
  }
}

#mapdiv {
  min-height: 1000px;
  width: 100%;
}

.filter-option {
  display: inline-block;
  margin-right: 20px;
}
</style>
  