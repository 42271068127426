<template>
  <div class="full-height pa-10" style="margin-left: 56px">
    <div class="mx-auto rounded-0 full-height">
      <v-sheet :elevation="5" dark class="pa-5" style="position: relative">
        <div class="hero-container">
          <div class="__content-container md:my-8">
            <div class="search-container">
              <vue-google-autocomplete
                id="propertyMap"
                ref="propertyMap"
                class="googlemap"
                placeholder="Search any location in the US"
                v-if="!loading"
                v-model="addressObject"
                v-bind:value="addressObject"
                v-on:placechanged="getAddressData"
              >
              </vue-google-autocomplete>

              <div v-if="disableSubmit">
                <v-btn class="search-btn ml-2" dark>
                  <v-icon dark> mdi-magnify </v-icon>
                </v-btn>
              </div>

              <div v-else>
                <v-btn class="search-btn ml-2" dark @click="createAddress">
                  <v-icon dark> mdi-magnify </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2">
          <v-data-table
            :headers="marketHeaders"
            :items="market"
            :items-per-page="10"
            item-key="email"
            :search="search"
            sort-by="createdAt"
            :sort-desc="true"
            class="elevation-1"
          >
            <template v-slot:item.createdAt="{ item }">
              <div v-if="item.createdAt">{{ item.createdAt.toDate() }}</div>
            </template>

            <template v-slot:item.actions="{ item }">
              <router-link :to="`/marketing/${item.id}`">
                <v-btn
                  x-small
                  outlined
                  color="green accent-2"
                  class="black--text"
                >
                  View Details
                </v-btn>
              </router-link>
            </template>
          </v-data-table>
        </div>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { firebaseDB } from "../../auth/firebase-auth";

export default {
  components: { VueGoogleAutocomplete },

  data() {
    return {
      loading: false,

      addressObject: "",
      propertyPlaceId: "",
      disableSubmit: false,

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,

      search: "",
      market: [],
      marketHeaders: [
        {
          text: "Address",
          align: "start",
          width: "380",
          value: "addressString",
        },
        {
          text: "City",
          align: "start",
          width: "150",
          value: "addressObject.locality",
        },
        {
          text: "Postal Code",
          align: "start",
          width: "150",
          value: "addressObject.postal_code",
        },
        {
          text: "Created At",
          align: "start",
          width: "350",
          value: "createdAt",
        },
        {
          text: "Actions",
          align: "center",
          width: "100",
          value: "actions",
        },
      ],
    };
  },
  mounted() {
    this.getMarketData();
  },
  methods: {
    getAddressData(addressData, placeResultData) {
      const addressArray = Object.values(addressData);
      addressArray.splice(-2);
      this.addressString = addressArray.join(", ");
      this.addressObject = addressData;
      this.propertyPlaceId = placeResultData.place_id;
    },
    async createAddress() {
      if (this.addressObject === "") {
        this.snackBarText = "Please enter a location before searching";
        this.color = "error";
      } else {
        this.disableSubmit = true;

        const timeElapsed = Date.now();
        const createdAt = new Date(timeElapsed);
        firebaseDB
          .firestore()
          .collection("markets")
          .add({
            addressObject: this.addressObject,
            addressString: this.addressString,
            propertyPlaceId: this.propertyPlaceId,
            // userUid: this.uid,
            createdAt,
          })

          .then((docRef) => {
            this.snackBarText = "Your search location is processing...";
            this.color = "success";
            this.docId = docRef.id;
            this.$router.replace({
              name: "marketingid",
              params: { id: this.docId },
            });
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
            this.snackBarText = error;
            this.color = "error";
          });
      }
    },
    async getMarketData() {
      this.loading = true;
      const marketRef = firebaseDB.firestore().collection("markets");
      try {
        const { docs } = await marketRef.get();

        this.market = docs.map((doc) => {
          const { id } = doc;
          const data = doc.data();
          return { id, ...data };
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("Error in fetching markets data:\n" + error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hero-container {
  // background: #1852f0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-top: 20px;
  // background-image: linear-gradient(
  //     to bottom,
  //     rgba(82, 84, 86, 0.85),
  //     rgba(109, 110, 111, 0.9)
  //   ),
  //   url("~@/assets/map-hero.png");
  // background-repeat: no-repeat;
  // background-position: center center;
  // background-size: cover;

  .__content-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;

    .v-dialog__container {
      display: block;
    }
  }
  .search-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .search-btn {
      min-height: 56px;
    }
  }
  .googlemap {
    // border-bottom: 1px solid;
    width: 100%;
    min-width: 250px;
    // min-height: 64px;
    //   min-height: 48px;
    min-height: 56px;
    // background: rgba(0, 0, 0, 0.06);
    background: #ffffff;
    // background: #000;
    // background: #1852f0;
    // color: white;
    color: black;
    padding: 0 12px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    // color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    // border: 1px solid #9e9e9e;
    border: thin solid rgba(0, 0, 0, 0.12);
    // border: 1px solid #1852f0;
    border-radius: 4px;
    // border-radius: 4px 4px 0 0;
    //   box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    //     0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

    &::placeholder {
      // color: rgba(0, 0, 0, 0.87);
      // color: white;
      color: black;
      padding: 8px;
      opacity: 1;
      // transition: opacity 0.5s;
    }

    &:focus {
      outline: none;
      border: 2px solid #1852f0;

      &::placeholder {
        opacity: 0;
      }
    }
  }
}
@media (max-width: 575px) {
  .hero-container {
    // margin-top: 55px;
  }

  .search-container {
    width: 100% !important;
  }
}
</style>
