<template>
  <div class="full-height pa-10" style="margin-left: 56px">
    <div v-if="loading">
      <Loader :progress="progress" />
    </div>
    <div v-if="loadingBar">
      <ProgressBar :progress="progress" />
    </div>

    <v-snackbar v-model="snackbar" :color="snackbarColor" top
      >{{ snackBarText }}
      <v-btn color="white" class="ml-2" text @click="snackbar = false">
        Close
      </v-btn></v-snackbar
    >

    <div class="mx-auto rounded-0 full-height" v-if="los != null">
      <div v-if="losObject != null">
        <v-sheet :elevation="5" dark class="pa-5 mb-10">
          <div class="d-flex align-center">
            <div class="text-subtitle-1 pr-5">Application: {{ this.id }}</div>

            <v-spacer />

            <div v-if="loanApproval && loanApproval.preapproved == true">
              <div class="text-subtitle-1 green--text text--accent-2 pr-5">
                Approved
              </div>
            </div>

            <div v-else-if="loanApproval && loanApproval.preapproved == false">
              <div class="red--text text--accent-2 pr-5">Not Approved</div>
            </div>

            <template>
              <v-btn
                class="mr-5"
                color="white"
                small
                outlined
                @click="dialog = true"
              >
                View Logs
              </v-btn>

              <v-dialog v-model="dialog" width="auto">
                <v-card width="600">
                  <v-card-title class="text-h6">
                    Loan Application Logs

                    <v-spacer />

                    <div class="c-pointer d-flex" @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </div>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="mt-5">
                    <template>
                      <v-timeline density="compact" side="end" align="start">
                        <v-timeline-item
                          v-for="logItem of this.losLogObject"
                          :key="logItem.id"
                          :color="logItem.status == 'Success' ? 'blue' : 'red'"
                          small
                        >
                          <span class="text-gray-700 font-bold align-self-end"
                            ><strong>{{ logItem.title }}</strong> |
                            {{ logItem.time }}</span
                          >
                          <div class="mt-2 text-caption">
                            {{ logItem.description }}
                          </div>
                        </v-timeline-item>
                      </v-timeline>
                    </template>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      class="btn-item custom-bg-secondary"
                      dark
                      small
                      block
                      @click="dialog = false"
                      >Close Logs</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="white" small outlined v-bind="attrs" v-on="on">
                  Automations
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-btn
                    color="white"
                    small
                    outlined
                    @click="processApplication"
                  >
                    Process Application
                  </v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn color="white" small outlined @click="generateReport()">
                    Download Pre Approval Letter
                  </v-btn>
                </v-list-item>
                <v-list-item
                  v-if="!this.loading && this.losObject.isProcessApplication"
                >
                  <v-btn
                    color="white"
                    small
                    outlined
                    @click="generateLoanReport()"
                  >
                    Download Term Sheet
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-sheet>

        <v-row class="mb-6">
          <v-col lg="4">
            <v-card color="grey darken-3" height="100%">
              <v-card-text>
                <div class="d-flex">
                  Investor
                  <div
                    v-bind:style="[
                      loanApproval && loanApproval.preapproved
                        ? { background: '#69f0ae !important' }
                        : { background: ' #ff5252!important' },
                    ]"
                    class="round"
                  ></div>
                </div>

                <div v-if="loanApproval">
                  <div v-if="loanApproval.preapproved == true">
                    <div class="text-h5 green--text text--accent-2 pt-2 pb-4">
                      {{ loanApproval.score.toFixed(2) }}/25
                    </div>
                  </div>

                  <div
                    v-else-if="
                      loanApproval && loanApproval.preapproved == false
                    "
                  >
                    <div class="text-h5 red--text text--accent-2 pt-2 pb-4">
                      {{ loanApproval && loanApproval.score.toFixed(2) }}/25
                    </div>
                  </div>

                  <div v-else class="text-h5 text--primary pt-2 pb-4">
                    Not Available
                  </div>
                </div>

                <p class="mb-1">
                  {{ this.losObject.B1417 }} {{ this.losObject.B1419 }},
                  {{ this.losObject.B1425 }}
                </p>

                <p class="orange--text">
                  CS: {{ this.losObject.CUSTOM_CreditScore }}, L:
                  {{ formatStrCurrency(this.losObject.F9009) }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col lg="4">
            <v-card color="grey darken-3" height="100%">
              <v-card-text>
                <div class="d-flex">
                  Keystone
                  <div
                    v-bind:style="[
                      loanApproval && loanApproval.preapproved
                        ? { background: '#69f0ae !important' }
                        : { background: ' #ff5252!important' },
                    ]"
                    class="round"
                  ></div>
                </div>
                <div
                  v-if="loanApproval && loanApproval.preapproved == true"
                  class="d-inline-flex align-center"
                >
                  <div
                    v-if="avmProperty"
                    class="text-h5 green--text text--accent-2 pt-2 pb-4 pr-4"
                  >
                    {{ formatKCurrency(this.keystoneV3low) }} -
                    {{ formatKCurrency(this.keystoneV3high) }}
                  </div>
                  <div
                    v-if="avmProperty"
                    class="light-blue--text text--lighten-2"
                  >
                    (<span>X&#771;</span>
                    {{ formatKCurrency(this.keystoneV3median) }})
                  </div>
                </div>

                <div
                  v-else-if="loanApproval && loanApproval.preapproved == false"
                  class="d-inline-flex align-center"
                >
                  <div
                    v-if="avmProperty"
                    class="text-h5 red--text text--accent-2 pt-2 pb-4 pr-4"
                  >
                    {{ formatKCurrency(this.keystoneV3low) }} -
                    {{ formatKCurrency(this.keystoneV3high) }}
                  </div>

                  <div
                    v-if="avmProperty"
                    class="light-blue--text text--lighten-2"
                  >
                    (<span>X&#771;</span>
                    {{ formatKCurrency(this.keystoneV3median) }})
                  </div>
                </div>

                <p class="mb-1" v-if="this.losObject && this.losObject.P1002">
                  {{ this.losObject.P1002 }}, {{ this.losObject.P1003 }},
                  {{ this.losObject.P1004 }}, {{ this.losObject.P1005 }}
                </p>

                <p class="orange--text">
                  PP: {{ formatStrCurrency(this.losObject.P1694) }}, NLE:{{
                    this.LE !== "" && this.LE !== null
                      ? formatCurrency(this.LE)
                      : "N/A"
                  }}, IARV:
                  {{ formatStrCurrency(this.losObject.P1697) }}
                  <!-- RB:{{ this.losObject.CUSTOM_RehabBudgetAmount }} -->
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col lg="4">
            <v-card color="grey darken-3" height="100%">
              <v-card-text>
                <div class="d-flex">
                  Viability
                  <div
                    v-bind:style="[
                      loanApproval && loanApproval.preapproved
                        ? { background: '#69f0ae !important' }
                        : { background: ' #ff5252!important' },
                    ]"
                    class="round"
                  ></div>
                </div>

                <div v-if="loanApproval && loanApproval.preapproved == true">
                  <div class="text-h5 green--text text--accent-2 pt-2 pb-4">
                    {{
                      (this.viabilityData &&
                      this.viabilityData.confidence &&
                      this.viabilityData.confidence != "N/A"
                        ? this.viabilityData.confidence
                        : 0
                      ).toFixed(2)
                    }}
                    %
                  </div>
                </div>

                <div
                  v-else-if="loanApproval && loanApproval.preapproved == false"
                >
                  <div class="text-h5 red--text text--accent-2 pt-2 pb-4">
                    {{
                      (this.viabilityData &&
                      this.viabilityData.confidence &&
                      this.viabilityData.confidence != "N/A"
                        ? this.viabilityData.confidence
                        : 0
                      ).toFixed(2)
                    }}
                    %
                  </div>
                </div>

                <p class="mb-1">
                  Exposure
                  <span
                    v-if="
                      this.viabilityData &&
                      this.viabilityData.exposure &&
                      this.viabilityData.exposure !== 'N/A'
                    "
                    >:
                    {{
                      this.viabilityData && this.viabilityData.exposure
                        ? this.viabilityData.exposure
                        : 0
                    }}% of</span
                  >
                  <span v-else>NA</span>
                  <span
                    v-if="avmProperty"
                    class="light-blue--text text--lighten-2 pl-1"
                  >
                    <span>X&#771;</span>
                    {{
                      formatCurrency(
                        this.viabilityData &&
                          this.viabilityData.keystoneV3median
                          ? this.viabilityData.keystoneV3median
                          : 0
                      )
                    }}
                  </span>
                </p>

                <p class="orange--text">
                  Investor Gross Profit:
                  {{
                    formatCurrency(
                      this.viabilityData &&
                        this.viabilityData.profitDollarAmount
                        ? this.viabilityData.profitDollarAmount
                        : 0
                    )
                  }}
                  {{ this.profitPercentage }}%
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="text-subtitle-1 white--text">All Data</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="text-subtitle-1 white--text">
                    Application Data
                  </div>
                  <div class="text-right">
                    <v-btn
                      @click="clicked"
                      v-if="editMode"
                      color="white"
                      class="mr-3"
                      x-small
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    >
                      Edit Application
                    </v-btn>
                    <v-btn
                      @click="saveApplication"
                      v-else
                      color="white"
                      class="mr-3"
                      x-small
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    >
                      Save Application
                    </v-btn>
                  </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <div v-if="losObject != null" class="pt-5">
                    <v-row>
                      <v-col
                        lg="6"
                        v-for="(value, propertyName, i) in mappedLosObj"
                        :key="i"
                      >
                        <v-text-field
                          :value="value"
                          :label="propertyName"
                          outlined
                          :readonly="editMode"
                          v-model="mappedLosObj[propertyName]"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="text-subtitle-1 white--text">Investor Data</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-if="loanApproval">
                    <div class="mt-3">
                      <div class="pb-2"><b>Scoring Output</b></div>

                      <v-row>
                        <v-col lg="2">
                          <div class="orange--text mb-1">
                            Credit Score Result:
                            <ul>
                              <li>
                                {{
                                  this.loanPreApprovalData.loanApproval.results.credit_score_result.toFixed(
                                    2
                                  )
                                }}
                              </li>

                              <li>
                                {{ this.losObject.CUSTOM_CreditScore }}
                              </li>
                            </ul>
                          </div>
                        </v-col>

                        <v-col lg="2">
                          <div class="orange--text mb-1">
                            Experience Result:
                            <ul>
                              <li>
                                {{
                                  this.loanPreApprovalData.loanApproval.results.experience_result.toFixed(
                                    2
                                  )
                                }}
                              </li>
                              <li>
                                {{ this.losObject.CUSTOM_RenovationProjects }}
                              </li>
                            </ul>
                          </div>
                        </v-col>

                        <v-col lg="2">
                          <div class="orange--text mb-1">
                            Liquidity Result:
                            <ul>
                              <li>
                                {{
                                  this.loanPreApprovalData.loanApproval.results.liquidity_result.toFixed(
                                    2
                                  )
                                }}
                              </li>
                              <li>
                                {{ this.losObject.F9009 }}
                              </li>
                            </ul>
                          </div>
                        </v-col>

                        <v-col lg="2">
                          <div class="orange--text mb-1">
                            Loan-to-value Result:
                            <ul>
                              <li>
                                {{
                                  this.loanPreApprovalData.loanApproval.results.loan_to_value_result.toFixed(
                                    2
                                  )
                                }}
                              </li>
                              <li>
                                {{ this.loanToValue }}
                              </li>
                            </ul>
                          </div>
                        </v-col>

                        <v-col lg="2">
                          <div class="orange--text mb-1">
                            Loan-to-cost Result:
                            <ul>
                              <li>
                                {{
                                  this.loanPreApprovalData.loanApproval.results.ltc_result.toFixed(
                                    2
                                  )
                                }}
                              </li>
                              <li>
                                {{ this.loanToCost }}
                              </li>
                            </ul>
                          </div>
                        </v-col>
                      </v-row>
                    </div>

                    <div class="mt-5">
                      <div><b>Scoring Rules</b></div>
                      Our 5-stage pre-approval system is based on years of
                      experience in the lending industry. Credit score,
                      liquidity, loan-to-value ratio, deal experience, and
                      loan-to-cost ratio all contribute a maximum score of five
                      points each, for a total possible application score of 25
                      points. A minimum score of 15/25 is required to advance a
                      loan application. In addition, certain disqualifying
                      factors may nullify otherwise acceptable applications:

                      <ul>
                        <li>Credit score must be at least 580</li>
                        <li>
                          Liquidity must be at least ${{
                            format$Currency(
                              this.losObject["bankStatementAmount"]
                            )
                          }}
                        </li>
                        <li>Loan-to-cost must be less than or equal to 95%</li>
                        <li>Loan-to-value must be less than or equal to 70%</li>
                      </ul>
                      <br />

                      <div><b>Scoring Composite</b></div>
                      <v-row>
                        <v-col lg="2">
                          Credit Score:
                          <ul>
                            <li>600-650: 2</li>
                            <li>650-700: 3</li>
                            <li>700-750: 4</li>
                            <li>750+: 5</li>
                          </ul>
                        </v-col>

                        <v-col lg="2">
                          Experience:
                          <ul>
                            <li>0: 1.5</li>
                            <li>1: 2.5</li>
                            <li>2: 3.5</li>
                            <li>3: 4.5</li>
                            <li>4+: 5</li>
                          </ul>
                        </v-col>

                        <v-col lg="2">
                          Liquidity:
                          <ul>
                            <li>$50,000: 1</li>
                            <li>$65,000: 2</li>
                            <li>$90,000: 3</li>
                            <li>$125,000: 4</li>
                            <li>$185,000+: 5</li>
                          </ul>
                        </v-col>

                        <v-col lg="2">
                          LTV:
                          <ul>
                            <li>70%: 3</li>
                            <li>65%: 4</li>
                            <li>&lt;52%: 5</li>
                          </ul>
                        </v-col>

                        <v-col lg="2">
                          LTC:
                          <ul>
                            <li>90%: 3</li>
                            <li>87%: 4</li>
                            <li>&lt;80%: 5</li>
                          </ul>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="text-subtitle-1 white--text">
                    Subject Property Data
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <div>
                      <v-sheet :elevation="5" dark class="pa-5">
                        <div class="text-subtitle-2 pb-3">
                          Keystone Formula Breakdown
                        </div>
                        <div>
                          <div v-if="this.losObject">
                            {{ this.losObject.P1002 }},
                            {{ this.losObject.P1003 }},
                            {{ this.losObject.P1004 }},
                            {{ this.losObject.P1005 }}
                          </div>
                          <div>
                            {{ totalBedrooms }} bed, {{ totalBathrooms }} bath
                          </div>
                          <div>Structure: {{ propertySize }} sqft</div>
                          <div>
                            Lot:
                            {{ Lot }} sqft
                          </div>

                          <div class="mt-5">
                            Purchase Price:
                            {{ formatStrCurrency(purchasePrice) }}
                          </div>
                          <div>Rehab: {{ formatStrCurrency(rehabBudget) }}</div>
                          <div>Investor ARV: {{ formatStrCurrency(ARV) }}</div>
                          <div>
                            Purchase Price PSF (PP / sqft) :
                            {{
                              this.losObject &&
                              this.losObject["purchasePricePSF"] !== undefined
                                ? this.formatKCurrency(
                                    this.losObject["purchasePricePSF"]
                                  )
                                : 0
                            }}
                          </div>
                          <div>
                            Rehab Budget PSF (RB / sqft) :
                            {{
                              this.losObject &&
                              this.losObject["rehabBudgetPSF"] !== undefined
                                ? this.formatKCurrency(
                                    this.losObject["rehabBudgetPSF"]
                                  )
                                : 0
                            }}
                          </div>
                          <div>
                            Investor ARV PSF (IARV / sqft) :
                            {{
                              this.losObject &&
                              this.losObject["investorARVPSF"] !== undefined
                                ? this.formatKCurrency(
                                    this.losObject["investorARVPSF"]
                                  )
                                : 0
                            }}
                          </div>
                          <div>
                            ATTOM AVM PSF (AVM / sqft) :
                            {{
                              this.losObject &&
                              this.losObject["attomAVMPSF"] !== undefined
                                ? this.formatKCurrency(
                                    this.losObject["attomAVMPSF"]
                                  )
                                : 0
                            }}
                          </div>
                          <div>
                            ATTOM AVM: {{ formatCurrency(this.avmAmount) }}
                          </div>
                          <div v-if="avmProperty">
                            ATTOM AVM Value Range: (
                            {{ formatKCurrency(this.avmAmountLow) }}
                            -
                            {{ formatKCurrency(this.avmAmountHigh) }}
                            )
                          </div>
                          <div>
                            ATTOM Average Comp/Sqft: ${{ this.avgCompAvmPsf }}
                          </div>
                          <div>
                            ATTOM Assessment Value:
                            {{ formatCurrency(this.assessmentValue) }}
                          </div>
                          <div>
                            ATTOM Assessment Psf:
                            {{ formatCurrency(this.assessmentValuePSF) }}
                          </div>
                          <div>
                            Days on Market Prediction (Model: MLS):
                            {{ this.predicted_days_on_market }}
                          </div>
                         
                          <div class="d-flex mt-5">
                            <div>
                              <div>AVM</div>
                              <div>Average Comp psf * sqft</div>
                              <div>Average</div>
                            </div>
                            <v-spacer></v-spacer>
                            <div
                              class="d-flex flex-column justify-end align-end"
                            >
                              <div>{{ formatCurrency(this.avmAmount) }}</div>
                              <div>
                                {{ formatCurrency(this.totalCompSQFT) }}
                              </div>
                              <div>{{ formatCurrency(this.Average1) }}</div>
                            </div>
                          </div>

                          <div class="d-flex mt-5">
                            <div>
                              <div>AVM</div>
                              <div>Investor ARV</div>
                              <div>Average</div>
                            </div>
                            <v-spacer></v-spacer>
                            <div
                              class="d-flex flex-column justify-end align-end"
                            >
                              <div>{{ formatCurrency(this.avmAmount) }}</div>
                              <div>{{ formatCurrency(this.ARV) }}</div>
                              <div>{{ formatCurrency(this.Average2) }}</div>
                            </div>
                          </div>

                          <div class="d-flex mt-5">
                            <div>
                              <div>AVM</div>
                              <div>PP + (RB * 1.75)</div>
                              <div>Average</div>
                            </div>
                            <v-spacer></v-spacer>
                            <div
                              class="d-flex flex-column justify-end align-end"
                            >
                              <div>{{ formatCurrency(this.avmAmount) }}</div>
                              <div>
                                {{ formatCurrency(this.PurchaseRehabValue) }}
                              </div>
                              <div>{{ formatCurrency(this.Average3) }}</div>
                            </div>
                          </div>

                          <div class="d-flex mt-5">
                            <div>
                              <div>Keystone (Average of all 3 averages)</div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{ formatCurrency(this.averageKeystone) }}
                              </div>
                            </div>
                          </div>

                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                Keystone variant 2 (Average of top 3 comps)
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>{{ formatCurrency(this.newKeystone) }}</div>
                            </div>
                          </div>

                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                Keystone variant 4 (Average of top 2-4 comps and
                                investor ARV)
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{ formatCurrency(this.keystoneV3medianV2) }}
                              </div>
                            </div>
                          </div>

                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                Keystone variant 4 Range (Average of top 2-4
                                comps and investor ARV)
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{ formatKCurrency(this.keystoneV3lowV2) }} -
                                {{ formatKCurrency(this.keystoneV3highV2) }}
                              </div>
                            </div>
                          </div>

                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                Keystone variant 3 (Average of top 1-3 comps and
                                investor ARV)
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{ formatCurrency(this.keystoneV3median) }}
                              </div>
                            </div>
                          </div>

                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                Keystone variant 3 Range (Average of top 1-3
                                comps and investor ARV)
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{ formatKCurrency(this.keystoneV3low) }} -
                                {{ formatKCurrency(this.keystoneV3high) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-sheet>
                    </div>

                    <div class="pt-10">
                      <div class="text-subtitle-2 pb-3">Sales Comparables</div>
                      <v-row class="justify-center">
                        <v-col style="width: 50px">
                          <v-card color="grey darken-3">
                            <v-card-text>
                              <div>Avg Comp AVM</div>
                              <p class="text-h5 text--primary">
                                {{ formatCurrency(this.avgCompAvm) }}
                              </p>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col style="width: 50px">
                          <v-card color="grey darken-3">
                            <v-card-text>
                              <div>Avg Comp Sqft</div>
                              <p class="text-h5 text--primary">
                                ${{ this.avgCompSqft.toFixed(0) }}
                              </p>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col style="width: 50px">
                          <v-card color="grey darken-3">
                            <v-card-text>
                              <div>Avg Comp AVM PSF</div>
                              <p class="text-h5 text--primary">
                                {{ this.formatCurrency(this.avgCompAvmPsf) }}
                              </p>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col style="width: 50px">
                          <v-card color="grey darken-3">
                            <v-card-text>
                              <div>Avg Comp Assessment</div>
                              <p class="text-h5 text--primary">
                                {{
                                  this.formatCurrency(this.avgCompAssessment)
                                }}
                              </p>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col style="width: 50px">
                          <v-card color="grey darken-3">
                            <v-card-text>
                              <div>Avg Comp Assessment PSF</div>
                              <p class="text-h5 text--primary">
                                {{
                                  this.formatCurrency(this.avgCompAssessmentPsf)
                                }}
                              </p>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-sheet :elevation="5" dark class="pa-5 mt-10">
                        <p class="mb-1">
                          {{
                            this.keystoneData && this.keystoneData.saleObj
                              ? this.keystoneData.saleObj.length - 2
                              : 0
                          }}
                          sales comparables found within
                          {{ this.mile }} mile that have sold within the last 6
                          months with the following property characteristics
                          variance from the subject property: 10 years built,
                          {{ this.sqFeetRange }} square feet living size,
                          {{ this.bedroomsRange }}
                          bedroom,
                          {{ this.bathroomRange }}
                          bathroom.
                        </p>
                        <div v-if="this.saleObj">
                          <div
                            v-for="(value, i) in this.saleObj"
                            :key="i"
                            :elevation="5"
                          >
                            <v-sheet
                              v-if="value['Address'] && value.isSubjectProperty"
                              dark
                              color="green darken-3"
                              class="pa-5 mt-5"
                            >
                              <div>
                                <span>Property Details:</span>
                                <div>
                                  AVM Value:
                                  <b>{{ formatCurrency(value["AVMValue"]) }}</b>
                                </div>
                                <div>
                                  AVM PSF:
                                  <b>{{ formatCurrency(value["AVMPSF"]) }}</b>
                                </div>
                                <div>
                                  Assessment Value:
                                  <b>{{
                                    formatCurrency(value["assessmentValue"])
                                  }}</b>
                                </div>
                                <div>
                                  Assessment PSF Value:
                                  <b>{{
                                    formatCurrency(value["assessmentValuePSF"])
                                  }}</b>
                                </div>
                                <div>
                                  Square Footage:
                                  <b>{{
                                    value["GrossLivingAreaSquareFeetCount"]
                                  }}</b>
                                </div>
                                <div>
                                  PSF from Latest Sales Transaction:
                                  <b>{{
                                    formatCurrency(
                                      value["PricePerSquareFootAmount"]
                                    )
                                  }}</b>
                                </div>

                                <div>
                                  Address:
                                  <b>{{ value["Address"] }}</b>
                                </div>
                              </div>
                            </v-sheet>

                            <v-sheet
                              v-else-if="value['Address']"
                              dark
                              color="grey darken-3"
                              class="pa-5 mt-5"
                            >
                              <div>
                                <span>Property Details:</span>
                                <div>
                                  AVM Value:
                                  <b>{{ formatCurrency(value["AVMValue"]) }}</b>
                                </div>
                                <div>
                                  AVM PSF:
                                  <b>{{ formatCurrency(value["AVMPSF"]) }}</b>
                                </div>
                                <div>
                                  Assessment Value:
                                  <b>{{
                                    formatCurrency(value["assessmentValue"])
                                  }}</b>
                                </div>
                                <div>
                                  Assessment PSF Value:
                                  <b>{{
                                    formatCurrency(value["assessmentValuePSF"])
                                  }}</b>
                                </div>
                                <div>
                                  Square Footage:
                                  <b>{{
                                    value["GrossLivingAreaSquareFeetCount"]
                                  }}</b>
                                </div>
                                <div>
                                  PSF from Latest Sales Transaction:
                                  <b>{{
                                    formatCurrency(
                                      value["PricePerSquareFootAmount"]
                                    )
                                  }}</b>
                                </div>

                                <div>
                                  Address:
                                  <b>{{ value["Address"] }}</b>
                                </div>
                              </div>
                            </v-sheet>
                          </div>
                        </div>
                      </v-sheet>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="text-subtitle-1 white--text">Deal Data</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-sheet :elevation="5" dark class="pa-5">
                    <div class="text-subtitle-2 pb-3">
                      The Deal Formula Breakdown
                    </div>

                    <div class="d-flex mt-5">
                      <div>
                        <div>
                          Confidence (Keystone Median/(Purchase Price + Rehab
                          Budget) =
                          {{
                            this.viabilityData && this.viabilityData.confidence
                              ? this.viabilityData.confidence
                              : 0
                          }}% )
                        </div>
                        <div>Exposure (Loan amount % of Keystone)</div>
                        <div>
                          Investor Gross Profit ({{ profitDollarAmount }})
                        </div>
                      </div>
                      <v-spacer></v-spacer>
                      <div>
                        <div>
                          {{
                            this.viabilityData && this.viabilityData.confidence
                              ? this.viabilityData.confidence
                              : 0
                          }}
                        </div>
                        <div>
                          {{
                            this.viabilityData && this.viabilityData.exposure
                              ? this.viabilityData.exposure
                              : 0
                          }}%
                        </div>
                        <div>
                          {{
                            this.dealData && this.dealData.profitPercentage
                              ? this.dealData.profitPercentage
                              : 0
                          }}%
                        </div>
                      </div>
                    </div>
                  </v-sheet>

                  <v-sheet :elevation="5" dark class="pa-5 mt-10">
                    <div class="text-subtitle-2 pb-5">
                      Deal Profit Calculator
                    </div>
                    <div v-if="loanApproval">
                      <v-row>
                        <v-col lg="6">
                          <v-text-field
                            :value="purchasePrice"
                            label="Purchase Price ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="rehabBudget"
                            label="Rehab Budget ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="totalAcquisitionCost"
                            label="Total Acquisition Cost ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="ARV"
                            label="ARV"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="avmAmount"
                            label="AVM ($)"
                            outlined
                            prefix="$"
                            disabled
                            dense
                          ></v-text-field>

                          <v-text-field
                            :value="loanAmount"
                            label="Loan Amount ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="downPayment"
                            label="Down Payment ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="interestAmount"
                            label="Interest Payments ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field outlined dense disabled></v-text-field>

                          <v-text-field
                            label="Loan Points ($)"
                            :value="loanPointsAmount"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            label="BPO Appraisal, Attorney Review & Doc Review Fee"
                            :value="bpoAppraisalValue"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="realtorAmount"
                            label="Realtor Fees ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="propertyTaxes"
                            label="Taxes ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-bind="attrs"
                                  v-on="on"
                                  :value="propertyInsurance"
                                  label="Homeowner Insurance ($)"
                                  outlined
                                  dense
                                  prefix="$"
                                  disabled
                                ></v-text-field>
                              </template>
                              <span
                                >Total Acquisition Cost*((Loan Term
                                Months/12)*Insurance Rate)</span
                              >
                            </v-tooltip>
                          </div>

                          <v-text-field
                            label="Title Insurance ($)"
                            :value="titleInsuranceAmount"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            label="Government Recording Fees & Daily Interest"
                            :value="governmentFee"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            label="Warehouse Investor Fee"
                            :value="warehouseInvestorFee"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="utilities"
                            label="Utilities ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="drawFees"
                            label="Draw Fees ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>
                        </v-col>

                        <v-col lg="6">
                          <v-text-field
                            :value="propertySize"
                            label="Square Footage"
                            outlined
                            disabled
                            dense
                          ></v-text-field>

                          <v-text-field outlined dense disabled></v-text-field>

                          <v-text-field
                            :value="acquisitionCostPsf"
                            label="Total Acquisition Cost PSF ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="arvPsfValue"
                            label="ARV PSF ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="avmPsfValue"
                            label="AVM PSF ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field outlined dense disabled></v-text-field>

                          <v-text-field outlined dense disabled></v-text-field>

                          <v-text-field
                            :value="loanTermMonths"
                            label="Loan Term Months"
                            outlined
                            dense
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="interestRate"
                            label="Interest Rate"
                            outlined
                            dense
                            prefix="%"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            label="Loan Points %"
                            :value="loanPointsPercent"
                            outlined
                            dense
                            prefix="%"
                            disabled
                          ></v-text-field>

                          <v-text-field outlined dense disabled></v-text-field>

                          <v-text-field
                            :value="realtorRate"
                            label="Realtor Rate"
                            outlined
                            dense
                            prefix="%"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="taxRate"
                            label="Tax Rate"
                            outlined
                            dense
                            prefix="%"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="insuranceRate"
                            label="Homeowner Insurance Rate"
                            outlined
                            dense
                            prefix="%"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            label="Title Insurance Rate"
                            :value="titleInsurancePercent"
                            outlined
                            dense
                            prefix="%"
                            disabled
                          ></v-text-field>

                          <v-text-field outlined dense disabled></v-text-field>

                          <v-text-field outlined dense disabled></v-text-field>

                          <v-text-field outlined dense disabled></v-text-field>

                          <v-text-field outlined dense disabled></v-text-field>
                        </v-col>
                      </v-row>

                      <div class="mt-5">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-on="on"
                              :value="totalCostBasis"
                              label="Total Cost Basis ($)"
                              outlined
                              dense
                              disabled
                              prefix="$"
                            ></v-text-field>
                          </template>
                          <span
                            >SUM(Total Acquisition Cost+Interest
                            Payments+Realtor Fees+Taxes+Insurance+Utilities+Draw
                            Fees)</span
                          >
                        </v-tooltip>
                      </div>

                      <v-text-field
                        :value="profitDollarAmount"
                        label="Profit ($)"
                        outlined
                        dense
                        disabled
                        prefix="$"
                      ></v-text-field>

                      <v-text-field
                        :value="profitPercentage"
                        label="Profit Percentage (%)"
                        outlined
                        dense
                        prefix="%"
                        disabled
                      ></v-text-field>
                    </div>
                  </v-sheet>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel
                v-if="losObject.platform && losObject.platform == 'mobile'"
              >
                <v-expansion-panel-header>
                  <div class="text-subtitle-1 white--text">Documents</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-sheet :elevation="5" dark class="pa-5">
                    <div>
                      <!-- Business Documents Section -->
                      <v-row>
                        <v-col>
                          <div class="text-subtitle-3 pb-5">
                            Business Documents
                          </div>
                          <v-btn
                            v-for="(url, key) in businessDocument"
                            :key="key"
                            @click="openDocument(url)"
                            dense
                            class="mt-3"
                            style="width: 400px"
                          >
                            {{ key.replace(/_/g, " ") }}
                          </v-btn>
                        </v-col>

                        <v-col>
                          <div class="text-subtitle-3 pb-5">
                            Personal Documents
                          </div>
                          <v-btn
                            v-for="(url, key) in personalDocument"
                            :key="key"
                            @click="openDocument(url)"
                            class="mt-3 mr-8"
                            style="width: 400px"
                          >
                            {{ key.replace(/_/g, " ") }}
                          </v-btn>
                        </v-col>

                        <v-col>
                          <div class="text-subtitle-3 pb-5">
                            Property Documents
                          </div>
                          <v-btn
                            v-for="(url, key) in propertyDocument"
                            :key="key"
                            @click="openDocument(url)"
                            dense
                            class="mt-3 mr- 10"
                            style="width: 400px"
                          >
                            {{ key.replace(/_/g, " ") }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-sheet>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                v-if="losObject.platform && losObject.platform == 'mobile'"
              >
                <v-expansion-panel-header>
                  <div class="text-subtitle-1 white--text">
                    Rehab Budget Data
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-sheet :elevation="5" dark class="pa-5">
                    <v-row>
                      <v-col
                        lg="6"
                        v-for="(value, propertyName, i) in rehabBudgetData"
                        :key="i"
                      >
                        <v-text-field
                          :value="formatStrCurrency(value)"
                          :label="propertyName"
                          outlined
                          :readonly="editMode"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparables JSON Data
        </div>

        <div v-if="!this.loading && this.salesComparables" class="code-details">
          <MonacoEditor
            v-model="salesComparables"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>

        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparables data is empty.
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Subject Property Street View
        </div>
        <!-- <div id="street_view_map_div"></div> -->
        <div
          v-if="subjectProperty && subjectProperty.Address"
          class="pa-5 mt-10"
        >
          <v-sheet :elevation="5" dark>
            <div id="pano"></div>
            <div id="map"></div>
          </v-sheet>
        </div>

        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 pb--1 green--text"
        >
          Subject Property Address is empty.
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparables Map
        </div>
        <div id="mapdiv">
          <div
            v-if="
              !subjectProperty &&
              !subjectProperty.Address &&
              !this.loading &&
              this.losObject.isProcessApplication
            "
            class="mt-15 green--text"
          >
            Subject Property Address is empty.
          </div>
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">AVM Snapshot Map</div>
        <div id="avm_ss_map_div">
          <div
            v-if="
              !subjectProperty &&
              !subjectProperty.Address &&
              !this.loading &&
              this.losObject.isProcessApplication
            "
            class="mt-15 green--text"
          >
            Subject Property Address is empty.
          </div>
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          AVM Snapshot JSON
          <!-- {{this.avmSnapShot}} -->
        </div>

        <div v-if="!this.loading && this.avmSnapShot" class="code-details">
          <MonacoEditor
            v-model="this.avmSnapShot"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          AVM Snapshot data is empty.
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          AVM Assessment Snapshot
          <!-- {{this.avmSnapShot}} -->
        </div>

        <div
          v-if="!this.loading && this.avmAssessmentSnapShot"
          class="code-details"
        >
          <MonacoEditor
            v-model="this.avmAssessmentSnapShotString"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          AVM Assessment Snapshot data is empty.
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Subject Property AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.attomAvmDetail" class="code-details">
          <MonacoEditor
            v-model="this.attomAvmDetail"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          AVM Details data is empty.
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 1 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp1Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp1Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 1 AVM Details data is empty.
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 2 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp2Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp2Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 2 AVM Details data is empty.
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 3 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp3Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp3Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 3 AVM Details data is empty.
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 4 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp4Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp4Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 4 AVM Details data is empty.
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 5 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp5Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp5Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 5 AVM Details data is empty.
        </div>
      </v-sheet>
      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 6 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp6Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp6Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 6 AVM Details data is empty.
        </div>
      </v-sheet>
      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 7 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp7Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp7Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 7 AVM Details data is empty.
        </div>
      </v-sheet>
      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 8 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp8Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp8Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 8 AVM Details data is empty.
        </div>
      </v-sheet>
      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 9 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp9Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp9Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 9 AVM Details data is empty.
        </div>
      </v-sheet>
      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 10 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp10Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp10Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 10 AVM Details data is empty.
        </div>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MonacoEditor from "monaco-editor-vue";
import { firebaseDB, firebaseappDB } from "../../../auth/firebase-auth";
import Loader from "../../../components/Loader.vue";
import ProgressBar from "../../../components/ProgressBar.vue";

export default {
  name: "Los",
  components: {
    Loader,
    MonacoEditor,
    ProgressBar,
  },
  props: ["id"],
  data() {
    return {
      rehabBudgetData: null,
      businessDocument: 0,
      personalDocument: 0,
      propertyDocument: 0,
      documents: null,
      progress: 0,
      apiDuration: 30000, // API call duration in milliseconds (e.g., 10 seconds)
      progressSteps: 4, // Number of progress steps (25% each)
      progressComplete: false,
      street_map: null,
      panorama: null,
      loading: true,
      loadingBar: false,
      dialog: false,
      color: "success",
      snackbar: false,
      snackBarText: "",
      snackbarColor: "",
      snackBarTimeout: 5000,
      disableDownload: false,
      options: {
        //Monaco Editor Options
      },
      avmProperty: "",
      subjectProperty: "",
      avgCompAvm: 0,
      avgCompSqft: 0,
      avgCompAvmPsf: 0,
      keystoneAvg2: (this.avmAmount + this.ARV) / 2,
      keystoneAvg3: "",
      keystoneAvg: "",
      minComAvmAvg: 0,
      maxComAvmAvg: 0,
      minComAvmpsf: 0,
      maxComAvmpsf: 0,
      newKeystone: 0,
      keystoneV3high: 0,
      keystoneV3low: 0,
      keystoneV3median: 0,
      keystoneV3highV2: 0,
      keystoneV3lowV2: 0,
      keystoneV3medianV2: 0,
      LE: "",
      borrowerDialog: false,
      propertyDialog: false,
      compsDialog: false,
      los: [],
      processLoanApplicationData: {},
      losObject: null,
      attomDataObject: null,
      salesComparablesParam: null,
      mile: 0,
      sqFeetRange: 0,
      bedroomsRange: 0,
      bathroomRange: 0,
      loanPreApprovalData: null,
      runsData: null,
      losLogObject: null,
      mappedLosObj: null,
      saleObj: null,
      keystoneData: null,
      dealData: null,
      viabilityData: null,
      propertyBasicProfile: "",
      attomAvmDetail: "",
      salesComparables: "",
      salesHistoryDetail: "",
      avgAVMCom: null,
      salesComparablesCount: "",
      compProp1Avm: "",
      compProp2Avm: "",
      compProp3Avm: "",
      compProp4Avm: "",
      compProp5Avm: "",
      compProp6Avm: "",
      compProp7Avm: "",
      compProp8Avm: "",
      compProp9Avm: "",
      compProp10Avm: "",
      totalBedrooms: 0,
      totalBathrooms: 0,
      propertySize: 0,
      Lot: 0,
      avmAmount: 0,
      avmAmountLow: 0,
      avmAmountHigh: 0,
      totalCompSQFT: 0,
      Average1: 0,
      Average2: 0,
      PurchaseRehabValue: 0,
      Average3: 0,
      averageKeystone: 0,
      avmPsfValue: 0,
      avmSnapShot: "",
      avmSnapShotDetails: "",
      avmAssessmentSnapShot: "",
      avmAssessmentSnapShotString: "",
      confidence: 0,
      totalAcquisitionCost: 0,
      downPayment: 0,
      interestAmount: 0,
      loanPointsAmount: 0,
      realtorAmount: 0,
      propertyTaxes: 0,
      propertyInsurance: 0,
      titleInsuranceAmount: 0,
      acquisitionCostPsf: 0,
      arvPsfValue: 0,
      assessmentValue: 0,
      assessmentValuePSF: 0,
      profitPercentage: "",
      profitDollarAmount: "",
      loanAmount: "",
      totalCostBasis: "",
      purchasePrice: "$150,000",
      rehabBudget: "$25,000",
      closingCosts: "$2000",
      ARV: "$225,000",
      loanTermMonths: 0,
      interestRate: 0,
      loanPointsPercent: 0,
      bpoAppraisalValue: 0,
      titleInsurancePercent: 0,
      governmentFee: 0,
      warehouseInvestorFee: 0,
      realtorRate: 0,
      insuranceRate: 0,
      taxRate: 0,
      closingCostsRate: "0.04",
      utilities: 0,
      drawFees: 0,
      profitAmount: "",
      editMode: true,
      loanToCost: 0,
      loanToValue: 0,
      predicted_days_on_market: 0,
      losDetailsFieldMap: [
        {
          field: "contactRefId",
          value: "Crebrid CRM Contact ID",
          order: 1,
        },
        {
          field: "createdAt",
          value: "Created At",
          order: 2,
        },
        {
          field: "loanStatus",
          value: "Loan Status",
          order: 3,
        },
        {
          field: "ReferenceNumber",
          value: "Reference Number",
          order: 4,
        },
        {
          field: "B1425",
          value: "Borrower's Email Address",
          order: 5,
        },
        {
          field: "B1417",
          value: "Borrower's First Name",
          order: 6,
        },
        {
          field: "B1418",
          value: "Borrower's M.I.",
          order: 7,
        },
        {
          field: "B1419",
          value: "Borrower's Last Name",
          order: 8,
        },
        {
          field: "F9009",
          value: "Liquid Assets",
          order: 9,
        },
        {
          field: "CUSTOM_CreditScore",
          value: "Credit Score",
          order: 10,
        },
        // {
        //   field: "B1034",
        //   value: "Borrower's SSN",
        //   order: 10,
        // },
        {
          field: "B1035",
          value: "Borrower's Phone",
          order: 11,
        },
        {
          field: "B1035_2",
          value: "Co-Borrower's Phone",
          order: 12,
        },
        {
          field: "B1036",
          value: "Borrower's DOB",
          order: 13,
        },
        {
          field: "B1038",
          value: "Borrower's Marital Status",
          order: 14,
        },
        {
          field: "B1042",
          value: "Borrower's Address",
          order: 15,
        },
        {
          field: "B1044",
          value: "Borrower's City",
          order: 16,
        },
        {
          field: "B1045",
          value: "Borrower's State",
          order: 17,
        },
        {
          field: "B1046",
          value: "Borrower's Zip",
          order: 18,
        },
        {
          field: "B1057",
          value: "Borrower's Employer Address",
          order: 19,
        },
        {
          field: "B1058",
          value: "Borrower's Employer City",
          order: 20,
        },
        {
          field: "B1058_2",
          value: "Co-Borrower's Employer City",
          order: 21,
        },
        {
          field: "B1059",
          value: "Borrower's Employer State",
          order: 22,
        },

        {
          field: "B1060",
          value: "Borrower's Employer Zip",
          order: 23,
        },

        {
          field: "CUSTOM_CoBorrower",
          value: "Do you have a Co-Borrower?",
          order: 24,
        },
        {
          field: "B1425_2",
          value: "Co-Borrower's Email Address",
          order: 25,
        },
        {
          field: "B1417_2",
          value: "Co-Borrower's First Name",
          order: 26,
        },
        {
          field: "B1418_2",
          value: "Co-Borrower's M.I.",
          order: 27,
        },
        {
          field: "B1419_2",
          value: "Co-Borrower's Last Name",
          order: 28,
        },
        {
          field: "F9009_2",
          value: "Co-Borrower - 1003 - Asset - Subtotal Liquid Assets",
          order: 29,
        },
        {
          field: "B1034_2",
          value: "Co-Borrower's SSN",
          order: 30,
        },
        {
          field: "B1036_2",
          value: "Co-Borrower's DOB",
          order: 31,
        },
        {
          field: "B1038_2",
          value: "Co-Borrower's Marital Status",
          order: 32,
        },
        {
          field: "B1042_2",
          value: "Co-Borrower's Address",
          order: 33,
        },
        {
          field: "B1044_2",
          value: "Co-Borrower's City",
          order: 34,
        },
        {
          field: "B1045_2",
          value: "Co-Borrower's State",
          order: 35,
        },
        {
          field: "B1046_2",
          value: "Co-Borrower's Zip",
          order: 36,
        },
        {
          field: "B1057_2",
          value: "Co-Borrower's Employer Address",
          order: 37,
        },
        {
          field: "B1059_2",
          value: "Co-Borrower's Employer State",
          order: 38,
        },
        {
          field: "B1060_2",
          value: "Co-Borrower's Employer Zip",
          order: 39,
        },

        {
          field: "CUSTOM_EntityNameYesOrNo",
          value: "Business Entity Name, Yes or No?",
          order: 40,
        },
        {
          field: "CUSTOM_BusinessEntityName",
          value: "Business Entity Name",
          order: 41,
        },

        {
          field: "CUSTOM_ForeClosure",
          value: "Have you had a Foreclosure?",
          order: 42,
        },
        {
          field: "CUSTOM_ForeClosexplain",
          value: "Foreclosure Explanation",
          order: 43,
        },
        {
          field: "CUSTOM_Bankruptcy",
          value: "Have you filed for Bankruptcy?",
          order: 44,
        },

        {
          field: "F2373",
          value: "Additional Comments",
          order: 45,
        },

        {
          field: "CUSTOM_FlipOrRent",
          value: "Flip or Rent",
          order: 46,
        },

        {
          field: "CUSTOM_PropertyUnderContract",
          value: "Property Under Contract",
          order: 47,
        },
        {
          field: "CUSTOM_StatePropertyPurchase",
          value: "State Property Purchase",
          order: 48,
        },

        {
          field: "CUSTOM_ContractCloseDate",
          value: "Contract Close Date",
          order: 49,
        },

        {
          field: "CUSTOM_EstimatedTimeFrame",
          value: "Estimated Time Frame",
          order: 50,
        },

        {
          field: "P1002",
          value: "Subject Property Address",
          order: 51,
        },
        {
          field: "P1003",
          value: "Subject Property City",
          order: 52,
        },
        {
          field: "P1004",
          value: "Subject Property State",
          order: 53,
        },
        {
          field: "P1005",
          value: "Subject Property Zip",
          order: 54,
        },
        {
          field: "P1694",
          value: "Purchase Price",
          order: 55,
        },
        {
          field: "CUSTOM_RehabBudgetAmount",
          value: "Rehab Budget Amount",
          order: 56,
        },
        {
          field: "P1697",
          value: "ARV",
          order: 57,
        },

        {
          field: "CUSTOM_RenovationProjects",
          value: "Renovation Projects",
          order: 58,
        },

        {
          field: "CUSTOM_RenoAddress1",
          value: "Renovation Project 1 - Address",
          order: 59,
        },
        {
          field: "CUSTOM_RenoCity1",
          value: "Renovation Project 1 - City",
          order: 60,
        },
        {
          field: "CUSTOM_RenoState1",
          value: "Renovation Project 1 - State",
          order: 61,
        },
        {
          field: "CUSTOM_RenoZip1",
          value: "Renovation Project 1 - Zip",
          order: 62,
        },
        {
          field: "CUSTOM_RenoAddress1",
          value: "Renovation Project 2 - Address",
          order: 63,
        },
        {
          field: "CUSTOM_RenoCity1",
          value: "Renovation Project 2 - City",
          order: 64,
        },
        {
          field: "CUSTOM_RenoState1",
          value: "Renovation Project 2 - State",
          order: 65,
        },
        {
          field: "CUSTOM_RenoZip1",
          value: "Renovation Project 2 - Zip",
          order: 66,
        },
        {
          field: "CUSTOM_RenoAddress1",
          value: "Renovation Project 3 - Address",
          order: 67,
        },
        {
          field: "CUSTOM_RenoCity1",
          value: "Renovation Project 3 - City",
          order: 68,
        },
        {
          field: "CUSTOM_RenoState1",
          value: "Renovation Project 3 - State",
          order: 69,
        },
        {
          field: "CUSTOM_RenoZip1",
          value: "Renovation Project 3 - Zip",
          order: 70,
        },
        {
          field: "CUSTOM_RenoAddress1",
          value: "Renovation Project 4 - Address",
          order: 71,
        },
        {
          field: "CUSTOM_RenoCity1",
          value: "Renovation Project 4 - City",
          order: 72,
        },
        {
          field: "CUSTOM_RenoState1",
          value: "Renovation Project 4 - State",
          order: 73,
        },
        {
          field: "CUSTOM_RenoZip1",
          value: "Renovation Project 4 - Zip",
          order: 74,
        },
        {
          field: "CUSTOM_RenoAddress1",
          value: "Renovation Project 5 - Address",
          order: 75,
        },
        {
          field: "CUSTOM_RenoCity1",
          value: "Renovation Project 5 - City",
          order: 76,
        },
        {
          field: "CUSTOM_RenoState1",
          value: "Renovation Project 5 - State",
          order: 77,
        },
        {
          field: "CUSTOM_RenoZip1",
          value: "Renovation Project 5 - Zip",
          order: 78,
        },

        {
          field: "CUSTOM_HearAboutUs",
          value: "How did you hear about us?",
          order: 79,
        },

        {
          field: "CUSTOM_WildcatAccntExecutive",
          value: "Wildcat Account Executive?",
          order: 80,
        },
        {
          field: "CUSTOM_AccountExec",
          value: "Wildcat Account Executive",
          order: 81,
        },

        {
          field: "B9167",
          value: "1003 - Sex - I do not wish to provide this information",
          order: 82,
        },
        {
          field: "B9080",
          value: "1003 - Hispanic or Latino",
          order: 83,
        },
        {
          field: "B9081",
          value: "1003 - Not Hispanic or Latino",
          order: 84,
        },
        {
          field: "B9082",
          value: "1003 - American Indian or Alaska Native",
          order: 85,
        },
        {
          field: "B9083",
          value: "1003 - Asian",
          order: 86,
        },
        {
          field: "B9084",
          value: "1003 - Black or African American",
          order: 87,
        },
        {
          field: "B9085",
          value: "1003 - Native Hawaiian or Other Pacific Islander",
          order: 88,
        },
        {
          field: "B9086",
          value: "1003 - White",
          order: 89,
        },
        {
          field: "B9087",
          value: "1003 - Female",
          order: 90,
        },
        {
          field: "B9088",
          value: "1003 - Male",
          order: 91,
        },
        {
          field: "B9087",
          value: "1003 - Female",
          order: 92,
        },
        {
          field: "B9087",
          value: "1003 - Female",
          order: 93,
        },
        {
          field: "CUSTOM_Agreement",
          value: "Agree to Wildcat's Terms",
          order: 94,
        },
      ],
    };
  },

  mounted() {
    this.fetchLosDetail();
  },

  computed: {
    // totalCostBasis: function () {
    //   return (
    //     // Number(this.propertyClosingCost) +
    //     (
    //       Number(this.propertyInsurance) +
    //       Number(this.propertyTaxes) +
    //       Number(this.utilities) +
    //       Number(this.drawFees) +
    //       Number(this.purchasePrice) +
    //       Number(this.rehabBudget) +
    //       Number(this.loanPointsAmount) +
    //       Number(this.titleInsuranceAmount) +
    //       Number(this.governmentFee) +
    //       Number(this.warehouseInvestorFee) +
    //       Number(this.interestAmount)
    //     ).toLocaleString("en-US")
    //   );
    // },

    loanApproval() {
      return this.loanPreApprovalData
        ? this.loanPreApprovalData.loanApproval
        : null;
    },
  },

  methods: {
    openDocument(url) {
      window.open(url, "_blank");
    },
    filterObjectByURL(obj) {
      return Object.fromEntries(
        Object.entries(obj).filter(
          ([key, value]) => value && value.trim() !== ""
        )
      );
    },
    async saveApplication() {
      const newObject = {};

      for (const property in this.mappedLosObj) {
        if (
          property === "Created At" ||
          property === "Crebrid CRM Contact ID"
        ) {
          continue;
        }
        const mapping = this.losDetailsFieldMap.find(
          (item) => item.value === property
        );
        if (mapping) {
          newObject[mapping.field] = this.mappedLosObj[property];
        }
      }
      try {
        const applicationRef = await firebaseDB
          .firestore()
          .collection("loans")
          .doc(this.id);

        await applicationRef.update(newObject);
        this.fetchLosDetail();
        console.log("Data updated in Firebase successfully!");
        this.editMode = true;
      } catch (error) {
        console.error("Error updating data in Firebase:", error);
      }
    },
    async streetViewMap(subjectProperty) {
      this.loading = true;

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        { address: subjectProperty.Address },
        (results, status) => {
          if (status === "OK") {
            const subLatLong = results[0].geometry.location;
            console.log("Subject property address subLatLong: " + subLatLong);

            const mapOptions = {
              center: subLatLong,
              zoom: 14,
            };

            this.street_map = new google.maps.Map(
              document.getElementById("map"),
              mapOptions
            );

            this.panorama = new google.maps.StreetViewPanorama(
              document.getElementById("pano"),
              {
                position: subLatLong,
                pov: { heading: 0, pitch: 0 },
                zoom: 0,
              }
            );

            this.street_map.setStreetView(this.panorama);
          } else {
            console.error(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    },
    async createMap(mapDiv, mapData, subjectProperty) {
      const canvas = document.createElement("canvas");
      const gl = canvas.getContext("webgl");
      gl.getParameter(gl.RENDERER);
      const subLatLong = {
        lat: Number(subjectProperty.lat),
        lng: Number(subjectProperty.long),
      };
      const zoom = 14;
      const options = {
        zoom,
        styles: [
          { elementType: "geometry", stylers: [{ color: "#242F3E" }] },
          {
            elementType: "labels.text.stroke",
            stylers: [{ color: "#242F3E" }],
          },
          { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
          {
            featureType: "administrative.locality",
            elementType: "labels.text.fill",
            stylers: [{ color: "#D59563" }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#D59563" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#263C3F" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#6B9A76" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#38414E" }],
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#212A37" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9CA5B3" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#746855" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#1F2835" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{ color: "#F3D19C" }],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#2F3948" }],
          },
          {
            featureType: "transit.station",
            elementType: "labels.text.fill",
            stylers: [{ color: "#D59563" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#17263C" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#515C6D" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#17263C" }],
          },
        ],
      };
      let map = new google.maps.Map(mapDiv, options);
      const { greenIcon, blueIcon } = this.getMarkerIcons();

      this.marker = new google.maps.Marker({
        position: subLatLong,
        map: map,
        icon: greenIcon,
        animation: google.maps.Animation.BOUNCE,
      });

      var subjectInfoWindow = new google.maps.InfoWindow({
        content: `
          <div id="content">
          <div id="bodyContent">
          </br>
          <p style='color: black;'>
            <b>Address:</b> ${subjectProperty.Address} </br>
            <b>AVM: </b>$${parseInt(subjectProperty.AVMValue).toLocaleString(
              "en-US"
            )}</br>
            <b>Assessment Value: </b>$${parseInt(
              subjectProperty.assessmentValue
            ).toLocaleString("en-US")}
          </p>
          </div>
          </div>
            `,
      });
      this.marker.addListener(
        "click",
        (function (marker, infoWin) {
          return function () {
            infoWin.open(map, marker);
          };
        })(this.marker, subjectInfoWindow)
      );

      var infoWindow = new google.maps.InfoWindow();
      for (let i = 0; i < mapData.length; i++) {
        if ("isSubjectProperty" in mapData[i]) {
          continue;
        }
        let latlong = {
          lat: Number(mapData[i].lat),
          lng: Number(mapData[i].long),
        };
        var local_marker = new google.maps.Marker({
          position: latlong,
          map: map,
          icon: blueIcon,
          title: mapData[i].Address,
          animation: google.maps.Animation.DROP,
        });

        local_marker.addListener(
          "click",
          (function (marker, data) {
            return function () {
              let content = `<div id="content">
                <div id="bodyContent">
                </br>
                <p style='color: black;'>
                  <b>Address:</b> ${mapData[i].Address} </br>
                  <b>AVM: </b>$${parseInt(mapData[i].AVMValue).toLocaleString(
                    "en-US"
                  )}</br>
                  <b>Assessment Value: </b>$${parseInt(
                    mapData[i].assessmentValue
                  ).toLocaleString("en-US")}`;

              content += `</p>
                      </div>
                      </div> `;
              infoWindow.setContent(content);
              infoWindow.open(this.map, marker);
            };
          })(local_marker, mapData[i])
        );
      }
      this.loading = false;
      map.setCenter(subLatLong);
    },

    getMarkerIcons() {
      const greenIcon = {
        url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
      };
      const blueIcon = {
        url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
      };

      return { greenIcon, blueIcon };
    },

    async propertiesMap(saleCompsData, subjectProperty) {
      this.loading = true;
      const mapDiv = document.getElementById("mapdiv");
      await this.createMap(mapDiv, saleCompsData, subjectProperty);
    },

    async avmSnapShotMap(avmSnapShotData, subjectProperty, avmAssessmentData) {
      this.loading = true;
      const avmMapDiv = document.getElementById("avm_ss_map_div");
      let avmDataArray = [];
      for (let i = 0; i < avmSnapShotData.length; i++) {
        var avmData = {};
        avmData.lat = Number(avmSnapShotData[i].location.latitude);
        avmData.long = Number(avmSnapShotData[i].location.longitude);
        avmData.Address = avmSnapShotData[i].address.oneLine;
        avmData.AVMValue = avmSnapShotData[i].avm.amount.value;

        let matchingProperty = avmAssessmentData.property.find((property) => {
          return (
            property.address.oneLine === avmSnapShotData[i].address.oneLine
          );
        });

        if (matchingProperty) {
          avmData.assessmentValue =
            matchingProperty.assessment.assessed.assdttlvalue;
        }
        avmDataArray.push(avmData);
      }
      await this.createMap(avmMapDiv, avmDataArray, subjectProperty);
    },

    async processApplication() {
      this.loading = true;
      const token = `Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJtZXNzYWdlIjoiVG9rZW4gZm9yIGNyZWJyaWQtb3MgQVBJIiwiaWF0IjoxNjc1MzQxOTA1fQ._MKnNlPgvURchmlE1YuewP_FEfJ7rJMmVnsiR0FyfPJ_1BQAdPojeOvfwTPaegyqMFYXQN21ytPMl24Zky589A`;
      (this.processLoanApplicationData["isProcessApplication"] = true),
        (this.processLoanApplicationData["id"] = this.$props.id);
      const url =
        "https://us-central1-crebrid-os.cloudfunctions.net/app/add-data";
      await axios
        .post(`${url}`, this.processLoanApplicationData, {
          headers: { Authorization: token },
        })
        .then((response) => {
          this.loading = false;
          this.snackBarText = response.data.message;
          this.snackbarColor = "green";
          this.snackbar = true;
          this.fetchLosDetail();
        })
        .catch((err) => {
          this.loading = false;
          this.snackBarText = "Something went wrong, please check the logs!";
          this.snackbarColor = "red";
          this.snackbar = true;
          console.log("Error: ", err);
        });
    },

    async fetchLosDetail() {
      this.loading = true;
      try {
        const losRef = await firebaseDB
          .firestore()
          .collection("loans")
          .doc(this.$props.id)
          .get();

        const losLogRef = await firebaseDB
          .firestore()
          .collection("loans")
          .doc(this.$props.id);

        let eventsResult = [];
        const logdata = await losLogRef.collection("logs").get();
        logdata.docs.forEach((doc) => {
          eventsResult.push(doc.data());
        });
        this.losObject = { ...losRef.data() };
        this.losLogObject = eventsResult;
        if (this.losObject.platform && this.losObject.platform == "mobile") {
          let linkData = await firebaseappDB
            .firestore()
            .collection("LoanApplications")
            .doc(this.losObject.appLoanApplicationId)
            .collection("documents")
            .doc("all_documents")
            .get();
          let rehabBudgetDoc = await firebaseappDB
            .firestore()
            .collection("LoanApplications")
            .doc(this.losObject.appLoanApplicationId)
            .collection("RehabBudgets")
            .doc("rehab_budgets_data")
            .get();
          this.rehabBudgetData = { ...rehabBudgetDoc.data() };

          this.documents = { ...linkData.data() };

          this.businessDocument = this.documents.businessDocument
            ? this.filterObjectByURL(this.documents.businessDocument)
            : null;
          this.personalDocument = this.documents.personalDocument
            ? this.filterObjectByURL(this.documents.personalDocument)
            : null;
          this.propertyDocument = this.documents.propertyDocument
            ? this.filterObjectByURL(this.documents.propertyDocument)
            : null;
        }

        const attomDocdata = await firebaseDB
          .firestore()
          .collection("loans")
          .doc(this.$props.id)
          .collection("attomData")
          .doc(this.losObject.latestAttomDataId)
          .get();
        this.attomDataObject = { ...attomDocdata.data() };

        if (this.losObject && this.losObject.latestSalesComparablesParamId) {
          const salesComparablesParamData = await firebaseDB
            .firestore()
            .collection("loans")
            .doc(this.$props.id)
            .collection("salesComparablesParameters")
            .doc(this.losObject.latestSalesComparablesParamId)
            .get();
          let salesComparablesParamDataObject = {
            ...salesComparablesParamData.data(),
          };

          this.salesComparablesParam =
            salesComparablesParamDataObject.params[
              salesComparablesParamDataObject.params.length - 1
            ];

          this.mile = this.salesComparablesParam["mile"];
          this.bedroomsRange = this.salesComparablesParam["bedroomsRange"];
          this.bathroomRange = this.salesComparablesParam["bathroomRange"];
          this.sqFeetRange = this.salesComparablesParam["sqFeetRange"];
          console.log("this.salesComparablesParam", this.salesComparablesParam);
        }
        const loanApprovalData = await firebaseDB
          .firestore()
          .collection("loans")
          .doc(this.$props.id)
          .collection("loanApproval")
          .doc(this.losObject.loanPreApprovalId)
          .get();
        this.loanPreApprovalData = { ...loanApprovalData.data() };
        const runsDocData = await firebaseDB
          .firestore()
          .collection("loans")
          .doc(this.$props.id)
          .collection("runs")
          .orderBy("createdAt", "desc")
          .limit(1)
          .get();

        if (runsDocData.docs.length > 0) {
          this.runsData = runsDocData.docs[0].data();
        }

        this.viabilityData =
          this.runsData && this.runsData.viability
            ? this.runsData.viability
            : null;

        console.log("losObject", this.losObject);
        console.log("losLogObject", this.losLogObject);
        console.log("attom data object--", this.attomDataObject);
        console.log("loanPreApprovalData ---", this.loanPreApprovalData);

        this.losObject.createdAt = this.losObject.createdAt.toDate();
        await this.mapfields();
        this.objectsToStrings();
        this.calculatorFields();
        this.salesCompsProperties();
        this.dealDataProperties();

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("Error in fetching los detail:\n" + error);
      }
    },

    updatePropertyClosingCost: function (newValue) {
      this.myValue = newValue;
    },

    formatStrCurrency: function (str) {
      str = str.replace(/[,$]/g, "");
      let num = parseFloat(str);

      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });

      let val = formatter.format(num);
      return val;
    },

    formatCurrency: function (num) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });

      let val = formatter.format(num);
      return val;
    },
    format$Currency: function (num) {
      if (num == null || typeof num === "undefined") {
        return "";
      }
      const roundedValue = Math.ceil(num);

      return roundedValue.toLocaleString("en-US");
    },
    formatKCurrency: function (num) {
      num = parseFloat(num);
      if (num >= 1000) {
        return "$" + (num / 1000).toFixed(0) + "k";
      } else {
        return "$" + num.toFixed(0);
      }
    },

    async mapfields() {
      var result = Object.entries(this.losObject);

      const sortedLosDetailsFieldMap = this.losDetailsFieldMap.sort(
        (a, b) => a.order - b.order
      );

      await sortedLosDetailsFieldMap.map((losmapobj) => {
        result.forEach((el) => {
          if (el[0] === losmapobj.field) {
            el[0] = losmapobj.value;
          }
        });
      });

      const orderedResult = {};
      sortedLosDetailsFieldMap.forEach((losmapobj) => {
        const key = losmapobj.value;
        const entry = result.find(([e1]) => e1 === key) || [null, null];
        const value = entry[1];
        orderedResult[key] = value;
      });

      this.mappedLosObj = orderedResult;
    },
    animateProgressBar() {
      const updateProgress = () => {
        if (!this.progressComplete) {
          this.progress += 5;
        }
        if (this.progressComplete) {
          this.progress = 100;
          this.progressComplete = false;
          clearInterval(progressInterval);
        }
        if (this.progress >= 100) {
          this.progress = 100;
          this.progressComplete = false;
          clearInterval(progressInterval);
        }
      };
      const progressInterval = setInterval(updateProgress, 500);
    },
    async generateLoanReport() {
      this.loadingBar = true;
      this.progress = 0;
      this.animateProgressBar();
      let pdfData = {
        B1417: this.losObject["B1417"],
        B1419: this.losObject["B1419"],
        P1002: this.losObject.P1002,
        P1003: this.losObject.P1003,
        P1004: this.losObject.P1004,
        P1005: this.losObject.P1005,
        loanAmount: this.format$Currency(this.loanAmount),
        interestRate:
          this.losObject && this.losObject.interestRate
            ? this.losObject["interestRate"] * 100
            : 12,
        purchasePrice: this.losObject.P1694,
        rehabBudget: this.losObject.CUSTOM_RehabBudgetAmount,
        totalAcquisitionCost: this.format$Currency(this.totalAcquisitionCost),
        ARV: this.losObject.P1697,
        LTC: this.losObject.LTC_percentage,
        LTV: this.losObject.LTV_percentage,
        loanTermDuration: this.losObject.loanTermDuration,
        reportDownPayment: this.format$Currency(
          this.losObject["reportDownPayment"]
        ),
        interestMonthlyAmount: this.format$Currency(this.interestMonthlyAmount),
        totalInterestCharges: this.format$Currency(
          this.losObject["totalInterestCharges"]
        ),
        processingFee: this.format$Currency(this.losObject["processingFee"]),
        appraisalFee: this.format$Currency(this.losObject["appraisalFee"]),
        creditReportFee: this.format$Currency(
          this.losObject["creditReportFee"]
        ),
        floodCertFee: this.format$Currency(this.losObject["floodCertFee"]),
        attorneyFee: this.format$Currency(this.losObject["attorneyFee"]),
        premiumFee: this.format$Currency(this.losObject["premiumFee"]),
        escrowFee: this.format$Currency(this.losObject["escrowFee"]),
        crebridCost: this.format$Currency(this.losObject["crebridCost"]),
        fundsClosing: this.format$Currency(this.losObject["fundsClosing"]),
        reservedCaseAmount: this.format$Currency(
          this.losObject["reservedCaseAmount"]
        ),
        bankStatementAmount: this.format$Currency(
          this.losObject["bankStatementAmount"]
        ),
        financingExpense: this.format$Currency(
          this.losObject["financingExpense"]
        ),
        id: this.$props.id,
        report: "loan",
      };

      const url =
        location.hostname === "localhost"
          ? `http://localhost:5000`
          : `https://hermosa-server-dot-crebrid-os.uc.r.appspot.com`;

      this.disableDownload = true;
      axios
        .post(`${url}/api/v1/pdf/download-pdf`, {
          losObject: pdfData,
        })
        .then((response) => {
          this.progressComplete = true;
          const pdfUrl = response.data.URL;
          this.disableDownload = true;

          const a = document.createElement("a");
          a.href = pdfUrl;
          a.download = `LoanReport`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.disableDownload = false;
          this.loadingBar = false;
          this.progressComplete = false;
        })
        .catch((err) => {
          this.alertMessage = "Something went wrong, please try again later!";
          this.alertType = "error";
          this.alertState = true;
          console.log("Error: ", err);
          this.loadingBar = false;
        });
    },

    async generateReport() {
      this.loadingBar = true;
      this.progress = 0;
      this.animateProgressBar();
      let date = new Date(this.losObject["createdAt"])
        .toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "/");
      let pdfData = {
        B1417: this.losObject["B1417"],
        B1419: this.losObject["B1419"],
        P1694: this.losObject["P1694"],
        Date: date,
        id: this.$props.id,
      };

      const url =
        location.hostname === "localhost"
          ? `http://localhost:5000`
          : `https://hermosa-server-dot-crebrid-os.uc.r.appspot.com`;
      this.disableDownload = true;
      axios
        .post(`${url}/api/v1/pdf/download-pdf`, {
          losObject: pdfData,
        })
        .then((response) => {
          const pdfUrl = response.data.URL;
          this.disableDownload = true;

          const a = document.createElement("a");
          a.href = pdfUrl;
          a.download = `PreApprovalReport`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.disableDownload = false;
          this.loadingBar = false;
          this.progressComplete = false;
        })
        .catch((err) => {
          this.alertMessage = "Something went wrong, please try again later!";
          this.alertType = "error";
          this.alertState = true;
          console.log("Error: ", err);
          this.loading = false;
        });
    },

    objectsToStrings() {
      this.attomAvmDetail = JSON.stringify(this.attomDataObject.attomAvmDetail);
      this.salesComparables = JSON.stringify(
        this.attomDataObject.salesComparables
      );

      for (let i = 1; i < 11; i++) {
        if (this.attomDataObject[`compProp${i}Avm`]) {
          let com = `compProp${i}Avm`;
          this[com] = JSON.stringify(this.attomDataObject[`compProp${i}Avm`]);
        } else {
          break;
        }
      }

      if (this.attomDataObject.avmSnapShot) {
        this.avmSnapShot = JSON.stringify(this.attomDataObject.avmSnapShot);
        this.avmSnapShotDetails = this.attomDataObject.avmSnapShot.property;
        this.avmAssessmentSnapShot = this.attomDataObject.avmAssessmentSnapShot;
        this.avmAssessmentSnapShotString = JSON.stringify(
          this.avmAssessmentSnapShot
        );
      }
    },

    calculatorFields() {
      this.purchasePrice = this.losObject.P1694.replace(/[$,]/g, "");
      this.rehabBudget = this.losObject.CUSTOM_RehabBudgetAmount.replace(
        /[$,]/g,
        ""
      );
      this.ARV = this.losObject.P1697.replace(/[$,]/g, "");
      if (this.attomDataObject.attomAvmDetail) {
        this.avmProperty = this.attomDataObject.attomAvmDetail.property;
      }
      this.loanToCost =
        this.losObject && this.losObject.LTC_percentage
          ? this.losObject.LTC_percentage
          : 0;
      this.loanToValue =
        this.losObject && this.losObject.LTV_percentage
          ? this.losObject.LTV_percentage
          : 0;
      this.predicted_days_on_market =
        this.losObject &&
        this.losObject.prediction_mls_features.predicted_days_on_market
          ? this.losObject.prediction_mls_features.predicted_days_on_market
          : 0;
    },
    dealDataProperties() {
      this.dealData =
        this.runsData && this.runsData.dealData ? this.runsData.dealData : null;
      this.drawFees =
        this.dealData && this.dealData.drawFees ? this.dealData.drawFees : 0;
      this.totalAcquisitionCost =
        this.dealData && this.dealData.totalAcquisitionCost
          ? this.dealData.totalAcquisitionCost
          : 0;
      this.downPayment =
        this.dealData && this.dealData.downPayment
          ? this.dealData.downPayment
          : 0;
      this.interestAmount =
        this.dealData && this.dealData.interestTotalAmount
          ? Math.round(this.dealData.interestTotalAmount)
          : 0;
      this.interestMonthlyAmount =
        this.dealData && this.dealData.interestMonthlyAmount
          ? Math.round(this.dealData.interestMonthlyAmount)
          : 0;
      this.loanPointsAmount =
        this.dealData && this.dealData.loanPointsAmount
          ? Math.round(this.dealData.loanPointsAmount)
          : 0;
      this.bpoAppraisalValue =
        this.dealData && this.dealData.bpoAppraisalValue
          ? this.dealData.bpoAppraisalValue
          : 0;
      this.realtorAmount =
        this.dealData && this.dealData.realtorAmount
          ? this.dealData.realtorAmount
          : 0;
      this.propertyTaxes =
        this.dealData && this.dealData.propertyTaxes
          ? Math.round(this.dealData.propertyTaxes)
          : 0;
      this.propertyInsurance =
        this.dealData && this.dealData.propertyInsurance
          ? Math.round(this.dealData.propertyInsurance)
          : 0;
      this.titleInsuranceAmount =
        this.dealData && this.dealData.titleInsuranceAmount
          ? Math.round(this.dealData.titleInsuranceAmount)
          : 0;
      this.governmentFee =
        this.dealData && this.dealData.governmentFee
          ? this.dealData.governmentFee
          : 0;
      this.warehouseInvestorFee =
        this.dealData && this.dealData.warehouseInvestorFee
          ? this.dealData.warehouseInvestorFee
          : 0;
      this.utilities =
        this.dealData && this.dealData.utilities ? this.dealData.utilities : 0;
      this.acquisitionCostPsf =
        this.dealData && this.dealData.acquisitionCostPsf
          ? Math.round(this.dealData.acquisitionCostPsf)
          : 0;
      this.arvPsfValue =
        this.dealData && this.dealData.arvPsfValue
          ? Math.round(this.dealData.arvPsfValue)
          : 0;
      this.loanTermMonths =
        this.dealData && this.dealData.loanTermDuration
          ? this.dealData.loanTermDuration
          : 0;
      this.interestRate =
        this.dealData && this.dealData.interestRate
          ? this.dealData.interestRate * 100
          : 0;
      this.loanPointsPercent =
        this.dealData && this.dealData.loanPointsPercent
          ? this.dealData.loanPointsPercent
          : 0;
      this.realtorRate =
        this.dealData && this.dealData.realtorRate
          ? this.dealData.realtorRate
          : 0;
      this.taxRate =
        this.dealData && this.dealData.taxRate ? this.dealData.taxRate : 0;
      this.insuranceRate =
        this.dealData && this.dealData.insuranceRate
          ? this.dealData.insuranceRate
          : 0;
      this.titleInsurancePercent =
        this.dealData && this.dealData.titleInsurancePercent
          ? this.dealData.titleInsurancePercent
          : 0;
      this.profitPercentage =
        this.dealData && this.dealData.profitPercentage
          ? this.dealData.profitPercentage
          : 0;
      this.profitDollarAmount =
        this.dealData && this.dealData.profitDollarAmount
          ? this.dealData.profitDollarAmount
          : 0;
      this.loanAmount =
        this.dealData && this.dealData.loanAmount
          ? Math.round(this.dealData.loanAmount)
          : 0;
      this.totalCostBasis =
        this.dealData && this.dealData.totalCostBasis
          ? this.dealData.totalCostBasis
          : 0;
    },
    salesCompsProperties() {
      this.keystoneData =
        this.runsData && this.runsData.keyStoneResult
          ? this.runsData.keyStoneResult
          : null;
      this.totalBedrooms =
        this.keystoneData && this.keystoneData.totalBedrooms
          ? this.keystoneData.totalBedrooms
          : 0;
      this.totalBathrooms =
        this.keystoneData && this.keystoneData.totalBathrooms
          ? this.keystoneData.totalBathrooms
          : 0;
      this.propertySize =
        this.keystoneData && this.keystoneData.propertySize
          ? this.keystoneData.propertySize
          : 0;
      this.Lot =
        this.keystoneData && this.keystoneData.Lot ? this.keystoneData.Lot : 0;
      this.avmAmount =
        this.keystoneData && this.keystoneData.avmAmount
          ? this.keystoneData.avmAmount
          : 0;
      this.avmAmountLow =
        this.keystoneData && this.keystoneData.avmAmountLow
          ? this.keystoneData.avmAmountLow
          : 0;
      this.avmAmountHigh =
        this.keystoneData && this.keystoneData.avmAmountHigh
          ? this.keystoneData.avmAmountHigh
          : 0;
      this.avgCompAvmPsf =
        this.keystoneData && this.keystoneData.avgCompAvmPsf
          ? this.keystoneData.avgCompAvmPsf
          : 0;
      this.avgCompAssessment =
        this.keystoneData && this.keystoneData.avgCompAssessment
          ? this.keystoneData.avgCompAssessment
          : 0;
      this.avgCompAssessmentPsf =
        this.keystoneData && this.keystoneData.avgCompAssessmentPsf
          ? this.keystoneData.avgCompAssessmentPsf
          : 0;
      this.assessmentValue =
        this.keystoneData && this.keystoneData.assessmentValue
          ? this.keystoneData.assessmentValue
          : 0;
      this.assessmentValuePSF =
        this.keystoneData && this.keystoneData.assessmentValuePSF
          ? this.keystoneData.assessmentValuePSF
          : 0;
      this.totalCompSQFT =
        this.keystoneData && this.keystoneData.totalCompSQFT
          ? this.keystoneData.totalCompSQFT
          : 0;
      this.Average1 =
        this.keystoneData && this.keystoneData.Average1
          ? this.keystoneData.Average1
          : 0;
      this.Average2 =
        this.keystoneData && this.keystoneData.Average2
          ? this.keystoneData.Average2
          : 0;
      this.PurchaseRehabValue =
        this.keystoneData && this.keystoneData.PurchaseRehabValue
          ? this.keystoneData.PurchaseRehabValue
          : 0;
      this.Average3 =
        this.keystoneData && this.keystoneData.Average3
          ? this.keystoneData.Average3
          : 0;
      this.averageKeystone =
        this.keystoneData && this.keystoneData.averageKeystone
          ? this.keystoneData.averageKeystone
          : 0;
      this.newKeystone =
        this.keystoneData && this.keystoneData.newKeystone
          ? this.keystoneData.newKeystone
          : 0;
      this.avgCompAvm =
        this.keystoneData && this.keystoneData.avgCompAvm
          ? this.keystoneData.avgCompAvm
          : 0;
      this.avgCompSqft =
        this.keystoneData && this.keystoneData.avgCompSqft
          ? this.keystoneData.avgCompSqft
          : 0;
      this.avmPsfValue =
        this.keystoneData && this.keystoneData.avmPsfValue
          ? Math.round(this.keystoneData.avmPsfValue)
          : 0;
      this.minComAvmpsf =
        this.keystoneData && this.keystoneData.minComAvmpsf
          ? this.keystoneData.minComAvmpsf
          : 0;
      this.maxComAvmpsf =
        this.keystoneData && this.keystoneData.maxComAvmpsf
          ? this.keystoneData.maxComAvmpsf
          : 0;
      this.LE =
        this.keystoneData && this.keystoneData.LE ? this.keystoneData.LE : 0;
      this.keystoneV3high =
        this.keystoneData && this.keystoneData.keystoneV3high
          ? this.keystoneData.keystoneV3high
          : 0;
      this.keystoneV3low =
        this.keystoneData && this.keystoneData.keystoneV3low
          ? this.keystoneData.keystoneV3low
          : 0;
      this.keystoneV3median =
        this.keystoneData && this.keystoneData.keystoneV3median
          ? this.keystoneData.keystoneV3median
          : 0;
      this.keystoneV3highV2 =
        this.keystoneData && this.keystoneData.keystoneV3highV2
          ? this.keystoneData.keystoneV3highV2
          : 0;
      this.keystoneV3lowV2 =
        this.keystoneData && this.keystoneData.keystoneV3lowV2
          ? this.keystoneData.keystoneV3lowV2
          : 0;
      this.keystoneV3medianV2 =
        this.keystoneData && this.keystoneData.keystoneV3medianV2
          ? this.keystoneData.keystoneV3medianV2
          : 0;
      this.salesComparablesCount =
        this.keystoneData && this.keystoneData.saleObj
          ? this.keystoneData.saleObj.length - 1
          : 0;
      this.saleObj =
        this.keystoneData && this.keystoneData.saleObj
          ? this.keystoneData.saleObj
          : 0;
      this.subjectProperty =
        this.keystoneData && this.keystoneData.saleObj
          ? this.keystoneData.saleObj.find(
              (item) => item.isSubjectProperty === true
            )
          : "";
      this.propertiesMap(this.saleObj, this.subjectProperty);
      this.avmSnapShotMap(
        this.avmSnapShotDetails,
        this.subjectProperty,
        this.avmAssessmentSnapShot
      );
      console.log("subject property data---->", this.subjectProperty);
      this.streetViewMap(this.subjectProperty);
    },

    clicked() {
      this.editMode = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.full-height {
  min-height: 100vh;
}
.v-text-field .v-label {
  overflow: visible !important;
  top: 1% !important;
}

.label {
  color: #c7c7c7 !important;
}

.item {
  cursor: pointer;
}
.fa {
  margin: 3px;
  font-size: 1rem;
}

.v-tab {
  text-transform: none !important;
}

.d-flex {
  display: flex;
  justify-content: space-between;

  .round {
    width: 20px;
    height: 20px;

    border-radius: 50%;
  }
}
.tab-nav-btn {
  background: #212121;
  width: 100%;
  max-width: 300px;
  padding: 0 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;

  a {
    text-decoration: none;
    color: #69f0ae;
  }
}

#mapdiv {
  min-height: 500px;
  width: 100%;
}

#avm_ss_map_div {
  min-height: 500px;
  width: 100%;
}

#pano {
  min-height: 500px;
  width: 100%;
}
</style>
