<template>
  <div class="pa-10 full-height" style="margin-left: 56px">
    <div class="mx-auto rounded-0 full-height">
      <v-sheet class="pa-4" color="grey darken-3" data-app>
        <div class="d-flex align-center">
          <v-text-field
            v-model="search"
            label="Search Data Warehouses..."
            flat
            solo-inverted
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>
        </div>
      </v-sheet>
      <div>
        <v-data-table
          :headers="dataHeaders"
          :items="dataWarehouses"
          :items-per-page="10"
          item-key="email"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:item.actions="{ item }">
            <router-link :to="`${item.actions}`">
              <v-btn x-small outlined light color="green accent-2">
                View Details
              </v-btn>
            </router-link>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,

      search: "",

      dataWarehouses: [
        {
          name: "Wildcat Historical Loans",
          provider: "Google BigQuery",
          source: "Calyx, TMO",
          actions: "/data/wildcat_loans",
        },
        {
          name: "Historical Property Flips, TX & OH",
          provider: "Google BigQuery",
          source: "ATTOM",
          actions: "/data/property_flips",
        },
        {
          name: "Property Flippers, TX & OH",
          provider: "Google BigQuery",
          source: "ATTOM",
          actions: "/data/attom_contacts",
        },
        {
          name: "Listing Analytics, MLS, OH Sample",
          provider: "Google BigQuery",
          source: "ATTOM",
          actions: "/data/analytics",
        },
        {
          name: "Private Lending Borrowers (Companies)",
          provider: "Google BigQuery",
          source: "Forecasa",
          actions: "/data/companies",
        },
      ],

      dataHeaders: [
        {
          text: "Name",
          align: "start",
          width: "380",
          value: "name",
        },
        {
          text: "Provider",
          align: "start",
          width: "150",
          value: "provider",
        },
        {
          text: "Source",
          align: "start",
          width: "150",
          value: "source",
        },
        {
          text: "Actions",
          align: "center",
          width: "100",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tab {
  text-transform: none !important;
}
a {
  text-decoration: none;
}
</style>
