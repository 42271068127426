<template>
  <v-container class="os-container">
    <div class="d-flex align-center justify-center flex-column mt-16 pt-16">
      <div class="text-h4 font-weight-black">Apps</div>
      <div class="text-h6 mt-2 font-weight-regular">
        What do you want to do today?
      </div>

      <div
        class="d-flex flex-column align-center justify-center full-width mt-8"
      >
        <v-row>
          <v-col sm="12" lg="4">
            <v-card class="mx-auto" outlined>
              <div class="d-flex align-start justify-center">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                      CRM
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Manage the full life cycle of
                      borrowers.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <div class="pa-3">
                  <v-icon x-large>mdi-cloud-tags</v-icon>
                </div>
              </div>

              <v-card-actions class="pa-4">
                <v-btn color="green accent-2" class="black--text" to="/snaps">
                  Go to App
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col sm="12" lg="4">
            <v-card class="mx-auto" outlined>
              <div class="d-flex align-start justify-center">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                      LOS
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Manage the full life cycle of
                      loans.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <div class="pa-3">
                  <v-icon x-large>mdi-cloud-tags</v-icon>
                </div>
              </div>

              <v-card-actions class="pa-4">
                <v-btn color="green accent-2" class="black--text" to="/apps/los">
                  Go to App
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col sm="12" lg="4">
            <v-card class="mx-auto" outlined>
              <div class="d-flex align-start justify-center">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                      FMS
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Manage the full life cycle of
                      funds.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <div class="pa-3">
                  <v-icon x-large>mdi-cloud-tags</v-icon>
                </div>
              </div>

              <v-card-actions class="pa-4">
                <v-btn color="green accent-2" class="black--text" to="/snaps">
                  Go to App
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.os-container {
  min-height: calc(100vh - 120px);
}
</style>
