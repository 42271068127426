import Vue from 'vue';
import Vuex from 'vuex';
import {fetchData} from "../../src/loan_data"
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    fundedLoans: [],
    inProgressLoans: []
  },
  mutations: {
    setFundedLoans(state, loans) {
      state.fundedLoans = loans;
    },
    setInProgressLoans(state, loans) {
      state.inProgressLoans = loans;
    }
  },
  actions: {
    async fetchLoans({ commit }) {
      const { fundedLoans, inProgressLoans } = await fetchData();
      commit('setFundedLoans', fundedLoans);
      commit('setInProgressLoans', inProgressLoans);
    }
  },
  getters: {
    getFundedLoans: state => state.fundedLoans,
    getInProgressLoans: state => state.inProgressLoans
  }
});