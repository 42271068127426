<template>
  <div style="margin-left: 56px" class="main-div">
    <v-container class="conatiner-section">
      <v-sheet :elevation="5" dark class="mx-auto pa-5">
        <v-snackbar
          v-model="snackbar"
          :timeout="snackBarTimeout"
          :color="color"
          >{{ snackBarText }}</v-snackbar
        >
        <div class="configassign-section">
          <h1 class="mb-3">AutoConfig Instance List</h1>
          <v-data-table
            loading-text="Loading... Please wait"
            v-model="selectedConfigInstance"
            :headers="headersConfigInstance"
            :items="configInstance"
            item-key="Id"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                icon
                :href="
                  baseURL + '/scheduler/autoconfiginstance/' + item.Id + '/log'
                "
                target="_blank"
              >
                <v-icon small dark>mdi-view-list</v-icon>
              </v-btn>
              <v-btn
                dark
                small
                v-if="
                  item.Status !== 'Complete' &&
                  item.Status !== 'Exited' &&
                  item.Status !== 'Exit'
                "
                color="primary"
                @click="stopInstance(item)"
                >Stop</v-btn
              >
            </template>
          </v-data-table>
        </div>

        <v-card-actions class="mt-8">
          <v-spacer />
          <v-btn
            outlined
            color="white"
            @click="$router.push({ name: 'schedule' })"
            >Back</v-btn
          >
        </v-card-actions>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AutoConfigInstance",
  components: {},
  props: ["id", "preview"],
  data() {
    return {
      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,
      baseURL: process.env.VUE_APP_BASEURL,

      //config assignment
      selectedConfigInstance: [],
      headersConfigInstance: [
        {
          text: "ConfigAssign ID",
          align: "left",
          value: "AutoConfigId",
        },
        { text: "id", value: "Id" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      configInstance: [],
    };
  },
  methods: {
    stopInstance(item) {
      item.Status = "Complete";
      axios
        .get(
          process.env.VUE_APP_BASEURL +
            `/scheduler/autoconfiginstance/${item.Id}/stop`
        )
        .then((response) => {
          if (response) {
            if (response.data.status) {
              this.color = "success";
              this.snackBarText = response.data.message;
              this.snackbar = true;
            } else {
              this.color = "error";
              this.snackBarText = response.data.message;
              this.snackbar = true;
            }
          }
        });
    },
    getAutoConfigInstance() {
      axios
        .get(process.env.VUE_APP_BASEURL + "/scheduler/autoconfiginstance", {
          params: {
            autoJobId: this.id,
          },
        })
        .then((response) => {
          if (response.data.status) {
            this.configInstance =
              response.data.data.autoConfigInstances.length > 0
                ? response.data.data.autoConfigInstances
                : [];
          }
        });
    },
  },
  mounted() {
    this.getAutoConfigInstance();
  },
};
</script>
<style scoped>
@media (max-width: 991px) {
  .main-div {
    margin: 5px !important;
  }
}
</style>
