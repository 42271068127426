import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./routes";
import axios from "axios";
import Router from "vue-router";
import Draggable from "vuedraggable";
import Swatches from "vue-swatches";
import { Sketch } from "vue-color";
import VueCookies from "vue-cookies";
import store from "./store";
import { mapActions } from "vuex";
// import GmapVue from 'gmap-vue'

Vue.use(Router);
Vue.use(VueCookies);
Vue.component("swatches", Swatches);
Vue.component("draggable", Draggable);
Vue.component("color-picker", Sketch);

Vue.config.productionTip = false;

// Import the plugin here
import { FirebasePlugin } from "./auth/firebase-auth";

// Install the authentication plugin here
Vue.use(FirebasePlugin, {
  onRedirectCallback: (appState) => {
    console.log("dashboard route added", router, Vue.$cookies.get("pathname"));
    if (Vue.$cookies.get("pathname")) {
      router.push(Vue.$cookies.get("pathname"));
    } else {
      router.push("/dashboard");
    }
  },
});

// Vue.use(GmapVue, {
//   load: {
//     // [REQUIRED] This is the unique required value by Google Maps API
//     key: 'AIzaSyDImTB4d3f21Hp1Md0lB0vq3h59h4PE13Q',
//     libraries: 'places',
//   },
//   installComponents: true,
// });
new Vue({
  router,
  vuetify,
  axios,
  store,
  async created() {
    await this.$store.dispatch("fetchLoans");
  },
  render: (h) => h(App),
}).$mount("#app");
