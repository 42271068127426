<template>
  <div style="margin-left: 56px" class="main-div">
    <v-container class="conatiner-section">
      <v-sheet :elevation="5" dark class="mx-auto pa-5">
        <v-snackbar
          v-model="snackbar"
          :timeout="snackBarTimeout"
          :color="color"
          >{{ snackBarText }}</v-snackbar
        >
        <v-form v-model="valid" ref="form">
          <v-container>
            <v-autocomplete
              v-model="autoconfigSearchModel"
              :items="autoconfigItems"
              :loading="autoconfigIsLoading"
              :search-input.sync="autoconfigSearch"
              hide-no-data
              hide-selected
              item-text="Name"
              item-value="Id"
              label="AutoConfig Id"
              :rules="[(v) => !!v || 'AutoConfig is required']"
              placeholder="Start typing to Search AutoConfig"
              return-object
              :readonly="isPreview"
              outlined
              color="white"
            ></v-autocomplete>

            <v-autocomplete
              v-model="cmpSearchModel"
              :items="cmpItems"
              :loading="cmpIsLoading"
              :search-input.sync="cmpSearch"
              hide-no-data
              hide-selected
              item-text="Name"
              item-value="Id"
              label="Company Id"
              :rules="[(v) => !!v || 'Company is required']"
              placeholder="Start typing to Search Company"
              return-object
              :readonly="isPreview"
              outlined
              color="white"
            ></v-autocomplete>

            <v-text-field
              :readonly="isPreview"
              v-model="Name"
              label="Name"
              :rules="[(v) => !!v || 'Name is required']"
              required
              outlined
              color="white"
            ></v-text-field>

            <!-- <v-text-field
              :readonly="isPreview"
              v-model="CronSchedule"
              label="CronSchedule"
              :rules="[(v) => !!v || 'CronSchedule is required']"
              outlined
              color="white"
            ></v-text-field> -->

            <v-text-field
              :readonly="isPreview"
              v-model="cronMinute"
              label="Minute"
              outlined
              color="white"
            ></v-text-field>

            <v-text-field
              :readonly="isPreview"
              v-model="cronHour"
              label="Hour"
              outlined
              color="white"
            ></v-text-field>

            <v-text-field
              :readonly="isPreview"
              v-model="cronMonthDay"
              label="MonthDay"
              outlined
              color="white"
            ></v-text-field>

            <v-text-field
              :readonly="isPreview"
              v-model="cronMonth"
              label="Month"
              outlined
              color="white"
            ></v-text-field>

            <v-text-field
              :readonly="isPreview"
              v-model="cronWeekDay"
              label="WeekDay"
              outlined
              color="white"
            ></v-text-field>

            <v-card-actions>
              <v-spacer />
              <v-btn
                outlined
                color="white"
                @click="$router.push({ name: 'schedule' })"
                >{{ isPreview ? "Back" : "Cancel" }}</v-btn
              >
              <v-btn color="blue darken-1" v-if="!isPreview" @click="create">{{
                isEdit ? "Update" : "Create"
              }}</v-btn>
            </v-card-actions>
          </v-container>
        </v-form>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AutoScheduleForm",
  components: {},
  props: ["id", "preview"],
  data() {
    return {
      isEdit: false,
      isPreview: false,
      valid: false,
      Name: "",
      CronSchedule: "",

      cronMinute: null,
      cronHour: null,
      cronMonthDay: null,
      cronMonth: null,
      cronWeekDay: null,

      //configSearch
      autoconfigSearch: null,
      autoconfigSearchModel: null,
      autoconfigEntries: [],
      autoconfigIsLoading: false,

      //companySearch
      cmpSearch: null,
      cmpSearchModel: null,
      cmpEntries: [],
      cmpIsLoading: false,

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,
    };
  },
  computed: {
    autoconfigFields() {
      if (!this.autoconfigSearchModel) return [];

      return Object.keys(this.autoconfigSearchModel).map((key) => {
        return {
          key,
          value: this.autoconfigSearchModel[key] || "n/a",
        };
      });
    },
    autoconfigItems() {
      return this.autoconfigEntries.map((entry) => {
        const Name = entry.Name;
        return Object.assign({}, entry, { Name });
      });
    },
    cmpFields() {
      if (!this.cmpSearchModel) return [];

      return Object.keys(this.cmpSearchModel).map((key) => {
        return {
          key,
          value: this.cmpSearchModel[key] || "n/a",
        };
      });
    },
    cmpItems() {
      return this.cmpEntries.map((entry) => {
        const Name = entry.Name;
        return Object.assign({}, entry, { Name });
      });
    },
  },
  watch: {
    autoconfigSearch(val) {
      if (
        this.autoconfigSearch !=
        (this.autoconfigSearchModel && this.autoconfigSearchModel.Name)
      ) {
        if (!val) {
          return;
        }
        this.clearautoconfigEntries();
        this.autoconfigIsLoading = true;
        this.fetchAutoconfigEntriesDebounced();
      }
    },
    cmpSearch(val) {
      if (this.cmpSearch != (this.cmpSearchModel && this.cmpSearchModel.Name)) {
        if (!val) {
          return;
        }
        this.clearcmpEntries();
        this.cmpIsLoading = true;
        this.fetchCmpEntriesDebounced();
      }
    },
  },
  methods: {
    async create() {
      if (this.$refs.form.validate()) {
        await this.convertTimeToCron(
          this.cronMinute,
          this.cronHour,
          this.cronMonthDay,
          this.cronMonth,
          this.cronWeekDay
        );

        if (this.isEdit) {
          //Update
          axios
            .put(
              `${process.env.VUE_APP_BASEURL}/scheduler/autoschedule/${this.id}`,
              {
                DataToUpdate: {
                  Name: this.Name,
                  CronSchedule: this.CronSchedule,
                  AutoConfigId: this.autoconfigSearchModel
                    ? this.autoconfigSearchModel.Id
                    : null,
                  CompanyId: this.cmpSearchModel
                    ? this.cmpSearchModel.Id
                    : null,
                },
              }
            )
            .then((response) => {
              if (response.data.status) {
                this.color = "success";
                this.snackBarText = response.data.message;
                this.snackbar = true;
                this.$router.push({
                  name: "schedule",
                });
              } else {
                this.color = "error";
                this.snackBarText = response.data.message;
                this.snackbar = true;
              }
            });
        } else {
          //Create
          axios
            .post(`${process.env.VUE_APP_BASEURL}/scheduler/autoschedule`, {
              Name: this.Name,
              CronSchedule: this.CronSchedule,
              AutoConfigId: this.autoconfigSearchModel
                ? this.autoconfigSearchModel.Id
                : null,
              CompanyId: this.cmpSearchModel ? this.cmpSearchModel.Id : null,
            })
            .then((response) => {
              if (response.data.status) {
                this.color = "success";
                this.snackBarText = response.data.message;
                this.snackbar = true;
                this.$router.push({
                  name: "schedule",
                });
              } else {
                this.color = "error";
                this.snackBarText = response.data.message;
                this.snackbar = true;
              }
            });
        }
      }
    },
    clearautoconfigEntries() {
      this.autoconfigEntries = [];
    },
    fetchAutoconfigEntriesDebounced() {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchAutoconfigEntries();
      }, 500);
    },
    fetchAutoconfigEntries() {
      axios
        .get(
          process.env.VUE_APP_BASEURL +
            "/scheduler/autoconfig?search=" +
            this.autoconfigSearch
        )
        .then((response) => {
          if (response.data.status) {
            this.autoconfigEntries = response.data.data.configs;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.autoconfigIsLoading = false));
    },
    clearcmpEntries() {
      this.cmpEntries = [];
    },
    fetchCmpEntriesDebounced() {
      clearTimeout(this._searchCmpTimerId);
      this._searchCmpTimerId = setTimeout(() => {
        this.fetchCmpEntries();
      }, 500);
    },
    fetchCmpEntries() {
      axios
        .get(process.env.VUE_APP_BASEURL + "/company?search=" + this.cmpSearch)
        .then((response) => {
          if (response.data.status) {
            this.cmpEntries = response.data.data.companies;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.cmpIsLoading = false));
    },
    convertCronToTime(cron) {
      let cronArr = cron.split(" ");
      this.cronMinute = cronArr[0] == "*" ? null : cronArr[0];
      this.cronHour = cronArr[1] == "*" ? null : cronArr[1];
      this.cronMonthDay = cronArr[2] == "*" ? null : cronArr[2];
      this.cronMonth = cronArr[3] == "*" ? null : cronArr[3];
      this.cronWeekDay = cronArr[4] == "*" ? null : cronArr[4];
    },
    convertTimeToCron(
      cronMinute,
      cronHour,
      cronMonthDay,
      cronMonth,
      cronWeekDay
    ) {
      cronMinute = cronMinute == "*" || cronMinute == null ? "*" : cronMinute;
      cronHour = cronHour == "*" || cronHour == null ? "*" : cronHour;
      cronMonthDay =
        cronMonthDay == "*" || cronMonthDay == null ? "*" : cronMonthDay;
      cronMonth = cronMonth == "*" || cronMonth == null ? "*" : cronMonth;
      cronWeekDay =
        cronWeekDay == "*" || cronWeekDay == null ? "*" : cronWeekDay;
      this.CronSchedule =
        cronMinute +
        " " +
        cronHour +
        " " +
        cronMonthDay +
        " " +
        cronMonth +
        " " +
        cronWeekDay;
    },
  },
  mounted() {
    if (typeof this.id !== "undefined") {
      if (this.preview == true) {
        this.isPreview = true;
      } else {
        this.isEdit = true;
      }
      axios
        .get(process.env.VUE_APP_BASEURL + "/scheduler/autoschedule/" + this.id)
        .then((response) => {
          if (response.data.status) {
            let configdata = response.data.data.autoSchedule;
            this.Name = configdata.Name;
            this.convertCronToTime(configdata.CronSchedule);
            //this.CronSchedule = configdata.CronSchedule;
            if (configdata.CompanyId) {
              this.cmpSearch = configdata.Company.Name;
              this.cmpSearchModel = configdata.Company;
              this.cmpEntries.push(configdata.Company);
            }
            if (configdata.AutoConfigId) {
              this.autoconfigSearchModel = configdata.AutoConfig;
              this.autoconfigSearch = configdata.AutoConfig.Name;
              this.autoconfigEntries.push(configdata.AutoConfig);
            }
          }
        });
    }
  },
};
</script>
<style scoped>
@media (max-width: 991px) {
  .main-div {
    margin: 5px !important;
  }
}
</style>