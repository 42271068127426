<template>
  <div style="margin-left: 56px" class="main-div">
    <v-container class="conatiner-section">
      <v-sheet :elevation="5" dark class="mx-auto pa-5">
        <v-snackbar
          v-model="snackbar"
          :timeout="snackBarTimeout"
          :color="color"
          >{{ snackBarText }}</v-snackbar
        >
        <v-form v-model="valid" ref="form">
          <v-container>
            <v-select
              v-if="autoClass != null"
              v-model="AutoClassName"
              :items="autoClassArray"
              label="Auto Class"
              :rules="[(v) => !!v || 'Auto Class is required']"
              required
              outlined
              color="white"
              :readonly="isPreview"
            ></v-select>

            <v-autocomplete
              v-model="projSearchModel"
              :items="projItems"
              :loading="projIsLoading"
              :search-input.sync="projSearch"
              hide-no-data
              hide-selected
              item-text="Name"
              item-value="Id"
              label="HermosaProject Id"
              :rules="[(v) => !!v || 'Project is required']"
              placeholder="Start typing to Search Project"
              return-object
              :readonly="isPreview"
              outlined
              color="white"
            ></v-autocomplete>

            <v-autocomplete
              v-model="cmpSearchModel"
              :items="cmpItems"
              :loading="cmpIsLoading"
              :search-input.sync="cmpSearch"
              hide-no-data
              hide-selected
              item-text="Name"
              item-value="Id"
              label="Company Id"
              :rules="[(v) => !!v || 'Company is required']"
              placeholder="Start typing to Search Company"
              return-object
              :readonly="isPreview"
              outlined
              color="white"
            ></v-autocomplete>

            <v-text-field
              :readonly="isPreview"
              v-model="Name"
              :rules="[(v) => !!v || 'Name is required']"
              label="Name"
              required
              outlined
              color="white"
            ></v-text-field>

            <v-text-field
              :readonly="isPreview"
              v-model="MinHoursBetweenRuns"
              type="number"
              label="MinHoursBetweenRuns"
              outlined
              color="white"
            ></v-text-field>

            <v-text-field
              :readonly="isPreview"
              v-model="InstanceBatchSize"
              type="number"
              label="InstanceBatchSize"
              outlined
              color="white"
            ></v-text-field>

            <div class="config-details">
              <v-subheader class="mb-2 pa-0">Config JSON</v-subheader>
              <MonacoEditor
                v-model="ConfigJson"
                width="100%"
                height="200"
                theme="vs-dark"
                language="json"
                class="mb-12"
                :options="options"
              ></MonacoEditor>
            </div>

            <v-text-field
              :readonly="isPreview"
              v-model="HermosaProjectVersion"
              type="number"
              label="HermosaProjectVersion"
              outlined
              color="white"
            ></v-text-field>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="white"
                outlined
                @click="$router.push({ name: 'schedule' })"
                >{{ isPreview ? "Back" : "Cancel" }}</v-btn
              >

              <v-btn
                v-if="!isPreview"
                color="green accent-2"
                class="black--text"
                @click="create"
                >{{ isEdit ? "Update" : "Create" }}</v-btn
              >
            </v-card-actions>

            <div class="configassign-section mt-5" style="position: relative">
              <h1 class="mb-3">AutoConfig Assignment List</h1>
              <v-btn
                dark
                fab
                absolute
                right
                small
                color="green accent-2"
                class="black--text"
                @click="addItem"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-data-table
                loading-text="Loading... Please wait"
                v-model="selectedConfigAssign"
                :headers="headersConfigAssign"
                :items="configAssign"
                item-key="Id"
                :items-per-page="10"
                class="elevation-1"
              >
                <template v-slot:item.actions="{ item }">
                  <v-menu bottom right>
                    <template v-slot:activator="{ on }">
                      <v-btn dark icon v-on="on">
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(listitem, i) in items"
                        :key="i"
                        @click="triggerClick(listitem.clickEvent, item)"
                      >
                        <v-list-item-title>{{
                          listitem.title
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </div>
          </v-container>
        </v-form>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import MonacoEditor from "monaco-editor-vue";

export default {
  components: {
    MonacoEditor,
  },
  name: "AutoConfigForm",
  props: ["id", "preview"],
  data() {
    return {
      items: [
        { title: "Preview", clickEvent: "previewItem" },
        { title: "Edit", clickEvent: "editItem" },
        { title: "Delete", clickEvent: "deleteItem" },
      ],
      isEdit: false,
      isPreview: false,
      valid: false,
      AutoClassName: null,
      Name: "",
      MinHoursBetweenRuns: 0,
      ConfigJson: null,
      HermosaProjectVersion: 0,
      InstanceBatchSize: 0,

      options: {
        //Monaco Editor Options
      },

      //projectSearch
      projSearch: null,
      projSearchModel: null,
      projEntries: [],
      projIsLoading: false,

      //companySearch
      cmpSearch: null,
      cmpSearchModel: null,
      cmpEntries: [],
      cmpIsLoading: false,

      autoClass: [],
      autoClassArray: [],

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,

      //config assignment
      selectedConfigAssign: [],
      headersConfigAssign: [
        {
          text: "ConfigAssign ID",
          align: "left",
          value: "AutoConfigId",
        },
        { text: "id", value: "Id" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      configAssign: [],
    };
  },
  computed: {
    projFields() {
      if (!this.projSearchModel) return [];

      return Object.keys(this.projSearchModel).map((key) => {
        return {
          key,
          value: this.projSearchModel[key] || "n/a",
        };
      });
    },
    projItems() {
      return this.projEntries.map((entry) => {
        const Name = entry.Name;
        return Object.assign({}, entry, { Name });
      });
    },
    cmpFields() {
      if (!this.cmpSearchModel) return [];

      return Object.keys(this.cmpSearchModel).map((key) => {
        return {
          key,
          value: this.cmpSearchModel[key] || "n/a",
        };
      });
    },
    cmpItems() {
      return this.cmpEntries.map((entry) => {
        const Name = entry.Name;
        return Object.assign({}, entry, { Name });
      });
    },
  },
  watch: {
    projSearch(val) {
      if (
        this.projSearch != (this.projSearchModel && this.projSearchModel.Name)
      ) {
        if (!val) {
          return;
        }
        this.clearprojEntries();
        this.projIsLoading = true;
        this.fetchProjEntriesDebounced();
      }
    },
    cmpSearch(val) {
      if (this.cmpSearch != (this.cmpSearchModel && this.cmpSearchModel.Name)) {
        if (!val) {
          return;
        }
        this.clearcmpEntries();
        this.cmpIsLoading = true;
        this.fetchCmpEntriesDebounced();
      }
    },
  },
  methods: {
    triggerClick(function_name, item) {
      this[function_name](item);
    },
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    create() {
      let isValidJson = true;
      if (this.ConfigJson != null) {
        isValidJson = this.isJsonString(this.ConfigJson);
        !isValidJson && alert("Please enter valid ConfigJson");
      }
      if (this.$refs.form.validate() && isValidJson) {
        let selectedClass = null;
        if (this.AutoClassName != null) {
          selectedClass = this.autoClass.find(
            (e) => e.Name == this.AutoClassName
          );
        }
        if (this.isEdit) {
          //Update
          axios
            .put(
              `${process.env.VUE_APP_BASEURL}/scheduler/autoconfig/${this.id}`,
              {
                DataToUpdate: {
                  Name: this.Name,
                  AutoClassId: selectedClass ? selectedClass.Id : null,
                  MinHoursBetweenRuns: this.MinHoursBetweenRuns,
                  ...(this.ConfigJson != null
                    ? { ConfigJson: this.ConfigJson }
                    : {}),
                  HermosaProjectVersion: this.HermosaProjectVersion,
                  InstanceBatchSize: this.InstanceBatchSize,
                  HermosaProjectId: this.projSearchModel
                    ? this.projSearchModel.Id
                    : null,
                  CompanyId: this.cmpSearchModel
                    ? this.cmpSearchModel.Id
                    : null,
                },
              }
            )
            .then((response) => {
              if (response.data.status) {
                this.color = "success";
                this.snackBarText = response.data.message;
                this.snackbar = true;
                this.$router.push({
                  name: "schedule",
                });
              } else {
                this.color = "error";
                this.snackBarText = response.data.message;
                this.snackbar = true;
              }
            });
        } else {
          //Create
          axios
            .post(`${process.env.VUE_APP_BASEURL}/scheduler/autoconfig`, {
              Name: this.Name,
              AutoClassId: selectedClass ? selectedClass.Id : null,
              MinHoursBetweenRuns: this.MinHoursBetweenRuns,
              ...(this.ConfigJson != null
                ? { ConfigJson: this.ConfigJson }
                : {}),
              HermosaProjectVersion: this.HermosaProjectVersion,
              InstanceBatchSize: this.InstanceBatchSize,
              HermosaProjectId: this.projSearchModel
                ? this.projSearchModel.Id
                : null,
              CompanyId: this.cmpSearchModel ? this.cmpSearchModel.Id : null,
            })
            .then((response) => {
              if (response.data.status) {
                this.color = "success";
                this.snackBarText = response.data.message;
                this.snackbar = true;
                this.$router.push({
                  name: "schedule",
                });
              } else {
                this.color = "error";
                this.snackBarText = response.data.message;
                this.snackbar = true;
              }
            });
        }
      }
    },
    getAutoClass() {
      axios
        .get(process.env.VUE_APP_BASEURL + "/scheduler/autoclass")
        .then((response) => {
          if (response.data.status) {
            this.autoClass =
              response.data.data.autoClasses.length > 0
                ? response.data.data.autoClasses
                : [];
            this.autoClass.length > 0 &&
              this.autoClass.map((el) => {
                this.autoClassArray.push(el.Name);
              });
          }
        });
    },
    //Autoconfig assign start
    getConfigAssign() {
      axios
        .get(process.env.VUE_APP_BASEURL + "/scheduler/autoconfigassignment", {
          params: {
            autoConfigId: this.id,
          },
        })
        .then((response) => {
          if (response.data.status) {
            this.configAssign =
              response.data.data.configs.length > 0
                ? response.data.data.configs
                : [];
          }
        });
    },
    addItem() {
      this.$router.push({
        name: "autoConfigAssignForm",
        params: {
          autoConfigId: this.id,
        },
      });
    },
    deleteItem(item) {
      confirm("Are you sure you want to delete this item?") &&
        axios
          .delete(
            process.env.VUE_APP_BASEURL +
              `/scheduler/autoconfigassignment/${item.Id}`
          )
          .then((response) => {
            if (response) {
              if (response.data.status) {
                this.color = "success";
                this.snackBarText = response.data.message;
                this.snackbar = true;
                this.getConfigAssign();
              } else {
                this.color = "error";
                this.snackBarText = response.data.message;
                this.snackbar = true;
              }
            }
          });
    },
    editItem(item) {
      this.$router.push({
        name: "autoConfigAssignForm",
        params: {
          id: item.Id,
        },
      });
    },
    previewItem(item) {
      this.$router.push({
        name: "autoConfigAssignForm",
        params: {
          id: item.Id,
          preview: true,
        },
      });
    },
    //Autoconfig assign end
    clearprojEntries() {
      this.projEntries = [];
    },
    fetchProjEntriesDebounced() {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchProjEntries();
      }, 500);
    },
    fetchProjEntries() {
      axios
        .get(
          process.env.VUE_APP_BASEURL +
            "/hermosa/project?search=" +
            this.projSearch
        )
        .then((response) => {
          if (response.data.status) {
            this.projEntries = response.data.data.projects;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.projIsLoading = false));
    },
    clearcmpEntries() {
      this.cmpEntries = [];
    },
    fetchCmpEntriesDebounced() {
      clearTimeout(this._searchCmpTimerId);
      this._searchCmpTimerId = setTimeout(() => {
        this.fetchCmpEntries();
      }, 500);
    },
    fetchCmpEntries() {
      axios
        .get(process.env.VUE_APP_BASEURL + "/company?search=" + this.cmpSearch)
        .then((response) => {
          if (response.data.status) {
            this.cmpEntries = response.data.data.companies;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.cmpIsLoading = false));
    },
  },
  mounted() {
    this.getAutoClass();
    if (typeof this.id !== "undefined") {
      if (this.preview == true) {
        this.isPreview = true;
      } else {
        this.isEdit = true;
      }
      this.getConfigAssign();
      axios
        .get(process.env.VUE_APP_BASEURL + "/scheduler/autoconfig/" + this.id)
        .then((response) => {
          if (response.data.status) {
            let configdata = response.data.data.config;
            this.Name = configdata.Name;
            this.MinHoursBetweenRuns = configdata.MinHoursBetweenRuns
              ? configdata.MinHoursBetweenRuns
              : 0;
            this.HermosaProjectVersion = configdata.HermosaProjectVersion
              ? configdata.HermosaProjectVersion
              : 0;
            this.InstanceBatchSize = configdata.InstanceBatchSize
              ? configdata.InstanceBatchSize
              : 0;
            if (configdata.AutoClassId) {
              this.AutoClassName = configdata.AutoClass.Name;
            }
            if (configdata.CompanyId) {
              this.cmpSearch = configdata.Company.Name;
              this.cmpSearchModel = configdata.Company;
              this.cmpEntries.push(configdata.Company);
            }
            if (configdata.HermosaProjectId) {
              this.projSearchModel = configdata.HermosaProject;
              this.projSearch = configdata.HermosaProject.Name;
              this.projEntries.push(configdata.HermosaProject);
            }
            this.ConfigJson = configdata.ConfigJson;
          }
        });
    }
  },
};
</script>
<style scoped>
@media (max-width: 991px) {
  .main-div {
    margin: 5px !important;
  }
}
</style>
