<!-- Loader.vue -->
<template>
  <v-overlay opacity=".95" dark>
    <div style="margin-left: 150px;"><img src="@/assets/crebrid-icon.png" width="80" class="mb-7" /></div>
    <br />
    <div class="d-flex flex-wrap align-center justify-center">
      <div class="loading-container">
        <div class="progress-bar">
          <div class="progress-bar-inner" :style="{ width: progress + '%' }">
            {{ progress }}%
          </div>
        </div>
        <p class="white--text">
          Automate data and configuration are loading...
        </p>
      </div>
    </div>
  </v-overlay>
</template>
  
  <script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.loading-container {
  text-align: center;
  color: white;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  margin-top: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar-inner {
  height: 100%;
  background: linear-gradient(90deg, #85ff66, #5cf496);
  text-align: center;
  line-height: 20px; /* Adjust as needed */
  color: white;
}

p {
  width: 100%;
  text-align: center;
  color: white;
  padding: 0 24px;
}
</style>
      