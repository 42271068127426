<template>
  <div style="margin-left: 56px" class="main-div">
    <v-snackbar v-model="snackbar" :timeout="snackBarTimeout" :color="color">{{
      snackBarText
    }}</v-snackbar>
    <div class="mx-auto py-5 px-10 full-height">
      <div v-if="addSnapCard">
        <v-card>
          <v-toolbar dark>
            <v-btn icon dark @click="addSnapCard = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Create New Snap Version</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="addSnapVersion">Save</v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <div>
            <v-sheet :elevation="5" dark class="my-5 mx-auto py-5 px-10">
              <v-select
                v-model="versionSnapModel.Environment"
                :items="envArray"
                outlined
                color="white"
                class="mt-8"
                label="Environment"
              ></v-select>

              <!-- <v-text-field
                :readonly="true"
                v-model="versionSnapModel.ClonedFromVersion"
                type="number"
                label="ClonedFromVersion"
              ></v-text-field> -->
            </v-sheet>

            <v-sheet :elevation="5" dark class="my-5 mx-auto py-5 px-10">
              <v-card>
                <v-card-text>
                  <v-textarea
                    label="Description"
                    v-model="versionSnapModel.Description"
                    color="white"
                    outlined
                  ></v-textarea>
                </v-card-text>
              </v-card>
            </v-sheet>

            <v-sheet :elevation="5" dark class="my-5 mx-auto py-5 px-10">
              <div class="snap-config-details" v-if="versionSnapConfig != null">
                <v-subheader class="mb-2 pa-0">Snap Config JSON</v-subheader>

                <MonacoEditor
                  v-model="versionSnapConfig"
                  width="100%"
                  height="500"
                  theme="vs-dark"
                  language="javascript"
                  :options="options"
                ></MonacoEditor>
              </div>
            </v-sheet>

            <v-sheet :elevation="5" dark class="my-5 mx-auto py-5 px-10">
              <div class="code-details" v-if="versionCode != null">
                <v-subheader class="mb-2 pa-0">Code</v-subheader>

                <MonacoEditor
                  v-model="versionCode"
                  width="100%"
                  height="500"
                  theme="vs-dark"
                  language="javascript"
                  :options="options"
                ></MonacoEditor>
              </div>
            </v-sheet>
          </div>
        </v-card>
      </div>

      <div v-if="addSnapCard == false">
        <v-sheet
          v-if="this.snapVersionList && this.snapVersionList.length > 0"
          :elevation="5"
          dark
          class="my-5 mx-auto py-5 px-10"
          style="position: relative"
        >
          <v-btn
            absolute
            dark
            fab
            top
            left
            color="green accent-2"
            @click="addSnapCard = true"
          >
            <v-icon color="black">mdi-plus</v-icon>
          </v-btn>

          <div class="snap-version pt-10">
            <v-select
              :items="this.snapVersionList"
              outlined
              color="white"
              label="Select Snap Version"
              @change="showSnap"
            ></v-select>
          </div>

          <div v-if="selectedVersion != null" class="snap-details">
            <v-select
              v-model="snapModel.Environment"
              :items="envArray"
              outlined
              color="white"
              label="Environment"
              class="mt-3"
            ></v-select>

            <v-text-field
              v-if="snapModel.ClonedFromVersion"
              :readonly="true"
              v-model="snapModel.ClonedFromVersion"
              outlined
              color="white"
              class="mt-3"
              type="number"
              label="Cloned From Version"
            ></v-text-field>
          </div>
        </v-sheet>

        <v-sheet
          v-if="selectedVersion != null"
          :elevation="5"
          dark
          class="my-5 mx-auto py-5 px-10"
        >
          <v-card>
            <v-card-text>
              <v-textarea
                label="Description"
                v-model="snapModel.Description"
                color="white"
                outlined
              ></v-textarea>
            </v-card-text>
          </v-card>
        </v-sheet>

        <v-sheet
          v-if="snapConfig != null"
          :elevation="5"
          dark
          class="my-5 mx-auto pt-5 pb-10 px-10"
        >
          <div class="snap-config-details">
            <v-subheader class="mb-2 pa-0">Snap Config JSON</v-subheader>

            <MonacoEditor
              v-model="snapConfig"
              width="100%"
              height="200"
              theme="vs-dark"
              language="json"
              :options="options"
            ></MonacoEditor>
          </div>
        </v-sheet>

        <v-sheet
          v-if="code != null"
          :elevation="5"
          dark
          class="my-5 mx-auto pt-5 pb-10 px-10"
        >
          <div class="code-details">
            <v-subheader class="mb-2 pa-0">Code</v-subheader>

            <MonacoEditor
              v-model="code"
              width="100%"
              height="500"
              theme="vs-dark"
              language="javascript"
              :options="options"
            ></MonacoEditor>
          </div>
        </v-sheet>

        <v-btn
          v-if="selectedVersion != null"
          fixed
          dark
          fab
          bottom
          right
          color="green accent-2"
          @click="snapDialog = true"
        >
          <v-icon color="black">mdi-content-save</v-icon>
        </v-btn>

        <v-dialog v-model="snapDialog" max-width="300px">
          <v-card class="pa-5" dark>
            <v-card-title class="justify-center mb-5">
              <span class="headline">Confirm Update</span>
            </v-card-title>
            <v-card-actions class="justify-center">
              <v-btn color="white" outlined @click="snapDialog = false"
                >Close</v-btn
              >
              <v-btn color="blue darken-1" @click="updateSnap">Update</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MonacoEditor from "monaco-editor-vue";

export default {
  components: {
    MonacoEditor,
  },
  name: "Snap",
  props: ["id"],
  data() {
    return {
      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,

      snapVersionList: [],
      snapVersionData: [],
      selectedVersion: null,
      list: [],

      snapDialog: false,

      snapid: null,

      options: {
        //Monaco Editor Options
      },

      code: null,
      snapConfig: null,
      snapModel: {},
      envArray: ["Dev", "Prod", "OptionalUpgrade", "MandatoryUpgrade"],

      //version add params
      addSnapCard: false,
      versionSnapModel: {},
      versionCode: "// Your code goes here",
      versionSnapConfig: "// Your code goes here",
    };
  },
  mounted() {
    this.snapid = this.id;
    if (this.snapid) {
      this.loadSnap();
    }
  },
  methods: {
    loadSnap() {
      axios
        .get(
          `${process.env.VUE_APP_BASEURL}/hermosa/snap/${this.snapid}/version`
        )
        .then((response) => {
          if (response) {
            this.snapVersionData =
              response.data.data.snapVersions &&
              response.data.data.snapVersions.length > 0
                ? response.data.data.snapVersions
                : [];

            if (this.snapVersionData && this.snapVersionData.length > 0) {
              this.snapVersionData.map((e) => {
                this.snapVersionList.push(e.Version);
              });
            }
          }
        });
    },

    showSnap(e) {
      if (e) {
        this.snapModel = {};
        this.selectedVersion = e;
        const index = this.snapVersionData.findIndex(
          (moduleObj) => moduleObj.Version == e
        );
        this.code = this.snapVersionData[index].Code
          ? this.snapVersionData[index].Code
          : null;
        setTimeout(() => {
          this.snapModel = this.snapVersionData[index];
          this.snapConfig = this.snapVersionData[index].SnapConfigJson;
        });
      }
    },

    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },

    updateSnap() {
      let isValidJson = false;
      if (this.snapConfig != null) {
        isValidJson = this.isJsonString(this.snapConfig);
        !isValidJson && alert("Please enter valid Json Data");
      }

      if (this.code != null && isValidJson) {
        axios
          .put(
            `${process.env.VUE_APP_BASEURL}/hermosa/snap/${this.snapid}/version/${this.selectedVersion}`,
            {
              code: this.code,
              environment: this.snapModel.Environment,
              ...(this.snapModel.ClonedFromVersion
                ? { clonedFrom: this.snapModel.ClonedFromVersion }
                : {}),
              snapConfigJson: this.snapConfig,
              description: this.snapModel.Description,
              //note: this.snapModel.Note
            }
          )
          .then((response) => {
            if (response) {
              if (response.data.status) {
                this.color = "success";
                this.snackBarText = response.data.message;
                this.snackbar = true;
                //this.$router.go();
              } else {
                this.color = "error";
                this.snackBarText = response.data.message;
                this.snackbar = true;
              }
            }
          });
      } else {
        this.color = "error";
        this.snackBarText = "Please enter code";
        this.snackbar = true;
      }

      this.snapDialog = false;
    },

    addSnapVersion() {
      if (this.versionCode != null) {
        let version = this.snapVersionList.length + 1;
        axios
          .put(
            `${process.env.VUE_APP_BASEURL}/hermosa/snap/${this.snapid}/version/${version}`,
            {
              code: this.versionCode,
              environment: this.versionSnapModel.Environment,
              ...(this.versionSnapModel.ClonedFromVersion
                ? { clonedFrom: this.versionSnapModel.ClonedFromVersion }
                : {}),
              snapConfigJson: this.versionSnapConfig,
              description: this.versionSnapModel.Description,
              //note: this.versionSnapModel.Note
            }
          )
          .then((response) => {
            if (response) {
              if (response.data.status) {
                this.color = "success";
                this.snackBarText = response.data.message;
                this.snackbar = true;
                this.$router.go();
              } else {
                this.color = "error";
                this.snackBarText = response.data.message;
                this.snackbar = true;
              }
            }
          });
      } else {
        this.color = "error";
        this.snackBarText = "Please enter code";
        this.snackbar = true;
      }
    },

    onChange(value) {
      console.log(value);
    },
  },
};
</script>
<style scoped>
.full-height {
  min-height: 100vh;
}

.v-data-table-header {
  background: #666666 !important;
}
.v-data-table-header tr th {
  font-size: 15px;
}
.v-data-table td,
.v-data-table th {
  padding: 10px 16px;
}
.snap-version .v-input,
.snap-details input {
  width: 50% !important;
}
.CodeMirror {
  height: auto !important;
  margin: 10px !important;
}
.v-text-field .v-label {
  overflow: visible !important;
  top: 1% !important;
}
.label {
  color: #c7c7c7 !important;
}
label {
  display: block;
  margin: 5px;
  padding: 5px;
}
.btn {
  margin: 5px;
}

@media (max-width: 991px) {
  .main-div {
    margin-left: 0 !important;

    .full-height {
      padding: 40px !important;

      .snap-version .v-select {
        width: auto !important;
      }
       
      .v-sheet {
        padding: 10px !important;
        .v-sheet {
          padding: 0 !important;
        }
      }
    }
  }
}
</style>
