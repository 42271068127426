<template>
  <div>
    <div v-if="loading">
      <loader />
    </div>

    <div v-else class="main-div" style="margin-left: 56px">
      <v-snackbar v-model="snackbar" :timeout="snackBarTimeout" :color="color">
        {{ snackBarText }}
      </v-snackbar>

      <v-btn
        dark
        fab
        fixed
        bottom
        right
        color="green accent-2"
        @click="snapDialog = true"
      >
        <v-icon color="black">mdi-plus</v-icon>
      </v-btn>

      <div class="mx-auto rounded-0 full-height" v-if="items != null">
        <v-sheet dark class="pa-12">
          <v-text-field
            v-model="search"
            label="Search Snaps..."
            dark
            flat
            solo-inverted
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>
        </v-sheet>

        <v-sheet
          :elevation="5"
          dark
          class="my-12 mx-12 py-5 px-10 main-content"
          style="position: relative"
        >
          <v-card-text>
            <v-treeview
              :items="items"
              :search="search"
              :filter="filter"
              :open.sync="open"
              :hoverable="hoverable"
            >
              <template v-slot:prepend="{ item }">
                <v-icon v-if="item.children">mdi-folder</v-icon>
                <span
                  v-if="!item.children"
                  class="item"
                  @click="rowClick(item)"
                >
                  <v-icon small>mdi-launch</v-icon>
                </span>
              </template>
            </v-treeview>
          </v-card-text>
        </v-sheet>
      </div>

      <v-dialog v-model="snapDialog" max-width="600px">
        <v-card class="pa-5" dark>
          <v-card-title class="justify-center mb-5">
            <span class="headline">Create New Snap</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Snap Name*"
                    v-model="snapObject.name"
                    :rules="[(v) => !!v || 'Name is required']"
                    required
                    outlined
                    color="white"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Snap Namespace*"
                    v-model="snapObject.namespace"
                    :rules="[(v) => !!v || 'Namespace is required']"
                    required
                    outlined
                    color="white"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="white" text @click="snapDialog = false">Close</v-btn>
            <v-btn
              color="green accent-2"
              class="black--text"
              :disabled="!snapObject.name || !snapObject.namespace"
              @click="addSnap"
              >Create</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../../components/Loader.vue";

export default {
  name: "Snaps",
  components: {
    Loader,
  },
  data() {
    return {
      loading: true,

      snaps: [],

      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,

      snapDialog: false,
      snapObject: {
        name: "",
        namespace: "",
        clonedFrom: null,
        snapConfigJson: "",
        code: "",
        note: "",
        environment: "",
        description: "",
      },
      items: null,
      hoverable: true,
      open: [],
      search: null,
    };
  },
  computed: {
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
  },
  methods: {
    rowClick(item) {
      if (item) {
        this.$router.push({
          name: "snap",
          params: {
            id: item.Id,
          },
        });
      }
    },

    addSnap(e) {
      axios
        .put(`${process.env.VUE_APP_BASEURL}/hermosa/snap/new/version/1`, {
          name: this.snapObject.name,
          namespace: this.snapObject.namespace,
          //...this.snapObject
        })
        .then((response) => {
          if (response) {
            if (response.data.status) {
              this.getSnaps();
              this.snapDialog = false;
              this.color = "success";
              this.snackBarText = response.data.message;
            } else {
              this.color = "error";
              this.snackBarText = response.data.message;
            }
            this.snackbar = true;
          }
        });
    },

    getSnaps() {
      axios
        .get(process.env.VUE_APP_BASEURL + "/hermosa/snap")
        .then((response) => {
          if (response) {
            this.snaps =
              response.data.data.snaps.length > 0
                ? response.data.data.snaps
                : [];
            this.rearrangeSnap();
          }
        });
    },

    rearrangeSnap() {
      const updatedKeySnaps_name = this.snaps.map(
        ({ Name: name, ...rest }) => ({
          name,
          ...rest,
        })
      );
      const updatedKeySnaps = updatedKeySnaps_name.map((el) => {
        var o = Object.assign({}, el);
        o.id = el.Id;
        return o;
      });
      let group = updatedKeySnaps.reduce((r, a) => {
        r[a.Namespace] = [...(r[a.Namespace] || []), a];
        return r;
      }, {});
      let result = [];
      Object.keys(group).forEach((key) => {
        let tempObj = this.createProperty(result, key);
        if (!tempObj.children) {
          tempObj.children = group[key];
        } else {
          tempObj.children = [...tempObj.children, ...group[key]];
        }
      });
      result[0].children.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      this.items = result;
      this.loading = false;
    },

    createProperty(result2, path) {
      const propArray = path.split(".");
      let tempObj;
      for (let i = 0; i < propArray.length; i++) {
        const prop = propArray[i];
        let tempRef = tempObj ? tempObj.children : result2;
        const index = tempRef.findIndex((obj) => obj.name === prop);
        let obj;
        if (index === -1) {
          obj = {
            id: Math.floor(Math.random() * 1000) + 1,
            name: prop,
            children: [],
          };
          tempRef.push(obj);
        } else {
          obj = tempRef[index];
        }
        tempObj = obj;
      }
      return tempObj;
    },
  },
  mounted() {
    this.getSnaps();
  },
};
</script>

<style scoped>
.full-height {
  min-height: 100vh;
}

h1 {
  text-align: center;
  margin: 10px;
}
.v-text-field .v-label {
  overflow: visible !important;
  top: 1% !important;
}
.label {
  color: #c7c7c7 !important;
}
.search-field {
  max-width: 50%;
}
.item {
  cursor: pointer;
}

@media (max-width: 991px) {
  .main-div {
    margin-left: auto !important;

    .main-content {
      margin: 0 !important;
      padding: 5px !important;
    }
  }
}
</style>
