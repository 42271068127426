<template>
  <div class="full-height pa-5" style="margin-left: 56px">
    <div v-if="this.loading">
      <loader />
    </div>

    <v-snackbar v-model="snackbar" :timeout="snackBarTimeout" :color="color">{{
      snackBarText
    }}</v-snackbar>

    <div class="mx-auto rounded-0 full-height">
      <v-tabs-items v-model="tab">
        <v-sheet class="pa-4" color="grey darken-3" data-app>
          <div class="d-flex align-center">
            <v-text-field
              v-model="companySearch"
              label="Search property..."
              flat
              solo-inverted
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
            ></v-text-field>
          </div>
        </v-sheet>

        <div>
          <!-- {{ selectedLeadItems }} -->
          <v-data-table
            :headers="companiesHeaders"
            :items="CompaniesData"
            :items-per-page="limit"
            item-key="id"
            :search="companySearch"
            hide-default-footer
            class="elevation-1"
            show-select
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <v-checkbox
                    :value="isSelectedCompany(item)"
                    @change="toggleCompanySelection(item)"
                    class="ma-0"
                    hide-details
                  ></v-checkbox>
                </td>
                <td>{{ item.FLIPGrantor1NameFull }}</td>
                <td>{{ item.AverageTransferAmount }}</td>
                <td>{{ item.NumberOfFlips }}</td>
                <td>
                  {{ item.LatestAddressInfo.FLIPGRANTORMailAddressState }}
                </td>
                <td>{{ item.LatestAddressInfo.FLIPGRANTORMailAddressZIP }}</td>
                <td>{{ item.LatestAddressInfo.FLIPGRANTORMailAddressCity }}</td>
                <td>{{ item.Score }}</td>
                <td>
                  <router-link
                    :to="`/data/attom_contacts/detail/${item.FLIPGrantor1NameFull}`"
                  >
                    <v-btn x-small outlined color="green accent-2">
                      View Details
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="5"
            @input="fetchCompaniesData()"
          ></v-pagination>
        </div>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../../components/Loader.vue";

export default {
  name: "crm",
  components: {
    Loader,
  },
  data() {
    return {
      loading: true,
      limit: 100,
      page: 1,
      selected: [],
      shapeSearch: "",
      shapesData: null,
      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,
      tab: "",
      totalShapeRecords: 0,
      totalCompanyRecords: 0,
      pageCount: 0,

      selectedItems: [],
      selectedCompanyItems: [],

      crm: [],
      companiesHeaders: [
        {
          text: "FLIPGrantor1NameFull",
          align: "start",
          width: "100",
          value: "FLIPGrantor1NameFull",
        },
        {
          text: "AverageTransferAmount",
          align: "start",
          width: "100",
          value: "AverageTransferAmount",
        },
        {
          text: "NumberOfFlips",
          align: "start",
          width: "100",
          value: "NumberOfFlips",
        },
        {
          text: "State",
          align: "start",
          width: "100",
          value: "LatestAddressInfo.FLIPGRANTORMailAddressState",
        },
        {
          text: "Zip",
          align: "start",
          width: "100",
          value: "LatestAddressInfo.FLIPGRANTORMailAddressZIP",
        },
        {
          text: "City",
          align: "start",
          width: "100",
          value: "LatestAddressInfo.FLIPGRANTORMailAddressCity",
        },
        {
          text: "Score",
          align: "start",
          width: "100",
          value: "Score",
        },
        {
          text: "Actions",
          align: "start",
          width: "100",
          value: "actions",
        },
      ],
      CompaniesData: [],
      companySearch: "",
    };
  },

  mounted() {
    this.fetchCompaniesData();
  },
  methods: {
    isSelected(item) {
      return this.selectedItems.some(
        (selectedItem) => selectedItem.id === item.id
      );
    },
    toggleSelection(item) {
      const index = this.selectedItems.findIndex(
        (selectedItem) => selectedItem.id === item.id
      );
      if (index === -1) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems.splice(index, 1);
      }
    },
    isSelectedCompany(item) {
      return this.selectedCompanyItems.some(
        (selectedCompanyItems) => selectedCompanyItems.name === item.name
      );
    },
    toggleCompanySelection(item) {
      const index = this.selectedCompanyItems.findIndex(
        (selectedCompanyItems) => selectedCompanyItems.name === item.name
      );
      if (index === -1) {
        this.selectedCompanyItems.push(item);
      } else {
        this.selectedCompanyItems.splice(index, 1);
      }
    },
    async fetchCompaniesData() {
      this.loading = true;

      try {
        const key = "21b77cec7cb516c40d9d7216ae74e3c3";
        console.log("pagee--", this.page);
        console.log("limit--", this.limit);

        await axios
          .get(
            `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/attom_contacts?page=${this.page}&limit=${this.limit}&key=${key}`
            // `http://localhost:3000/attom_contacts?page=${this.page}&limit=${this.limit}&key=${key}`
          )
          .then((response) => {
            this.CompaniesData = response.data.companiesData;
            this.loading = false;
            this.totalCompanyRecords = response.data.TotalRecords;
            this.pageCount = Math.ceil(this.totalCompanyRecords / this.limit);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        this.loading = false;

        console.log("Error in fetching Shapes data:\n" + error);
      }
    },
  },
};
</script>

<style scoped>
.full-height {
  min-height: 100vh;
}
.v-tab {
  text-transform: none !important;
}

a {
  text-decoration: none;
}
#input-561 {
  color: #ffffff;
}
</style>
